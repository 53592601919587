/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { SimpleModal } from "../widgets/modals/SimpleModal";
import {
    IconButton,
    Snackbar,
    Button,
    Grid,
    Alert,
    Badge
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BasicLoader from "../loaders/SimpleLoader";
import { makeStyles } from '@mui/styles';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import {
    designateDriverAccountAsStaffAccount,
    designateDriverAccountAsTestAccount,
    removeTestAccountDesignation,
    removeStaffAccountDesignation
} from "../../../app/modules/Auth/_redux/authCrud";

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
}));

export function DropdownMenu4({ driverData, onchangeFunction, isChanger }) {

    const TableMessage = useRef();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [loadingAlt, setLoadingAlt] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [confirmationModalTitle, setConfirmationModalTitle] = useState("");
    const [modalDesignationMessage, setModalDesignationMessage] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [designation, setDesignation] = useState("");
    const [shift, setShift] = useState(false);

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    }

    function noBackModalRouter() {
        setShowConfirmationModal(false);
    }

    const ConfirmationModalComponent = ({ noBackModalRouter, designationMessage, driverDetails }) => {

        return (

            <>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openSnackbar}
                    autoHideDuration={8000}
                    onClose={handleSnackbarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    action={[
                        <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                            UNDO
                        </Button>,
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={handleSnackbarClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        severity={severity}
                        onClose={handleSnackbarClose}
                    >
                        <span id="message-id">
                            {TableMessage.current}
                        </span>
                    </Alert>
                </Snackbar>
                <div className="card mb-2">
                    <div className="card-body">
                        <Container>
                            <Row className="mb-10">
                                <Col xs={12} md={6} className="border-1">
                                    <div className="mt-1 centerMan">
                                        {driverDetails.document?.isStaffAccount ?
                                            <>
                                                <Badge className={classes.margin} variant="dot" color="success">
                                                    <VerifiedTwoToneIcon
                                                        color="success"
                                                    />
                                                </Badge>
                                                <span className="text-success">
                                                    Designated as Staff Account
                                                </span>
                                                <div className="centerMan">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="error"
                                                        disabled={loading ? true : false}
                                                        className={`font-weight-bold px-9 py-4 my-3`}
                                                        onClick={async () => {

                                                            setLoadingAlt(true);

                                                            await removeStaffAccountDesignation(driverDetails.document.ID).then((response) => {

                                                                setSeverity("success");
                                                                TableMessage.current = response.data.message;

                                                                setLoadingAlt(false);
                                                                setOpenSnackbar(true);

                                                                onchangeFunction();
                                                                isChanger();

                                                                setTimeout(() => {

                                                                    setOpenSnackbar(false);
                                                                    noBackModalRouter();

                                                                }, 2000);

                                                            }).catch((error) => {

                                                                setSeverity("error");
                                                                TableMessage.current = error.response.data.message ?? "could not perform action, please try again";

                                                                setLoadingAlt(false);
                                                                setOpenSnackbar(true);

                                                            });

                                                        }}
                                                        startIcon={<CloseTwoToneIcon />}
                                                    >
                                                        Remove Staff Account Designation
                                                        {
                                                            loadingAlt && <BasicLoader size={"sm"} />
                                                        }
                                                    </Button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <Badge className={classes.margin} variant="dot" color="error">
                                                    <CloseTwoToneIcon
                                                        color="error"
                                                    />
                                                </Badge>
                                                <span className="text-danger">
                                                    Not designated as Staff Account
                                                </span>
                                            </>
                                        }
                                    </div>
                                </Col>
                                <Col xs={12} md={6} className="border-1">
                                    <div className="mt-1 centerMan">
                                        {driverDetails.document?.isTestAccount ?
                                            <>
                                                <Badge className={classes.margin} variant="dot" color="success">
                                                    <VerifiedTwoToneIcon
                                                        color="success"
                                                    />
                                                </Badge>
                                                <span className="text-success">
                                                    Designated as Test Account
                                                </span>
                                                <div className="centerMan">
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="error"
                                                        disabled={loading ? true : false}
                                                        className={`font-weight-bold px-9 py-4 my-3`}
                                                        onClick={async () => {

                                                            setLoadingAlt(true);

                                                            await removeTestAccountDesignation(driverDetails.document.ID).then((response) => {

                                                                setSeverity("success");
                                                                TableMessage.current = response.data.message;

                                                                setLoadingAlt(false);
                                                                setOpenSnackbar(true);

                                                                onchangeFunction();
                                                                isChanger();

                                                                setTimeout(() => {

                                                                    setOpenSnackbar(false);
                                                                    noBackModalRouter();

                                                                }, 2000);

                                                            }).catch((error) => {

                                                                setSeverity("error");
                                                                TableMessage.current = error.response.data.message ?? "could not perform action, please try again";

                                                                setLoadingAlt(false);
                                                                setOpenSnackbar(true);

                                                            });

                                                        }}
                                                        startIcon={<CloseTwoToneIcon />}
                                                    >
                                                        Remove Test Account Designation
                                                        {
                                                            loadingAlt && <BasicLoader size={"sm"} />
                                                        }
                                                    </Button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <Badge className={classes.margin} variant="dot" color="error">
                                                    <CloseTwoToneIcon
                                                        color="error"
                                                    />
                                                </Badge>
                                                <span className="text-danger">
                                                    Not designated as Test Account
                                                </span>
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className="card mb-2">
                    <div className="card-body">
                        <div className="mt-1 mb-5">
                            <div className="centerMan">
                                <h6>
                                    {designationMessage}
                                </h6>
                            </div>
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="success"
                                        onClick={async () => {

                                            setLoading(true);

                                            designation === "test" && shift === true && await designateDriverAccountAsTestAccount(driverDetails.document.ID).then((response) => {

                                                setSeverity("success");
                                                TableMessage.current = response.data.message;

                                                setLoading(false);
                                                setOpenSnackbar(true);

                                                onchangeFunction();
                                                isChanger();

                                                setTimeout(() => {

                                                    setOpenSnackbar(false);
                                                    noBackModalRouter();

                                                }, 2000);

                                            }).catch((error) => {

                                                setSeverity("error");
                                                TableMessage.current = error.response.data.message ?? "could not perform action, please try again";

                                                setLoading(false);
                                                setOpenSnackbar(true);

                                            });

                                            designation === "staff" && shift === true && await designateDriverAccountAsStaffAccount(driverDetails.document.ID).then((response) => {

                                                setSeverity("success");
                                                TableMessage.current = response.data.message;

                                                setLoading(false);
                                                setOpenSnackbar(true);

                                                onchangeFunction();
                                                isChanger();

                                                setTimeout(() => {

                                                    setOpenSnackbar(false);
                                                    noBackModalRouter();

                                                }, 2000);

                                            }).catch((error) => {

                                                setSeverity("error");
                                                TableMessage.current = error.response.data.message ?? "could not perform action, please try again";

                                                setLoading(false);
                                                setOpenSnackbar(true);

                                            });

                                        }}
                                        disabled={loading ? true : false}
                                    >
                                        Yes &nbsp;
                                        {
                                            loading && <BasicLoader size={"sm"} />
                                        }
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="error"
                                        aria-label="Add"
                                        onClick={() => {
                                            noBackModalRouter();
                                        }}
                                        disabled={loading ? true : false}
                                    >
                                        No
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </div>

            </>

        );

    };

    return (

        <>

            <SimpleModal
                modal_tittle={confirmationModalTitle}
                close={() => setShowConfirmationModal(false)}
                size="lg"
                show={showConfirmationModal}
                body={<ConfirmationModalComponent
                    noBackModalRouter={noBackModalRouter}
                    designationMessage={modalDesignationMessage}
                    driverDetails={driverData}
                />}
            />
            <ul className="navi navi-hover py-5">
                <li className="navi-item">
                    <a
                        href="#"
                        className="navi-link"
                        onClick={() => {

                            setModalDesignationMessage(`Are you sure you want to designate this driver's account (${driverData.document.firstName} ${driverData.document.lastName}) as a staff account?`);
                            setConfirmationModalTitle("Confirm Staff Account Designation");

                            setShift(true);
                            setDesignation("staff");

                            setShowConfirmationModal(true);

                        }}
                    >
                        <span className="navi-icon">
                            <i className="flaticon2-avatar"></i>
                        </span>
                        <span className="navi-text">Designate as Staff Account</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a
                        href="#"
                        className="navi-link"
                        onClick={() => {

                            setModalDesignationMessage(`Are you sure you want to designate this driver's account (${driverData.document.firstName} ${driverData.document.lastName}) as a test account?`);
                            setConfirmationModalTitle("Confirm Test Account Designation");

                            setShift(true);
                            setDesignation("test");

                            setShowConfirmationModal(true);

                        }}
                    >
                        <span className="navi-icon">
                            <i className="flaticon-rotate"></i>
                        </span>
                        <span className="navi-text">Designate as Test Account</span>
                    </a>
                </li>
                {/* <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-rocket-1"></i>
                        </span>
                        <span className="navi-text">Groups</span>
                        <span className="navi-link-badge">
                            <span className="label label-light-primary label-inline font-weight-bold">new</span>
                        </span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-bell-2"></i>
                        </span>
                        <span className="navi-text">Calls</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-gear"></i>
                        </span>
                        <span className="navi-text">Settings</span>
                    </a>
                </li> */}

                {/* <li className="navi-separator my-3"></li> */}

                {/* <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-magnifier-tool"></i>
                        </span>
                        <span className="navi-text">Help</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon">
                            <i className="flaticon2-bell-2"></i>
                        </span>
                        <span className="navi-text">Privacy</span>
                        <span className="navi-link-badge">
                            <span className="label label-light-danger label-rounded font-weight-bold">5</span>
                        </span>
                    </a>
                </li> */}
            </ul>

        </>

    );

}
