import { CardContent, Typography } from "@mui/material";
import React from "react";
import { Card } from "react-bootstrap";


export function CardTileWidget({ title, number, percentage }) {
    return (
        <Card variant='outlined' alignitems={'center'} justifyitems={"center"} height={'100px'} >
            <CardContent>
                <Typography variant="p" align="center" component="div" gutterBottom textTransform='uppercase'>
                    {title}
                </Typography>
                <Typography variant="h5" align="center" component="div" gutterBottom>
                    {number}
                </Typography>
                <Typography variant="p" align="center" component="div">
                    5%
                </Typography>
            </CardContent>
        </Card>
    );
}