import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { addServiceCenter, updateServiceCenter } from "../../../../app/modules/Auth/_redux/authCrud";
import { Button, Form, FormGroup } from "react-bootstrap";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { FormAlert } from "../../controls";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function AddUpdateServiceCenterForm({ initialValues, onChanger, editState, createEditText, fieldDisabled, serviceCenterId }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [mapValue, setMapValue] = useState(null);
    const [coordinate, setCoordinates] = useState();


    const creationSchema = Yup.object().shape({
        serviceDescription: Yup.string().required(),
        locationLatitude: Yup.number(),
        locationLongitude: Yup.number(),
        name: Yup.string().required(),
        locationName: Yup.string(),
        phoneNumber: Yup.string().required(),
        email: Yup.string().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    let lat = 0;
    let lng = 0;

    const handleSelect = async (value) => {

        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        if (latLng)
            setCoordinates(latLng);
        lat = latLng.lat;
        lng = latLng.lng;

        console.log(lat, lng);

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            enableLoading();
            setTimeout(
                () => {

                    if (!editState) {

                        handleSelect(mapValue.label).then(() => {

                            setTimeout(() => {

                                values.locationLatitude = lat;
                                values.locationLongitude = lng;
                                values.locationName = mapValue.label;

                                addServiceCenter(values).then((response) => {
                                    disableLoading();
                                    setSubmitting(false);

                                    if (response) {

                                        setFormAlertEnabled(true);

                                        setAlertType({
                                            type: 'success',
                                            icon: <CheckCircleTwoToneIcon />
                                        });
                                        onChanger();
                                        return setStatus(response.data.message);
                                    }
                                }).catch((error) => {
                                    setFormAlertEnabled(true);

                                    setAlertType({
                                        type: 'error',
                                        icon: <WarningTwoToneIcon />
                                    });

                                    onChanger();
                                    return setStatus(
                                        error.response.data?.error?.code
                                        ??
                                        error.response.data.message
                                        ??
                                        'an error occurred, please try again later'
                                    );
                                }).finally(() => {
                                    disableLoading();
                                    setSubmitting(false);

                                    return;
                                });
                                return;

                            }, 1000);
                        });
                    }
                    if (editState === true) {

                        const editPayload = {
                            serviceDescription: values.serviceDescription,
                            locationLatitude: values.locationLatitude,
                            locationLongitude: values.locationLongitude,
                            name: values.name,
                            locationName: values.locationName,
                            phoneNumber: values.phoneNumber,
                            email: values.email
                        };

                        updateServiceCenter(editPayload, serviceCenterId).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: <CheckCircleTwoToneIcon />
                                });
                                onChanger();
                                return setStatus(response.data.message);
                            }

                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'error',
                                icon: <WarningTwoToneIcon />
                            });

                            onChanger();
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );
                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);
                            return;
                        });
                        return;
                    }
                }, 3000
            );



        }
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>

                <FormGroup id="serviceCenterName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="John Doe" value={formik.values.name} onChange={formik.handleChange} className={`${getInputClasses("name")}`} name="name" {...formik.getFieldProps("name")} />
                    <Form.Text className="text-muted">
                        Enter Service Center Name
                    </Form.Text>
                    <Form.Control.Feedback>Service center name looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Service center name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.name}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup id="serviceCenterDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" placeholder="Level 2 repairer..." value={formik.values.serviceDescription} onChange={formik.handleChange} className={`${getInputClasses("serviceDescription")}`} name="serviceDescription" {...formik.getFieldProps("serviceDescription")} />
                    <Form.Text className="text-muted">
                        Enter a short description
                    </Form.Text>
                    <Form.Control.Feedback>Service description looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Service description cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.serviceDescription && formik.errors.serviceDescription ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.serviceDescription}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup id="serviceCenterPhoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder="+233" value={formik.values.phoneNumber} onChange={formik.handleChange} className={`${getInputClasses("phoneNumber")}`} name="phoneNumber" {...formik.getFieldProps("phoneNumber")} />
                    <Form.Text className="text-muted">
                        Add country code when inputting phone number
                    </Form.Text>
                    <Form.Control.Feedback>Your phone number looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Phone number cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.phoneNumber}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup id="serviceCenterEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="example@mail.com" value={formik.values.email} onChange={formik.handleChange} className={`${getInputClasses("email")}`} name="email" {...formik.getFieldProps("email")} disabled={fieldDisabled} />
                    <Form.Text className="text-muted">
                        Provide email address
                    </Form.Text>
                    <Form.Control.Feedback>Provided email looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email address cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <Form.Group controlId="serviceCenterLocation">
                    <Form.Label>Location</Form.Label>
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyDYPQI1VL7rgGsE1V8yzA9g2dp14O3mnOw"
                        selectProps={{
                            mapValue,
                            onChange: setMapValue, handleSelect,
                            onselect: handleSelect
                        }}
                    />
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form>
        </>
    );

}
