/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import DirectionsBikeTwoToneIcon from '@mui/icons-material/DirectionsBikeTwoTone';
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SatelliteAltTwoToneIcon from '@mui/icons-material/SatelliteAltTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import ChecklistTwoToneIcon from '@mui/icons-material/ChecklistTwoTone';
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone';
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import GroupRemoveTwoToneIcon from '@mui/icons-material/GroupRemoveTwoTone';
import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import BikeScooterTwoToneIcon from '@mui/icons-material/BikeScooterTwoTone';
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';
import PauseCircleFilledTwoToneIcon from '@mui/icons-material/PauseCircleFilledTwoTone';
import AlarmOnTwoToneIcon from '@mui/icons-material/AlarmOnTwoTone';
import NearbyErrorTwoToneIcon from '@mui/icons-material/NearbyErrorTwoTone';
import PriceCheckTwoToneIcon from '@mui/icons-material/PriceCheckTwoTone';
import AirTwoToneIcon from '@mui/icons-material/AirTwoTone';
import PedalBikeTwoToneIcon from '@mui/icons-material/PedalBikeTwoTone';
import Co2TwoToneIcon from '@mui/icons-material/Co2TwoTone';
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone';
import GroupWorkTwoToneIcon from '@mui/icons-material/GroupWorkTwoTone';
import WorkspacesTwoToneIcon from '@mui/icons-material/WorkspacesTwoTone';
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {

    const loggedInUser = useSelector(({ auth }) => auth.user, shallowEqual);
    const userRole = loggedInUser.user_data.document.role;

    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
    };

    return (
        <>

            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li
                    className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                        </span>
                        <span className="menu-text">Home</span>
                    </NavLink>
                </li>

                {userRole.includes("admin") && <>
                    <li className="menu-section">
                        <h4 className="menu-text">Fleet Management</h4>
                        <i className="menu-icon flaticon-more-v2"></i>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/vehicles",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/vehicles">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Vehicles-Icon.svg")} />
                            </span>
                            <span className="menu-text">Vehicles</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Vehicle Management</span>
                                    </span>
                                </li>
                                <li className="menu-section">
                                    <h4 className="menu-text">Vehicle Management</h4>
                                    <i className="menu-icon flaticon-more-v2"></i>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/all-bikes", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/all-bikes">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Electric/Electric-bike.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">All Bikes</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                                        "/vehicles/ghana",
                                        true
                                    )}`}
                                    aria-haspopup="true"
                                    data-menu-toggle="hover"
                                >
                                    <NavLink className="menu-link menu-toggle" to="/vehicles/ghana">
                                        <span className="svg-icon menu-icon">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Countries/Ghana.svg")} />
                                        </span>
                                        <span className="menu-text">Ghana Bikes</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <i className="menu-arrow" />
                                        <ul className="menu-subnav">
                                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                                <span className="menu-link">
                                                    <span className="menu-text">Ghana Bikes</span>
                                                </span>
                                            </li>
                                            <li
                                                className={`menu-item ${getMenuItemActive("/vehicles/ghana", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/vehicles/ghana">
                                                    <span className="svg-icon menu-icon">
                                                        <PedalBikeTwoToneIcon />
                                                    </span>
                                                    <span className="menu-text">All Bikes</span>
                                                </NavLink>
                                            </li>
                                            <li
                                                className={`menu-item ${getMenuItemActive("/vehicles/carbon-bikes-ghana", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/vehicles/carbon-bikes-ghana">
                                                    <span className="svg-icon menu-icon">
                                                        <Co2TwoToneIcon />
                                                    </span>
                                                    <span className="menu-text">Carbon Relevant Bikes</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/togo", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/togo">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Countries/Togo.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Togo Bikes</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/zambia", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/zambia">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Countries/Zambia.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Zambia Bikes</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/untapped", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/untapped">
                                        <span className="svg-icon menu-icon">
                                            <ChangeHistoryTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Untapped</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/in-repair", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/in-repair">
                                        <span className="svg-icon menu-icon">
                                            <BikeScooterTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Bikes in Repair</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/broken", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/broken">
                                        <span className="svg-icon menu-icon">
                                            <NearbyErrorTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Broken Vehicles</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/ready", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/ready">
                                        <span className="svg-icon menu-icon">
                                            <AlarmOnTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Ready Bikes</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/staff", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/staff">
                                        <span className="svg-icon menu-icon">
                                            <ManageAccountsTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Staff Bikes</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/customer", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/customer">
                                        <span className="svg-icon menu-icon">
                                            <PeopleAltTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Customer Bikes</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                                        "vehicles/cohorts",
                                        true
                                    )}`}
                                    aria-haspopup="true"
                                    data-menu-toggle="hover"
                                >
                                    <NavLink className="menu-link menu-toggle" to="vehicles/cohorts">
                                        <span className="svg-icon menu-icon">
                                            <GroupWorkTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Vehicle Cohorts</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <i className="menu-arrow" />
                                        <ul className="menu-subnav">
                                            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                                <span className="menu-link">
                                                    <span className="menu-text">Vehicle Cohorts</span>
                                                </span>
                                            </li>
                                            <li
                                                className={`menu-item ${getMenuItemActive("/vehicles/cohorts", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/vehicles/cohorts">
                                                    <span className="svg-icon menu-icon">
                                                        <WorkspacesTwoToneIcon />
                                                    </span>
                                                    <span className="menu-text">Deployed Cohorts</span>
                                                </NavLink>
                                            </li>
                                            {/* <li
                                            className={`menu-item ${getMenuItemActive("/vehicles/carbon-bikes", false)}`}
                                            aria-haspopup="true"
                                        >
                                            <NavLink className="menu-link" to="/vehicles/carbon-bikes">
                                                <span className="svg-icon menu-icon">
                                                    <SVG
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/icons/Electric/Electric-bike.svg"
                                                        )}
                                                    />
                                                </span>
                                                <span className="menu-text">Carbon Cohorts</span>
                                            </NavLink>
                                        </li> */}
                                        </ul>
                                    </div>
                                </li>
                                <li className="menu-section">
                                    <h4 className="menu-text">Vehicle Management</h4>
                                    <i className="menu-icon flaticon-more-v2"></i>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/models", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/models">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Bike-model.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Vehicle Models</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/types", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/types">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Vehicle-type.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Vehicle Types</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/manufacturers", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/manufacturers">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Vehicle-manufacturer.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Manufacturers</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/battery-configuration", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/battery-configuration">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Battery-configuration.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Battery Configuration</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vehicles/motor-configuration", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vehicles/motor-configuration">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Motor-configuration.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Motor Configuration</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/drivers",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/drivers">
                            <span className="svg-icon menu-icon">
                                <SportsMotorsportsTwoToneIcon />
                            </span>
                            <span className="menu-text">Riders</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Riders</span>
                                    </span>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/all", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/all">
                                        <span className="svg-icon menu-icon">
                                            <DirectionsBikeTwoToneIcon />
                                        </span>
                                        <span className="menu-text">All Riders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                                        "/drivers/in-pipeline",
                                        true
                                    )}`}
                                    aria-haspopup="true"
                                    data-menu-toggle="hover"
                                >
                                    <NavLink className="menu-link menu-toggle" to="/drivers/in-pipeline">
                                        <span className="svg-icon menu-icon">
                                            <AirTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Pipeline</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <i className="menu-arrow" />
                                        <ul className="menu-subnav">
                                            <li
                                                className={`menu-item ${getMenuItemActive("/drivers/in-pipeline", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/drivers/in-pipeline">
                                                    <span className="svg-icon menu-icon">
                                                        <GroupAddRoundedIcon />
                                                    </span>
                                                    <span className="menu-text">All Accounts</span>
                                                </NavLink>
                                            </li>
                                            <li
                                                className={`menu-item ${getMenuItemActive("/drivers/activations", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/drivers/activations">
                                                    <span className="svg-icon menu-icon">
                                                        <PriceCheckTwoToneIcon />
                                                    </span>
                                                    <span className="menu-text">Activated</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/active", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/active">
                                        <span className="svg-icon menu-icon">
                                            <HowToRegTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Active Riders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/paused", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/paused">
                                        <span className="svg-icon menu-icon">
                                            <PauseCircleFilledTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Paused Subscriptions</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/dvla", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/dvla">
                                        <span className="svg-icon menu-icon">
                                            <DetailsTwoToneIcon />
                                        </span>
                                        <span className="menu-text">DVLA Demo</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/churned", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/churned">
                                        <span className="svg-icon menu-icon">
                                            <GroupRemoveTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Churned Riders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/ghana", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/ghana">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Countries/Ghana.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Ghana Riders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/togo", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/togo">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Countries/Togo.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Togo Riders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/zambia", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/zambia">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Countries/Zambia.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Zambia Riders</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/drivers/untapped", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/drivers/untapped">
                                        <span className="svg-icon menu-icon">
                                            <ChangeHistoryTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Untapped Riders</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/driver-cohorts", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/driver-cohorts">
                            <span className="svg-icon menu-icon">
                                <PeopleAltTwoToneIcon />
                            </span>
                            <span className="menu-text">Rider Cohorts</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/subscription-plans", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/subscription-plans">
                            <span className="svg-icon menu-icon">
                                <PublishedWithChangesIcon />
                            </span>
                            <span className="menu-text">Subscription Plans</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/trackers", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/trackers">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Tracking.svg"
                                    )}
                                />
                            </span>
                            <span className="menu-text">Trackers</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/tracking", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/tracking">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/TrackingMap.svg"
                                    )}
                                />
                            </span>
                            <span className="menu-text">Tracking</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/service-centers",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/fault">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/service-settings.svg")} />
                            </span>
                            <span className="menu-text">Manage Service Centers</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                {/* <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Fault Reports</span>
                                </span>
                            </li> */}
                                <li
                                    className={`menu-item ${getMenuItemActive("/service-centers", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/service-centers">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/services.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">All Service Centers</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/serviceCenters", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/serviceCenters">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/serviceCenter.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Service Centers</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/fault",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/fault">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/management.svg")} />
                            </span>
                            <span className="menu-text">Fault Management</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                {/* <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Fault Reports</span>
                                </span>
                            </li> */}
                                <li
                                    className={`menu-item ${getMenuItemActive("/fault", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/fault">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Report.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Fault Reports</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/fault-config",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/fault-config">
                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Danger.svg")} />
                            </span>
                            <span className="menu-text">Fault Setting</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                {/* <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Fault Reports</span>
                                </span>
                            </li> */}
                                <li
                                    className={`menu-item ${getMenuItemActive("/faultconfig", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/faultconfig">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Tools/Tools.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Fault Configuration</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/faultCategories", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/faultCategories">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Categories.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Fault Categories</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/faultSubcategories", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/faultSubcategories">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Subgroup.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Fault Subcategories</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/labourUnits", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/labourUnits">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/token.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Labour Units</span>
                                    </NavLink>
                                </li>

                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/maintenance", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/maintenance">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Maintenance.svg"
                                    )}
                                />
                            </span>
                            <span className="menu-text">Maintenance</span>
                        </NavLink>
                    </li>

                    <li className="menu-section">
                        <h4 className="menu-text">User Management</h4>
                        <i className="menu-icon flaticon-more-v2"></i>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/admins", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/admins">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Users-cog.svg"
                                    )}
                                />
                            </span>
                            <span className="menu-text">Admins</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/vendors",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/vendors">
                            <span className="svg-icon menu-icon">
                                <HandshakeTwoToneIcon />
                            </span>
                            <span className="menu-text">Vendors</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Vendors</span>
                                    </span>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vendor-accounts", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vendor-accounts">
                                        <span className="svg-icon menu-icon">
                                            <ManageAccountsTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Accounts</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/vendor-requests", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/vendor-requests">
                                        <span className="svg-icon menu-icon">
                                            <PendingActionsTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Requests</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/customers", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/customers">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Users-viewfinder.svg"
                                    )}
                                />
                            </span>
                            <span className="menu-text">Customers</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/organizations", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/organizations">
                            <span className="svg-icon menu-icon">
                                <CorporateFareTwoToneIcon />
                            </span>
                            <span className="menu-text">Organizations</span>
                        </NavLink>
                    </li>

                    <li className="menu-section">
                        <h4 className="menu-text">Operations</h4>
                        <i className="menu-icon flaticon-more-v2"></i>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/careers", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/careers">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Briefcase-account.svg"
                                    )}
                                />
                            </span>
                            <span className="menu-text">Careers</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive("/communications", true)}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/communications">
                            <span className="svg-icon menu-icon">
                                <SatelliteAltTwoToneIcon />
                            </span>
                            <span className="menu-text">Communications</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Communications</span>
                                    </span>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/communications/bulk-messaging", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/communications/bulk-messaging">
                                        <span className="svg-icon menu-icon">
                                            <QuestionAnswerTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Bulk Messaging</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/communications/bike-interests-logs", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/communications/bike-interests-logs">
                                        <span className="svg-icon menu-icon">
                                            <ChecklistTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Bike Requests</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/communications/consultation-requests-logs", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/communications/consultation-requests-logs">
                                        <span className="svg-icon menu-icon">
                                            <AddTaskTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Consultation Requests</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/communications/consultation-requests-logs", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/communications/consultation-requests-logs">
                                        <span className="svg-icon menu-icon">
                                            <HandshakeTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Partnerships</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/communications/consultation-requests-logs", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/communications/consultation-requests-logs">
                                        <span className="svg-icon menu-icon">
                                            <InventoryTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Purchase Requests</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/communications/contact-us-logs", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/communications/contact-us-logs">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/Contact-us.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Contact Us Logs</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className="menu-section">
                        <h4 className="menu-text">Platform</h4>
                        <i className="menu-icon flaticon-more-v2"></i>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/invoices", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/invoices">
                            <span className="svg-icon menu-icon">
                                <ReceiptTwoToneIcon
                                    fontSize="large"
                                />
                            </span>
                            <span className="menu-text">Invoicing</span>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/user-transactions",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/user-transactions">
                            <span className="svg-icon menu-icon">
                                <PaidTwoToneIcon />
                            </span>
                            <span className="menu-text">Transactions</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Transactions</span>
                                    </span>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/parts-management", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/user-transactions">
                                        <span className="svg-icon menu-icon">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/recurring-payment.svg"
                                                )}
                                            />
                                        </span>

                                        <span className="menu-text">Subscription Payments</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/requisitions", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/requisitions">
                                        <span className="svg-icon menu-icon">

                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/General/purchases.svg"
                                                )}
                                            />
                                        </span>
                                        <span className="menu-text">Outright Purchases</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            "/inventory",
                            true
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to="/parts-management">
                            <span className="svg-icon menu-icon">
                                <Inventory2TwoToneIcon
                                    fontSize="large"
                                />
                            </span>
                            <span className="menu-text">Inventory</span>
                            <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                            <i className="menu-arrow" />
                            <ul className="menu-subnav">
                                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                    <span className="menu-link">
                                        <span className="menu-text">Inventory</span>
                                    </span>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/parts-management", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/parts-management">
                                        <span className="svg-icon menu-icon">
                                            <AccountTreeTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Manage Parts</span>
                                    </NavLink>
                                </li>
                                <li
                                    className={`menu-item ${getMenuItemActive("/requisitions", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/requisitions">
                                        <span className="svg-icon menu-icon">
                                            <RequestQuoteTwoToneIcon />
                                        </span>
                                        <span className="menu-text">Requisitions</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/settings", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/settings">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/General/Settings-1.svg"
                                    )}
                                />
                            </span>
                            <span className="menu-text">Settings</span>
                        </NavLink>
                    </li>
                </>}

                {userRole.includes("dvla") && <>
                    <li
                        className={`menu-item ${getMenuItemActive("/drivers/all", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/drivers/all">
                            <span className="svg-icon menu-icon">
                                <DirectionsBikeTwoToneIcon />
                            </span>
                            <span className="menu-text">All Riders</span>
                        </NavLink>
                    </li>
                </>}

                <li className="menu-section">
                    <h4 className="menu-text">Profile</h4>
                    <i className="menu-icon flaticon-more-v2"></i>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/user-profile">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/General/User.svg"
                                )}
                            />
                        </span>
                        <span className="menu-text">Your Profile</span>
                    </NavLink>
                </li>
            </ul>

        </>
    );
}
