import React, { useEffect, useMemo, useRef, useState } from "react";
import { PAYMENT_BASE_URL } from "../../../../app/services/baseService";
import { ExportToCsv } from 'export-to-csv';
import axios from "axios";
import {
    IconButton,
    Snackbar,
    ListItemIcon,
    MenuItem,
    Button,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    Box,
} from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MaterialReactTable } from "material-react-table";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    invoiceContainer: {
        width: 200
    },
}));

export function UserInvoicesTable({ driverID, driverName }) {

    const TableErrorMessage = useRef();
    const classes = useStyles();

    const [userInvoices, setUserInvoices] = useState([]);
    const [invoicesDocumentArray, setInvoicesDocumentArray] = useState([]);

    const [invoicesRowCount, setInvoicesRowCount] = useState(0);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
    const [isRefetchingInvoices, setIsRefetchingInvoices] = useState(false);
    const [isInvoiceFetchError, setIsInvoiceFetchError] = useState(false);

    const [initialDepositInvoice, setInitialDepositInvoice] = useState(null);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });

    const [reRender, setReRender] = useState(0);
    const [severity, setSeverity] = useState('info');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: `Subscription Invoices for ${driverName}`,
        showTitle: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportInvoices = () => {
        csvExporter.generateCsv(invoicesDocumentArray);
    };

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    const handleExportRows = (rows) => {

        csvExporter.generateCsv(rows.map((row) => ({
            ID: row.original.document_id,
            "Due Date": row.original.document.dueDate,
            "Payment Status": row.original.document.paid,
        })));

    };

    function handleSnackbarClose(event, reason) {

        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);

    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchUserInvoices = async () => {

            if (!userInvoices.length) {
                setIsLoadingInvoices(true);
            } else {
                setIsRefetchingInvoices(true);
            }

            const url = new URL(`/invoices/${driverID}/subscription-invoices`, `${PAYMENT_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                const invoicesArrayAlt = [];
                setUserInvoices(data.data);
                setInvoicesRowCount(data.max_count);

                for (let i = 0; i < userInvoices.length; i++) {

                    let element = userInvoices[i];
                    const invoiceArrayDocument = {
                        ID: element.document_id,
                        dueDate: element.document.dueDate,
                        isPaid: element.document.paid,
                        initialDepositInvoice: element?.document?.initialDepositInvoice ?? null,
                    };
                    invoicesArrayAlt.push(invoiceArrayDocument);
                    if (element?.document?.initialDepositInvoice === true) {
                        setInitialDepositInvoice(invoiceArrayDocument);
                    }

                    if ((i + 1) >= userInvoices.length)
                        setInvoicesDocumentArray(invoicesArrayAlt);

                }

            } catch (error) {

                setSeverity("error");
                setIsInvoiceFetchError(true);

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {
                    TableErrorMessage.current = "network error, please check your internet connection";
                    return setOpenSnackbar(true);
                }

                TableErrorMessage.current = error?.response ? `Code: ${error.response.status}, Message: ${error.response.data.message}` : `Code: ${error.code}, Message: ${error.message}`;
                setOpenSnackbar(true);

            } finally {

                setIsLoadingInvoices(false);
                setIsRefetchingInvoices(false);

            }

        };

        isMounted && fetchUserInvoices();

        return () => {

            isMounted = false;
            controller.abort();

            setUserInvoices([]);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender,
    ]);

    const invoiceColumns = useMemo(() => [
        {
            accessorFn: (row) => new Date(row?.document?.dueDate),
            id: 'dueDate',
            enableClickToCopy: true,
            header: 'Due Date',
            filterVariant: 'date-range',
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
            grow: true,
            size: 350,
        },
        {
            accessorFn: (row) => `${row?.document?.paid}`,
            id: "isTransactionComplete",
            header: 'Status',
            Cell: ({ cell }) => {

                let chipColor;
                let chipLabel;
                const status = cell.getValue();
                switch (status) {
                    case "true":
                        chipColor = 'success';
                        chipLabel = "Paid";
                        break;
                    case "false":
                        chipColor = 'error';
                        chipLabel = "Not Paid";
                        break;
                    default:
                        chipColor = 'error';
                        return chipLabel = "Not Paid";
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            fontWeight: "bold"
                        }}
                    >
                        <Chip label={chipLabel} color={chipColor} />
                    </Box >
                );
            },
            grow: true,
        },
        {
            accessorFn: (row) => `${row?.document_id}`,
            id: "invoiceID",
            enableClickToCopy: true,
            header: 'Invoice ID',
            grow: true,
        },
    ],
        [],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <Accordion className="rounded-xl">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    <span className="font-size-lg font-weight-bold">
                        Invoices
                        <br />
                        <small className="text-muted">
                            Click to expand/collapse
                        </small>
                    </span>
                </AccordionSummary>
                <AccordionDetails>

                    {initialDepositInvoice && (
                        <>
                            <div className="bg-light-primary pl-2 pr-2 rounded-lg mb-2">
                                <div className="centerMan bold">
                                    {initialDepositInvoice.isPaid}
                                </div>
                            </div>
                        </>
                    )}

                    {(!isLoadingInvoices && !isRefetchingInvoices) && (userInvoices && userInvoices.length <= 0) && (
                        <>
                            <div className="bg-light-danger pl-2 pr-2 rounded-lg mb-2">
                                <div className="centerMan bold">
                                    no invoices generated because this rider has not yet selected a payment plan, you can correct this on the onboarding tab
                                </div>
                            </div>
                        </>
                    )}
                    <div>
                        <MaterialReactTable
                            columns={invoiceColumns}
                            data={userInvoices}
                            enableRowSelection
                            getRowId={(row) => row.id}
                            initialState={{ showColumnFilters: true }}
                            manualPagination
                            enableStickyHeader
                            enableColumnFilterModes
                            enableColumnOrdering
                            enableGrouping
                            enableColumnPinning
                            enableRowPinning
                            enableRowActions
                            muiToolbarAlertBannerProps={
                                isInvoiceFetchError ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                } : undefined
                            }
                            onPaginationChange={setPagination}
                            rowCount={invoicesRowCount}
                            state={{
                                isLoadingInvoices,
                                pagination,
                                showAlertBanner: isInvoiceFetchError,
                                showProgressBars: isRefetchingInvoices,
                            }}
                            renderRowActionMenuItems={({ closeMenu, row }) => [
                                <MenuItem
                                    key={0}
                                    onClick={() => {

                                        onChangeWorker();
                                        closeMenu();

                                    }}
                                    sx={{ m: 0 }}
                                >
                                    <ListItemIcon>
                                        <VerifiedIcon
                                            color="primary"
                                        />
                                    </ListItemIcon>
                                    Apply Payment
                                </MenuItem>,
                            ]}
                            renderTopToolbarCustomActions={({ table }) => {

                                return (

                                    <>
                                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                                            <Button
                                                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                color="info"
                                                fullWidth
                                            >
                                                Export Selected
                                            </Button>
                                            <Button
                                                onClick={handleExportInvoices}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                color="info"
                                                fullWidth
                                            >
                                                Export Page
                                            </Button>
                                        </div>
                                    </>

                                );

                            }}
                            enableColumnResizing
                            muiSkeletonProps={{
                                animation: 'wave',
                                height: 30,
                            }}
                            muiLinearProgressProps={{
                                color: 'primary',
                            }}
                            enableStickyFooter
                            paginationDisplayMode={"pages"}
                            paginateExpandedRows={true}
                            muiTableContainerProps={{
                                sx: {
                                    maxHeight: '650px',
                                }
                            }}
                            enableBatchRowSelection
                            enableRowNumbers
                            rowNumberDisplayMode={'original'}
                            selectAllMode={"all"}
                            options={{
                                selection: true,
                            }}
                            muiSelectCheckboxProps={{
                                color: 'primary',
                            }}
                            muiTableBodyRowProps={({ row, table }) => {
                                const { density } = table.getState();
                                return {
                                    sx: {
                                        //Set a fixed height for pinned rows
                                        height: row.getIsPinned() ?
                                            `${density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69}px`
                                            :
                                            undefined,
                                    },
                                };
                            }}
                            autoResetSelectedRows={false}
                            autoResetPageIndex={false}
                            muiPaginationProps={{
                                rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                                showFirstButton: true,
                                showLastButton: true,
                                color: 'success',
                                shape: 'rounded',
                                showRowsPerPage: true,
                                variant: 'outlined',
                            }}
                            enableBottomToolbar
                            positionPagination="both"
                        />
                    </div>

                </AccordionDetails>
            </Accordion>
        </>
    );


}