import React from 'react';
import { styled } from '@mui/material';
import {
    Box,
    Typography,
    Grid,
    Paper,
} from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function GridList({ dataArray }) {

    return (
        <>
            <div className='mb-3'>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                    {
                        dataArray.map((item, index) => (
                            <Grid item xs={6} key={index}>
                                <Item>
                                    <Box sx={{ textAlign: 'left' }}>
                                        <Typography
                                            variant="label"
                                        >
                                            {`${item.header}: `}
                                        </Typography>
                                        <br />
                                        <Typography
                                            variant="p"
                                            className="bold"
                                        >
                                            {item.field}
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </>
    );

}