import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { addNoteToRequest } from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls';
import { shallowEqual, useSelector } from "react-redux";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function AddNoteToRequestForm({ initialValues, createEditText, onChanger, requestID, requestData, requestType }) {

    const loggedInUser = useSelector(({ auth }) => auth.user, shallowEqual);

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        note: Yup.string().required("no note attached to payload"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                values.adderID = loggedInUser.user_data.document.ID;
                values.adderName = `${loggedInUser.user_data.document.firstName} ${loggedInUser.user_data.document.lastName}`;

                addNoteToRequest(values, requestID, requestType).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(response.data.message);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'error',
                        icon: <WarningTwoToneIcon />
                    });

                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {

                    disableLoading();
                    setSubmitting(false);
                    return onChanger();

                });

            }, 1000);

        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="bikeInterestLogNotesForm">
                    <Form.Label>Note</Form.Label>
                    <Form.Control as="textarea" rows="5" placeholder="Note..." value={formik.values.note} onChange={formik.handleChange} className={`${getInputClasses("note")}`} name="note" {...formik.getFieldProps("note")} />
                    <Form.Text className="text-muted">
                        Add your notes here
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Notes field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.note && formik.errors.note ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.note}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}