import React from 'react';
import { VehiclesTable } from './VehiclesTable';

export const ReadyVehiclesTable = () => {

    return (
        <>
            <VehiclesTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/vehicles/ready-vehicles/getAll"}
                tableTopHeader={"Ready Bikes"}
                tableSubHeader={"Manage Ghana vehicle records from this table"}
            />
        </>
    );

};