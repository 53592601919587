import React from "react";
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    customCardHeight: {
        height: '500px !important',
        overflow: 'scroll',
    }
}));

export function NotificationsWidget({ className }) {

    const classes = useStyles();

    return (
        <>
            <div className={`card card-custom ${className} rounded-xl ${classes.customCardHeight}`}>
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">
                        Notifications &amp; Announcements
                    </h3>

                    <div className="card-toolbar">

                    </div>
                </div>

                <div className="card-body pt-0">
                    <div className="mb-6">
                        <div className="d-flex align-items-center flex-grow-1">
                            <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                                <input type="checkbox" value="1" />
                                <span></span>
                            </label>

                            <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                                <div className="d-flex flex-column py-2 w-75">
                                    <a
                                        href="#0"
                                        className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                                    >
                                        Daily Standup Meeting
                                    </a>

                                    <span className="text-muted font-weight-bold">
                                        <AccessTimeTwoToneIcon />
                                        {" "}
                                        DateTime
                                    </span>
                                </div>

                                <span className="label label-lg label-light-success label-inline font-weight-bold py-4">
                                    Read
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
