import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    Typography,
    Grid,
    Paper,
    styled,
    Chip,
    Alert,
    Select,
    FormControl,
    FormHelperText,
    InputLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { convertUnixTimestampToUTC } from "../../../_helpers";
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import {
    activateVehicle,
    assignTracker,
    assignVehicles,
    assignVehiclesToOrganization,
    deactivateVehicle,
    designateAsCustomerVehicle,
    designateAsStaffVehicle,
    getTrackerByID,
    removeCustomerVehicleDesignation,
    removeStaffVehicleDesignation,
    unassignTrackerFromVehicle,
    unassignVehicle,
    unassignVehicleFromOrganization
} from '../../../../app/modules/Auth/_redux/authCrud';
import {
    FLEET_MANAGING_BASE_URL,
    setStorageItem
} from '../../../../app/services/baseService';
import { CreateEditVehicleForm } from '../forms/CreateEditVehicleForm';
import { SimpleModal } from '../modals/SimpleModal';
import { AdderDetails, OrganizationsTable, SetVehicleInRepairForm, TrackersTable, VehicleDetailsList } from '../';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { removeVehicle } from '../../../../app/modules/Auth/_redux/authCrud';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import TrackChangesTwoToneIcon from '@mui/icons-material/TrackChangesTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import ElectricBikeTwoToneIcon from '@mui/icons-material/ElectricBikeTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import PanToolTwoToneIcon from '@mui/icons-material/PanToolTwoTone';
import ToggleOnTwoToneIcon from '@mui/icons-material/ToggleOnTwoTone';
import ToggleOffTwoToneIcon from '@mui/icons-material/ToggleOffTwoTone';
import { shallowEqual, useSelector } from "react-redux";
import BasicLoader from '../../loaders/SimpleLoader';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import GpsNotFixedTwoToneIcon from '@mui/icons-material/GpsNotFixedTwoTone';
import HandymanTwoToneIcon from '@mui/icons-material/HandymanTwoTone';
import BuildCircleTwoToneIcon from '@mui/icons-material/BuildCircleTwoTone';
import ReplayCircleFilledTwoToneIcon from '@mui/icons-material/ReplayCircleFilledTwoTone';
import { VehicleMetricsPage } from '../../../../app/pages/VehicleMetricsPage';
import AutoGraphTwoToneIcon from '@mui/icons-material/AutoGraphTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `80px`,
        width: `80px`
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 1,
        width: 50,
        height: 50,
    },
    allCaps: {
        textTransform: "uppercase"
    },
    itemInnerDiv: {
        height: '200px',
        overflow: 'auto',
        padding: '5px',
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
}));

export function VehiclesTable({ className, baseFetchUrl, tableTopHeader, tableSubHeader, onChanger, assignmentType, assignAction, selectedDriverIdForVehicleAssignment, organizationIdForVehicleAssignment, trackerIdForVehicleAssignment, cohortView }) {

    const loggedInUser = useSelector(({ auth }) => auth.user, shallowEqual);

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [show, setShow] = useState(false);
    const [showVehicleDetails, setShowVehicleDetails] = useState(false);

    const [initialValues, setInitialValues] = useState({
        vehicleModelID: '',
        VIN: '',
        batteryID: ''
    });

    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Record');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [selectedVehicleModelID, setSelectedVehicleModelID] = useState("");
    const [selectedVehicleData, setSelectedVehicleData] = useState(null);
    const [selectedVehicleID, setSelectedVehicleID] = useState("");
    const [vehicleTelemetryModal, setVehicleTelemetryModal] = useState(false);

    const [isUnassignRunning, setIsUnassignRunning] = useState(false);

    const [toggleTrackerUnassignmentConfirmationModal, setToggleTrackerUnassignmentConfirmationModal] = useState(false);
    const [toggleOrganizationUnassignmentConfirmationModal, setToggleOrganizationUnassignmentConfirmationModal] = useState(false);
    const [toggleOrganizationAssignmentConfirmationModal, setToggleOrganizationAssignmentConfirmationModal] = useState(false);

    const [toggleDriverUnassignmentConfirmationModal, setToggleDriverUnassignmentConfirmationModal] = useState(false);
    const [selectedDriverID, setSelectedDriverID] = useState("");
    const [selectedVehicleForUnassignmentID, setSelectedVehicleForUnassignmentID] = useState("");

    const [trackerIMEIToUnassign, setTrackerIMEIToUnassign] = useState("");
    const [trackerManufacturerToUnassign, setTrackerManufacturerToUnassign] = useState("");
    const [trackerIDToUnassign, setTrackerIDToUnassign] = useState("");
    const [trackerAssignerIDToUnassign, setTrackerAssignerIDToUnassign] = useState("");
    const [trackerVehicleIdToUnassign, setTrackerVehicleIdToUnassign] = useState("");
    const [trackerVehicleVinToUnassign, setTrackerVehicleVinToUnassign] = useState("");

    const [organizationIDToUnassign, setOrganizationIDToUnassign] = useState("");
    const [organizationNameToUnassign, setOrganizationNameToUnassign] = useState("");
    const [organizationAssignerIDToUnassign, setOrganizationAssignerIDToUnassign] = useState("");
    const [organizationVehicleIdToUnassign, setOrganizationVehicleIdToUnassign] = useState("");
    const [organizationVehicleVinToUnassign, setOrganizationVehicleVinToUnassign] = useState("");

    const [isLoadingTrackerUnassignment, setIsLoadingTrackerUnassignment] = useState(false);
    const [isLoadingOrganizationUnassignment, setIsLoadingOrganizationUnassignment] = useState(false);
    const [isFetchingTrackerData, setIsFetchingTrackerData] = useState(false);
    const [selectedVehicleIDForOrganization, setSelectedVehicleIDForOrganization] = useState("");

    const [toggleTrackerAssignmentModal, setToggleTrackerAssignmentModal] = useState(false);
    const [selectedVehicleIDForTrackerAssignment, setSelectedVehicleIDForTrackerAssignment] = useState("");
    const [selectedVehicleVinForDriverUnassignment, setSelectedVehicleVinForDriverUnassignment] = useState("");
    const [selectedDriverNameForUnassignment, setSelectedDriverNameForUnassignment] = useState("");

    const [selectedVehicleIDForRepair, setSelectedVehicleIDForRepair] = useState("");
    const [selectedVehicleVINForRepair, setSelectedVehicleVINForRepair] = useState("");
    const [toggleRepairVehicleModal, setToggleRepairVehicleModal] = useState(false);
    const [specRepairType, setSpecRepairType] = useState("");
    const [specRepairModalTile, setSpecRepairModalTile] = useState("");
    const [repairCallToActionText, setRepairCallToActionText] = useState("");

    const [selectedVehicleDataForMetrics, setSelectedVehicleDataForMetrics] = useState(null);
    const [selectedVehicleVinForMetrics, setSelectedVehicleVinForMetrics] = useState("");

    const [disableActionButtons, setDisableActionButtons] = useState(true);
    const [monthFilterString, setMonthFilterString] = useState("");
    const [loadingMonthFilterString, setLoadingMonthFilterString] = useState(false);

    const [showAdderDetailsModal, setShowAdderDetailsModal] = useState(false);
    const [selectedAdderID, setSelectedAdderID] = useState("");
    const [selectedEventTime, setSelectedEventTime] = useState(null);
    const [selectedAssignedEntity, setSelectedAssignedEntity] = useState("");
    const [selectedAssignmentType, setSelectedAssignmentType] = useState("");

    const TableErrorMessage = useRef();
    const classes = useStyles();

    function openSnackBar() {
        setOpenSnackbar(true);
    }

    function closeSnackbar(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    const Unassignment = ({ trackerID, vehicleVin, vehicleID, trackerIMEI, trackerManufacturer, assignerID }) => {

        return (
            <>
                <div className='border-0'>
                    <div className='container-border p-3 rounded'>
                        <div className='p-2 centerMan font-weight-bold mb-2'>
                            Confirming this prompt will unassign the tracker from the vehicle with details below:
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={4}>
                                <div className='bg-light-success rounded'>
                                    <div className='p-2'>
                                        <span>
                                            <span className='text-nowrap font-weight-bold'>
                                                Tracker Model:
                                            </span>
                                            {" "}
                                            {trackerManufacturer}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className='bg-light-success rounded'>
                                    <div className='p-2'>
                                        <span>
                                            <span className='text-nowrap font-weight-bold'>
                                                Tracker IMEI:
                                            </span>
                                            {" "}
                                            {trackerIMEI}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className='bg-light-success rounded'>
                                    <div className='p-2'>
                                        <span>
                                            <span className='text-nowrap font-weight-bold'>
                                                Vehicle VIN:
                                            </span>
                                            {" "}
                                            {vehicleVin}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='mt-5'>
                        <div className='p-2 centerMan text-danger font-weight-bold mb-3'>
                            Are you sure you want to unassign this tracker from the vehicle with VIN above?
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="error"
                                        aria-label="Add"
                                        onClick={async () => {

                                            setIsLoadingTrackerUnassignment(true);
                                            setOpenSnackbar(false);

                                            try {

                                                const payload = {
                                                    vehicleID: vehicleID,
                                                    assignerID: assignerID,
                                                    trackerID: trackerID,
                                                };

                                                const response = await unassignTrackerFromVehicle(payload);
                                                setSeverity('success');
                                                TableErrorMessage.current = response.data.message;
                                                setReRender(prevState => prevState + 1);
                                                openSnackBar();

                                                setTimeout(() => {
                                                    setIsLoadingTrackerUnassignment(false);
                                                    setToggleTrackerUnassignmentConfirmationModal(false);
                                                }, 3000);

                                            } catch (error) {

                                                setSeverity('error');
                                                setIsLoadingTrackerUnassignment(false);
                                                TableErrorMessage.current = error.response.data.message;
                                                openSnackBar();

                                            }

                                        }}
                                        disabled={isLoadingTrackerUnassignment ? true : false}
                                    >
                                        <span>
                                            Yes
                                            {' '}
                                            {isLoadingTrackerUnassignment && <BasicLoader size={'sm'} />}
                                        </span>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="success"
                                        aria-label="Add"
                                        onClick={() => {
                                            setToggleTrackerUnassignmentConfirmationModal(false);
                                        }}
                                        disabled={isLoadingTrackerUnassignment ? true : false}
                                    >
                                        No
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );

    };

    const OrganizationUnassignment = ({ organizationID, organizationName, vehicleVin, vehicleID, assignerID }) => {

        return (
            <>
                <div className='border-0'>
                    <div className='container-border p-3 rounded'>
                        <div className='p-2 centerMan font-weight-bold mb-2'>
                            Confirming this prompt will unassign the organization from the vehicle with details below:
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={6}>
                                <div className='bg-light-success rounded'>
                                    <div className='p-2'>
                                        <span>
                                            <span className='text-nowrap font-weight-bold'>
                                                Organization Name:
                                            </span>
                                            {" "}
                                            {organizationName}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className='bg-light-success rounded'>
                                    <div className='p-2'>
                                        <span>
                                            <span className='text-nowrap font-weight-bold'>
                                                Vehicle VIN:
                                            </span>
                                            {" "}
                                            {vehicleVin}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='mt-5'>
                        <div className='p-2 centerMan text-danger font-weight-bold mb-3'>
                            Are you sure you want to unassign this organization from the vehicle with VIN above?
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="error"
                                        aria-label="Add"
                                        onClick={async () => {

                                            setIsLoadingOrganizationUnassignment(true);
                                            setOpenSnackbar(false);

                                            try {

                                                const requestPayload = {
                                                    vehicleID: vehicleID,
                                                    organizationID: organizationID,
                                                    assignerID: assignerID,
                                                };

                                                const response = await unassignVehicleFromOrganization(requestPayload);
                                                if (response) {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = response.data.message;
                                                    setReRender(prevState => prevState + 1);
                                                    openSnackBar();

                                                    setTimeout(() => {
                                                        setIsLoadingOrganizationUnassignment(false);
                                                        return setToggleOrganizationUnassignmentConfirmationModal(false);
                                                    }, 3000);

                                                }

                                            } catch (error) {

                                                setSeverity('error');
                                                setIsLoadingOrganizationUnassignment(false);
                                                TableErrorMessage.current = error.response.data.message;
                                                openSnackBar();

                                            }

                                        }}
                                        disabled={isLoadingOrganizationUnassignment ? true : false}
                                    >
                                        <span>
                                            Yes
                                            {' '}
                                            {isLoadingOrganizationUnassignment && <BasicLoader size={'sm'} />}
                                        </span>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="success"
                                        aria-label="Add"
                                        onClick={() => {
                                            setToggleOrganizationUnassignmentConfirmationModal(false);
                                        }}
                                        disabled={isLoadingOrganizationUnassignment ? true : false}
                                    >
                                        No
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );

    };

    const DriverUnassignment = ({ driverID, driverName, vehicleVin, vehicleID }) => {

        return (
            <>
                <div className='border-0'>
                    <div className='container-border p-3 rounded'>
                        <div className='p-2 centerMan font-weight-bold mb-2'>
                            Confirming this prompt will unassign the driver from the vehicle with details below:
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={6}>
                                <div className='bg-light-success rounded'>
                                    <div className='p-2'>
                                        <span>
                                            <span className='text-nowrap font-weight-bold'>
                                                Driver Name:
                                            </span>
                                            {" "}
                                            {driverName}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className='bg-light-success rounded'>
                                    <div className='p-2'>
                                        <span>
                                            <span className='text-nowrap font-weight-bold'>
                                                Vehicle VIN:
                                            </span>
                                            {" "}
                                            {vehicleVin}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='mt-5'>
                        <div className='p-2 centerMan text-danger font-weight-bold mb-3'>
                            Are you sure you want to unassign this driver from the vehicle with VIN above?
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="error"
                                        aria-label="Add"
                                        onClick={async () => {

                                            setIsUnassignRunning(true);
                                            const userID = loggedInUser.user_data.document.ID;

                                            const requestPayload = {
                                                vehicleID: vehicleID,
                                                driverID: driverID,
                                                assignerID: userID,
                                            };

                                            unassignVehicle(requestPayload).then((response) => {

                                                setIsUnassignRunning(false);

                                                if (response) {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = response.data.message;
                                                    return openSnackBar();

                                                }

                                            }).catch((error) => {

                                                setSeverity('error');
                                                TableErrorMessage.current = error?.response?.data?.message ?? error.message;
                                                openSnackBar();

                                                return setIsUnassignRunning(false);

                                            });

                                        }}
                                        disabled={isUnassignRunning}
                                    >
                                        <span>
                                            Yes
                                            {" "}
                                            {isUnassignRunning && <BasicLoader size={'sm'} />}
                                        </span>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="centerMan">
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="success"
                                        aria-label="No"
                                        onClick={() => {
                                            setToggleDriverUnassignmentConfirmationModal(false);
                                        }}
                                        disabled={isUnassignRunning}
                                    >
                                        No
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );

    };

    const assignerRenderer = (vehicleEntity, index) => {

        if (vehicleEntity && (vehicleEntity.assignmentType !== null || vehicleEntity.assignmentType !== undefined)) {

            return (
                <div key={index} className="uppercase pb-2">
                    <div className='bg-light-primary p-3 rounded-xl'>
                        <div className='p-1'>
                            <span>
                                {vehicleEntity.assignmentType === "added" ? vehicleEntity.assignedEntity === "driver" ? <SportsMotorsportsTwoToneIcon
                                    color={vehicleEntity.assignmentType === "added" ? "success" : "error"}
                                /> : vehicleEntity.assignedEntity === "organization" ? <CorporateFareTwoToneIcon
                                    color={vehicleEntity.assignmentType === "added" ? "success" : "error"}
                                /> : vehicleEntity.assignedEntity === "tracker" ? <TrackChangesTwoToneIcon
                                    color={vehicleEntity.assignmentType === "added" ? "success" : "error"}
                                /> : <RemoveCircleTwoToneIcon
                                    color={vehicleEntity.assignmentType === "added" ? "success" : "error"}
                                /> : <RemoveCircleTwoToneIcon
                                    color={vehicleEntity.assignmentType === "added" ? "success" : "error"}
                                />
                                }
                            </span>
                            {" "}
                            <span>
                                {`${vehicleEntity.assignedEntity} ${vehicleEntity.assignmentType} on ${new Date(vehicleEntity.assignmentDateTime).toUTCString()}`}
                            </span>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    onClick={() => {

                                        setSelectedAdderID(vehicleEntity.assigner);
                                        setSelectedEventTime(vehicleEntity.assignmentDateTime);
                                        setSelectedAssignedEntity(vehicleEntity.assignedEntity);
                                        setSelectedAssignmentType(vehicleEntity.assignmentType);

                                        setShowAdderDetailsModal(true);

                                    }}
                                >
                                    See Adder Details
                                </Button>
                            </div>
                            <div className='col'>

                            </div>
                        </div>
                    </div>
                </div>
            );

        }

        return (<>
            <span>
                Nothing to see here...
            </span>
        </>);

    };

    const vehicleDelegationRenderer = (rowData) => {

        const isStaffVehicleBool = rowData?.document?.isStaffVehicle;
        const isCustomerVehicle = rowData?.document?.isCustomerVehicle;
        const vehicleOrganizationArray = rowData?.document?.organizations;

        if (isStaffVehicleBool === true) {
            return (
                <Chip label="Staff Vehicle" variant="filled" color="success" icon={<ManageAccountsTwoToneIcon />}></Chip>
            );
        }

        if (isCustomerVehicle === true) {
            return (
                <Chip label="Customer Vehicle" variant="filled" color="info" icon={< PersonOutlineTwoToneIcon />}></Chip >
            );
        }

        if (vehicleOrganizationArray && vehicleOrganizationArray?.length > 0) {

            for (let i = 0; i < vehicleOrganizationArray.length; i++) {

                const organizationID = vehicleOrganizationArray[i];
                if (organizationID === "MORG-041b040e-4a5d-4077-9415-1f5976c309c6") {
                    return (
                        <>
                            <Chip
                                label="Untapped Funded"
                                variant="outlined"
                                color="info"
                                icon={<CorporateFareTwoToneIcon />}
                            >

                            </Chip>
                        </>
                    );
                }

            }

        }

        return (
            <Chip label="Rider Vehicle" variant="filled" color="default" icon={<SportsMotorsportsTwoToneIcon />}></Chip>
        );

    };

    const returnVehicleDelegation = (rowData) => {

        const isStaffVehicleBool = rowData?.document?.isStaffVehicle;
        const isCustomerVehicle = rowData?.document?.isCustomerVehicle;

        if (isStaffVehicleBool === true) {
            return "Staff Vehicle";
        }

        if (isCustomerVehicle === true) {
            return "Customer Vehicle";
        }

        return "Rider Vehicle";

    };

    const returnAssignedDriver = (rowData) => {

        if (rowData && rowData.length > 0) {

            let nameString = "";
            for (let i = 0; i < rowData.length; i++) {

                const item = rowData[i];
                if (item) {
                    if (nameString === "")
                        return nameString = item.driverFirstName + " " + item.driverLastName;
                    return nameString = nameString + ", " + item.driverFirstName + " " + item.driverLastName;
                }

                if ((i + 1) === rowData.length) {
                    return nameString;
                }

            }

        }

        return "";

    };

    const returnAssignedOrganizationName = (rowData) => {

        if (rowData && rowData.length > 0) {

            let nameString = "";
            for (let i = 0; i < rowData.length; i++) {

                const item = rowData[i];
                if (item) {
                    if (nameString === "")
                        return nameString = item.organizationName;
                    return nameString = nameString + ", " + item.organizationName;
                }

                if ((i + 1) === rowData.length) {
                    return nameString;
                }

            }

        }

        return "";

    };

    const returnAssignedOrganizationID = (rowData) => {

        if (rowData && rowData.length > 0) {

            let iDString = "";
            for (let i = 0; i < rowData.length; i++) {

                const item = rowData[i];
                if (item) {
                    if (iDString === "")
                        return iDString = item.organizationID;
                    return iDString = iDString + ", " + item.organizationID;
                }

                if ((i + 1) === rowData.length) {
                    return iDString;
                }

            }
        }

        return "";

    };

    const returnActivationDate = (rowData) => {

        if (rowData && rowData.length > 0) {

            let dateString = "";
            for (let i = 0; i < rowData.length; i++) {

                const item = rowData[i];
                if ((item?.assignedEntity === "driver" && item?.assignmentType === "added")) {
                    if (dateString === "")
                        dateString = new Date(item.assignmentDateTime).toDateString();
                    dateString = dateString + ", " + new Date(item.assignmentDateTime).toDateString();
                }

                if ((i + 1) === rowData.length) {
                    return dateString;
                }

            }

        }

        return "";

    };

    const returnDeploymentDate = (rowData) => {

        if (rowData && rowData.length > 0) {

            let dateString = "";
            let count = 0;
            for (let i = 0; i < rowData.length; i++) {

                const item = rowData[i];
                if ((item?.assignedEntity === "driver" && item?.assignmentType === "added") && count === 0) {
                    dateString = new Date(item.assignmentDateTime).toDateString();
                    count = count + 1;
                }

                if ((i + 1) === rowData.length) {
                    return dateString;
                }

            }

        }

        return "";

    };

    const returnAssemblySpec = (vehicleVIN) => {

        const firstTwoChars = vehicleVIN.substring(0, 2);
        if (firstTwoChars === "WB")
            return "CKD - 1";

        if (firstTwoChars === "LL")
            return "SKD - LANTU";

        if (firstTwoChars === "OT")
            return "SKD - OTMAR";

        return "SKD";

    };

    const handleMonthChange = (event) => {

        setMonthFilterString(event.target.value);
        setLoadingMonthFilterString(true);

        const dataArrayAlt = [];
        for (let i = 0; i < data.length; i++) {

            const vehicleData = data[i];
            fetchMileageData(vehicleData?.document_id, vehicleData, event.target.value, dataArrayAlt).then(() => {

                if ((1 + i) === data.length) {

                    if (dataArrayAlt.length <= 0) {

                        dataArrayAlt.push({
                            "Vehicle ID": "vehicleID",
                            "VIN": "",
                            "Assembly Spec": "",
                            "Vehicle Designation": "",
                            "Tracker IMEI": "",
                            "Date Created": "",
                            "Latest Tracker Update": "",
                            "Mileage": 0,
                            "Operational Status": "",
                            "Assignment Date(s)": "",
                            "Deployment Date": "",
                            "Assigned Driver(s)": "",
                            "Assigned Organization": "",
                            "Assigned Organization ID": "",
                            "dateCreated": 0,
                        });

                    }
                    setTimeout(() => {
                        const dataArrayAltAlt = dataArrayAlt.sort((a, b) => {
                            return a.VIN.localeCompare(b.VIN);
                        });
                        csvExporter.generateCsv(dataArrayAltAlt);
                        setLoadingMonthFilterString(false);
                    }, 8000);

                }

            });

        }

    };

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL(baseFetchUrl ? baseFetchUrl : '/vehicles/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                setData(data.data);
                setRowCount(data.max_count);

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    const elementDoc = element.document;

                    const vehicleID = elementDoc.ID;
                    const vehicleVIN = elementDoc.VIN;
                    const assemblySpec = returnAssemblySpec(elementDoc.VIN);
                    const vehicleDesignation = returnVehicleDelegation(element);
                    const vehicleTrackerIMEI = elementDoc.trackerIMEI;
                    const vehicleLatestTrackerDataTimeStamp = elementDoc.latestTrackerDataTimeStamp > 0 ? (Math.abs((new Date().getTime()) - (elementDoc.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3) + " hours ago" : "Never";
                    const vehicleMileage = elementDoc.vehicleMileage ?? 0;
                    const vehicleIsActive = elementDoc?.isActive ? "Active" : "Inactive";
                    const vehicleAssignedDrivers = returnAssignedDriver(elementDoc?.assignedDriversData);
                    const vehicleAssignedOrganizationName = returnAssignedOrganizationName(elementDoc?.assignedOrganizationData);
                    const vehicleAssignedOrganizationID = returnAssignedOrganizationID(elementDoc?.assignedOrganizationData);
                    const assignmentDates = returnActivationDate(elementDoc?.assignmentHistory);
                    const dateCreated = new Date(elementDoc.createdAt).toLocaleDateString();
                    const deploymentDate = returnDeploymentDate(elementDoc?.assignmentHistory);

                    dataArrayAlt.push({
                        "Vehicle ID": vehicleID,
                        "VIN": vehicleVIN,
                        "Assembly Spec": assemblySpec,
                        "Vehicle Designation": vehicleDesignation,
                        "Tracker IMEI": vehicleTrackerIMEI,
                        "Date Created": dateCreated,
                        "Latest Tracker Update": vehicleLatestTrackerDataTimeStamp,
                        "Mileage": vehicleMileage,
                        "Operational Status": vehicleIsActive,
                        "Assignment Date(s)": assignmentDates,
                        "Deployment Date": deploymentDate,
                        "Assigned Driver(s)": vehicleAssignedDrivers,
                        "Assigned Organization": vehicleAssignedOrganizationName,
                        "Assigned Organization ID": vehicleAssignedOrganizationID,
                    });

                    if ((i + 1) >= dataArray.length) {
                        setDataDocument(dataArrayAlt);
                    }

                }

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";

                    setIsLoading(false);
                    setIsRefetching(false);

                    return setOpenSnackbar(true);

                }

                TableErrorMessage.current = `Code: ${error?.response?.status ?? error?.status}, Message: ${error?.response.data.message}`;
                setSeverity("error");
                setIsError(true);

                setIsLoading(false);
                setIsRefetching(false);

                setOpenSnackbar(true);

            } finally {

                setIsRefetching(false);
                setIsLoading(false);

            };

        };

        isMounted === true && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);
            setDataDocument([]);

            setSelectedAdderID(null);
            setSelectedEventTime(null);
            setSelectedAssignedEntity(null);
            setSelectedAssignmentType(null);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            id: 'designation',
            header: 'Designation',
            Cell: ({ cell, row }) => (
                vehicleDelegationRenderer(row?.original)
            ),
        },
        {
            accessorFn: (row) => row?.document?.trackerIMEI,
            enableClickToCopy: true,
            id: 'document.trackerIMEI',
            header: 'Tracker IMEI',
            Cell: ({ cell, row }) => (
                row?.original?.document?.trackerIMEI ? `${row?.original?.document?.trackerIMEI}` : (<>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Typography
                            sx={(theme) => ({
                                backgroundColor: theme.palette.error.dark,
                                borderRadius: '0.35rem',
                                color: '#fff',
                                p: '0.35rem',
                                textAlign: 'center',
                                textTransform: 'uppercase'
                            })}
                            noWrap
                            variant='p'
                        >
                            No tracker Assigned
                        </Typography>
                    </Box>
                </>)
            ),
        },
        {
            accessorFn: (row) => row?.document?.VIN,
            id: "vin",
            enableClickToCopy: true,
            header: 'VIN/Serial #'
        },
        {
            accessorFn: (row) => row?.original?.document?.latestTrackerDataTimeStamp,
            enableClickToCopy: true,
            id: 'latestTrackerDataTimeStamp',
            header: 'Tracker Last Post',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        borderRadius: '0.35rem',
                        p: '0.35rem',
                        textAlign: 'center'
                    }}
                >
                    {row?.original?.document?.latestTrackerDataTimeStamp && row?.original?.document?.latestTrackerDataTimeStamp > 0 ? (
                        <>

                            {((Math.abs((new Date().getTime()) - (row?.original?.document?.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3)) > 8 ?
                                (
                                    <>
                                        <Typography
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.warning.light,
                                                borderRadius: '0.35rem',
                                                color: '#fff',
                                                p: '0.35rem',
                                                textAlign: 'center',
                                                textTransform: 'uppercase'
                                            })}
                                            noWrap
                                            variant='p'
                                        >
                                            {(Math.abs((new Date().getTime()) - (row?.original?.document?.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3) + " hours ago"}
                                        </Typography>
                                    </>
                                ) :
                                (
                                    <>
                                        <Typography
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.success.light,
                                                borderRadius: '0.35rem',
                                                color: '#fff',
                                                p: '0.35rem',
                                                textAlign: 'center',
                                                textTransform: 'uppercase'
                                            })}
                                            noWrap
                                            variant='p'
                                        >
                                            {(Math.abs((new Date().getTime()) - (row?.original?.document?.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3) + " hours ago"}
                                        </Typography>
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <>
                            <Box
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.error.dark,
                                    borderRadius: '0.35rem',
                                    color: '#fff',
                                    p: '0.35rem',
                                    textAlign: 'center'
                                })}
                            >
                                <Typography
                                    sx={(theme) => ({
                                        borderRadius: '0.35rem',
                                        p: '0.35rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase'
                                    })}
                                    noWrap
                                    variant='p'
                                >
                                    Never
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.vehicleMileage ?? 0,
            id: "vehicleMileage",
            enableClickToCopy: true,
            header: 'km - Total Mileage'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Vehicle Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.light : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    <Typography
                        sx={(theme) => ({
                            borderRadius: '0.35rem',
                            p: '0.35rem',
                            textAlign: 'center',
                            textTransform: 'uppercase'
                        })}
                        noWrap
                        variant='p'
                    >
                        {cell.getValue() === true ? "Running" : "Not Running"}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => new Date(row?.document?.createdAt),
            id: 'document.createdAt',
            header: 'Date Created',
            filterVariant: 'date-range',
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
            grow: true,
            size: 350,
        },
        {
            accessorFn: (row) => row?.original?.document?.assignedDriversData,
            enableClickToCopy: true,
            id: 'assignedDriversData',
            header: 'Assigned Driver',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        borderRadius: '0.35rem',
                        p: '0.35rem',
                        textAlign: 'center'
                    }}
                >
                    {row?.original?.document?.assignedDriversData && row?.original?.document?.assignedDriversData?.length > 0 ? (
                        <>
                            <Typography
                                noWrap
                                sx={(theme) => ({
                                    borderRadius: '0.35rem',
                                    p: '0.35rem',
                                    textAlign: 'center',
                                    textTransform: 'uppercase'
                                })}
                                variant='p'
                            >
                                {row?.original?.document?.assignedDriversData[(row?.original?.document?.assignedDriversData?.length) - 1]?.driverFirstName + " " + row?.original?.document?.assignedDriversData[(row?.original?.document?.assignedDriversData?.length) - 1]?.driverLastName}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.error.dark,
                                    borderRadius: '0.35rem',
                                    color: '#fff',
                                    p: '0.35rem',
                                    textAlign: 'center'
                                })}
                            >
                                <Typography
                                    noWrap
                                    sx={(theme) => ({
                                        borderRadius: '0.35rem',
                                        p: '0.35rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase'
                                    })}
                                    variant='p'
                                >
                                    Unassigned
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "ID",
            enableClickToCopy: true,
            header: 'ID'
        },
        {
            accessorFn: (row) => row?.original?.document?.organizations,
            enableClickToCopy: true,
            id: 'assignedOrganization',
            header: 'Assigned Organization',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        borderRadius: '0.35rem',
                        p: '0.35rem',
                        textAlign: 'center'
                    }}
                >
                    {row?.original?.document?.organizations && row?.original?.document?.organizations?.length > 0 ? (
                        <>
                            {
                                row?.original?.document?.organizations.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Typography
                                                noWrap
                                                sx={(theme) => ({
                                                    borderRadius: '0.35rem',
                                                    p: '0.35rem',
                                                    textAlign: 'center',
                                                    textTransform: 'uppercase'
                                                })}
                                                variant='p'
                                            >
                                                {item === "MORG-041b040e-4a5d-4077-9415-1f5976c309c6" ? "Untapped Global" : item === "MORG-6844bf7d-f3ac-4dfb-ba65-320cfb130bc5" ? "IKI/Siemens/Impact Hub" : item === "MORG-77484577-4194-4b06-9938-16795848e34b" ? "Wahu Mobility Ghana" : item === "MORG-ac457b8a-a511-48b2-a9a0-4b2bad722f34" ? "Wahu Mobility Togo" : "No Organization"}
                                            </Typography>
                                        </div>
                                    );
                                })
                            }
                        </>
                    ) : (
                        <>
                            <Box
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.error.dark,
                                    borderRadius: '0.35rem',
                                    color: '#fff',
                                    p: '0.35rem',
                                    textAlign: 'center'
                                })}
                            >
                                <Typography
                                    noWrap
                                    sx={(theme) => ({
                                        borderRadius: '0.35rem',
                                        p: '0.35rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase'
                                    })}
                                    variant='p'
                                >
                                    No Organization
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            ),
        },
    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Vehicle Data',
        showTitle: true,
        boolDisplay: true,
        filename: "Wahu Vehicles",
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {

        csvExporter.generateCsv(rows.map((row) => ({
            "Vehicle ID": row.original?.document?.ID,
            "VIN": row.original?.document?.VIN,
            "Assembly Spec": returnAssemblySpec(row.original?.document?.VIN),
            "Vehicle Designation": returnVehicleDelegation(row.original?.document),
            "Tracker IMEI": row.original?.document?.trackerIMEI,
            "Date Created": new Date(row.original?.document?.createdAt).toDateString(),
            "Latest Tracker Update": row.original?.document?.latestTrackerDataTimeStamp > 0 ? (Math.abs((new Date().getTime()) - (row.original?.document?.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3) + " hours ago" : "Never",
            "Mileage": row.original?.document?.vehicleMileage ?? 0,
            "Operational Status": row.original?.document?.isActive ? "Active" : "Inactive",
            "Activation Date": returnActivationDate(row.original?.document?.assignmentHistory),
            "Assigned Driver(s)": returnAssignedDriver(row.original?.document?.assignedDriversData),
            "Assigned Organization": returnAssignedOrganizationName(row.original?.document?.assignedOrganizationData),
            "Assigned Organization ID": returnAssignedOrganizationID(row.original?.document?.assignedOrganizationData),
        })));

    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    const fetchMileageData = async (vehicleID, vehicleData, filterMonthYear, dataArrayAlt) => {

        const url = new URL(`/vehicles/telemetry/${vehicleID}`, `${FLEET_MANAGING_BASE_URL}`);
        try {

            const response = axios.get(url.href);
            const data = (await response).data;

            let mileageCount = 0;

            for (let i = 0; i < data.monthlyTelemetry.length; i++) {

                const monthlyMileage = Number(data.monthlyTelemetry[i].monthlyMileage);
                if (data?.monthlyTelemetry[i]?.weeklyTelemetryDocID === filterMonthYear) {

                    if (monthlyMileage)
                        mileageCount = mileageCount + monthlyMileage;

                }

                if ((i + 1) === data.monthlyTelemetry.length) {

                    const vehicleID = vehicleData.document.ID;
                    const vehicleVIN = vehicleData.document.VIN;
                    const assemblySpec = returnAssemblySpec(vehicleData.document.VIN);
                    const vehicleDesignation = returnVehicleDelegation(vehicleData.document);
                    const vehicleTrackerIMEI = vehicleData.document.trackerIMEI;
                    const vehicleLatestTrackerDataTimeStamp = vehicleData.document.latestTrackerDataTimeStamp > 0 ? (Math.abs((new Date().getTime()) - (vehicleData.document.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3) + " hours ago" : "Never";
                    const vehicleMileage = mileageCount ?? 0;
                    const vehicleIsActive = vehicleData.document?.isActive ? "Active" : "Inactive";
                    const vehicleAssignedDrivers = returnAssignedDriver(vehicleData.document?.assignedDriversData);
                    const vehicleAssignedOrganizationName = returnAssignedOrganizationName(vehicleData.document?.assignedOrganizationData);
                    const vehicleAssignedOrganizationID = returnAssignedOrganizationID(vehicleData.document?.assignedOrganizationData);
                    const assignmentDates = returnActivationDate(vehicleData.document?.assignmentHistory);
                    const deploymentDate = returnDeploymentDate(vehicleData.document?.assignmentHistory);
                    const dateCreated = new Date(vehicleData.document.createdAt).toLocaleDateString();
                    const createdAtTimeStamp = vehicleData.document.createdAt;

                    dataArrayAlt.push({
                        "Vehicle ID": vehicleID,
                        "VIN": vehicleVIN,
                        "Assembly Spec": assemblySpec,
                        "Vehicle Designation": vehicleDesignation,
                        "Tracker IMEI": vehicleTrackerIMEI,
                        "Date Created": dateCreated,
                        "Latest Tracker Update": vehicleLatestTrackerDataTimeStamp,
                        "Mileage": vehicleMileage,
                        "Operational Status": vehicleIsActive,
                        "Assignment Date(s)": assignmentDates,
                        "Deployment Date": deploymentDate,
                        "Assigned Driver(s)": vehicleAssignedDrivers,
                        "Assigned Organization": vehicleAssignedOrganizationName,
                        "Assigned Organization ID": vehicleAssignedOrganizationID,
                        "dateCreated": createdAtTimeStamp,
                    });

                }

            }

            return;

        } catch (error) {

            TableErrorMessage.current = error?.response?.data?.message ? `error fetching some data point: ${error?.response?.data?.message}` : "encountered some errors fetching some data point";

            setSeverity("error");
            return setOpenSnackbar(true);

        }

    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={closeSnackbar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={closeSnackbar}
                    severity={severity}
                >
                    {TableErrorMessage.current}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Adder Details`}
                show={showAdderDetailsModal}
                close={() => setShowAdderDetailsModal(false)}
                size="lg"
                body={<AdderDetails
                    adderID={selectedAdderID}
                    eventTime={selectedEventTime}
                    assignedEntity={selectedAssignedEntity}
                    assignmentType={selectedAssignmentType}
                />}
            />
            <SimpleModal
                modal_tittle={`Create / Edit Vehicle Records`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditVehicleForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    vehicleID={selectedVehicleID}
                />}
            />
            <SimpleModal
                modal_tittle={`Vehicle Details`}
                show={showVehicleDetails}
                close={() => setShowVehicleDetails(false)}
                size="lg"
                body={<VehicleDetailsList
                    className="card-stretch gutter-b"
                    selectedVehicleModelID={selectedVehicleModelID}
                    selectedVehicleData={selectedVehicleData}
                />}
            />
            <SimpleModal
                modal_tittle={`Confirm Tracker Unassignment`}
                show={toggleTrackerUnassignmentConfirmationModal}
                close={() => setToggleTrackerUnassignmentConfirmationModal(false)}
                size="lg"
                body={<Unassignment
                    trackerID={trackerIDToUnassign}
                    vehicleID={trackerVehicleIdToUnassign}
                    vehicleVin={trackerVehicleVinToUnassign}
                    trackerIMEI={trackerIMEIToUnassign}
                    trackerManufacturer={trackerManufacturerToUnassign}
                    assignerID={trackerAssignerIDToUnassign}
                />}
            />
            <SimpleModal
                modal_tittle={`Confirm Organization Unassignment`}
                show={toggleOrganizationUnassignmentConfirmationModal}
                close={() => setToggleOrganizationUnassignmentConfirmationModal(false)}
                size="lg"
                body={<OrganizationUnassignment
                    organizationID={organizationIDToUnassign}
                    vehicleID={organizationVehicleIdToUnassign}
                    vehicleVin={organizationVehicleVinToUnassign}
                    organizationName={organizationNameToUnassign}
                    assignerID={organizationAssignerIDToUnassign}
                />}
            />
            <SimpleModal
                modal_tittle={`Assign Vehicle to Organization`}
                show={toggleOrganizationAssignmentConfirmationModal}
                close={() => setToggleOrganizationAssignmentConfirmationModal(false)}
                size="xl"
                body={<OrganizationsTable
                    onChanger={onChangeWorker}
                    vehicleID={selectedVehicleIDForOrganization}
                    assignAction={true}
                    assignmentType={"vehicle"}
                    assignmentEntityName={"Assign Vehicles"}
                    assignmentModalCloser={() => {
                        setToggleOrganizationAssignmentConfirmationModal(false);
                    }}
                />}
            />
            <SimpleModal
                modal_tittle={`Vehicle Tracker Assignment`}
                show={toggleTrackerAssignmentModal}
                close={() => setToggleTrackerAssignmentModal(false)}
                size="lg"
                body={<TrackersTable
                    onChanger={onChangeWorker}
                    vehicleID={selectedVehicleIDForTrackerAssignment}
                    assignAction={true}
                    assignmentType={"vehicle"}
                    assignmentEntityName={"Assign Tracker"}
                />}
            />
            <SimpleModal
                modal_tittle={specRepairModalTile}
                show={toggleRepairVehicleModal}
                close={() => setToggleRepairVehicleModal(false)}
                size="lg"
                body={<SetVehicleInRepairForm
                    onChanger={onChangeWorker}
                    vehicleID={selectedVehicleIDForRepair}
                    vehicleVIN={selectedVehicleVINForRepair}
                    repairType={specRepairType}
                    repairCallToActionText={repairCallToActionText}
                />}
            />
            <SimpleModal
                modal_tittle={`Confirm Driver Unassignment`}
                show={toggleDriverUnassignmentConfirmationModal}
                close={() => setToggleDriverUnassignmentConfirmationModal(false)}
                size="lg"
                body={<DriverUnassignment
                    driverID={selectedDriverID}
                    vehicleID={selectedVehicleForUnassignmentID}
                    vehicleVin={selectedVehicleVinForDriverUnassignment}
                    driverName={selectedDriverNameForUnassignment}
                />}
            />
            <SimpleModal
                modal_tittle={`Vehicle Telemetry Data for ${selectedVehicleVinForMetrics}`}
                show={vehicleTelemetryModal}
                close={() => setVehicleTelemetryModal(false)}
                size="xl"
                body={<VehicleMetricsPage
                    vehicleData={selectedVehicleDataForMetrics}
                    vehicleID={selectedVehicleID}
                    vehicleVIN={selectedVehicleVinForMetrics}
                />}
            />
            <div className={`card card-custom rounded-lg ${className}`}>
                <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {isLoading && <BasicLoader size={"sm"} />}
                            {!isLoading && rowCount}
                            {" "}
                            {tableTopHeader ? tableTopHeader : "Vehicles"}
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            {tableSubHeader ? tableSubHeader : "Manage vehicle records from this table"}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setEditState(false);

                                        setCreateEditText("Create Record");
                                        setInitialValues({
                                            vehicleModelID: '',
                                            VIN: '',
                                            batteryID: ''
                                        });

                                        setShow(true);
                                    }}
                                >
                                    Add Bike
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        options={{
                            selection: true,
                        }}
                        muiToolbarAlertBannerProps={
                            isError ? {
                                color: `${severity}`,
                                children: `${TableErrorMessage.current}`,
                            } : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={4}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Item className='rounded-xl'>
                                                    <div className={`${classes.itemInnerDiv}`}>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>Assignment History</Typography>
                                                            <div className='mt-5'>
                                                                {row.original.document?.assignmentHistory ? row.original.document?.assignmentHistory?.length > 0 ? row.original.document?.assignmentHistory?.map(assignerRenderer) : (<>
                                                                    <span>
                                                                        Nothing to see here...
                                                                    </span>
                                                                </>) : (<>
                                                                    <span>
                                                                        Nothing to see here...
                                                                    </span>
                                                                </>)}
                                                            </div>
                                                        </Box>
                                                    </div>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item className='mt-3 rounded-xl'>
                                                    <div className={`${classes.itemInnerDiv}`}>
                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                            <Grid item xs={12}>
                                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="label" className={`bold ${classes.allCaps}`}>Repair History</Typography>
                                                                        <div className='p-1 mt-5'>
                                                                            {row?.original?.document?.repairLog ? row?.original?.document?.repairLog.map((item, index) =>
                                                                                <div className="border-bottom mb-2 bg-light-primary p-1 rounded-lg" key={index}>
                                                                                    <div className='p-1 mb-1'>
                                                                                        {item.status === "completed" ? (<>
                                                                                            <span className='text-uppercase bold'>
                                                                                                End Date:
                                                                                                {" "}
                                                                                            </span>
                                                                                            <span className='p-1 bg-light-primary rounded-lg'>
                                                                                                {new Date(item.endDate).toLocaleDateString()}
                                                                                            </span>
                                                                                        </>) : (<>
                                                                                            <span className='text-uppercase bold'>
                                                                                                Start Date:
                                                                                                {" "}
                                                                                            </span>
                                                                                            <span className='p-1 bg-light-primary rounded-lg'>
                                                                                                {new Date(item.startDate).toLocaleDateString()}
                                                                                            </span>
                                                                                        </>)}
                                                                                    </div>
                                                                                    <div className='p-1 mb-1'>
                                                                                        <span className='text-uppercase bold'>
                                                                                            Status:
                                                                                            {" "}
                                                                                        </span>
                                                                                        <span>
                                                                                            {item.status === "completed" ? (<>
                                                                                                <span className='p-1 text-uppercase bg-success rounded-lg'>
                                                                                                    {item.status}
                                                                                                </span>
                                                                                                {" "}
                                                                                                @
                                                                                                {" "}
                                                                                            </>) : (<>
                                                                                                <span className='p-1 text-uppercase bg-warning rounded-lg'>
                                                                                                    In Repair
                                                                                                </span>
                                                                                            </>)}
                                                                                            {" "}
                                                                                            <span>
                                                                                                {item.status === "completed" ? (<>
                                                                                                    <span className='p-1 bg-light-primary rounded-lg'>
                                                                                                        {new Date(item.endDate).toLocaleDateString()}
                                                                                                    </span>
                                                                                                </>) : ""}
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='p-1'>
                                                                                        <span>
                                                                                            <span className=''>
                                                                                                <span className='text-uppercase bold'>
                                                                                                    Notes:
                                                                                                </span>
                                                                                                {" "}
                                                                                                {item.notes}
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                                : (<>
                                                                                    <span>
                                                                                        Nothing to see here
                                                                                    </span>
                                                                                </>)
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Item className='rounded-xl'>
                                                    <div className={`${classes.itemInnerDiv}`}>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>Assigned Drivers</Typography>
                                                            <div className='mt-5'>
                                                                {row?.original?.document?.drivers?.length > 0 ? row?.original?.document?.assignedDriversData ? row?.original?.document?.assignedDriversData.length > 0 ? row?.original?.document?.assignedDriversData.map((item, index) =>
                                                                    <div key={index} className='mb-2'>
                                                                        <div className='p-2 container-border rounded'>
                                                                            <div className='mb-2'>
                                                                                <span>
                                                                                    {index + 1}
                                                                                    {"."}
                                                                                </span>
                                                                                <br />
                                                                                <span className='mb-1'>
                                                                                    <span className='font-weight-bolder'>NAME:</span>
                                                                                    {" "}
                                                                                    <span>
                                                                                        {item.driverFirstName + " " + item.driverLastName}
                                                                                    </span>
                                                                                </span>
                                                                                <br />
                                                                                <span className='mb-1'>
                                                                                    <span className='font-weight-bolder'>PHONE NUMBER:</span>
                                                                                    {" "}
                                                                                    <span>
                                                                                        {item.driverPhoneNumber}
                                                                                    </span>
                                                                                </span>
                                                                                <br />
                                                                                <span className='mb-0'>
                                                                                    <span className='font-weight-bolder'>ID:</span>
                                                                                    {" "}
                                                                                    <span>
                                                                                        {item.driverID}
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div className='mt-2 mb-2'>
                                                                                <Button
                                                                                    color='error'
                                                                                    size='small'
                                                                                    variant='contained'
                                                                                    onClick={() => {

                                                                                        setIsUnassignRunning(true);

                                                                                        const driverID = item.driverID;
                                                                                        const vehicleID = row?.original?.document?.ID;

                                                                                        setSelectedDriverID(driverID);
                                                                                        setSelectedVehicleForUnassignmentID(vehicleID);
                                                                                        setSelectedVehicleVinForDriverUnassignment(row?.original?.document?.VIN);
                                                                                        setSelectedDriverNameForUnassignment(`${item.driverFirstName} ${item.driverLastName}`);

                                                                                        setIsUnassignRunning(false);
                                                                                        return setToggleDriverUnassignmentConfirmationModal(true);

                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        Unassign this Driver
                                                                                        {" "}
                                                                                        {isUnassignRunning && <BasicLoader size={"sm"} />}
                                                                                    </span>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (<>
                                                                    <span>
                                                                        Nothing to see here
                                                                    </span>
                                                                </>) : (<>
                                                                    <span>
                                                                        Nothing to see here
                                                                    </span>
                                                                </>) : (<>
                                                                    <span>
                                                                        Nothing to see here
                                                                    </span>
                                                                </>)
                                                                }
                                                            </div>
                                                        </Box>
                                                    </div>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item className='mt-3 rounded-xl'>
                                                    <div className={`${classes.itemInnerDiv}`}>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>Assigned Organization</Typography>
                                                            <div className='mt-5'>
                                                                <Typography variant="p">
                                                                    {row?.original?.document?.assignedOrganizationData ? row?.original?.document?.assignedOrganizationData?.length > 0 ? row?.original?.document?.assignedOrganizationData.map((item, index) =>
                                                                        <div key={index} className='mb-2 p-2 container-border rounded'>
                                                                            <div className='font-weight-bold mb-1'>
                                                                                <span>
                                                                                    {index + 1}
                                                                                    {"."}
                                                                                </span>
                                                                                <br />
                                                                                <span>
                                                                                    <span className='font-weight-bolder'>NAME:</span>
                                                                                    {" "}
                                                                                    <span>
                                                                                        {item.organizationName}
                                                                                    </span>
                                                                                </span>
                                                                                <br />
                                                                                <span className='text-nowrap'>
                                                                                    <span className='font-weight-bolder'>ID:</span>
                                                                                    {" "}
                                                                                    <span className='text-nowrap'>
                                                                                        {item.organizationID}
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mt-1">
                                                                                    <Grid item xs={12}>
                                                                                        <Button
                                                                                            size='small'
                                                                                            color='error'
                                                                                            variant='contained'
                                                                                            onClick={async () => {

                                                                                                const organizationID = item.organizationID;
                                                                                                const vehicleID = row?.original?.document?.ID;
                                                                                                const vehicleVin = row?.original?.document?.VIN;
                                                                                                const assignerID = loggedInUser.userID;

                                                                                                setOrganizationIDToUnassign(organizationID);
                                                                                                setOrganizationAssignerIDToUnassign(assignerID);
                                                                                                setOrganizationVehicleIdToUnassign(vehicleID);
                                                                                                setOrganizationVehicleVinToUnassign(vehicleVin);
                                                                                                setOrganizationNameToUnassign(item.organizationName);

                                                                                                setToggleOrganizationUnassignmentConfirmationModal(true);

                                                                                            }}
                                                                                        >
                                                                                            Unassign this organization
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                    ) : (<>
                                                                        <span>
                                                                            Nothing to see here
                                                                        </span>
                                                                    </>) : (<>
                                                                        <span>
                                                                            Nothing to see here
                                                                        </span>
                                                                    </>)}
                                                                </Typography>
                                                            </div>
                                                        </Box>
                                                    </div>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Item className='rounded-xl'>
                                                    <div className={`${classes.itemInnerDiv}`}>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>Details</Typography>
                                                            <div className='mt-5 p-2 container-border rounded'>
                                                                <span className='mb-1'>
                                                                    <span className='font-weight-bolder'>ID:</span>
                                                                    {" "}
                                                                    <span>
                                                                        {row.original.document?.ID}
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <span className='mb-1'>
                                                                    <span className='font-weight-bolder'>Staff Vehicle?:</span>
                                                                    {" "}
                                                                    <span>
                                                                        {row.original.document?.isStaffVehicle ? "YES" : "NO"}
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <span className='mb-1'>
                                                                    <span className='font-weight-bolder'>Customer Vehicle?:</span>
                                                                    {" "}
                                                                    <span>
                                                                        {row.original.document?.isCustomerVehicle ? "YES" : "NO"}
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <span className='mb-1'>
                                                                    <span className='font-weight-bolder'>CreatedAt:</span>
                                                                    {" "}
                                                                    <span>
                                                                        {convertUnixTimestampToUTC(row.original.document?.createdAt)}
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <span className='mb-1'>
                                                                    <span className='font-weight-bolder'>Last Updated:</span>
                                                                    {" "}
                                                                    <span>
                                                                        {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                                                    </span>
                                                                </span>
                                                                <div className='mt-2 mb-2'>
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="error"
                                                                        aria-label="Add"
                                                                        onClick={() => {

                                                                        }}
                                                                    >
                                                                        Immobilize Bike
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </div>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>

                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const vehicleID = row.original.document.ID;
                                    const valuesToSet = {
                                        vehicleModelID: row.original.document.vehicleModelID,
                                        VIN: row.original.document.VIN,
                                        profileImage: row.original.document.profileImageURL,
                                        batteryID: row.original.document.battery.ID
                                    };

                                    setSelectedVehicleID(vehicleID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShow(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <EditTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Edit Vehicle Record
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const vehicleModelID = row.original.document?.vehicleModelID;
                                    setSelectedVehicleModelID(vehicleModelID);
                                    setStorageItem('vehicleModelIdForModelData', vehicleModelID);

                                    const vehicleData = row.original;
                                    setSelectedVehicleData(vehicleData);
                                    setStorageItem('vehicleDataForDetailing', vehicleData);
                                    setShowVehicleDetails(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <InfoTwoToneIcon
                                        style={{ color: "#22ABE2" }}
                                    />
                                </ListItemIcon>
                                See Vehicle Details
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={async () => {

                                    const vehicleID = row.original.document?.ID;
                                    try {

                                        await designateAsStaffVehicle(vehicleID).then((response) => {

                                            setSeverity('success');
                                            TableErrorMessage.current = response.data.message;
                                            openSnackBar();
                                            setReRender(prevState => prevState + 1);
                                            closeMenu();

                                        }).catch((error) => {

                                            setSeverity('error');
                                            TableErrorMessage.current = (error).toString();
                                            openSnackBar();
                                            closeMenu();

                                        });

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                        openSnackBar();
                                        closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <ManageAccountsTwoToneIcon
                                        color='secondary'
                                    />
                                </ListItemIcon>
                                Designate As Staff Vehicle
                            </MenuItem>,
                            <MenuItem
                                key={3}
                                onClick={async () => {

                                    const vehicleID = row.original.document?.ID;
                                    try {

                                        await removeStaffVehicleDesignation(vehicleID).then(() => {

                                            setSeverity('success');
                                            TableErrorMessage.current = "Vehicle staff designation removed successfully";
                                            openSnackBar();
                                            setReRender(prevState => prevState + 1);
                                            closeMenu();

                                        }).catch((error) => {

                                            setSeverity('error');
                                            TableErrorMessage.current = (error).toString();
                                            openSnackBar();
                                            closeMenu();

                                        });

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                        openSnackBar();
                                        closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <RemoveCircleTwoToneIcon
                                        style={{ color: "#FF0000" }}
                                    />
                                </ListItemIcon>
                                Remove Staff Vehicle Designation
                            </MenuItem>,
                            <MenuItem
                                key={4}
                                onClick={async () => {

                                    const vehicleID = row.original.document?.ID;
                                    try {

                                        await designateAsCustomerVehicle(vehicleID).then((response) => {

                                            setSeverity('success');
                                            TableErrorMessage.current = response.data.message;
                                            openSnackBar();
                                            setReRender(prevState => prevState + 1);

                                            return closeMenu();

                                        }).catch((error) => {

                                            setSeverity('error');
                                            TableErrorMessage.current = (error).toString();
                                            openSnackBar();

                                            return closeMenu();

                                        });

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.response.data.message ?? "an error occurred, please try again later";
                                        openSnackBar();
                                        closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <ElectricBikeTwoToneIcon
                                        style={{ color: "#330033" }}
                                    />
                                </ListItemIcon>
                                Designate as Customer Vehicle
                            </MenuItem>,
                            <MenuItem
                                key={5}
                                onClick={async () => {

                                    const vehicleID = row.original.document?.ID;
                                    try {

                                        await removeCustomerVehicleDesignation(vehicleID).then(() => {

                                            setSeverity('success');
                                            TableErrorMessage.current = "Vehicle customer designation removed successfully";
                                            openSnackBar();
                                            setReRender(prevState => prevState + 1);

                                            return closeMenu();

                                        }).catch((error) => {

                                            setSeverity('error');
                                            TableErrorMessage.current = (error).toString();
                                            openSnackBar();

                                            return closeMenu();

                                        });

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                        openSnackBar();
                                        closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <RemoveCircleTwoToneIcon
                                        style={{ color: "#FF0000" }}
                                    />
                                </ListItemIcon>
                                Remove Customer Vehicle Designation
                            </MenuItem>,
                            <MenuItem
                                key={6}
                                onClick={async () => {

                                    const vehicleID = row.original.document?.ID;
                                    try {

                                        await removeVehicle(vehicleID).then(() => {

                                            setSeverity('error');
                                            TableErrorMessage.current = "Vehicle record removed successfully";
                                            openSnackBar();
                                            setReRender(prevState => prevState + 1);
                                            closeMenu();

                                        }).catch((error) => {

                                            setSeverity('error');
                                            TableErrorMessage.current = (error).toString();
                                            openSnackBar();
                                            closeMenu();

                                        });

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                        openSnackBar();
                                        closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <DeleteForeverTwoToneIcon
                                        style={{ color: "#FF0000" }}
                                    />
                                </ListItemIcon>
                                Remove Vehicle Record
                            </MenuItem>,
                            <MenuItem
                                key={7}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <PanToolTwoToneIcon
                                        color='warning'
                                    />
                                </ListItemIcon>
                                Immobilize Vehicle
                            </MenuItem>,
                            <MenuItem
                                key={8}
                                onClick={async () => {

                                    const vehicleID = row?.original?.document?.ID;
                                    try {

                                        const response = await activateVehicle(vehicleID);
                                        setSeverity('success');
                                        TableErrorMessage.current = response.data.message;
                                        openSnackBar();
                                        setReRender(prevState => prevState + 1);

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                        openSnackBar();
                                        closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <ToggleOnTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Activate Vehicle
                            </MenuItem>,
                            <MenuItem
                                key={9}
                                onClick={async () => {

                                    const vehicleID = row?.original?.document?.ID;
                                    try {

                                        const response = await deactivateVehicle(vehicleID);
                                        setSeverity('success');
                                        TableErrorMessage.current = response.data.message;
                                        openSnackBar();
                                        setReRender(prevState => prevState + 1);
                                        closeMenu();

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                        openSnackBar();
                                        closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <ToggleOffTwoToneIcon
                                        color='error'
                                    />
                                </ListItemIcon>
                                Deactivate Vehicle
                            </MenuItem>,
                            <MenuItem
                                key={10}
                                onClick={async () => {

                                    const vehicleID = row.original.document?.ID;

                                    setToggleTrackerAssignmentModal(true);
                                    setSelectedVehicleIDForTrackerAssignment(vehicleID);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <GpsFixedTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                Assign Tracker
                            </MenuItem>,
                            <MenuItem
                                key={11}
                                onClick={async () => {

                                    setIsFetchingTrackerData(true);

                                    const vehicleID = row.original?.document?.ID;
                                    const vehicleVin = row.original?.document?.VIN;
                                    const assignerID = loggedInUser.userID;
                                    const assignmentHistory = row.original?.document?.assignmentHistory;

                                    if (assignmentHistory && assignmentHistory.length) {

                                        for (let i = 0; i < assignmentHistory.length; i++) {

                                            const assignedEntity = assignmentHistory[i].assignedEntity;
                                            const assignmentType = assignmentHistory[i].assignmentType;

                                            if (assignmentType === "added" && assignedEntity === "tracker") {

                                                const trackerID = assignmentHistory[i].assigneeID;
                                                try {

                                                    const response = await getTrackerByID(trackerID);

                                                    const IMEI = response?.data?.document?.IMEI;
                                                    const manufacturer = response?.data?.document?.model;

                                                    setTrackerIMEIToUnassign(IMEI);
                                                    setTrackerManufacturerToUnassign(manufacturer);
                                                    setTrackerIDToUnassign(trackerID);
                                                    setTrackerAssignerIDToUnassign(assignerID);
                                                    setTrackerVehicleIdToUnassign(vehicleID);
                                                    setTrackerVehicleVinToUnassign(vehicleVin);

                                                    setIsFetchingTrackerData(false);
                                                    setToggleTrackerUnassignmentConfirmationModal(true);
                                                    return closeMenu();

                                                } catch (error) {

                                                    setSeverity('error');
                                                    setIsFetchingTrackerData(false);
                                                    TableErrorMessage.current = error.response.data.message;
                                                    openSnackBar();
                                                    return closeMenu();

                                                }

                                            }

                                            if ((1 + i) === assignmentHistory.length) {

                                                setSeverity('info');
                                                setIsFetchingTrackerData(false);
                                                TableErrorMessage.current = "no tracker assigned to this vehicle";
                                                openSnackBar();
                                                return closeMenu();

                                            }

                                        }

                                    }

                                    setSeverity('info');
                                    setIsFetchingTrackerData(false);
                                    TableErrorMessage.current = "no tracker assigned to this vehicle";
                                    openSnackBar();
                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <GpsNotFixedTwoToneIcon
                                        color='error'
                                    />
                                </ListItemIcon>
                                <span>
                                    Unassign Tracker
                                    {" "}
                                    <span>
                                        {isFetchingTrackerData && <BasicLoader size={"sm"} />}
                                    </span>
                                </span>
                            </MenuItem>,
                            <MenuItem
                                key={12}
                                onClick={async () => {

                                    setSelectedVehicleIDForOrganization(row.original?.document?.ID);

                                    setToggleOrganizationAssignmentConfirmationModal(true);
                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <CorporateFareTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                <span>
                                    Assign to Organization
                                </span>
                            </MenuItem>,
                            <MenuItem
                                key={13}
                                onClick={async () => {

                                    setSpecRepairModalTile("Set Vehicle as in Repair");
                                    setSpecRepairType("in-repair");
                                    setRepairCallToActionText("Set as in repair");

                                    setSelectedVehicleIDForRepair(row.original?.document?.ID);
                                    setSelectedVehicleVINForRepair(row.original?.document?.VIN);

                                    setToggleRepairVehicleModal(true);
                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <HandymanTwoToneIcon
                                        color='warning'
                                    />
                                </ListItemIcon>
                                <span>
                                    Set Vehicle as in Repair
                                </span>
                            </MenuItem>,
                            <MenuItem
                                key={14}
                                onClick={async () => {

                                    setSpecRepairModalTile("Set Vehicle as Out of Repair");
                                    setSpecRepairType("out-repair");
                                    setRepairCallToActionText("Set as out of repair");

                                    setSelectedVehicleIDForRepair(row.original?.document?.ID);
                                    setSelectedVehicleVINForRepair(row.original?.document?.VIN);

                                    setToggleRepairVehicleModal(true);
                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={false}
                            >
                                <ListItemIcon>
                                    <BuildCircleTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                <span>
                                    Set Vehicle as out of Repair
                                </span>
                            </MenuItem>,
                            <MenuItem
                                key={15}
                                onClick={async () => {

                                    setSelectedVehicleDataForMetrics(row.original?.document);
                                    setSelectedVehicleID(row.original?.document?.ID);
                                    setSelectedVehicleVinForMetrics(row.original?.document?.VIN);

                                    setVehicleTelemetryModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={false}
                            >
                                <ListItemIcon>
                                    <AutoGraphTwoToneIcon
                                        color='success'
                                    />
                                </ListItemIcon>
                                <span>
                                    Vehicle Metrics
                                </span>
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleAssignment = () => {

                                setDisableActionButtons(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {

                                    try {

                                        closeSnackbar();

                                        if (!assignmentType) {

                                            TableErrorMessage.current = "Assignment parent entity not set at this time!";
                                            setSeverity('warning');

                                            setDisableActionButtons(false);
                                            return openSnackBar();

                                        }

                                        switch ((assignmentType).toLowerCase()) {

                                            case 'driver':

                                                if (!selectedDriverIdForVehicleAssignment) {

                                                    TableErrorMessage.current = "No driverID provided for vehicle assignment";
                                                    setSeverity('warning');

                                                    setDisableActionButtons(false);
                                                    return openSnackBar();

                                                }

                                                const driverAssignmentPayload = ({
                                                    vehicleID: row.original.document.ID,
                                                    driverID: selectedDriverIdForVehicleAssignment,
                                                    assignerID: loggedInUser.user_data.document.ID
                                                });

                                                await assignVehicles(driverAssignmentPayload).then((driverAssignmentResponse) => {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = driverAssignmentResponse.data.message;
                                                    openSnackBar();

                                                    setDisableActionButtons(false);
                                                    setReRender(prevState => prevState + 1);
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('error');

                                                    if (error.response) {

                                                        TableErrorMessage.current = error?.response?.data?.message;
                                                        openSnackBar();

                                                        setDisableActionButtons(false);
                                                        return onChanger();

                                                    }

                                                    console.log(error);
                                                    TableErrorMessage.current = "error" ?? "an expected error occurred";
                                                    openSnackBar();

                                                    setDisableActionButtons(false);
                                                    return onChanger();

                                                });

                                                break;

                                            case 'organization':

                                                if (!organizationIdForVehicleAssignment) {

                                                    TableErrorMessage.current = "No organizationID provided for vehicle assignment";
                                                    setSeverity('warning');

                                                    setDisableActionButtons(false);
                                                    return openSnackBar();

                                                }

                                                const organizationAssignmentPayload = ({
                                                    vehicleID: row.original.vehicleID,
                                                    organizationID: organizationIdForVehicleAssignment,
                                                    assignerID: loggedInUser.user_data.document.ID
                                                });

                                                await assignVehiclesToOrganization(organizationAssignmentPayload).then((assignmentResponse) => {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = assignmentResponse.data.message;
                                                    openSnackBar();

                                                    setReRender(prevState => prevState + 1);
                                                    setDisableActionButtons(false);
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('error');

                                                    if (error.response) {

                                                        TableErrorMessage.current = error?.response?.data?.message;
                                                        openSnackBar();

                                                        setDisableActionButtons(false);
                                                        return onChanger();

                                                    }

                                                    TableErrorMessage.current = error ?? "an expected error occurred";
                                                    openSnackBar();

                                                    setDisableActionButtons(false);
                                                    return onChanger();

                                                });

                                                break;

                                            case 'tracker':

                                                if (!organizationIdForVehicleAssignment) {

                                                    TableErrorMessage.current = "No trackerID provided for vehicle assignment";
                                                    setSeverity('warning');

                                                    setDisableActionButtons(false);
                                                    return openSnackBar();

                                                }

                                                const trackerAssignmentPayload = ({
                                                    vehicleID: row.original.document.ID,
                                                    trackerID: trackerIdForVehicleAssignment,
                                                    assignerID: loggedInUser.user_data.document.ID,
                                                });

                                                await assignTracker(trackerAssignmentPayload).then((trackerAssignmentResponse) => {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = trackerAssignmentResponse.data.message;
                                                    openSnackBar();

                                                    setReRender(prevState => prevState + 1);
                                                    setDisableActionButtons(false);
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('error');
                                                    setDisableActionButtons(false);

                                                    if (error.response) {

                                                        TableErrorMessage.current = error?.response?.data?.message;
                                                        openSnackBar();

                                                        return onChanger();

                                                    }

                                                    TableErrorMessage.current = error ?? "an expected error occurred";
                                                    openSnackBar();

                                                    return onChanger();

                                                });

                                                break;

                                            default:
                                                setSeverity('warning');
                                                TableErrorMessage.current = 'Assignment parent entity not set at this time!';

                                                openSnackBar();
                                                return setDisableActionButtons(false);
                                        }

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error.message ?? error ?? 'an unexpected error occurred';
                                        openSnackBar();

                                        return setDisableActionButtons(false);

                                    }

                                });

                            };

                            return (

                                <>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                color="info"
                                                onClick={handleExportData}
                                                variant="contained"
                                                size="small"
                                                fullWidth
                                                className="text-nowrap"
                                            >
                                                Export page
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                variant="contained"
                                                size="small"
                                                color='info'
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Export Selected
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <IconButton
                                                aria-label="reload"
                                                color='info'
                                                size="small"
                                                onClick={() => {
                                                    setReRender(prevState => prevState + 1);
                                                }}
                                            >
                                                <ReplayCircleFilledTwoToneIcon
                                                    fontSize='medium'
                                                />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            {assignAction && <Button
                                                color="success"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || !disableActionButtons || !assignAction}
                                                onClick={handleAssignment}
                                                variant="contained"
                                                size="small"
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Assign Vehicle
                                            </Button>}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            {cohortView && <div>
                                                <FormControl
                                                    sx={{ m: 1, minWidth: 120 }}
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <InputLabel id="demo-simple-select-autowidth-label">Year-Month</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-autowidth-label"
                                                        id="demo-simple-select-autowidth"
                                                        value={monthFilterString}
                                                        onChange={handleMonthChange}
                                                        autoWidth
                                                        label="Age"
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select Month for Metrics</em>
                                                        </MenuItem>
                                                        <MenuItem value={"2023-January"}>2023-January</MenuItem>
                                                        <MenuItem value={"2023-February"}>2023-February</MenuItem>
                                                        <MenuItem value={"2023-March"}>2023-March</MenuItem>
                                                        <MenuItem value={"2023-April"}>2023-April</MenuItem>
                                                        <MenuItem value={"2023-May"}>2023-May</MenuItem>
                                                        <MenuItem value={"2023-June"}>2023-June</MenuItem>
                                                        <MenuItem value={"2023-July"}>2023-July</MenuItem>
                                                        <MenuItem value={"2023-August"}>2023-August</MenuItem>
                                                        <MenuItem value={"2023-September"}>2023-September</MenuItem>
                                                        <MenuItem value={"2023-October"}>2023-October</MenuItem>
                                                        <MenuItem value={"2023-November"}>2023-November</MenuItem>
                                                        <MenuItem value={"2023-December"}>2023-December</MenuItem>
                                                        <MenuItem value={"2024-January"}>2024-January</MenuItem>
                                                        <MenuItem value={"2024-February"}>2024-February</MenuItem>
                                                        <MenuItem value={"2024-March"}>2024-March</MenuItem>
                                                        <MenuItem value={"2024-April"}>2024-April</MenuItem>
                                                        <MenuItem value={"2024-May"}>2024-May</MenuItem>
                                                        <MenuItem value={"2024-June"}>2024-June</MenuItem>
                                                        <MenuItem value={"2024-July"}>2024-July</MenuItem>
                                                        <MenuItem value={"2024-August"}>2024-August</MenuItem>
                                                        <MenuItem value={"2024-September"}>2024-September</MenuItem>
                                                        <MenuItem value={"2024-October"}>2024-October</MenuItem>
                                                        <MenuItem value={"2024-November"}>2024-November</MenuItem>
                                                        <MenuItem value={"2024-December"}>2024-December</MenuItem>
                                                    </Select>
                                                    <FormHelperText className="text-muted font-weight-bold">
                                                        Select a month to export Cohort Mileage Data
                                                    </FormHelperText>
                                                </FormControl>
                                                {loadingMonthFilterString && <BasicLoader size={"lg"} />}
                                            </div>}
                                        </Grid>
                                    </Grid>

                                </>

                            );

                        }}
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        enableBottomToolbar
                        positionPagination="bottom"
                    />
                </div>
            </div >
        </>
    );
}
