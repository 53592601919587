import React, { useEffect, useState } from "react";
import { useSubheader } from "../../_wahu/layout";
import OnboardingForm from "../../_wahu/_partials/widgets/forms/OnboardingForm";

export const OnboardingPage = () => {

    const suhbeader = useSubheader();

    const [reRender, setReRender] = useState(0);

    const initialValues = window.history.state.state.initialValues;
    const createEditText = window.history.state.state.createEditText;
    const fieldDisabled = window.history.state.state.fieldDisabled;
    const adminID = window.history.state.state.adminID;
    const displayName = window.history.state.state.displayName;

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        const load = async () => {
            try {
                suhbeader.setTitle("Onboarding");
            } catch (error) {
                console.log(error);
            }
        };

        isMounted && load();

        return () => {
            isMounted = false;
        };

    }, [suhbeader, reRender]);

    return (
        <>
            <div className="row">
                <div className="bg-white col-xxl-12 rounded-lg">
                    <div className="p-3">
                        <OnboardingForm
                            initialValues={initialValues}
                            createEditText={createEditText}
                            fieldDisabled={fieldDisabled}
                            onChanger={onChangeWorker}
                            adminID={adminID}
                            displayName={displayName}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
