/**
 * App entry point. This and `public/index.html` files can not be changed or moved.
*/
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
//import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_wahu/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_wahu/_assets/plugins/flaticon/flaticon.css";
import "./_wahu/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import {
    ManaLayoutProvider,
    ManaSplashScreenProvider,
    ManaSubheaderProvider,
} from "./_wahu/layout";
import { ManaI18nProvider } from "./_wahu/i18n";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic mana mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = *//*  _redux.mockAxios(axios); */
/**
 * Inject mana interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ManaI18nProvider>
        <ManaLayoutProvider>
            <ManaSubheaderProvider>
                <ManaSplashScreenProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <App store={store} persistor={persistor} basename={PUBLIC_URL} />
                    </LocalizationProvider>
                </ManaSplashScreenProvider>
            </ManaSubheaderProvider>
        </ManaLayoutProvider>
    </ManaI18nProvider>
    //document.getElementById("root")
);
