import React, { useEffect, useState } from "react";
import {
    Grow,
} from '@mui/material';
import BasicLoader from "../../loaders/SimpleLoader";
import BarChartAltAlt from "../../charts/BarChartAltAlt";

export function CarbonSummaryCard({ quarterMonthlyMileageValue, bikeCount, specCount, specQuarter }) {

    const [isLoading, setIsLoading] = useState(true);

    function randomAnimationDuration() {
        return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
    }

    useEffect(() => {

        let isMounted = true;

        setTimeout(() => {
            isMounted && setIsLoading(false);
        }, 500);

        return () => {
            isMounted = false;
        };

    }, []);

    return (
        <>

            <div className="centerMan">
                {isLoading && (<>
                    <BasicLoader size={"lg"} />
                </>)}
            </div>

            <Grow
                in={!isLoading}
                style={{ transformOrigin: '0 1 0' }}
                {...(!isLoading ? { timeout: randomAnimationDuration() } : {})}
            >
                <div className="row p-5">
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Total Distance Ridden (km All Bikes):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {(quarterMonthlyMileageValue).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Total # of Bikes deployed(Distributed):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {bikeCount}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                # of Bikes deployed(Distributed in Quarter):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {specCount}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </Grow>
            <Grow
                in={!isLoading}
                style={{ transformOrigin: '0 1 0' }}
                {...(!isLoading ? { timeout: randomAnimationDuration() } : {})}
            >
                <div className="row p-5">
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                # of bikes Written off(Non-Operational in Quarter):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {0}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Average Distance Ridden per Bike(km/quarter):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {(quarterMonthlyMileageValue / bikeCount).toFixed(2)}
                                </span>
                                <br />
                                <span className="bg-light-danger p-1 rounded-xl text-danger">
                                    Critical Minimum Threshold:
                                    {" "}
                                    <span className="bold">
                                        3600 km
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Average Distance Ridden per Bike per day(km/quarter):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {((quarterMonthlyMileageValue / bikeCount) / 75).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </Grow>
            <Grow
                in={!isLoading}
                style={{ transformOrigin: '0 1 0' }}
                {...(!isLoading ? { timeout: randomAnimationDuration() } : {})}
            >
                <div className="row p-5">
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Quarterly Expected Contract ITMOs(QECI/Bike):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {((quarterMonthlyMileageValue / bikeCount) * 0.000094491).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Expected Contract ITMOs(ECI: Bike in Quarter):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {(specCount * ((quarterMonthlyMileageValue / bikeCount) * 0.000094491) * 8).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Total Revenue Generated (USD):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {((42.7) * (specCount * ((quarterMonthlyMileageValue / bikeCount) * 0.000094491) * 8)).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </Grow>
            <Grow
                in={!isLoading}
                style={{ transformOrigin: '0 1 0' }}
                {...(!isLoading ? { timeout: randomAnimationDuration() } : {})}
            >
                <div className="row p-5">
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Government of Ghana Fees (USD):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {((5.2) * (specCount * ((quarterMonthlyMileageValue / bikeCount) * 0.000094491) * 8)).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                First Climate Fees (USD):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {((2.5) * (specCount * ((quarterMonthlyMileageValue / bikeCount) * 0.000094491) * 8)).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="col p-1">
                        <div className="bg-light rounded-lg p-3" style={{
                            height: "91px"
                        }}>
                            <span className="bold">
                                Wahu Carbon Sales Revenue (USD):
                                <br />
                                <span style={{
                                    fontSize: "20px"
                                }}>
                                    {((35) * (specCount * ((quarterMonthlyMileageValue / bikeCount) * 0.000094491) * 8)).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </Grow>
            <Grow
                in={!isLoading}
                style={{ transformOrigin: '0 1 0' }}
                {...(!isLoading ? { timeout: randomAnimationDuration() } : {})}
            >
                <div className="row p-5">
                    <div className="col-12 bg-light rounded-xl">
                        <div className="row mt-5">
                            <div className="col p-2">
                                <div className="centerMan">
                                    <span className="bg-light-primary text-primary pt-1 pb-1 pl-2 pr-2 rounded-xl bold">
                                        ECI - Expected Contract ITMOs
                                    </span>
                                </div>
                            </div>
                            <div className="col p-2">
                                <div className="centerMan">
                                    <span className="bg-light-primary text-primary pt-1 pb-1 pl-2 pr-2 rounded-xl bold">
                                        km - Distance in Kilometers
                                    </span>
                                </div>
                            </div>
                        </div>
                        <BarChartAltAlt
                            categories={[
                                "Q1",
                                "Q2",
                                "Q3"
                            ]}
                            data={[
                                8.62,
                                87.77,
                                183.10,
                            ]}
                            dataAlt={[
                                2.58,
                                57.34,
                                53.83
                            ]}
                            exportFilename={`Test`}
                            xAxisName={"Quarters"}
                            yAxisName={"ECI & km"}
                            yAxisNameAlt={"Average Distance Ridden(Bike/Day)"}
                            chartHeight={"450px"}
                        />
                    </div>
                </div>
            </Grow>

        </>
    );

}