import React, { useState } from 'react';
import Chart from "react-apexcharts";

const LineChart = ({ categories, data, exportFilename }) => {

    const [options, setOptions] = useState({
        chart: {
            id: "line-chart",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: exportFilename,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        }
                    },
                    svg: {
                        filename: exportFilename,
                    },
                    png: {
                        filename: exportFilename,
                    }
                }
            },
            stroke: {
                curve: "straight",
                show: true,
                width: 2,
            },
            dataLabels: {
                enabled: true
            },
            title: {
                text: "Vehicle production trends",
                align: "left"
            },
            grid: {
                row: {
                    colors: ['#dbd9d9', 'transparent'],
                    opacity: 0.5
                },
            },
        },
        xaxis: {
            categories: categories.timeSeriesArray,
            labels: {
                show: true
            }
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    legend: {
                        position: "bottom"
                    }
                }
            }
        ]
    });

    const [series, setSeries] = useState(
        [
            {
                data: data.countArray,
                name: "# of vehicles produced",
            }
        ]
    );

    const handleRangeSelector = (range) => {

        let newData = [];

        switch (range) {
            case 'Week':
                newData = [30, 40, 45, 50, 49, 60, 70, 91]; // Replace this with data for 1 month range
                break;
            case 'Month':
                newData = [40, 50, 55, 60, 59, 70, 80, 101]; // Replace this with data for 3 months range
                break;
            case 'Year':
                newData = [50, 60, 65, 70, 69, 80, 90, 111]; // Replace this with data for 6 months range
                break;
            case 'All':
                newData = [60, 70, 75, 80, 79, 90, 100, 121]; // Replace this with data for 1 year range
                break;
            default:
                newData = [30, 40, 45, 50, 49, 60, 70, 91];
        }

        setSeries([{ data: newData }]);
    };

    return (

        <div className='line-chart-area'>
            <Chart
                type='line'
                options={options}
                series={series}
            />
        </div>

    );

};

export default LineChart;