import React, { useState, useRef, useEffect } from "react";
import { DriverProfileCard } from "./components/DriverProfileCard";
import PropTypes from 'prop-types';
import { DriverActionsTabs } from "./components";
import { AUTH_BASE_URL } from "../../services/baseService";
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    IconButton,
    Snackbar,
    Button,
    Alert,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import BasicLoader from "../../../_wahu/_partials/loaders/SimpleLoader";
import { riderOnboardingStepNames } from "../../../_wahu/_helpers/Interfaces";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function DriverDetailsPage({ selectedDriverData, onChangeFunction }) {

    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [isError, setIsError] = useState(false);

    const [specDriverData, setSpecDriverData] = useState(null);
    const [reRender, setReRender] = useState(0);

    const TableMessage = useRef();

    function isChanger() {
        setLoading(true);
        setReRender(prevState => prevState + 1);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchDriverData = async () => {

            const url = new URL(`/users/drivers/${selectedDriverData.userID}`, `${AUTH_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const restructuredOnboardingArray = [];
                restructuredOnboardingArray.length = 14;

                const driverOnboarding = data.user_data.onboarding;
                for (let i = 0; i < driverOnboarding.length; i++) {

                    const element = driverOnboarding[i];
                    const elementObjectKey = Object.keys(element)[0];

                    switch (elementObjectKey) {
                        case riderOnboardingStepNames[0]:
                            restructuredOnboardingArray[0] = element;
                            break;
                        case riderOnboardingStepNames[1]:
                            restructuredOnboardingArray[1] = element;
                            break;

                        case riderOnboardingStepNames[2]:
                            restructuredOnboardingArray[2] = element;
                            break;

                        case riderOnboardingStepNames[3]:
                            restructuredOnboardingArray[6] = element;
                            break;

                        case riderOnboardingStepNames[4]:
                            restructuredOnboardingArray[10] = element;
                            break;

                        case riderOnboardingStepNames[5]:
                            restructuredOnboardingArray[11] = element;
                            break;

                        case riderOnboardingStepNames[6]:
                            restructuredOnboardingArray[9] = element;
                            break;

                        case riderOnboardingStepNames[7]:
                            restructuredOnboardingArray[3] = element;
                            break;

                        case riderOnboardingStepNames[8]:
                            restructuredOnboardingArray[4] = element;
                            break;

                        case riderOnboardingStepNames[9]:
                            restructuredOnboardingArray[5] = element;
                            break;

                        case riderOnboardingStepNames[10]:
                            restructuredOnboardingArray[7] = element;
                            break;

                        case riderOnboardingStepNames[11]:
                            restructuredOnboardingArray[8] = element;
                            break;

                        case riderOnboardingStepNames[12]:
                            restructuredOnboardingArray[12] = element;
                            break;

                        case riderOnboardingStepNames[13]:
                            restructuredOnboardingArray[13] = element;
                            break;

                        case riderOnboardingStepNames[14]:
                            restructuredOnboardingArray[14] = element;
                            break;

                        default:
                            break;
                    }

                    if ((i + 1) === driverOnboarding.length) {

                        data.onboarding = restructuredOnboardingArray;
                        setSpecDriverData(data);

                        return setLoading(false);

                    }

                }

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableMessage.current = "network error, please check your internet connection";

                    setIsError(true);
                    setOpenSnackbar(true);

                    return setLoading(false);

                }

                TableMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                setSeverity("error");
                setIsError(true);
                setOpenSnackbar(true);

                return setLoading(false);

            }

        };

        isMounted && fetchDriverData();

        return () => isMounted = false;

    }, [
        selectedDriverData,
        reRender,
    ]);

    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <div className="row">
                <div className="col-xxl-12">
                    {loading && (<>
                        <div className="centerMan p-4">
                            <BasicLoader size={"lg"} />
                        </div>
                    </>)}
                    {isError && !loading && (<>
                        <div className="centerMan p-4">
                            <p className="text-danger">
                                <WarningTwoToneIcon color="error" />
                                {" "}
                                {TableMessage.current}
                            </p>
                        </div>
                    </>)}
                    <div className="row">
                        {!loading && !isError && (<>
                            <div className="col-xxl-12 mb-5">
                                <Accordion className="rounded-lg">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <span className="font-size-lg font-weight-bold">
                                            Account Actions
                                            <br />
                                            <small className="text-muted">
                                                Click to expand/collapse
                                            </small>
                                        </span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DriverProfileCard
                                            driverDetails={specDriverData}
                                            onchangeFunction={onChangeFunction}
                                            isChanger={isChanger}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="col-xxl-12">
                                <Accordion className="rounded-lg">
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                    >
                                        <span className="font-size-lg font-weight-bold">
                                            Account Details
                                            <br />
                                            <small className="text-muted">
                                                Click to expand/collapse
                                            </small>
                                        </span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DriverActionsTabs
                                            className="card-stretch gutter-b"
                                            driverDetails={specDriverData}
                                            onChangeFunction={onChangeFunction}
                                            isChanger={isChanger}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>

        </>
    );
}
