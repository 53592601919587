import React from 'react';
import { DriversTable } from './DriversTable';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';

export function ActivatedDriversTable() {

    return (
        <>
            <DriversTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/drivers/activated-drivers/getAll"}
                tableTopHeader={"Active Riders"}
                baseUrl={FLEET_MANAGING_BASE_URL}
                tableSubHeader={"Manage Active Rider records from this table"}
            />
        </>
    );

}
