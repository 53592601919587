import React from 'react';
import { VehiclesTable } from './VehiclesTable';

export const GhanaVehiclesTable = () => {

    return (
        <>
            <VehiclesTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/organizations/wahu-ghana/vehicles/getAll"}
                tableTopHeader={"Ghana Vehicles"}
                tableSubHeader={"Manage Ghana vehicle records from this table"}
            />
        </>
    );

};