import React, { useEffect } from "react";
import { useSubheader } from "../../_wahu/layout";

export const MyPage = () => {

    const suhbeader = useSubheader();
    useEffect(() => {
        const load = async () => {
            try {
                suhbeader.setTitle("Test Tables");
            } catch (error) {
                console.log(error);
            }
        };
        load();
    }, [suhbeader]);

    return (
        <>
            <div className="row">
                {/* <div className="col-xxl-12">
                    <UserTableWidget className="card-stretch gutter-b" />
                </div> */}
            </div>
        </>
    );
};
