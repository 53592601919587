import React from 'react';
import {
    PerspectiveCamera,
    Scene,
    AmbientLight,
    WebGLRenderer,
    Matrix4,
    DirectionalLight,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { googleMapOptions } from '../../../../_helpers/Interfaces';

const WebglOverlayComponent = ({ map }) => {

    React.useEffect(() => {

        if (!map || !window.google) return;

        let renderer, scene, camera, loader;
        const overlay = new window.google.maps.WebGLOverlayView();

        // Setup the WebGLOverlayView with custom functions
        overlay.onAdd = () => {
            // Called when the overlay is added to the map
            scene = new Scene();
            camera = new PerspectiveCamera();

            const ambientLight = new AmbientLight(0xffffff, 0.75); // Soft white light.

            scene.add(ambientLight);

            const directionalLight = new DirectionalLight(0xffffff, 0.25);

            directionalLight.position.set(0.5, -1, 0.5);
            scene.add(directionalLight);
            // Load the model.
            loader = new GLTFLoader();

            const source = "https://raw.githubusercontent.com/googlemaps/js-samples/main/assets/pin.gltf";

            loader.load(source, (gltf) => {
                gltf.scene.scale.set(10, 10, 10);
                gltf.scene.rotation.x = Math.PI; // Rotations are in radians.
                scene.add(gltf.scene);
            });
        };

        overlay.onContextRestored = ({ gl }) => {
            // Create the js renderer, using the
            // maps's WebGL rendering context.
            renderer = new WebGLRenderer({
                canvas: gl.canvas,
                context: gl,
                ...gl.getContextAttributes(),
            });
            renderer.autoClear = false;
            // Wait to move the camera until the 3D model loads.
            loader.manager.onLoad = () => {
                renderer.setAnimationLoop(() => {
                    overlay.requestRedraw();

                    const { tilt, heading, zoom } = googleMapOptions;

                    map.moveCamera({ tilt, heading, zoom });
                    // Rotate the map 360 degrees.
                    if (googleMapOptions.tilt < 67.5) {
                        googleMapOptions.tilt += 0.5;
                    } else if (googleMapOptions.heading <= 360) {
                        googleMapOptions.heading += 0.2;
                        googleMapOptions.zoom -= 0.0005;
                    } else {
                        renderer.setAnimationLoop(null);
                    }
                });
            };
        };

        overlay.onDraw = ({ gl, transformer }) => {
            const latLngAltitudeLiteral = {
                lat: googleMapOptions.center.lat,
                lng: googleMapOptions.center.lng,
                altitude: 100,
            };
            // Update camera matrix to ensure the model is georeferenced correctly on the map.
            const matrix = transformer.fromLatLngAltitude(latLngAltitudeLiteral);

            camera.projectionMatrix = new Matrix4().fromArray(matrix);
            overlay.requestRedraw();
            renderer.render(scene, camera);
            // Sometimes it is necessary to reset the GL state.
            renderer.resetState();
        };

        overlay.onRemove = () => {
            //Cleanup WebGL resources
        };

        // Set the overlay to the map (this ensures it is added to the map)
        overlay.setMap(map);

        // Cleanup when the component unmounts or map changes
        return () => {
            /* if (overlay) {
                overlay.setMap(null);
            } */
        };
    }, [map]);

    return null;
};

export default WebglOverlayComponent;
