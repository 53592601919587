import { useFormik } from "formik";
import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from "yup";
import { getStorageItem } from '../../../../app/services/baseService';
import { createDriverAccount, editDriverDetails } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls/';
import { toAbsoluteUrl } from "../../../_helpers";
import {
    IconButton,
    Snackbar,
    Avatar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Paper,
    Grid,
    styled,
    Alert,
    Badge
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { SimpleModal } from "../../../../_wahu/_partials/widgets/modals/SimpleModal";
import BasicLoader from "../../loaders/SimpleLoader";
import {
    updatedDriverDigitalAddress,
    setBikeAccessoriesAsReceivedByDriver,
    setBikeAccessoriesAsNotReceivedByDriver,
    setGhanaCardAsProvided,
    setGhanaCardAsNotProvided,
    setDigitalAddressAsProvided,
    setDigitalAddressAsNotProvided,
    setInfoSessionAsAttended,
    setInfoSessionAsNotAttended,
    setSelectedPaymentPlanAsChosen,
    setSelectedPaymentPlanAsNotChosen,
    setSafetyCVideoAsWatched,
    setSafetyCVideoAsNotWatched,
    setMaintenanceVideoAsWatched,
    setMaintenanceVideoAsNotWatched,
    setInitialDepositAsPaid,
    setInitialDepositAsNotPaid,
    setInterviewAsAttended,
    setInterviewAsNotAttended,
    setDriverGuarantorDetailsAsProvided,
    setDriverGuarantorDetailsAsNotProvided,
    setDriverHomeAsVisited,
    setDriverHomeAsNotVisited,
    setDriverContractAsSigned,
    setDriverContractAsNotSigned,
    setDriverGuarantorContractAsSigned,
    setDriverGuarantorContractAsNotSigned,
    setBikeAsReceivedByDriver,
    setBikeAsNotReceivedByDriver,
} from "../../../../app/modules/Auth/_redux/authCrud";
import CloseIcon from '@mui/icons-material/Close';
import { riderOnboardingStepNames } from "../../../_helpers/Interfaces";

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
}));

export function ConfirmationForm({ passedData, modalBackControl, stepIdentifier, upState, downState, onChangerFunction, isChanger, noBackModalRouter }) {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('info');

    const TableMessage = useRef();

    async function runOnboardingStepToggler(apiCallerFunction) {

        setLoading(true);

        await apiCallerFunction(passedData.user_data.document.ID).then((apiResponse) => {

            setSeverity("success");
            TableMessage.current = apiResponse.data.message;

            setOpenSnackbar(true);

            onChangerFunction();
            isChanger();

            setTimeout(() => {
                setLoading(false);
                modalBackControl();
            }, 2000);

        }).catch((error) => {

            setLoading(false);
            setSeverity("error");
            TableMessage.current = error?.data?.message ?? "Something went wrong, please try again later";

            setOpenSnackbar(true);

        });

    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    }

    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <div className="card mb-2">
                <div className="card-body">
                    <div className="mt-1 mb-5 centerMan">
                        Are you sure you want to perform this action?
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                        <Grid item xs={6}>
                            <div className="centerMan">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="success"
                                    onClick={async () => {

                                        stepIdentifier === riderOnboardingStepNames[0] && upState && await runOnboardingStepToggler(setGhanaCardAsProvided);
                                        stepIdentifier === riderOnboardingStepNames[0] && downState && await runOnboardingStepToggler(setGhanaCardAsNotProvided);

                                        stepIdentifier === riderOnboardingStepNames[1] && upState && await runOnboardingStepToggler(setDigitalAddressAsProvided);
                                        stepIdentifier === riderOnboardingStepNames[1] && downState && await runOnboardingStepToggler(setDigitalAddressAsNotProvided);

                                        stepIdentifier === riderOnboardingStepNames[2] && upState && await runOnboardingStepToggler(setInfoSessionAsAttended);
                                        stepIdentifier === riderOnboardingStepNames[2] && downState && await runOnboardingStepToggler(setInfoSessionAsNotAttended);

                                        stepIdentifier === riderOnboardingStepNames[3] && upState && await runOnboardingStepToggler(setSelectedPaymentPlanAsChosen);
                                        stepIdentifier === riderOnboardingStepNames[3] && downState && await runOnboardingStepToggler(setSelectedPaymentPlanAsNotChosen);

                                        stepIdentifier === riderOnboardingStepNames[4] && upState && await runOnboardingStepToggler(setSafetyCVideoAsWatched);
                                        stepIdentifier === riderOnboardingStepNames[4] && downState && await runOnboardingStepToggler(setSafetyCVideoAsNotWatched);

                                        stepIdentifier === riderOnboardingStepNames[5] && upState && await runOnboardingStepToggler(setMaintenanceVideoAsWatched);
                                        stepIdentifier === riderOnboardingStepNames[5] && downState && await runOnboardingStepToggler(setMaintenanceVideoAsNotWatched);

                                        stepIdentifier === riderOnboardingStepNames[6] && upState && await runOnboardingStepToggler(setInitialDepositAsPaid);
                                        stepIdentifier === riderOnboardingStepNames[6] && downState && await runOnboardingStepToggler(setInitialDepositAsNotPaid);

                                        stepIdentifier === riderOnboardingStepNames[7] && upState && await runOnboardingStepToggler(setInterviewAsAttended);
                                        stepIdentifier === riderOnboardingStepNames[7] && downState && await runOnboardingStepToggler(setInterviewAsNotAttended);

                                        stepIdentifier === riderOnboardingStepNames[8] && upState && await runOnboardingStepToggler(setDriverGuarantorDetailsAsProvided);
                                        stepIdentifier === riderOnboardingStepNames[8] && downState && await runOnboardingStepToggler(setDriverGuarantorDetailsAsNotProvided);

                                        stepIdentifier === riderOnboardingStepNames[9] && upState && await runOnboardingStepToggler(setDriverHomeAsVisited);
                                        stepIdentifier === riderOnboardingStepNames[9] && downState && await runOnboardingStepToggler(setDriverHomeAsNotVisited);

                                        stepIdentifier === riderOnboardingStepNames[10] && upState && await runOnboardingStepToggler(setDriverContractAsSigned);
                                        stepIdentifier === riderOnboardingStepNames[10] && downState && await runOnboardingStepToggler(setDriverContractAsNotSigned);

                                        stepIdentifier === riderOnboardingStepNames[11] && upState && await runOnboardingStepToggler(setDriverGuarantorContractAsSigned);
                                        stepIdentifier === riderOnboardingStepNames[11] && downState && await runOnboardingStepToggler(setDriverGuarantorContractAsNotSigned);

                                        stepIdentifier === riderOnboardingStepNames[12] && upState && await runOnboardingStepToggler(setBikeAsReceivedByDriver);
                                        stepIdentifier === riderOnboardingStepNames[12] && downState && await runOnboardingStepToggler(setBikeAsNotReceivedByDriver);

                                        stepIdentifier === riderOnboardingStepNames[13] && upState && await runOnboardingStepToggler(setBikeAccessoriesAsReceivedByDriver);
                                        stepIdentifier === riderOnboardingStepNames[13] && downState && await runOnboardingStepToggler(setBikeAccessoriesAsNotReceivedByDriver);

                                    }}
                                    disabled={loading ? true : false}
                                >
                                    Yes &nbsp;
                                    {
                                        loading && <BasicLoader size={"sm"} />
                                    }
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="centerMan">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="error"
                                    aria-label="Add"
                                    onClick={() => {
                                        noBackModalRouter();
                                    }}
                                    disabled={loading ? true : false}
                                >
                                    No
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                </div>
            </div>

        </>
    );

}