import {
    Alert,
    Avatar,
    CardHeader,
    IconButton,
    Snackbar,
    Step,
    StepLabel,
    Stepper,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { CreateFaultJobForm } from "../../_wahu/_partials/widgets/forms/CreateFaultJobForm";
import { FaultConfigTable } from "../../_wahu/_partials/widgets/advance-tables/FaultConfigTable";
import { ServiceCenterTable } from "../../_wahu/_partials/widgets/advance-tables/ServiceCenterTable";
import { Button, Card } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    separator: {
        borderColor: theme.palette.success,
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
}));

export function FaultJob({ faultReportId, onChanger }) {
    const [activeStep, setActiveStep] = useState(0);
    const [show, setShow] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [reRender, setReRender] = useState(0);
    const steps = [
        'Select Fault Configuration',
        'Select Service Center',
        'Confirm Details'
    ];

    const globalMessage = useRef();
    const classes = useStyles();

    const handleReset = () => {

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (activeStep === (steps.length - 1)) {
            console.log("here");
            setReRender(prevState => prevState + 1);
            onChanger();
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);

    };

    const handleNext = () => {

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (activeStep === (steps.length - 1)) {
            console.log("here");
            setReRender(prevState => prevState + 1);
            onChanger();
        }

    };


    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <FaultConfigTable
                            isSelectionAction={true}
                            selectionAction={() => {
                                setActiveStep(prevStep => prevStep + 1);
                            }}
                            className={'mt-5'}
                        />
                    </>

                );

            case 1:
                return (
                    <ServiceCenterTable
                        isSelectionAction={true}
                        selectionAction={() => {
                            setActiveStep(prevStep => prevStep + 1);
                        }}
                        className={'mt-5'}
                    />
                );

            case 2:
                return (
                    <div className="mt-5">
                        <CreateFaultJobForm
                            faultReportId={faultReportId}
                            onChanger={onChanger}
                        />
                    </div>
                );

            default:
                return 'Unknown stepIndex';
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={show}
                autoHideDuration={8000}
                onClose={() => {
                    setShow(false);
                }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button
                        key="undo"
                        color="primary"
                        size="small"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        CLOSE
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        setShow(false);
                    }}
                    severity={severity}
                >
                    {<span id="message-id">
                        {globalMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <div>
                <div className="centerMan">Create Fault Job</div>
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {steps.map(step => {
                        return (
                            <Step key={step}>
                                <StepLabel>
                                    {step}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <>
                    {activeStep === steps.length ? (
                        <>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="success"
                                            className={classes.avatar}
                                        >
                                            <CheckCircleTwoToneIcon
                                                color="success"
                                            />
                                        </Avatar>
                                    }
                                    title="Fault Job created successfully"
                                    subheader=""
                                />
                            </Card>
                            <div className="p-1">
                                <hr className={classes.separator} />
                            </div>
                            <Button
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                        </>
                    ) : (
                        <>
                            <div >
                                {activeStep !== 0 &&
                                    <>
                                        <div className="float-right">
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        {activeStep === steps.length - 1 ?
                                            ''
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                Next
                                            </Button>
                                        }
                                    </>
                                }
                            </div>
                            <div className="mt-10">
                                {getStepContent(activeStep)}
                            </div>

                        </>
                    )}
                </>


            </div>

        </>
    );
}