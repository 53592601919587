import {
    Fab,
    IconButton,
    Snackbar,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Paper
} from '@mui/material';
import { styled } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { convertUnixTimestampToUTC } from "../../../_helpers";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    activateTracker,
    deactivateTracker,
    setManaBikeSubscriptionPending,
    setManaBikeSubscriptionCompleted,
    setManaBikeSubscriptionCancelled
} from '../../../../app/modules/Auth/_redux/authCrud';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { CreateEditVendorAccountForm } from '../forms/CreateEditVendorAccountForm';
import { SimpleModal } from '../modals/SimpleModal';
import { JobDetailsList } from "../lists/JobDetailsList";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function WahuBikeSubscriptionsTable({ className }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [show, setShow] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const [initialValues, setInitialValues] = useState({
        contactFirstName: "",
        contactLastName: "",
        businessName: "",
        country: "",
        phoneNumber: "",
        email: "",
        businessRegistrationNumber: "",
        locationLat: 0,
        locationLng: 0,
        locationName: "",
        requestID: ""
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Add Tracker');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');

    const TableErrorMessage = useRef();

    const classes = useStyles();

    const defaultValuesToSet = {
        closeDate: 0,
        requirements: [],
        responsibilities: [],
        roleDescription: "",
        roleTitle: ""
    };

    function triggerSnackBar() {
        setOpenSnackbar(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    function Alerter(props) {
        return (
            <>
                <Alert elevation={6} variant="filled" {...props} />
            </>
        );
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/vehicles/manaBikeSubscriptions/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setData(data.data);
                setRowCount(data.max_count);

            } catch (error) {

                TableErrorMessage.current = error;
                setIsError(true);
                return;

            } finally {

                setIsError(false);
                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);
            setRowCount(0);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            header: 'Subscription Details',
            id: 'document.ID',
            columns: [
                {
                    accessorFn: (row) => `${row.document.driverFirstName}`,
                    id: 'driverFirstName',
                    enableClickToCopy: true,
                    header: 'Driver',
                    Cell: ({ cell, row }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        >
                            <Typography>
                                {cell.getValue()}
                            </Typography>
                        </Box>
                    )
                },
                {
                    accessorFn: (row) => `${(row.document.status).toUpperCase()}`,
                    id: "subscriptionStatus",
                    enableClickToCopy: true,
                    header: 'Status'
                },
                {
                    accessorFn: (row) => `${new Date(row.document.createdAt).toUTCString()}`,
                    id: "createdAt",
                    enableClickToCopy: true,
                    header: 'Created Dated'
                }
            ]
        },
        {
            id: 'document.ID',
            header: 'Actionables',
            columns: [
                {
                    accessorKey: 'document.isActive',
                    header: 'Is Active?',
                    Cell: ({ cell }) => (
                        <Box
                            sx={(theme) => ({
                                backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                                borderRadius: '0.35rem',
                                color: '#fff',

                                p: '0.35rem',
                                textAlign: 'center'
                            })}
                        >
                            {cell.getValue() === true ? "Active" : "Deactivated"}
                        </Box>
                    ),
                },
                {
                    accessorFn: (row) => `${new Date(row.document.createdAt).toLocaleDateString()}`,
                    id: 'document.ID',
                    header: 'Date Created',
                    filterFn: 'lessThanOrEqualTo',
                    sortingFn: 'datetime',
                    Cell: ({ cell }) => cell.getValue(),
                    Header: ({ column }) => <em>
                        {column.columnDef.header}
                    </em>,
                    Filter: ({ column }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                onChange={(newValue) => {
                                    column.setFilterValue(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        helperText={'Filter Mode: Less Than'}
                                        sx={{ minWidth: '120px' }}
                                        variant="standard"
                                    />
                                )}
                                value={column.getFilterValue()}
                            />
                        </LocalizationProvider>
                    )
                }
            ]
        }
    ],
        [],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alerter
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alerter>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Tracker`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditVendorAccountForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Applications`}
                show={showDetailsModal}
                close={() => setShowDetailsModal(false)}
                size="lg"
                body={<JobDetailsList
                    className="card-stretch gutter-b"
                />}
            />
            <div className={`card card-custom ${className} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Wahu Bike Subscription List
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Subscriptions
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Fab
                                    variant="extended"
                                    size="small"
                                    color="secondary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        //setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Add Subscription");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                >
                                    Add Subscription
                                </Fab>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (
                            <div>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Driver: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.driverID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Subscription ID: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.ID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>CreatedAt: </Typography>
                                                <Typography variant="p">
                                                    {convertUnixTimestampToUTC(row.original.document?.createdAt)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Last Updated: </Typography>
                                                <Typography variant="p">
                                                    {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={async () => {

                                    const requestID = row.original.document.ID;
                                    const response = await setManaBikeSubscriptionPending(requestID);

                                    TableErrorMessage.current = response.data.message;
                                    setSeverity('success');

                                    setReRender(prevState => prevState + 1);
                                    setActionBtnDisabled(false);
                                    triggerSnackBar();

                                    setEditState(false);
                                    setFieldDisabled(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <PendingIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Set Subscription Pending
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={async () => {

                                    const requestID = row.original.document.ID;
                                    const response = await setManaBikeSubscriptionCompleted(requestID);

                                    TableErrorMessage.current = response.data.message;
                                    setSeverity('success');

                                    setReRender(prevState => prevState + 1);
                                    setActionBtnDisabled(false);
                                    triggerSnackBar();

                                    setEditState(false);
                                    setFieldDisabled(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <AddTaskIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Complete Subscription Process
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={async () => {

                                    const requestID = row.original.document.ID;
                                    const response = await setManaBikeSubscriptionCancelled(requestID);

                                    TableErrorMessage.current = response.data.message;
                                    setSeverity('success');

                                    setReRender(prevState => prevState + 1);
                                    setActionBtnDisabled(false);
                                    triggerSnackBar();

                                    setEditState(false);
                                    setFieldDisabled(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <CancelIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Cancel Subscription
                            </MenuItem>
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleDeactivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await deactivateTracker(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    } catch (error) {
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    }
                                });
                            };

                            const handleActivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await activateTracker(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    } catch (error) {
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="error"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleDeactivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Deactivate
                                    </Button>
                                    <Button
                                        color="success"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleActivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Activate
                                    </Button>
                                </div>
                            );

                        }}
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
