import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box } from "@mui/material";
import { FLEET_MANAGING_BASE_URL, setStorageItem } from "../../../../app/services/baseService";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar, Button, MenuItem, ListItemIcon, Typography, Grid, styled, Paper, Alert } from "@mui/material";
import { SimpleModal } from "../modals/SimpleModal";
import { MaterialReactTable } from "material-react-table";
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { ModeEditTwoTone } from "@mui/icons-material";
import { CreateEditFaultConfiguration } from "../forms/CreateEditFaultConfiguration";
import DataSaverOnTwoToneIcon from '@mui/icons-material/DataSaverOnTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import UpgradeTwoToneIcon from '@mui/icons-material/UpgradeTwoTone';
import { PartsManagementTable } from "..";
import { FaultCategoriesTable } from "./FaultCategoriesTable";
import { convertUnixTimestampToUTC } from "../../../_helpers";
import { UpdateLabourUnitsForm } from "../forms/UpdateLabourUnitsForm";
import { deleteFaultConfig } from "../../../../app/modules/Auth/_redux/authCrud";
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { FaultConfigParts } from "./FaultConfigParts";
import { FaultConfigCategories } from "./FaultConfigCategories";
import { ButtonGroup } from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function FaultConfigTable({ className, selectionAction, isSelectionAction }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [initialValues, setInitialValues] = useState({
        categories: "",
        recommendedSolution: '',
        labourUnits: 1,
        parts: [],
        personnelCount: 1,
        labourUnitConfigID: '',
        faultDescription: ''
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Fault Config');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [openAddParts, setOpenAddParts] = useState(false);
    const [openRemoveParts, setOpenRemoveParts] = useState(false);
    const [openRemoveCategories, setOpenRemoveCategories] = useState(false);
    const [openAddCategories, setOpenAddCategories] = useState(false);
    const [openUpdateLabourUnits, setOpenUpdateLabourUnits] = useState(false);
    const [initialUpdateValues, setInitialUpdateValues] = useState({
        labourUnitConfigID: '',
        labourUnits: 0
    });

    const TableErrorMessage = useRef();
    const faultConfigId = useRef();
    const classes = useStyles();

    const defaultValuesToSet = {
        categories: "",
        recommendedSolution: '',
        labourUnits: 1,
        parts: [],
        personnelCount: 1,
        labourUnitConfigID: '',
        faultDescription: ''
    };

    function handleClick() {
        setOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    const Alerter = React.forwardRef((props, ref) => {
        return <Alert elevation={6} variant="filled" {...props} ref={ref} />;
    });

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('faultConfigs/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setData(data.data);
                setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    return setOpen(true);

                }

                setSeverity("error");
                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;

                setIsError(true);
                setOpen(true);

            } finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.faultDescription,
            id: 'faultDescription',
            header: 'Fault Description',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.categories?.length,
            id: 'categories',
            header: 'Number of Categories',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.labourUnits,
            id: 'labourUnits',
            header: 'Labour Units',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.recommendedSolution,
            id: 'recommendedSolution',
            header: 'Recommended Solution',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },


    ], []);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alerter
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alerter>
            </Snackbar>
            <SimpleModal
                modal_tittle={"Create/ Edit Fault Configuration"}
                show={show}
                size="lg"
                close={() => {
                    setShow(false);
                }}
                body={
                    <CreateEditFaultConfiguration
                        onChanger={onChangeWorker}
                        initialValues={initialValues}
                        editState={editState}
                        createEditText={createEditText}
                        fieldDisabled={fieldDisabled}
                    />
                }
            />
            <SimpleModal
                modal_tittle={"Add Part(s)"}
                show={openAddParts}
                size="lg"
                close={() => {
                    setOpenAddParts(false);
                }}
                body={
                    <PartsManagementTable
                        assignAction={true}
                    // faultConfigId={faultConfigId.current}
                    />
                }
            />
            <SimpleModal
                modal_tittle={"Remove Part(s)"}
                show={openRemoveParts}
                size="lg"
                close={() => {
                    setOpenRemoveParts(false);
                }}
                body={
                    <FaultConfigParts
                        faultConfigId={faultConfigId.current}
                    />
                }
            />
            <SimpleModal
                modal_tittle={"Remove Categories"}
                show={openRemoveCategories}
                size="lg"
                close={() => {
                    setOpenRemoveCategories(false);
                }}
                body={
                    <FaultConfigCategories
                        faultConfigId={faultConfigId.current}
                    />
                }
            />
            <SimpleModal
                modal_tittle={"Add Categories"}
                show={openAddCategories}
                size="lg"
                close={() => {
                    setOpenAddCategories(false);
                }}
                body={
                    <FaultCategoriesTable
                        assignAction={true}
                        faultConfigId={faultConfigId.current}
                    />
                }
            />
            <SimpleModal
                modal_tittle={"Update Labour Units"}
                show={openUpdateLabourUnits}
                size="lg"
                close={() => {
                    setOpenUpdateLabourUnits(false);
                }}
                body={
                    <UpdateLabourUnitsForm
                        faultConfigID={faultConfigId.current}
                        initialValues={initialUpdateValues}
                        onChanger={onChangeWorker}
                    />
                }
            />
            <div className={`card card-custom ${className} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Fault Configurations Records
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Fault Configuration Records from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Fault Configuration");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Create Fault Configuration
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableRowSelection
                    getRowId={(row) => row.id}
                    initialState={{ showColumnFilters: true }}
                    manualPagination
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableGrouping
                    enableColumnPinning
                    enableRowPinning
                    enableRowActions
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: `${TableErrorMessage.current}`,
                            }
                            : undefined
                    }
                    onPaginationChange={setPagination}
                    rowCount={rowCount}
                    state={{
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching
                    }}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        <MenuItem key={1} onClick={() => {

                            const faultConfigID = row.original.document.ID;
                            const valuesToSet = {
                                categories: row.original.document.categories,
                                recommendedSolution: row.original.document.recommendedSolution,
                                labourUnits: row.original.document.labourUnits,
                                parts: row.original.document.parts,
                                personnelCount: row.original.document.personnelCount,
                                faultDescription: row.original.document.faultDescription,
                                labourUnitConfigID: row.original.document.labourUnitConfigID
                            };
                            setStorageItem('faultConfigID', faultConfigID);
                            setShow(true);
                            setEditState(true);
                            setFieldDisabled(true);
                            setCreateEditText("Update Fault Configuration");
                            setInitialValues(valuesToSet);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <ModeEditTwoTone color="primary" />
                            </ListItemIcon>
                            Edit Fault Configuration
                        </MenuItem>,
                        <MenuItem key={2} onClick={() => {
                            const faultConfigID = row.original.document.ID;
                            faultConfigId.current = faultConfigID;
                            setOpenAddParts(true);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <DataSaverOnTwoToneIcon color="primary" />
                            </ListItemIcon>
                            Add Parts
                        </MenuItem>,
                        <MenuItem key={3} onClick={() => {
                            const faultConfigID = row.original.document.ID;
                            faultConfigId.current = faultConfigID;
                            setOpenRemoveParts(true);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <HighlightOffTwoToneIcon color="primary" />
                            </ListItemIcon>
                            Delete Parts
                        </MenuItem>,
                        <MenuItem key={4} onClick={() => {
                            const faultConfigID = row.original.document.ID;
                            faultConfigId.current = faultConfigID;
                            setOpenAddCategories(true);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <DataSaverOnTwoToneIcon color="primary" />
                            </ListItemIcon>
                            Add Categories
                        </MenuItem>,
                        <MenuItem key={5} onClick={() => {
                            const faultConfigID = row.original.document.ID;
                            // setStorageItem("faultConfigID", faultConfigID)
                            faultConfigId.current = faultConfigID;
                            setOpenRemoveCategories(true);

                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <HighlightOffTwoToneIcon color="primary" />
                            </ListItemIcon>
                            Delete Categories
                        </MenuItem>,
                        <MenuItem key={6} onClick={() => {
                            const faultConfigID = row.original.document.ID;
                            faultConfigId.current = faultConfigID;
                            setOpenUpdateLabourUnits(true);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <UpgradeTwoToneIcon color="primary" />
                            </ListItemIcon>
                            Update Labour Units
                        </MenuItem>,
                        <MenuItem key={7} onClick={async () => {
                            const faultConfigID = row.original.document.ID;
                            try {

                                await deleteFaultConfig(faultConfigID).then(() => {
                                    setSeverity('error');
                                    TableErrorMessage.current = "Fault Configuration removed successfully";
                                    handleClick();
                                    onChangeWorker();
                                    closeMenu();
                                }).catch((error) => {
                                    setSeverity('error');
                                    TableErrorMessage.current = (error).toString();
                                    handleClick();
                                    closeMenu();
                                });

                            } catch (error) {
                                setSeverity('error');
                                TableErrorMessage.current = (error).toString();
                                handleClick();
                                closeMenu();
                            }
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <DeleteTwoToneIcon style={{ color: "#990000" }} />
                            </ListItemIcon>
                            Delete Fault Config
                        </MenuItem>,
                    ]}
                    renderDetailPanel={({ row }) => (
                        <>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Categories: </Typography>
                                            {row.original.document?.categories?.map((item, i) => {
                                                return (
                                                    <li key={i} variant="p">
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Parts: </Typography>
                                            {row.original.document?.parts?.map((item, i) => {
                                                return (
                                                    <li key={i} variant="p">
                                                        {item.partData.partName}
                                                    </li>
                                                );
                                            })}
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>ID: </Typography>
                                            <Typography variant="p">
                                                {row.original.document?.ID}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Last Updated: </Typography>
                                            <Typography variant="p">
                                                {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    renderTopToolbarCustomActions={({ table }) => {

                        const handleSelectionAction = () => {

                            setActionBtnDisabled(false);
                            const selectedRows = table.getSelectedRowModel().rows;

                            setStorageItem("selectedFaultConfig", selectedRows);
                            selectionAction();
                        };

                        return (
                            <div>
                                {isSelectionAction && <ButtonGroup size="small" aria-label="small button group">
                                    <Button
                                        color="secondary"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleSelectionAction}
                                        variant="contained"
                                        size="small"
                                    >
                                        Select Configuration
                                    </Button>
                                </ButtonGroup>}
                            </div>
                        );
                    }}
                    muiSkeletonProps={{
                        animation: 'wave',
                        height: 30,
                    }}
                    muiLinearProgressProps={{
                        color: 'primary',
                    }}
                    muiPaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                        showFirstButton: true,
                        showLastButton: true,
                        color: 'success',
                        shape: 'rounded',
                        showRowsPerPage: true,
                        variant: 'outlined',
                    }}
                    enableStickyFooter
                    enableColumnResizing
                    paginationDisplayMode={"pages"}
                    paginateExpandedRows={true}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: '650px',
                        }
                    }}
                    enableBatchRowSelection
                    enableRowNumbers
                    rowNumberDisplayMode={'original'}
                    selectAllMode={"all"}
                    options={{
                        selection: true,
                    }}
                />
            </div >

        </>
    );
}