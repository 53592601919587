import React, { useState } from 'react';
import Chart from "react-apexcharts";

const BarChart = ({ categories, data, exportFilename }) => {

    const [options, setOptions] = useState({
        chart: {
            id: "bar-chart",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: exportFilename,
                        columnDelimiter: ',',
                        headerCategory: 'category',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        }
                    },
                    svg: {
                        filename: exportFilename,
                    },
                    png: {
                        filename: exportFilename,
                    }
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                    columnWidth: '25%',
                }
            }

        },
        xaxis: {
            categories: categories.current.timeSeriesArray,
        },
        // yaxis: {
        //     labels: {
        //         show: true
        //     }
        // }
    });

    const [series, setSeries] = useState(
        [
            {
                name: "series-1",
                data: [30, 40,]
            }
        ]
    );

    return (
        <div className='centerMan'>
            <Chart
                type='bar'
                options={options}
                series={series}
                width={350}
                height={300}
            />
        </div>

    );

};

export default BarChart;