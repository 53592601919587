import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { addManufacturer, editManufacturer } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls/';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateEditManufactureForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, manufacturerID }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    // eslint-disable-next-line no-useless-escape
    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const creationSchema = Yup.object().shape({
        name: Yup.string().required(),
        contactPerson: Yup.string().required(),
        email: Yup.string().matches(emailReg, 'Email address is not valid!').required(),
        location: Yup.string().required(),
        phone: Yup.string()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    addManufacturer(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        name: values.name,
                        contactPerson: values.contactPerson,
                        email: values.email,
                        location: values.location,
                        phone: values.phone
                    };

                    editManufacturer(editPayload, manufacturerID).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="manufacturerFormName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Manufacturer Name" value={formik.values.name} onChange={formik.handleChange} className={`${getInputClasses("name")}`} name="name" {...formik.getFieldProps("name")} />
                    <Form.Text className="text-muted">
                        Enter Manufacturer Name
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Manufacturer Name field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.name}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="manufacturerFormEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Manufacturer Email" value={formik.values.email} onChange={formik.handleChange} className={`${getInputClasses("email")}`} name="email" {...formik.getFieldProps("email")} />
                    <Form.Text className="text-muted">
                        Enter Manufacturer Email Address
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Address field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="manufacturerFormContactPerson">
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control type="text" placeholder="Contact Person" value={formik.values.contactPerson} onChange={formik.handleChange} className={`${getInputClasses("contactPerson")}`} name="contactPerson" {...formik.getFieldProps("contactPerson")} />
                    <Form.Text className="text-muted">
                        Name of Contact Person for Manufacturer
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Contact Person field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.contactPerson && formik.errors.contactPerson ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.contactPerson}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="manufacturerFormLocation">
                    <Form.Label>Location</Form.Label>
                    <Form.Control type="text" placeholder="Location" value={formik.values.location} onChange={formik.handleChange} className={`${getInputClasses("location")}`} name="location" {...formik.getFieldProps("location")} />
                    <Form.Text className="text-muted">
                        Location of the Manufacturer
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Location field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.location && formik.errors.location ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.location}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="manufacturerFormPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder="Phone Number" value={formik.values.phone} onChange={formik.handleChange} className={`${getInputClasses("phone")}`} name="phone" {...formik.getFieldProps("phone")} />
                    <Form.Text className="text-muted">
                        Manufacturer's Phone Number (Not required)
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Phone Number field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.phone && formik.errors.phone ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.phone}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}