import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_wahu/layout";
import {
    signOut
} from "firebase/auth";
import * as auth from "../_redux/authRedux";
import { authentication } from "../firebase";
import { clearStorage } from "../../../services/baseService";

class Logout extends Component {

    componentDidMount() {

        signOut(authentication).then(() => {
            clearStorage();
            this.props.logout();
        }).catch(() => {
            clearStorage();
            this.props.logout();
        });

    }

    render() {
        const { hasAuthToken } = this.props;
        return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
    }

}

export default connect(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }), auth.actions)(Logout);
