import { makeStyles } from "@mui/styles";
import { Alert, IconButton, Snackbar } from "@mui/material";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(2),
    },
    padding: {
        padding: theme.spacing(0, 2),
    },
}));

export function PeriodicalsDropdownMenu({ handleClick, nameOfPeriodicals }) {

    const TableMessage = useRef();
    const classes = useStyles();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const dispatch = useDispatch();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    }

    function noBackModalRouter() {
        setShowConfirmationModal(false);
    }

    const handleSetPeriodical = (element) => {
        dispatch({
            type: "addPeriod",
            payload: element
        });
    };

    const listOfPeriodicals = nameOfPeriodicals.map((item) => {
        return (
            <li className="navi-item" key={item.value}>
                <span
                    className="navi-link cursor-pointer"
                    onClick={handleClick}
                    id={item.value}
                    data-title={item.title}
                >
                    <span className="navi-text">
                        {item.title}
                    </span>
                </span>
            </li>
        );
    });

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableMessage.current}
                    </span>
                </Alert>
            </Snackbar >
            <ul className="navi navi-hover py-5">
                {listOfPeriodicals}
            </ul>
        </>
    );
}