import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { addMotor, editMotor } from "../../../../app/modules/Auth/_redux/authCrud";
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { FormAlert } from '../../controls/';
import axios from "axios";
import BasicLoader from '../../loaders/SimpleLoader';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateEditMotorForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, motorID }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [showLoader, setShowLoader] = useState('d-none');
    const [isLoading, setIsLoading] = useState(false);
    const [manufacturerData, setManufacturerData] = useState([]);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);

    const TableErrorMessage = useRef();

    const creationSchema = Yup.object().shape({
        type: Yup.string().required(),
        nominalWattage: Yup.number().required(),
        peakWattage: Yup.number().required(),
        operatingVoltage: Yup.number().required(),
        manufacturerID: Yup.string().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    addMotor(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        type: values.type,
                        nominalWattage: values.nominalWattage,
                        peakWattage: values.peakWattage,
                        operatingVoltage: values.operatingVoltage,
                        manufacturerID: values.manufacturerID
                    };

                    editMotor(editPayload, motorID).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    const makeManufacturerOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.name}
        </option>;
    };

    const handleLoadingAndErrors = () => {
        if (isLoading)
            return true;
        if (isRefetching)
            return true;

        return false;
    };

    useEffect(() => {

        let isMounted = true;

        const fetchManufacturerData = async () => {

            setShowLoader('');

            if (!manufacturerData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/manufacturers/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setManufacturerData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        fetchManufacturerData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="motorFormManufacturer">
                    <Form.Label>Manufacturer</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.manufacturerID} onChange={formik.handleChange} className={`${getInputClasses("manufacturerID")}`} name="manufacturerID" {...formik.getFieldProps("manufacturerID")}>
                        <option defaultValue="" disabled value="">Select one</option>
                        {manufacturerData.map(makeManufacturerOption)}
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Select Manufacturer
                    </Form.Text>
                    <Form.Control.Feedback>Your manufacturer looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Manufacturer selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.manufacturerID && formik.errors.manufacturerID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.manufacturerID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="motorFormType">
                    <Form.Label>Motor Type</Form.Label>
                    <Form.Control type="text" placeholder="Motor Type" value={formik.values.type} onChange={formik.handleChange} className={`${getInputClasses("type")}`} name="type" {...formik.getFieldProps("type")} />
                    <Form.Text className="text-muted">
                        Enter Motor Type: eg. DC Hub Motor
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Motor Type field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.type && formik.errors.type ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.type}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="motorFormNominalWattage">
                    <Form.Label>Nominal Wattage (kW)</Form.Label>
                    <Form.Control type="number" placeholder="Nominal Wattage" value={formik.values.nominalWattage} onChange={formik.handleChange} className={`${getInputClasses("nominalWattage")}`} name="nominalWattage" {...formik.getFieldProps("nominalWattage")} />
                    <Form.Text className="text-muted">
                        Motor Nominal Wattage
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Motor nominal wattage cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.nominalWattage && formik.errors.nominalWattage ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.nominalWattage}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="motorFormPeakWattage">
                    <Form.Label>Peak Wattage (kW)</Form.Label>
                    <Form.Control type="number" placeholder="Peak Wattage" value={formik.values.peakWattage} onChange={formik.handleChange} className={`${getInputClasses("peakWattage")}`} name="peakWattage" {...formik.getFieldProps("peakWattage")} />
                    <Form.Text className="text-muted">
                        Motor Peak Wattage
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Motor peak wattage cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.peakWattage && formik.errors.peakWattage ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.peakWattage}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="motorFormOperatingVoltage">
                    <Form.Label>Operating Voltage (V)</Form.Label>
                    <Form.Control type="number" placeholder="Operating Voltage" value={formik.values.operatingVoltage} onChange={formik.handleChange} className={`${getInputClasses("operatingVoltage")}`} name="operatingVoltage" {...formik.getFieldProps("operatingVoltage")} />
                    <Form.Text className="text-muted">
                        Motor Operating Voltage
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Motor operating voltage cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.operatingVoltage && formik.errors.operatingVoltage ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.operatingVoltage}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}