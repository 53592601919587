import React, { useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Button,
    Paper,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { Alert } from '@mui/material';
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../dropdowns";
import { SimpleModal } from "../modals/SimpleModal";
import { PdfViewer } from "../viewers/PdfViewer";
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import ChecklistTwoToneIcon from '@mui/icons-material/ChecklistTwoTone';
import {
    markApplicantAsAccepted,
    markApplicantAsInterviewed,
    markApplicantAsPotentialCandidate,
    markApplicationAsAcknowledge
} from '../../../../app/modules/Auth/_redux/authCrud';
import BasicLoader from '../../loaders/SimpleLoader';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone';
import TaskAltTwoToneIcon from '@mui/icons-material/TaskAltTwoTone';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `100%`,
        width: `100%`
    },
    imgContainer: {
        height: `100px`,
        width: `100px`
    }
}));

export function JobApplicantDetails({ jobData, onChangeFunction, item, index }) {

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [applicantName, setApplicantName] = useState("");
    const [applicationFileURL, setApplicationFileURL] = useState("");
    const [severity, setSeverity] = useState('info');

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAlt, setIsLoadingAlt] = useState(false);
    const [isLoadingAltAlt, setIsLoadingAltAlt] = useState(false);
    const [isLoadingInt, setIsLoadingInt] = useState(false);

    const PageErrorMessage = useRef();
    const classes = useStyles();

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={handleClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {PageErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={applicantName}
                show={showPDFModal}
                close={() => setShowPDFModal(false)}
                size="lg"
                body={<PdfViewer
                    data={jobData}
                    fileURL={applicationFileURL}
                />}
            />
            <Item>
                <div className={`d-flex align-items-center ${item.isAccepted ? "bg-light-success" : ""} rounded p-6`}>
                    <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
                        {+(index + 1)}
                    </span>
                    <div className="d-flex flex-column flex-grow-1 mr-1">
                        <div className="font-weight-bolder text-warning py-1 px-2 font-size-lg mb-2">
                            <div className="card-toolbar">
                                <Dropdown className="dropdown-inline" align="end">
                                    <Dropdown.Toggle
                                        id="dropdown-toggle-top"
                                        as={DropdownCustomToggler}
                                    >
                                        <i className="ki ki-bold-more-ver" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                        <ul className="navi navi-hover py-5">
                                            <li
                                                className="navi-item"
                                                onClick={(async () => {

                                                    if (item.respondedTo === true) {

                                                        setIsLoading(true);

                                                        setTimeout(() => {

                                                            PageErrorMessage.current = "applicant's application already acknowledged";
                                                            setIsLoading(false);
                                                            setSeverity("warning");

                                                            setOpenSnackBar(true);

                                                        }, 2000);

                                                        setTimeout(() => {
                                                            setSeverity("info");
                                                        }, 20000);

                                                        return;

                                                    }

                                                    if (!isLoading) {

                                                        setIsLoading(true);

                                                        const requestPayload = {
                                                            jobID: jobData.ID,
                                                            applicantIndex: index
                                                        };

                                                        await markApplicationAsAcknowledge(requestPayload).then((response) => {

                                                            if (response) {

                                                                PageErrorMessage.current = response.data.message;
                                                                onChangeFunction();

                                                                setTimeout(() => {
                                                                    setIsLoading(false);
                                                                    setSeverity("success");

                                                                    setOpenSnackBar(true);
                                                                }, 2000);

                                                                setTimeout(() => {
                                                                    setSeverity("info");
                                                                }, 20000);

                                                            }

                                                        }).catch((error) => {

                                                            setIsLoading(false);
                                                            setSeverity("error");
                                                            PageErrorMessage.current = error.response.data.message;

                                                            setOpenSnackBar(true);

                                                            setTimeout(() => {
                                                                setSeverity("info");
                                                            }, 20000);

                                                        });

                                                        return;

                                                    }

                                                })}
                                            >
                                                <span className="navi-link cursor-pointer">
                                                    <span className="navi-icon">
                                                        <ApprovalTwoToneIcon
                                                            color='info'
                                                        />
                                                    </span>
                                                    <span className="navi-text">
                                                        Acknowledge Application
                                                        {" "}
                                                        {isLoading && <BasicLoader size={"sm"} />}
                                                    </span>
                                                </span>
                                            </li>
                                            <li
                                                className="navi-item"
                                                onClick={(async () => {

                                                    if (item.isPotential === true) {

                                                        setIsLoadingAlt(true);

                                                        setTimeout(() => {

                                                            PageErrorMessage.current = "applicant marked already as a potential candidate";
                                                            setIsLoadingAlt(false);
                                                            setSeverity("warning");

                                                            setOpenSnackBar(true);

                                                        }, 2000);

                                                        setTimeout(() => {
                                                            setSeverity("info");
                                                        }, 20000);

                                                        return;

                                                    }

                                                    if (!isLoadingAlt) {

                                                        setIsLoadingAlt(true);

                                                        const requestPayload = {
                                                            jobID: jobData.ID,
                                                            applicantIndex: index
                                                        };

                                                        await markApplicantAsPotentialCandidate(requestPayload).then((response) => {

                                                            if (response) {

                                                                PageErrorMessage.current = response.data.message;
                                                                onChangeFunction();

                                                                setTimeout(() => {

                                                                    setIsLoadingAlt(false);
                                                                    setSeverity("success");

                                                                    setOpenSnackBar(true);

                                                                }, 2000);

                                                                setTimeout(() => {
                                                                    setSeverity("info");
                                                                }, 20000);

                                                            }

                                                        }).catch((error) => {

                                                            setIsLoadingAlt(false);
                                                            setSeverity("error");

                                                            PageErrorMessage.current = error.response.data.message;

                                                            setOpenSnackBar(true);

                                                            setTimeout(() => {
                                                                setSeverity("info");
                                                            }, 20000);

                                                        });

                                                        return;

                                                    }

                                                })}
                                            >
                                                <span className="navi-link cursor-pointer">
                                                    <span className="navi-icon">
                                                        <ChecklistTwoToneIcon
                                                            color='primary'
                                                        />
                                                    </span>
                                                    <span className="navi-text">
                                                        Shortlist Applicant
                                                        {" "}
                                                        {isLoadingAlt && <BasicLoader size={"sm"} />}
                                                    </span>
                                                </span>
                                            </li>
                                            <li
                                                className="navi-item"
                                                onClick={(async () => {

                                                    if (item.isInterviewed === true) {

                                                        setIsLoadingInt(true);

                                                        setTimeout(() => {

                                                            PageErrorMessage.current = "applicant marked already as the accepted candidate";
                                                            setIsLoadingInt(false);
                                                            setSeverity("warning");

                                                            setOpenSnackBar(true);

                                                        }, 2000);

                                                        setTimeout(() => {
                                                            setSeverity("info");
                                                        }, 20000);

                                                        return;

                                                    }

                                                    if (!isLoadingInt) {

                                                        setIsLoadingInt(true);

                                                        const requestPayload = {
                                                            jobID: jobData.ID,
                                                            applicantIndex: index
                                                        };

                                                        await markApplicantAsInterviewed(requestPayload).then((response) => {

                                                            if (response) {

                                                                PageErrorMessage.current = response.data.message;
                                                                onChangeFunction();

                                                                setTimeout(() => {

                                                                    setIsLoadingInt(false);
                                                                    setSeverity("success");

                                                                    setOpenSnackBar(true);

                                                                }, 2000);

                                                                setTimeout(() => {
                                                                    setSeverity("info");
                                                                }, 20000);

                                                            }

                                                        }).catch((error) => {

                                                            setIsLoadingInt(false);
                                                            setSeverity("error");

                                                            PageErrorMessage.current = error.response.data.message;

                                                            setOpenSnackBar(true);

                                                            setTimeout(() => {
                                                                setSeverity("info");
                                                            }, 20000);

                                                        });

                                                        return;

                                                    }

                                                })}
                                            >
                                                <span href="#" className="navi-link cursor-pointer">
                                                    <span className="navi-icon">
                                                        <TaskAltTwoToneIcon
                                                            color='secondary'
                                                        />
                                                    </span>
                                                    <span className="navi-text">
                                                        Mark as Interviewed
                                                        {" "}
                                                        {isLoadingInt && <BasicLoader size={"sm"} />}
                                                    </span>
                                                </span>
                                            </li>
                                            <li
                                                className="navi-item"
                                                onClick={(async () => {

                                                    if (item.isAccepted === true) {

                                                        setIsLoadingAltAlt(true);

                                                        setTimeout(() => {

                                                            PageErrorMessage.current = "applicant marked already as the accepted candidate";
                                                            setIsLoadingAltAlt(false);
                                                            setSeverity("warning");

                                                            setOpenSnackBar(true);

                                                        }, 2000);

                                                        setTimeout(() => {
                                                            setSeverity("info");
                                                        }, 20000);

                                                        return;

                                                    }

                                                    if (!isLoadingAltAlt) {

                                                        setIsLoadingAltAlt(true);

                                                        const requestPayload = {
                                                            jobID: jobData.ID,
                                                            applicantIndex: index
                                                        };

                                                        await markApplicantAsAccepted(requestPayload).then((response) => {

                                                            if (response) {

                                                                PageErrorMessage.current = response.data.message;
                                                                onChangeFunction();

                                                                setTimeout(() => {

                                                                    setIsLoadingAltAlt(false);
                                                                    setSeverity("success");

                                                                    setOpenSnackBar(true);

                                                                }, 2000);

                                                                setTimeout(() => {
                                                                    setSeverity("info");
                                                                }, 20000);

                                                            }

                                                        }).catch((error) => {

                                                            setIsLoadingAltAlt(false);
                                                            setSeverity("error");

                                                            PageErrorMessage.current = error.response.data.message;

                                                            setOpenSnackBar(true);

                                                            setTimeout(() => {
                                                                setSeverity("info");
                                                            }, 20000);

                                                        });

                                                        return;

                                                    }

                                                })}
                                            >
                                                <span href="#" className="navi-link cursor-pointer">
                                                    <span className="navi-icon">
                                                        <VerifiedTwoToneIcon
                                                            color='primary'
                                                        />
                                                    </span>
                                                    <span className="navi-text">
                                                        Accept Applicant
                                                        {" "}
                                                        {isLoadingAltAlt && <BasicLoader size={"sm"} />}
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <label className="font-weight-bold text-dark-75 font-size-lg mb-1">
                            {item.firstName}
                            {" "}
                            {item.lastName}
                        </label>
                        <span className="text-muted font-weight-bold">
                            <a href={'mailto:' + item.email} target='_blank' rel="noopener noreferrer">
                                {item.email}
                            </a>
                        </span>
                        <span className="text-muted font-weight-bold">
                            <a href={'tel:' + item.phoneNumber} target='_blank' rel="noopener noreferrer">
                                {item.phoneNumber}
                            </a>
                        </span>
                        <div className="mb-5 mt-5">
                            <div className="timeline timeline-justified timeline-4">
                                <div className="timeline-bar"></div>
                                <div className="timeline-items">
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            <CheckCircleTwoToneIcon
                                                fontSize='large'
                                                color='success'
                                            />
                                        </div>
                                        <div className="timeline-label">
                                            <Chip
                                                label={("Applied").toUpperCase()}
                                                color="success"
                                                size="small"
                                            />
                                        </div>
                                        <div className="timeline-content">
                                            {`${item.firstName} ${item.lastName} applied on ${new Date(item.createdAt).toUTCString()}`}
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            {item.respondedTo ?
                                                <CheckCircleTwoToneIcon
                                                    fontSize='large'
                                                    color='success'
                                                />
                                                :
                                                <CancelTwoToneIcon
                                                    fontSize='small'
                                                    color='error'
                                                />
                                            }
                                        </div>
                                        <div className="timeline-label">
                                            {item.respondedTo ?
                                                <Chip
                                                    label={("Acknowledged").toUpperCase()}
                                                    color="success"
                                                    size="small"
                                                />
                                                :
                                                <Chip
                                                    label={("Not acknowledged").toUpperCase()}
                                                    color="error"
                                                    size="small"
                                                />
                                            }
                                        </div>
                                        <div className="timeline-content">
                                            {item.respondedTo ?
                                                "Application has been acknowledged"
                                                :
                                                <span>
                                                    Application has not been acknowledged
                                                    {" "}
                                                    <Button
                                                        size='small'
                                                        variant='outlined'
                                                        color='warning'
                                                        onClick={(async () => {

                                                            if (item.respondedTo === true) {

                                                                setIsLoading(true);

                                                                setTimeout(() => {

                                                                    PageErrorMessage.current = "applicant's application already acknowledged";
                                                                    setIsLoading(false);
                                                                    setSeverity("warning");

                                                                    setOpenSnackBar(true);

                                                                }, 2000);

                                                                setTimeout(() => {
                                                                    setSeverity("info");
                                                                }, 20000);

                                                                return;

                                                            }

                                                            if (!isLoading) {

                                                                setIsLoading(true);

                                                                const requestPayload = {
                                                                    jobID: jobData.ID,
                                                                    applicantIndex: index
                                                                };

                                                                await markApplicationAsAcknowledge(requestPayload).then((response) => {

                                                                    if (response) {

                                                                        PageErrorMessage.current = response.data.message;
                                                                        onChangeFunction();

                                                                        setTimeout(() => {
                                                                            setIsLoading(false);
                                                                            setSeverity("success");

                                                                            setOpenSnackBar(true);
                                                                        }, 2000);

                                                                        setTimeout(() => {
                                                                            setSeverity("info");
                                                                        }, 20000);

                                                                    }

                                                                }).catch((error) => {

                                                                    setIsLoading(false);
                                                                    setSeverity("error");
                                                                    PageErrorMessage.current = error.response.data.message;

                                                                    setOpenSnackBar(true);

                                                                    setTimeout(() => {
                                                                        setSeverity("info");
                                                                    }, 20000);

                                                                });

                                                                return;

                                                            }

                                                        })}
                                                        disabled={isLoading}
                                                    >
                                                        Acknowledge
                                                        {" "}
                                                        {isLoading && <BasicLoader size={"sm"} />}
                                                    </Button>
                                                </span>

                                            }
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            {item.isPotential ?
                                                <CheckCircleTwoToneIcon
                                                    fontSize='large'
                                                    color='success'
                                                />
                                                :
                                                <CancelTwoToneIcon
                                                    fontSize='small'
                                                    color='error'
                                                />
                                            }
                                        </div>
                                        <div className="timeline-label">
                                            {item.isPotential ?
                                                <Chip
                                                    label={("Shortlisted").toUpperCase()}
                                                    color="success"
                                                    size="small"
                                                />
                                                :
                                                <Chip
                                                    label={("Not shortlisted").toUpperCase()}
                                                    color="error"
                                                    size="small"
                                                />
                                            }
                                        </div>
                                        <div className="timeline-content">
                                            {item.isPotential ?
                                                "Applicant is shortlisted"
                                                :
                                                <span>
                                                    Applicant is not shortlisted
                                                    {" "}
                                                    <Button
                                                        size='small'
                                                        variant='outlined'
                                                        color='warning'
                                                        onClick={(async () => {

                                                            if (item.isPotential === true) {

                                                                setIsLoadingAlt(true);

                                                                setTimeout(() => {

                                                                    PageErrorMessage.current = "applicant marked already as a potential candidate";
                                                                    setIsLoadingAlt(false);
                                                                    setSeverity("warning");

                                                                    setOpenSnackBar(true);

                                                                }, 2000);

                                                                setTimeout(() => {
                                                                    setSeverity("info");
                                                                }, 20000);

                                                                return;

                                                            }

                                                            if (!isLoadingAlt) {

                                                                setIsLoadingAlt(true);

                                                                const requestPayload = {
                                                                    jobID: jobData.ID,
                                                                    applicantIndex: index
                                                                };

                                                                await markApplicantAsPotentialCandidate(requestPayload).then((response) => {

                                                                    if (response) {

                                                                        PageErrorMessage.current = response.data.message;
                                                                        onChangeFunction();

                                                                        setTimeout(() => {

                                                                            setIsLoadingAlt(false);
                                                                            setSeverity("success");

                                                                            setOpenSnackBar(true);

                                                                        }, 2000);

                                                                        setTimeout(() => {
                                                                            setSeverity("info");
                                                                        }, 20000);

                                                                    }

                                                                }).catch((error) => {

                                                                    setIsLoadingAlt(false);
                                                                    setSeverity("error");

                                                                    PageErrorMessage.current = error.response.data.message;

                                                                    setOpenSnackBar(true);

                                                                    setTimeout(() => {
                                                                        setSeverity("info");
                                                                    }, 20000);

                                                                });

                                                                return;

                                                            }

                                                        })}
                                                        disabled={isLoadingAlt}
                                                    >
                                                        Shortlist
                                                        {" "}
                                                        {isLoadingAlt && <BasicLoader size={"sm"} />}
                                                    </Button>
                                                </span>

                                            }
                                        </div>
                                    </div>
                                    {item.isAccepted ?
                                        <div className="timeline-item">
                                            <div className="timeline-badge">
                                                <VerifiedTwoToneIcon
                                                    fontSize='large'
                                                    color='success'
                                                />
                                            </div>
                                            <div className="timeline-label">
                                                <Chip
                                                    label={("accepted").toUpperCase()}
                                                    color="success"
                                                    size="small"
                                                />
                                            </div>
                                            <div className="timeline-content">
                                                Applicant accepted
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="text-muted font-weight-bold">
                            {item.filesArray.map((file, index) =>
                                <Button
                                    onClick={() => {
                                        setApplicantName(item.firstName + " " + item.lastName);
                                        setApplicationFileURL(file.applicationFileURL);

                                        setShowPDFModal(true);
                                    }}
                                    color='success'
                                    variant='contained'
                                    key={index}
                                    className="mb-3 mr-1"
                                    size="medium"
                                >
                                    See Attached File
                                </Button>
                            )}
                        </div>
                    </div>
                    {item.isAccepted && <div className="d-flex flex-column flex-grow-1 mr-1"> </div>}
                </div>
            </Item>
        </>
    );

}