import React, { useState } from 'react';
import { GoogleMap, LoadScript, MarkerF, InfoWindow } from '@react-google-maps/api';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `100%`,
        width: `100%`
    },
    imgContainer: {
        height: `100px`,
        width: `100px`
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
}));

const ShowBatPercentage = (externalBatteryVoltage, peakVoltage) => {

    let batteryPercentage = (Math.round(((externalBatteryVoltage - 42) / (peakVoltage - 42)) * 100)).toString();
    if (externalBatteryVoltage < 40 || !externalBatteryVoltage)
        return batteryPercentage = 'Detached';
    if (Math.round(Number(batteryPercentage)) >= 100)
        return batteryPercentage = "100 %";
    if (Number(batteryPercentage) < 0)
        return batteryPercentage = "0 %";

    return batteryPercentage + "%";

};

const ShowBatStatus = (externalBatteryVoltage) => {
    if (externalBatteryVoltage < 42)
        return <span className="font-red">
            External Battery Detached:
            {" "}
            {externalBatteryVoltage}
        </span>;
    return externalBatteryVoltage;
};

export const MapContainer = ({ locations, vehicleData }) => {

    const [selected, setSelected] = useState({});
    const classes = useStyles();

    if (locations && locations.length > 0) {

        const onSelect = item => {
            setSelected(item);
        };

        const mapStyles = {
            height: "100%",
            width: "100%",
        };

        const defaultCenter = {
            lat: 6.2414088,
            lng: 0.3311344
        };

        return (
            <>
                <LoadScript
                    googleMapsApiKey='AIzaSyCZQXzHdOyxtRg5hg2m06vFeQ12YRroi4M'>
                    <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={8}
                        center={defaultCenter}
                    >
                        {
                            locations.map(item => {
                                return (
                                    <MarkerF
                                        key={item.location.lat}
                                        position={item.location}
                                        onClick={() => onSelect(item)}
                                        icon={toAbsoluteUrl("/media/svg/icons/General/BikeLocation.svg")}
                                    />
                                );
                            })
                        }
                        {
                            selected.location &&
                            (
                                <InfoWindow
                                    position={selected.location}
                                    clickable={true}
                                    onCloseClick={() => setSelected({})}
                                >
                                    <div className='info-window centerMan'>
                                        <p>
                                            Mileage:
                                            {" "}
                                            <span className="bold">
                                                {selected.vehicleMileage}
                                                km
                                            </span>
                                        </p>
                                        <p>
                                            Battery Voltage:
                                            {" "}
                                            <span className="bold">
                                                {ShowBatStatus(selected.externalPowerSourceVoltage)}
                                                V
                                            </span>
                                        </p>
                                        <p>
                                            Current Speed:
                                            {" "}
                                            <span className="bold">
                                                {selected.positionSpeed}
                                                km/h
                                            </span>
                                        </p>
                                        <p>
                                            Position Altitude:
                                            {" "}
                                            <span className="bold">
                                                {selected.positionAltitude}
                                                m
                                            </span>
                                        </p>
                                        <p>
                                            Battery Percentage:
                                            {" "}
                                            <span className="bold">
                                                {ShowBatPercentage(selected.externalPowerSourceVoltage, selected.battery.batteryPeakVoltage)}
                                            </span>
                                        </p>
                                    </div>
                                </InfoWindow>
                            )
                        }
                    </GoogleMap>
                </LoadScript>
            </>
        );

    }

    return (
        <div className="d-flex align-items-center mb-9 bg-light-danger rounded p-5">
            <span className="svg-icon svg-icon-warning mr-5 svg-icon-lg">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")}></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
                <label className="font-weight-bold text-dark-75 font-size-lg mb-1">
                    No Tracker Data Logged here yet
                </label>
                <span className="text-muted font-weight-bold">
                    <Button
                        variant="outlined"
                    >
                        <Link to={"/trackers"}>
                            Assign a tracker to this vehicle
                        </Link>
                    </Button>
                </span>
            </div>
            <span className={`${classes.imgContainer} font-weight-bolder text-warning py-1 font-size-lg`}>
                <Image
                    alt="avatar"
                    src={vehicleData.document?.profileImageURL ?? toAbsoluteUrl("/media/products/ebike-mana.webp")}
                    roundedCircle={true}
                    fluid={true}
                    className={classes.rounder}
                    loading="lazy"
                />
            </span>
        </div>
    );

};

export default MapContainer;