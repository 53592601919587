import React, { useEffect } from "react";
import { useSubheader } from "../../_wahu/layout";
import {
    VendorRequestsTable
} from "../../_wahu/_partials/widgets";

export const VendorRequestsPage = () => {

    const suhbeader = useSubheader();
    useEffect(() => {
        const load = async () => {
            try {
                suhbeader.setTitle("Vendor Account Requests");
            } catch (error) {
                console.log(error);
            }
        };
        load();
    }, [suhbeader]);

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <VendorRequestsTable className="card-stretch gutter-b" />
                </div>
            </div>
        </>
    );
};
