import React from 'react';
import { DriversTable } from './DriversTable';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';

export function PausedDriversTable() {

    return (
        <>
            <DriversTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/drivers/paused-drivers/getAll"}
                tableTopHeader={"Paused Rider Accounts"}
                baseUrl={FLEET_MANAGING_BASE_URL}
                tableSubHeader={"Manage paused rider subscriptions from this table"}
            />
        </>
    );

}
