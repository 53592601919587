import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { addCountry, editCountry } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls/';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toAbsoluteUrl } from "../../../_helpers";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function AddCountryForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, countryID, flagImgURL }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const [singleSelections, setSingleSelections] = useState([{
        id: initialValues?.countryName ?? "",
        label: initialValues?.countryName ?? "",
    }]);
    const [flagImageURL, setFlagImageURL] = useState("");
    const [flagImageFile, setFlagImageFile] = useState(null);

    const creationSchema = Yup.object().shape({
        countryName: Yup.string(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const options = [
        { id: "Afghanistan", label: "Afghanistan" },
        { id: "Albania", label: "Albania" },
        { id: "Algeria", label: "Algeria" },
        { id: "American Samoa", label: "American Samoa" },
        { id: "Andorra", label: "Andorra" },
        { id: "Angola", label: "Angola" },
        { id: "Anguilla", label: "Anguilla" },
        { id: "Argentina", label: "Argentina" },
        { id: "Armenia", label: "Armenia" },
        { id: "Aruba", label: "Aruba" },
        { id: "Australia", label: "Australia" },
        { id: "Austria", label: "Austria" },
        { id: "Azerbaijan", label: "Azerbaijan" },
        { id: "Bahamas", label: "Bahamas" },
        { id: "Bahrain", label: "Bahrain" },
        { id: "Bangladesh", label: "Bangladesh" },
        { id: "Barbados", label: "Barbados" },
        { id: "Belarus", label: "Belarus" },
        { id: "Belgium", label: "Belgium" },
        { id: "Belize", label: "Belize" },
        { id: "Benin", label: "Benin" },
        { id: "Bermuda", label: "Bermuda" },
        { id: "Bhutan", label: "Bhutan" },
        { id: "Bolivia", label: "Bolivia" },
        { id: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
        { id: "Botswana", label: "Botswana" },
        { id: "Bouvet Island", label: "Bouvet Island" },
        { id: "Brazil", label: "Brazil" },
        { id: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
        { id: "Brunei Darussalam", label: "Brunei Darussalam" },
        { id: "Bulgaria", label: "Bulgaria" },
        { id: "Burkina Faso", label: "Burkina Faso" },
        { id: "Burundi", label: "Burundi" },
        { id: "Cambodia", label: "Cambodia" },
        { id: "Cameroon", label: "Cameroon" },
        { id: "Canada", label: "Canada" },
        { id: "Cape Verde", label: "Cape Verde" },
        { id: "Cayman Islands", label: "Cayman Islands" },
        { id: "Central African Republic", label: "Central African Republic" },
        { id: "Chad", label: "Chad" },
        { id: "Chile", label: "Chile" },
        { id: "China", label: "China" },
        { id: "Christmas Island", label: "Christmas Island" },
        { id: "Cocos (Keeling) Islands", label: "Cocos(Keeling) Islands" },
        { id: "Colombia", label: "Colombia" },
        { id: "Comoros", label: "Comoros" },
        { id: "Congo", label: "Congo" },
        { id: "Democratic Republic of the Congo", label: "Democratic Republic of the Congo " },
        { id: "Cook Islands", label: "Cook Islands" },
        { id: "Costa Rica", label: "Costa Rica" },
        {
            id: "Cote d'Ivoire", label: "Cote d'Ivoire"
        },
        { id: "Croatia (Hrvatska)", label: "Croatia(Hrvatska)" },
        { id: "Cuba", label: "Cuba" },
        { id: "Cyprus", label: "Cyprus" },
        { id: "Czech Republic", label: "Czech Republic" },
        { id: "Denmark", label: "Denmark" },
        { id: "Djibouti", label: "Djibouti" },
        { id: "Dominica", label: "Dominica" },
        { id: "Dominican Republic", label: "Dominican Republic" },
        { id: "Ecuador", label: "Ecuador" },
        { id: "Egypt", label: "Egypt" },
        { id: "El Salvador", label: "El Salvador" },
        { id: "Equatorial Guinea", label: "Equatorial Guinea" },
        { id: "Eritrea", label: "Eritrea" },
        { id: "Estonia", label: "Estonia" },
        { id: "Ethiopia", label: "Ethiopia" },
        { id: "Falkland Islands (Malvinas)", label: "Falkland Islands(Malvinas)" },
        { id: "Faroe Islands", label: "Faroe Islands" },
        { id: "Fiji", label: "Fiji" },
        { id: "Finland", label: "Finland" },
        { id: "France", label: "France" },
        { id: "French Guiana", label: "French Guiana" },
        { id: "French Polynesia", label: "French Polynesia" },
        { id: "French Southern Territories", label: "French Southern Territories " },
        { id: "Gabon", label: "Gabon" },
        { id: "Gambia", label: "Gambia" },
        { id: "Georgia", label: "Georgia" },
        { id: "Germany", label: "Germany" },
        { id: "Ghana", label: "Ghana" },
        { id: "Gibraltar", label: "Gibraltar" },
        { id: "Greece", label: "Greece" },
        { id: "Greenland", label: "Greenland" },
        { id: "Grenada", label: "Grenada" },
        { id: "Guadeloupe", label: "Guadeloupe" },
        { id: "Guam", label: "Guam" },
        { id: "Guatemala", label: "Guatemala" },
        { id: "Guinea", label: "Guinea" },
        { id: "Guinea-Bissau", label: "Guinea-Bissau" },
        { id: "Guyana", label: "Guyana" },
        { id: "Haiti", label: "Haiti" },
        { id: "Heard and Mc Donald Islands", label: "Heard and Mc Donald Islands" },
        { id: "Holy See (Vatican City State)", label: "Holy See(Vatican City State)" },
        { id: "Honduras", label: "Honduras" },
        { id: "Hong Kong", label: "Hong Kong" },
        { id: "Hungary", label: "Hungary" },
        { id: "Iceland", label: "Iceland" },
        { id: "India", label: "India" },
        { id: "Indonesia", label: "Indonesia" },
        { id: "Iran (Islamic Republic of)", label: "Iran(Islamic Republic of)" },
        { id: "Iraq", label: "Iraq" },
        { id: "Ireland", label: "Ireland" },
        { id: "Israel", label: "Israel" },
        { id: "Italy", label: "Italy" },
        { id: "Jamaica", label: "Jamaica" },
        { id: "Japan", label: "Japan" },
        { id: "Jordan", label: "Jordan" },
        { id: "Kazakhstan", label: "Kazakhstan" },
        { id: "Kenya", label: "Kenya" },
        { id: "Kiribati", label: "Kiribati" },
        { id: "Korea, Democratic People's Republic of", label: "Korea, Democratic People's Republic of" },
        { id: "Korea, Republic of", label: "Korea, Republic of" },
        { id: "Kuwait", label: "Kuwait" },
        { id: "Kyrgyzstan", label: "Kyrgyzstan" },
        { id: "Lao People's Democratic Republic", label: "Lao People's Democratic Republic" },
        { id: "Latvia", label: "Latvia" },
        { id: "Lebanon", label: "Lebanon" },
        { id: "Lesotho", label: "Lesotho" },
        { id: "Liberia", label: "Liberia" },
        { id: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
        { id: "Liechtenstein", label: "Liechtenstein" },
        { id: "Lithuania", label: "Lithuania" },
        { id: "Luxembourg", label: "Luxembourg" },
        { id: "Macau", label: "Macau" },
        { id: "Macedonia, The Former Yugoslav Republic of", label: "Macedonia, The Former Yugoslav Republic of" },
        { id: "Madagascar", label: "Madagascar" },
        { id: "Malawi", label: "Malawi" },
        { id: "Malaysia", label: "Malaysia" },
        { id: "Maldives", label: "Maldives" },
        { id: "Mali", label: "Mali" },
        { id: "Malta", label: "Malta" },
        { id: "Marshall Islands", label: "Marshall Islands" },
        { id: "Martinique", label: "Martinique" },
        { id: "Mauritania", label: "Mauritania" },
        { id: "Mauritius", label: "Mauritius" },
        { id: "Mayotte", label: "Mayotte" },
        { id: "Mexico", label: "Mexico" },
        { id: "Micronesia, Federated States of", label: "Micronesia, Federated States of" },
        { id: "Moldova, Republic of", label: "Moldova, Republic of" },
        { id: "Monaco", label: "Monaco" },
        { id: "Mongolia", label: "Mongolia" },
        { id: "Montserrat", label: "Montserrat" },
        { id: "Morocco", label: "Morocco" },
        { id: "Mozambique", label: "Mozambique" },
        { id: "Myanmar", label: "Myanmar" },
        { id: "Namibia", label: "Namibia" },
        { id: "Nauru", label: "Nauru" },
        { id: "Nepal", label: "Nepal" },
        { id: "Netherlands", label: "Netherlands" },
        { id: "Netherlands Antilles", label: "Netherlands Antilles" },
        { id: "New Caledonia", label: "New Caledonia" },
        { id: "New Zealand", label: "New Zealand" },
        { id: "Nicaragua", label: "Nicaragua" },
        { id: "Niger", label: "Niger" },
        { id: "Nigeria", label: "Nigeria" },
        { id: "Niue", label: "Niue" },
        { id: "Norfolk Island", label: "Norfolk Island" },
        { id: "Northern Mariana Islands", label: "Northern Mariana Islands" },
        { id: "Norway", label: "Norway" },
        { id: "Oman", label: "Oman" },
        { id: "Pakistan", label: "Pakistan" },
        { id: "Palau", label: "Palau" },
        { id: "Panama", label: "Panama" },
        { id: "Papua New Guinea", label: "Papua New Guinea" },
        { id: "Paraguay", label: "Paraguay" },
        { id: "Peru", label: "Peru" },
        { id: "Philippines", label: "Philippines" },
        { id: "Pitcairn", label: "Pitcairn" },
        { id: "Poland", label: "Poland" },
        { id: "Portugal", label: "Portugal" },
        { id: "Puerto Rico", label: "Puerto Rico" },
        { id: "Qatar", label: "Qatar" },
        { id: "Reunion", label: "Reunion" },
        { id: "Romania", label: "Romania" },
        { id: "Russian Federation", label: "Russian Federation" },
        { id: "Rwanda", label: "Rwanda" },
        { id: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
        { id: "Saint LUCIA", label: "Saint LUCIA" },
        { id: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
        { id: "Samoa", label: "Samoa" },
        { id: "San Marino", label: "San Marino" },
        { id: "Sao Tome and Principe", label: "Sao Tome and Principe" },
        { id: "Saudi Arabia", label: "Saudi Arabia" },
        { id: "Senegal", label: "Senegal" },
        { id: "Seychelles", label: "Seychelles" },
        { id: "Sierra Leone", label: "Sierra Leone" },
        { id: "Singapore", label: "Singapore" },
        { id: "Slovakia (Slovak Republic)", label: "Slovakia(Slovak Republic)" },
        { id: "Slovenia", label: "Slovenia" },
        { id: "Solomon Islands", label: "Solomon Islands" },
        { id: "Somalia", label: "Somalia" },
        { id: "South Africa", label: "South Africa" },
        { id: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands" },
        { id: "Spain", label: "Spain" },
        { id: "Sri Lanka", label: "Sri Lanka" },
        { id: "St. Helena", label: "St.Helena" },
        { id: "St. Pierre and Miquelon", label: "St.Pierre and Miquelon" },
        { id: "Sudan", label: "Sudan" },
        { id: "Suriname", label: "Suriname" },
        { id: "Svalbard and Jan Mayen Islands", label: "Svalbard and Jan Mayen Islands" },
        { id: "Swaziland", label: "Swaziland" },
        { id: "Sweden", label: "Sweden" },
        { id: "Switzerland", label: "Switzerland" },
        { id: "Syrian Arab Republic", label: "Syrian Arab Republic" },
        { id: "Taiwan, Province of China", label: "Taiwan, Province of China" },
        { id: "Tajikistan", label: "Tajikistan" },
        { id: "Tanzania, United Republic of", label: "Tanzania, United Republic of" },
        { id: "Thailand", label: "Thailand" },
        { id: "Togo", label: "Togo" },
        { id: "Tokelau", label: "Tokelau" },
        { id: "Tonga", label: "Tonga" },
        { id: "Trinidad and Tobago", label: "Trinidad and Tobago" },
        { id: "Tunisia", label: "Tunisia" },
        { id: "Turkey", label: "Turkey" },
        { id: "Turkmenistan", label: "Turkmenistan" },
        { id: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
        { id: "Tuvalu", label: "Tuvalu" },
        { id: "Uganda", label: "Uganda" },
        { id: "Ukraine", label: "Ukraine" },
        { id: "United Arab Emirates", label: "United Arab Emirates" },
        { id: "United Kingdom", label: "United Kingdom" },
        { id: "United States", label: "United States" },
        { id: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
        { id: "Uruguay", label: "Uruguay" },
        { id: "Uzbekistan", label: "Uzbekistan" },
        { id: "Vanuatu", label: "Vanuatu" },
        { id: "Venezuela", label: "Venezuela" },
        { id: "Viet Nam", label: "Viet Nam" },
        { id: "Virgin Islands (British)", label: "Virgin Islands(British)" },
        { id: "Virgin Islands (U.S.)", label: "Virgin Islands(U.S.)" },
        { id: "Wallis and Futuna Islands", label: "Wallis and Futuna Islands" },
        { id: "Western Sahara", label: "Western Sahara" },
        { id: "Yemen", label: "Yemen" },
        { id: "Zambia", label: "Zambia" },
        { id: "Zimbabwe", label: "Zimbabwe" },
    ];

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();

            if (flagImageFile)
                values.countryFlagImage = flagImageFile;
            values.countryName = singleSelections[0].label;
            values.countryID = countryID;

            setTimeout(() => {

                if (!editState) {

                    addCountry(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    editCountry(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    const getFlagPic = () => {
        if (!flagImageURL) {
            return "none";
        }

        return `url(${flagImageURL})`;
    };

    const handleNewProfileImage = (event) => {

        let file = event.target.files[0];
        if (file) {

            setFlagImageFile(file);

            let reader = new FileReader();
            let url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                setFlagImageURL(reader.result);
            };
            console.log(url);

        }

    };

    const removePic = () => {
        setFlagImageURL("");
    };

    return (
        <>

            <div className="form-group row">
                <div className="col-lg-12 col-xl-12">
                    <div className="centerMan">
                        <div
                            className="image-input image-input-outline"
                            id="kt_profile_avatar"
                            style={{
                                backgroundImage: `url(${flagImgURL ?? toAbsoluteUrl("/media/users/blank.png")}`,
                            }}
                        >
                            <div
                                className="image-input-wrapper"
                                style={{ backgroundImage: `${getFlagPic()}` }}
                            />
                            <label
                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="change"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Change avatar"
                            >
                                <i className="fa fa-pen icon-sm text-muted"></i>
                                <input
                                    type="file"
                                    name="pic"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e => handleNewProfileImage(e)}
                                />
                                <input type="hidden" name="profile_avatar_remove" />
                            </label>
                            <span
                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="cancel"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Cancel avatar"
                            >
                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                            </span>
                            <span
                                onClick={removePic}
                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="remove"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Remove avatar"
                            >
                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                            </span>
                        </div>
                        <span className="form-text text-muted">
                            Allowed file types: png, jpg, jpeg.
                        </span>
                    </div>
                </div>
            </div>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="countryFormName">
                    <Form.Label>Country</Form.Label>
                    <Typeahead
                        id="countryName"
                        labelKey="label"
                        onChange={(value) => {
                            setSingleSelections(value);
                        }}
                        options={options}
                        placeholder="Select a country..."
                        selected={singleSelections}
                        name="countryName"
                    />
                    <Form.Text className="text-muted">
                        Select a country
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Country field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.countryName && formik.errors.countryName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.countryName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}