import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../layout";
import { DashboardOne } from "./DashboardOne";
import { DashboardTwo } from "./DashboardTwo";
import { DashboardThree } from "./DashboardThree";
import { DashboardDefault } from "./DashboardDefault";
import { DashboardFour } from "./DashboardFour";
import { DashboardTest } from "./DashboardTest";

export function Dashboard() {

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            dashboard: objectPath.get(
                uiService.config,
                "dashboard"
            )
        };
    }, [uiService]);
    return (
        <>
            {layoutProps.dashboard === 'default' && <DashboardTest />}
            {layoutProps.dashboard === 'secondary' && <DashboardTwo />}
            {layoutProps.dashboard === 'tertiary' && <DashboardThree />}
            {layoutProps.dashboard === 'alternate' && <DashboardOne />}
        </>
    );
}
