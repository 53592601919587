import React from 'react';
import { VehiclesTable } from './VehiclesTable';

export function UntappedVehiclesTable() {

    return (
        <>
            <VehiclesTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/organizations/untapped/vehicles/getAll"}
                tableTopHeader={"Untapped Vehicles"}
                tableSubHeader={"Manage Untapped Global vehicle records from this table"}
            />
        </>
    );

}
