import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Paper,
    styled,
    Alert,
    ButtonGroup,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { toAbsoluteUrl } from "../../../_helpers";
import { SimpleModal } from '../modals/SimpleModal';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import {
    FLEET_MANAGING_BASE_URL
} from '../../../../app/services/baseService';
import { CreateEditPartForm, RemoveRecordConfirmForm } from '../index';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { addParts } from '../../../../app/modules/Auth/_redux/authCrud';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `80px`,
        width: `80px`
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function PartsManagementTable({ customClassName, assignAction, faultConfigId }) {

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showSimpleModal, setShowSimpleModal] = useState(false);
    const [showDeleteConfirmationForm, setShowDeleteConfirmationForm] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Add Part');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [selectedPartID, setSelectedPartID] = useState("");
    const [selectedPartData, setSelectedPartData] = useState(null);
    const [initialValues, setInitialValues] = useState({
        vehicleBatchNumber: 0,
        partName: "",
        partCount: 0,
        partMinimumCount: 0,
        purchaseLocation: "",
        partPrice: "",
        SKU: "",
    });

    const defaultValuesToSet = {
        vehicleBatchNumber: 0,
        partName: "",
        partCount: 0,
        partMinimumCount: 0,
        purchaseLocation: "",
        partPrice: "",
        SKU: "",
    };

    const TableStatusMessage = useRef();
    const specPartID = useRef();
    const specPartImage = useRef();
    const classes = useStyles();

    function handleClick() {
        setOpenSnackbar(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/inventory/parts', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                setData(data.data);
                setRowCount(data.max_count);

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];

                    let dataToExport = {
                        partID: element.partID,
                        partPrice: element.document.partPrice,
                        partCount: element.document.partCount,
                        purchaseLocation: element.document.purchaseLocation,
                        partMinimumCount: element.document.partMinimumCount,
                        vehicleBatch: element.document.vehicleBatch,
                        partName: element.document.partName,
                        createdAt: new Date(element.document.createdAt).toLocaleDateString(),
                        SKU: element.document.partSKU,
                    };
                    dataArrayAlt.push(dataToExport);

                    if ((i + 1) >= dataArray.length)
                        setDataDocument(dataArrayAlt);

                }

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableStatusMessage.current = "network error, please check your internet connection";
                    return setOpenSnackbar(true);

                }

                setSeverity("error");
                TableStatusMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;

                setIsError(true);
                setOpenSnackbar(true);

            } finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.partName,
            id: "partName",
            enableClickToCopy: true,
            header: 'Part Name'
        },
        {
            accessorFn: (row) => row?.document?.partSKU,
            id: "partSKU",
            enableClickToCopy: true,
            header: 'SKU'
        },
        {
            accessorFn: (row) => row?.document?.vehicleBatch,
            id: "vehicleBatch",
            enableClickToCopy: true,
            header: 'Batch #'
        },
        {
            accessorFn: (row) => row?.document?.partPrice,
            id: "partPrice",
            enableClickToCopy: true,
            header: 'Part Price'
        },
        {
            accessorFn: (row) => row?.document?.partCount,
            id: "partCount",
            enableClickToCopy: true,
            header: 'Part Count'
        },
        {
            accessorFn: (row) => row?.document?.partMinimumCount,
            id: "partMinimumCount",
            enableClickToCopy: true,
            header: 'Part Minimum Count'
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toUTCString()}`,
            id: 'createdAt',
            header: 'Creation Date',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less Than'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "partID",
            enableClickToCopy: true,
            header: 'Part ID'
        },
    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Inventory',
        showTitle: true
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original?.document));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableStatusMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Part`}
                show={showSimpleModal}
                close={() => setShowSimpleModal(false)}
                size="lg"
                body={<CreateEditPartForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    partId={specPartID.current}
                    initialImagesArray={specPartImage.current}
                />}
            />
            <SimpleModal
                modal_tittle={`Confirm Delete Action`}
                show={showDeleteConfirmationForm}
                close={() => setShowDeleteConfirmationForm(false)}
                size="lg"
                body={<RemoveRecordConfirmForm
                    recordID={selectedPartID}
                    recordData={selectedPartData}
                    onChanger={onChangeWorker}
                    entityType={"inventoryParts"}
                    modalBackAction={() => setShowDeleteConfirmationForm(false)}
                />}
            />
            <div className={`card card-custom ${customClassName} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Inventory - Parts
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            See all added parts records
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {

                                        setEditState(false);
                                        specPartImage.current = null;
                                        setFieldDisabled(false);
                                        setInitialValues(defaultValuesToSet);

                                        setShowSimpleModal(true);

                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Add Part
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10 overflow-auto">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableStatusMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img alt="avatar" height={80} width={80} src={row.original.document?.partImageURL ?? toAbsoluteUrl("/media/svg/icons/General/Attachment1.svg")} loading="lazy" style={{ borderRadius: '50%' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="">
                                        <Grid item xs={6}>
                                            <img src={`https://quickchart.io/qr?text=https://fleet-managing-api-62cj62noaq-oa.a.run.app/handle-part-qr/${row.original.document?.document_id}&size=200&format=svg`} alt='Part QR' />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item>
                                                <Box sx={{ textAlign: 'left' }}>
                                                    <Typography variant="label" className='bold'>Document ID: </Typography>
                                                    <Typography variant="p">
                                                        {row.original.document?.document_id}
                                                    </Typography>
                                                </Box>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const partID = row.original.document.ID;
                                    const valuesToSet = {
                                        vehicleBatchNumber: row.original.document.vehicleBatch,
                                        partName: row.original.document.partName,
                                        partCount: row.original.document.partCount,
                                        partMinimumCount: row.original.document.partMinimumCount,
                                        purchaseLocation: row.original.document.purchaseLocation,
                                        partPrice: row.original.document.partPrice,
                                        partSKU: row.original.document.partSKU,
                                    };

                                    specPartID.current = partID;
                                    specPartImage.current = row.original.document.partImages;

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShowSimpleModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <EditTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Edit Part
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const partID = row.original.document.ID;
                                    const partData = row.original.document;

                                    setSelectedPartID(partID);
                                    setSelectedPartData(partData);

                                    setShowDeleteConfirmationForm(true);
                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <DeleteForeverTwoToneIcon
                                        color='error'
                                    />
                                </ListItemIcon>
                                Delete Part
                            </MenuItem>
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleAddParts = () => {
                                setActionBtnDisabled(false);

                                const allSelectedRowIDs = [];

                                table.getSelectedRowModel().flatRows.map(async (row) => {

                                    allSelectedRowIDs.push(row.original.document.ID);

                                    try {

                                        const addPartPayload = {
                                            parts: allSelectedRowIDs,
                                        };

                                        await addParts(faultConfigId, addPartPayload).then((response) => {

                                            setSeverity('success');
                                            TableStatusMessage.current = response.data.message;
                                            handleClick();
                                            onChangeWorker();
                                            setActionBtnDisabled(true);
                                            return onChangeWorker();
                                        }).catch((error) => {

                                            setSeverity('error');
                                            setActionBtnDisabled(true);

                                            if (error.response) {
                                                TableStatusMessage.current = error?.response?.data?.message;
                                                handleClick();
                                                return onChangeWorker();
                                            }

                                            TableStatusMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                            handleClick();
                                            return onChangeWorker();

                                        });

                                    } catch (error) {
                                        setSeverity('error');
                                        TableStatusMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? 'an unexpected error occurred';
                                        setActionBtnDisabled(true);
                                        handleClick();
                                    }

                                });

                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    {assignAction &&
                                        <ButtonGroup size="small" aria-label="small button group">
                                            <Button
                                                color="secondary"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                                onClick={handleAddParts}
                                                variant="contained"
                                                size="small"
                                            >
                                                Add Part(s)
                                            </Button>
                                        </ButtonGroup>
                                    }
                                    <ButtonGroup
                                        aria-label="button group"
                                        variant="contained"
                                    >
                                        {/* export all data that is currently in the table (ignore pagination, sorting, filtering, etc.) */}
                                        <Button
                                            color="primary"
                                            onClick={handleExportData}
                                            variant="contained"
                                            size="small"
                                        >
                                            Export All Data
                                        </Button>

                                        {/* export all rows, including from the next page, (still respects filtering and sorting) */}
                                        <Button
                                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                                            onClick={() =>
                                                handleExportRows(table.getPrePaginationRowModel().rows)
                                            }
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                        >
                                            Export All Rows
                                        </Button>

                                        {/* export all rows as seen on the screen (respects pagination, sorting, filtering, etc.) */}
                                        <Button
                                            disabled={table.getRowModel().rows.length === 0}
                                            onClick={() => handleExportRows(table.getRowModel().rows)}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                        >
                                            Export Page Rows
                                        </Button>

                                        {/* only export selected rows */}
                                        <Button
                                            disabled={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                            }
                                            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                        >
                                            Export Selected Rows
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            );
                        }}
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
