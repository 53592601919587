import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { getStorageItem } from '../../../../app/services/baseService';
import { createTrackerRecord, editTrackerRecord } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateEditTrackerForm({ initialValues, editState, createEditText, fieldDisabled, onChanger }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        name: Yup.string().required(),
        IMEI: Yup.string().required(),
        model: Yup.string().required(),
        manufacturer: Yup.string().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    createTrackerRecord(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        id: getStorageItem('trackerIdForEdit'),
                        name: values.name,
                        manufacturer: values.manufacturer,
                        IMEI: values.IMEI,
                        model: values.model
                    };

                    editTrackerRecord(editPayload).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="trackerFormName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Preferred Tracker Name" value={formik.values.name} onChange={formik.handleChange} className={`${getInputClasses("name")}`} name="name" {...formik.getFieldProps("name")} />
                    <Form.Text className="text-muted">
                        Enter Preferred Tracker Name - Must be Uniquely Identifiable
                    </Form.Text>
                    <Form.Control.Feedback>Tracker name looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Tracker name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.name}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="trackerFormIMEI">
                    <Form.Label>IMEI</Form.Label>
                    <Form.Control type="text" placeholder="Tracker IMEI number" value={formik.values.IMEI} onChange={formik.handleChange} className={`${getInputClasses("IMEI")}`} name="IMEI" {...formik.getFieldProps("IMEI")} />
                    <Form.Text className="text-muted">
                        Enter Tracker IMEI Number
                    </Form.Text>
                    <Form.Control.Feedback>IMEI looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Tracker IMEI cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.IMEI && formik.errors.IMEI ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.IMEI}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="trackerFormModel">
                    <Form.Label>Tracker Model</Form.Label>
                    <Form.Control as={'select'} placeholder="Tracker Model" value={formik.values.model} onChange={formik.handleChange} className={`${getInputClasses("model")}`} name="model" {...formik.getFieldProps("model")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value={"TFT100"}>TFT100</option>
                        <option value={"VT100L"}>VT100L</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Enter Tracker Model eg: VT100L
                    </Form.Text>
                    <Form.Control.Feedback>looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Tracker Model field cannot be blank!
                    </Form.Control.Feedback>
                    {formik.touched.model && formik.errors.model ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.model}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="trackerFormManufacturer">
                    <Form.Label>Tracker Manufacturer</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.manufacturer} onChange={formik.handleChange} className={`${getInputClasses("manufacturer")}`} name="manufacturer" {...formik.getFieldProps("manufacturer")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option>iStarTek</option>
                        <option>Teltonika</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Select tracker manufacturer
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Tracker manufacturer selection cannot be empty
                    </Form.Control.Feedback>
                    {formik.touched.manufacturer && formik.errors.manufacturer ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.manufacturer}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );
}