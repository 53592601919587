import React from "react";
import {
    OrganizationsTable
} from "../../_wahu/_partials/widgets";
import { useSubheader } from "../../_wahu/layout";

export const OrganizationsPage = () => {

    const suhbeader = useSubheader();
    React.useEffect(() => {

        const load = async () => {
            try {
                suhbeader.setTitle("Organization Table");
            } catch (error) {
                // your Promise won't ever error, but an actual API might
            }
        };
        load();

    }, [suhbeader]);

    return (
        <>
            <div className="row">
                {/* <div className="col-xxl-12">
                    <UserTableWidget className="card-stretch gutter-b" />
                </div> */}
                <div className="col-xxl-12">
                    <OrganizationsTable className="card-stretch gutter-b" />
                </div>
            </div>
        </>
    );
};
