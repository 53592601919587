import React, { useEffect, useState, useRef } from "react";
import { useSubheader } from "../../_wahu/layout";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { toAbsoluteUrl } from "../../_wahu/_helpers";
import { FLEET_MANAGING_BASE_URL } from "../services/baseService";
import Axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import {
    Button,
    IconButton,
    Snackbar,
    Alert,
} from "@mui/material";
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function AllServiceCentersPage() {

    console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    const [zoom, setZoom] = useState(12);
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [severity, setSeverity] = useState('info');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [selected, setSelected] = useState({});

    const classes = useStyles();
    const subheader = useSubheader();
    const pageError = useRef();

    const mapStyles = {
        height: "100%",
        width: "100%",
    };

    const defaultCenter = {
        lat: 5.6747321,
        lng: -0.1996352
    };

    const location = [
        {
            id: 1,
            title: "East Legon (Koko King)",
            lat: 5.6410231,
            lng: -0.1611002,
            location: {
                lat: 5.6410231,
                lng: -0.1611002
            },
            name: "Joseph Baba",
            phoneNumber: "+233533180193"
        },
        {
            id: 2,
            title: "AUTOtech Pro Consult, Nii Teiko Din St, Accra",
            lat: 5.568288,
            lng: -0.227953,
            location: {
                lat: 5.568288,
                lng: -0.227953
            },
            name: "Michael Agyapong",
            phoneNumber: "+233208506568"
        },
        {
            id: 3,
            title: "Fourth Ln, Accra",
            lat: 5.5728476,
            lng: -0.1577767,
            location: {
                lat: 5.5728476,
                lng: -0.1577767
            },
            name: "Daniel Appekey",
            phoneNumber: "+233247173870"
        },
        {
            id: 4,
            title: "JWFP+GXP",
            lat: 5.6238375,
            lng: -0.0625469,
            location: {
                lat: 5.6238375,
                lng: -0.0625469
            },
            name: "Michael Olaga",
            phoneNumber: "+233547949791"
        },
        {
            id: 5,
            title: "Tesano, Accra",
            lat: 5.5962199,
            lng: -0.2345204,
            location: {
                lat: 5.5962199,
                lng: -0.2345204
            },
            name: "Mumuni Issa",
            phoneNumber: "+233548858761"
        }
    ];

    const onSelect = item => {
        setSelected(item);
    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpenSnackBar(false);
    }

    useEffect(() => {

        let isMounted = true;

        const load = async () => {
            try {
                subheader.setTitle("Servicing");
            } catch (error) {
                console.log(error);
            }
        };

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/service-center/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = Axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                console.log(error);

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    pageError.current = "network error, please check your internet connection";
                    setOpenSnackBar(true);

                    return;

                }

                pageError.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackBar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();
        isMounted && load();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        subheader,
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackBar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {pageError.current}
                    </span>}
                </Alert>
            </Snackbar>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    Service Center Locations
                                </span>
                                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                    Visualize all service centers on map
                                </span>
                            </h3>
                            <div className="card-toolbar">
                                <div className="row">
                                    <div className="col-xxl-12 order-1 order-xxl-1">
                                        <Button
                                            variant='outlined'
                                            size="large"
                                            aria-label="Add"
                                            className={classes.margin}
                                            component={Link} to="/serviceCenters"
                                            startIcon={<AddBoxTwoToneIcon />}
                                        >
                                            Add Service Center
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-3 pb-0">
                            <div style={{ width: '100%', height: '600px', marginBottom: '10px' }}>
                                <LoadScript googleMapsApiKey='AIzaSyDYPQI1VL7rgGsE1V8yzA9g2dp14O3mnOw'>
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        zoom={zoom}
                                        center={defaultCenter}
                                    >
                                        {
                                            location.map(mark => (

                                                <Marker
                                                    title="More info"
                                                    key={mark.location.lat}
                                                    position={mark.location}
                                                    onClick={() => {
                                                        onSelect(mark);
                                                    }}
                                                    icon={toAbsoluteUrl("/media/svg/icons/General/location.svg")}
                                                />

                                            ))
                                        }
                                        {
                                            selected.location &&
                                            (
                                                <InfoWindow
                                                    position={selected.location}
                                                    clickable={true}
                                                    onCloseClick={() => setSelected({})}
                                                >
                                                    <div className='info-window centerMan'>
                                                        <h5>
                                                            <span className="bold">
                                                                {selected.title}
                                                            </span>
                                                        </h5>
                                                        <p>
                                                            Contact Person:
                                                            {" "}
                                                            <span className="bold">
                                                                {selected.name}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            Phone Number:
                                                            {" "}
                                                            <span className="bold">
                                                                {selected.phoneNumber}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </InfoWindow>
                                            )
                                        }
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}