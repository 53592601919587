import React, { useEffect, useMemo, useRef, useState } from "react";
import { SUPPORT_SERVICE_BASE_URL, setStorageItem } from "../../../../app/services/baseService";
import axios from "axios";
import { MaterialReactTable } from "material-react-table";
import {
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Snackbar,
    Typography,
    Button,
    Alert,
} from "@mui/material";
import styled from "@emotion/styled";
import StarBorderPurple500TwoToneIcon from '@mui/icons-material/StarBorderPurple500TwoTone';
import { activateSubscription, deactivateSubscription, deleteSubscriptionPlan } from "../../../../app/modules/Auth/_redux/authCrud";
import { ExportToCsv } from "export-to-csv";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import { SimpleModal } from "../modals/SimpleModal";
import { CreateEditSubscriptionPlan } from "../forms/CreateEditSubscriptionPlan";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

export function SubscriptionPlansTable({ className }) {

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [paymentPlansPagination, setPaymentPlansPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const TableErrorMessage = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState({
        planTitle: "",
        planPrice: 0,
        planBenefits: "",
        planDuration: "",
        isRecommended: false,
        weekNumber: 0,
        dailyPaymentBuffer: 0,
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Account');
    const [fieldDisabled, setFieldDisabled] = useState(false);

    const defaultValuesToSet = {
        planTitle: "",
        planPrice: 0,
        planBenefits: [],
        planDuration: "",
        isRecommended: false,
        weekNumber: 0,
        dailyPaymentBuffer: 0,
    };

    function triggerSnackBar() {
        setOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/riderSubscriptionPlans/getAll', `${SUPPORT_SERVICE_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${paymentPlansPagination.pageIndex * paymentPlansPagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${paymentPlansPagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    let dataArrayDocument = element.document;
                    dataArrayAlt.push(dataArrayDocument);

                    if ((i + 1) >= dataArray.length)
                        setDataDocument(dataArrayAlt);

                }

                setData(data.data);
                setRowCount(data.max_count.count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    return setOpen(true);

                }

                setSeverity("error");
                TableErrorMessage.current = error?.response ? `Code: ${error.response.status}, Message: ${error.response.data.message}` : `Code: ${error.code}, Message: ${error.message}`;

                setIsError(true);
                setOpen(true);

            } finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);
            setDataDocument([]);
            setInitialValues(null);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [

        paymentPlansPagination.pageIndex,
        paymentPlansPagination.pageSize,
        reRender,

    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => `${row?.document?.planTitle}`,
            id: "planTitle",
            enableClickToCopy: true,
            header: "Plan Name"

        },
        {
            accessorFn: (row) => `${row?.document?.subscriptionsCount} riders subscribed`,
            id: "planTakeCount",
            enableClickToCopy: true,
            header: "Subscription Count"

        },
        {
            accessorFn: (row) => `${row?.document?.planPrice ?? ""}`,
            id: "planPrice",
            enableClickToCopy: true,
            header: 'Unit Price'
        },
        {
            accessorFn: (row) => `${row?.document?.weekNumber ?? ""}`,
            id: "weekNumber",
            enableClickToCopy: true,
            header: 'Plan Duration',
            Cell: ({ cell }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                        {" "}
                        weeks
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => `${row?.document?.planDuration ?? ""}`,
            id: "due",
            enableClickToCopy: true,
            header: 'Payment Frequency',
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Inactive"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row?.document?.ID}`,
            id: "planID",
            enableClickToCopy: true,
            header: "Plan ID"

        },

    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Driver Data',
        showTitle: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original?.document));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Subscription Plan`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditSubscriptionPlan
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}

                />}
            />
            <div className={`card card-custom ${className} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Subscription Plans
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage subscription plans from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Subscription Plan");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                >
                                    Create Subscription Plan
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        data={data}
                        columns={columns}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError ? {
                                color: 'error',
                                children: `${TableErrorMessage.current}`,
                            } : undefined
                        }
                        onPaginationChange={setPaymentPlansPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            paymentPlansPagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderRowActionMenuItems={({ row, closeMenu }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const subID = row.original.document?.ID;
                                    const planBenefits = row?.original?.document?.planBenefits;
                                    let benefitsString = "";

                                    if (planBenefits) {

                                        for (let i = 0; i < planBenefits.length; i++) {

                                            const specBenefitString = planBenefits[i];
                                            if (benefitsString !== "")
                                                benefitsString = benefitsString + ";" + specBenefitString;
                                            else
                                                benefitsString = specBenefitString;

                                            if ((1 + i) === planBenefits.length) {

                                                const valuesToSet = {
                                                    planTitle: row.original.document?.planTitle,
                                                    planPrice: row.original.document?.planPrice,
                                                    planBenefits: benefitsString,
                                                    planDuration: row.original.document?.planDuration,
                                                    weekNumber: row.original.document?.weekNumber,
                                                    isRecommended: row.original.document?.isRecommended,
                                                    dailyPaymentBuffer: 0
                                                };

                                                setCreateEditText("Save Changes");
                                                setStorageItem("subscriptionForEdit", subID);

                                                setEditState(true);
                                                setFieldDisabled(true);
                                                setInitialValues(valuesToSet);
                                                setShow(true);

                                                return closeMenu();

                                            }

                                        }

                                    }

                                }}
                            >
                                <ListItemIcon >
                                    <EditTwoToneIcon color="secondary" />
                                </ListItemIcon>
                                Edit Subscription Plan Details
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const subID = row.original.document?.ID;
                                    const removeSubscriptionPlan = async () => {

                                        try {

                                            const response = (await deleteSubscriptionPlan(subID));
                                            TableErrorMessage.current = response.data.message;
                                            setSeverity('success');
                                            triggerSnackBar();

                                        } catch (error) {

                                            TableErrorMessage.current = error.message;
                                            setSeverity('error');
                                            triggerSnackBar();

                                        }

                                    };

                                    removeSubscriptionPlan();
                                    onChangeWorker();

                                }}
                            >
                                <ListItemIcon >
                                    <DeleteForeverTwoToneIcon style={{ color: "#FF0000" }} />
                                </ListItemIcon>
                                Delete Subscription Plan
                            </MenuItem>,
                            // <MenuItem
                            //     key={2}
                            //     onClick={() => {
                            //         console.log(row.original.document?.planPrice);
                            //     }}
                            // >
                            //     <ListItemIcon >
                            //         <VisibilityIcon color="primary" />
                            //     </ListItemIcon>
                            //     View Plan Metrics
                            // </MenuItem>,
                        ]}
                        renderDetailPanel={({ row }) => (
                            <div>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={12}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label">Benefits: </Typography>
                                                <List>
                                                    {row.original.document.planBenefits.map((benefit, i) => {
                                                        return <ListItem key={i}>
                                                            <ListItemIcon>
                                                                <StarBorderPurple500TwoToneIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary={benefit} />
                                                        </ListItem>;
                                                    })}
                                                </List>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        renderTopToolbarCustomActions={({ table }) => {
                            const handleDeactivate = async () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        const response = await deactivateSubscription(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        return triggerSnackBar();

                                    } catch (error) {

                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        return triggerSnackBar();

                                    }
                                });

                            };

                            const handleActivate = async () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        const response = await activateSubscription(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        return triggerSnackBar();

                                    } catch (error) {

                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        return triggerSnackBar();

                                    }
                                });
                            };

                            return (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                color="error"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                                onClick={handleDeactivate}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Deactivate
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                color="info"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                                onClick={handleActivate}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Activate
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                color="info"
                                                onClick={handleExportData}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Export Page
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                disabled={
                                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                                }
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                fullWidth
                                                color="info"
                                            >
                                                Export Selected
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }}
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>

            </div>
        </>
    );

}