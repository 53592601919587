import React, { useEffect, useState } from "react";
import {
    Tab,
    Nav,
    Card,
} from 'react-bootstrap';
import { useSubheader } from "../../_wahu/layout";
import {
    VehicleTypeSettingsTableSub,
    MotorSettingsTableSub,
    BatteriesSettingsTableSub,
    ManufacturerSettingsTableSub,
    VehicleModelsSettingsTableSub,
    CountriesTable
} from "../../_wahu/_partials/widgets";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function SettingsPage() {

    const suhbeader = useSubheader();

    const [expanded, setExpanded] = useState("panel1");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const load = async () => {
            try {
                suhbeader.setTitle("Platform Settings");
            } catch (error) {
                console.log(error);
            }
        };
        load();
    }, [suhbeader]);

    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="vehicle-settings">
                <div className="row" style={{ height: "100vh" }}>
                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 p-2">
                        <div className="card w-100 h-100 rounded-lg">
                            <div className="card-body p-0">
                                <Nav variant="pills" className="flex-column mt-5 p-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="vehicle-settings" className="tab-head-active border mb-2">
                                            <span className="bold">Vehicle Settings</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fault-settings" className="tab-head-active border mb-2">
                                            <span className="bold"> Fault Settings</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="country-settings" className="tab-head-active border mb-2">
                                            <span className="bold">Country Settings</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9 p-2" style={{ overflowY: "scroll", }}>
                        <div className="card w-100 h-100 rounded-lg">
                            <div className="card-body">
                                <Tab.Content className="tab-pane mt-5">
                                    <Tab.Pane eventKey="vehicle-settings">

                                        <Accordion className="rounded-lg mb-5 border-0" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <span>
                                                    <h5>Vehicle Types</h5>
                                                    <small className="text-muted">
                                                        Click to expand/collapse
                                                    </small>
                                                </span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <VehicleTypeSettingsTableSub className="card-stretch gutter-b" />
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion className="rounded-lg mb-5 border-0" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2-content"
                                                id="panel2-header"
                                            >
                                                <span>
                                                    <h5>Motors</h5>
                                                    <small className="text-muted">
                                                        Click to expand/collapse
                                                    </small>
                                                </span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <MotorSettingsTableSub className="card-stretch gutter-b" />
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion className="rounded-lg mb-5 border-0" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel3-content"
                                                id="panel3-header"
                                            >
                                                <span>
                                                    <h5>Battery Configurations</h5>
                                                    <small className="text-muted">
                                                        Click to expand/collapse
                                                    </small>
                                                </span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <BatteriesSettingsTableSub className="card-stretch gutter-b" />
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion className="rounded-lg mb-5 border-0" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel4-content"
                                                id="panel4-header"
                                            >
                                                <span>
                                                    <h5>Manufacturers</h5>
                                                    <small className="text-muted">
                                                        Click to expand/collapse
                                                    </small>
                                                </span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <ManufacturerSettingsTableSub className="card-stretch gutter-b" />
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion className="rounded-lg border-0" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel5-content"
                                                id="panel5-header"
                                            >
                                                <span>
                                                    <h5>
                                                        Vehicle Models
                                                    </h5>
                                                    <small className="text-muted">
                                                        Click to expand/collapse
                                                    </small>
                                                </span>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <VehicleModelsSettingsTableSub className="card-stretch gutter-b" />
                                            </AccordionDetails>
                                        </Accordion>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="country-settings">
                                        <CountriesTable
                                            tableClassName={"card-stretch gutter-b"}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fault-settings">
                                        <Card body>
                                            <Card border="secondary">
                                                <Card.Body>
                                                    <i className="fa fa-exclamation-triangle font-red pr-2"></i>
                                                    Slow down, construction ahead
                                                </Card.Body>
                                            </Card>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </>
    );
};
