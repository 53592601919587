import axios from "axios";
import {
    getAuth,
    signInWithPhoneNumber
} from "firebase/auth";
import { AUTH_BASE_URL, DELIVERY_SERVICE_BASE_URL, FLEET_MANAGING_BASE_URL, PAYMENT_BASE_URL, setStorageItem, SUPPORT_SERVICE_BASE_URL } from '../../../services/baseService';
import { authentication } from "../firebase";

export const authenticator = getAuth();

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export function login(email, password) {
    return axios.post(LOGIN_URL, { email, password });
}

export async function signin(phoneNumber, appVerifier) {

    try {
        const confirmationResult = await signInWithPhoneNumber(authentication, phoneNumber, appVerifier);
        window.confirmationResult = confirmationResult;
    } catch (error) {
        return error;
    }

}

export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken(signal, adminID, idToken) {

    const AuthStr = 'Bearer '.concat(idToken);
    const GETUSER_URL = `${AUTH_BASE_URL}/users/admins/${adminID}`;

    if (signal)
        return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr }, signal: signal });

    return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

}

export function updateUserNotificationToken(userID, notificationToken, signal) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/messaging/add-token`;
    const requestPayload = {
        userID: userID,
        token: notificationToken,
    };

    if (signal)
        return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr }, signal: signal });

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function sendUserNotificationToken(userID, notice, noticeTitle, adderID, clickActionURL, dataObject) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/notifications/push`;
    const requestPayload = {
        userID: userID,
        notice: notice,
        noticeTitle: noticeTitle,
        adderID: adderID,
        clickActionURL: clickActionURL,
        dataObject: dataObject,
    };

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function getGuestByToken(signal) {

    const userID = authenticator.currentUser.uid;
    const token = authenticator.currentUser.accessToken;

    const AuthStr = 'Bearer '.concat(token);
    const GETUSER_URL = `${AUTH_BASE_URL}/users/${userID}`;

    if (signal)
        return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr }, signal: signal });

    return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

}

export function getAllAdmins() {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const GETUSER_URL = `${AUTH_BASE_URL}/users/admins/getAll`;

    return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateDriver(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/${driverID}/activate`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateDriver(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/${driverID}/deactivate`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function pauseDriverSubscription(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/subscriptions/pause`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function unpauseDriverSubscription(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/subscriptions/unpause/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateUser(userID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/auth/deactivateUser/${userID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateUser(userID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/auth/activateUser/${userID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateOrganization(organizationID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/organizations/deactivate/${organizationID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateOrganization(organizationID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/organizations/activate/${organizationID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function createAdminAccount(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/admins/createAccountWithPhoneNumber`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function createPartRecord(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/inventory/parts`;

    const createPartFormData = new FormData();

    for (let i = 0; i < requestPayload.partImages.length; i++) {

        const partImage = requestPayload.partImages[i];
        createPartFormData.append('partImages', partImage);

        if ((i + 1) === requestPayload.partImages.length) {

            createPartFormData.append('vehicleBatchNumber', Number(requestPayload.vehicleBatchNumber));
            createPartFormData.append('partName', requestPayload.partName);
            createPartFormData.append('partCount', Number(requestPayload.partCount));
            createPartFormData.append('partMinimumCount', Number(requestPayload.partMinimumCount));
            createPartFormData.append('purchaseLocation', requestPayload.purchaseLocation);
            createPartFormData.append('partPrice', requestPayload.partPrice);
            createPartFormData.append('SKU', requestPayload.partSKU);

            return axios.post(REQUEST_URL, createPartFormData, { headers: { 'Authorization': AuthStr } });

        }

    }

}

export function updateDriverGhanaCard(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/ghana-card`;

    const ghanaCardImagesData = new FormData();

    ghanaCardImagesData.append('userID', requestPayload.userID);
    ghanaCardImagesData.append('idCardFront', requestPayload.idCardFront);
    ghanaCardImagesData.append('idCardBack', requestPayload.idCardBack);

    return axios.patch(REQUEST_URL, ghanaCardImagesData, { headers: { 'Authorization': AuthStr } });

}

export function editPartDetails(requestPayload, partID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/inventory/parts/${partID}`;
    const editPartFormData = new FormData();

    if (requestPayload.partImages) {

        for (let i = 0; i < requestPayload.partImages.length; i++) {

            const partImage = requestPayload.partImages[i];
            editPartFormData.append('partImages', partImage);

            if ((i + 1) === requestPayload.partImages.length) {

                editPartFormData.append('vehicleBatchNumber', Number(requestPayload.vehicleBatchNumber));
                editPartFormData.append('partName', requestPayload.partName);
                editPartFormData.append('partCount', Number(requestPayload.partCount));
                editPartFormData.append('partMinimumCount', Number(requestPayload.partMinimumCount));
                editPartFormData.append('purchaseLocation', requestPayload.purchaseLocation);
                editPartFormData.append('partPrice', requestPayload.partPrice);
                editPartFormData.append('SKU', requestPayload.partSKU);

                return axios.patch(REQUEST_URL, editPartFormData, { headers: { 'Authorization': AuthStr } });

            }

        }

        return;

    }

    editPartFormData.append('partImage', null);
    editPartFormData.append('vehicleBatchNumber', Number(requestPayload.vehicleBatchNumber));
    editPartFormData.append('partName', requestPayload.partName);
    editPartFormData.append('partCount', Number(requestPayload.partCount));
    editPartFormData.append('partMinimumCount', Number(requestPayload.partMinimumCount));
    editPartFormData.append('purchaseLocation', requestPayload.purchaseLocation);
    editPartFormData.append('partPrice', requestPayload.partPrice);
    editPartFormData.append('SKU', requestPayload.partSKU);

    return axios.patch(REQUEST_URL, editPartFormData, { headers: { 'Authorization': AuthStr } });

}

export function removePartImage(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/inventory/parts/remove-images?partID=${requestPayload.partID}&&imageFileName=${requestPayload.imageFileName}`;

    return axios.delete(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editAdminDetails(requestPayload, adminID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/admins/update/${adminID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function createDriverAccount(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/createAccountWithPhoneNumber`;

    const createDriverAccountFormData = new FormData();
    if (requestPayload.profileImage)
        createDriverAccountFormData.append('profileImage', requestPayload.profileImage);
    createDriverAccountFormData.append('phoneNumber', requestPayload.phoneNumber);
    createDriverAccountFormData.append('licenseID', requestPayload.licenseID ?? null);
    createDriverAccountFormData.append('firstName', requestPayload.firstName);
    createDriverAccountFormData.append('lastName', requestPayload.lastName);
    createDriverAccountFormData.append('gender', requestPayload.gender);
    createDriverAccountFormData.append('country', requestPayload.country);

    return axios.post(REQUEST_URL, createDriverAccountFormData, { headers: { 'Authorization': AuthStr } });

}

export function editDriverDetails(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/update/${requestPayload.id}`;

    const editDriverAccountFormData = new FormData();
    editDriverAccountFormData.append('profileImage', requestPayload.profileImage ?? null);
    editDriverAccountFormData.append('licenseID', requestPayload.licenseID ?? null);
    editDriverAccountFormData.append('firstName', requestPayload.firstName);
    editDriverAccountFormData.append('lastName', requestPayload.lastName);
    editDriverAccountFormData.append('gender', requestPayload.gender);
    editDriverAccountFormData.append('country', requestPayload.country);

    return axios.patch(REQUEST_URL, editDriverAccountFormData, { headers: { 'Authorization': AuthStr } });

}

export function churnDriver(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/drivers/churn-driver`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function unChurnDriver(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/drivers/${driverID}/un-churn`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function updateUserEmail(requestPayload, driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/auth/updateEmail/${driverID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function setSubscriptionStartDate(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/drivers/set-subscription-start-date`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function createOrganizationAccount(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/organizations/create`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editOrganizationDetails(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/organizations/update`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function createVehicleRecord(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/add`;

    const bodyFormData = new FormData();
    bodyFormData.append('profileImage', requestPayload.profileImage ?? null);
    bodyFormData.append('VIN', requestPayload.VIN);
    bodyFormData.append('vehicleModelID', requestPayload.vehicleModelID);
    bodyFormData.append('batteryID', requestPayload.batteryID);

    return axios.post(REQUEST_URL, bodyFormData, { headers: { 'Authorization': AuthStr } });

}

export function createVehicleCohort(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/cohorts`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function getVehicleCohortSizeLimit() {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehiclesCohorts/size`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setVehicleCohortSizeLimit(cohortSize) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehiclesCohorts/${cohortSize}/set-size`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function editVehicleRecord(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/update/${requestPayload.id}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function getVehicle(vehicleID, abortControllerSignal) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/${vehicleID}`;

    if (abortControllerSignal)
        return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr }, signal: abortControllerSignal });

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function createVehicleModel(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleModels/create`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editVehicleModel(requestPayload, vehicleID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleModels/update/${vehicleID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export async function assignVehicles(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/assignToDriver`;
    //const REQUEST_URL = `http://localhost:8080/vehicles/assignToDriver`;

    return await axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export async function assignTracker(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/trackers/assignToVehicle`;

    return await axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export async function getTrackerByID(trackerID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/trackers/${trackerID}`;

    return await axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function assignVehiclesToOrganization(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/assignToOrganization`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function deactivateVehicle(vehicleID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/deactivate/${vehicleID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setVehicleOutOfRepair(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/set-repaired`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function setVehicleInRepair(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/set-repairing`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function activateVehicle(vehicleID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/activate/${vehicleID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function assignDriversToOrganization(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/drivers/assignToOrganization`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export async function refreshToken() {

    const payload = ({
        refresh_token: getRefreshToken()
    });

    const REQUEST_URL = `${AUTH_BASE_URL}/auth/reAuthenticate`;
    await axios.post(REQUEST_URL, payload).then((response) => {
        setStorageItem("token", response.data.id_token);
    }).catch((error) => {
        console.log(error.response);
    });

}

export function getRefreshToken() {
    return authenticator.currentUser.refreshToken;
}

export function addVehicleType(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleTypes/add`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editVehicleType(requestPayload, vehicleTypeID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleTypes/update/${vehicleTypeID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function activateVehicleType(vehicleTypeID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleTypes/activate/${vehicleTypeID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateVehicleType(vehicleTypeID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleTypes/deactivate/${vehicleTypeID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function addMotor(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/motors/add`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editMotor(requestPayload, motorID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/motors/update/${motorID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function activateMotor(motorID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/motors/activate/${motorID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateMotor(motorID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/motors/deactivate/${motorID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function addBattery(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/batteries/add`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editBattery(requestPayload, batteryID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/batteries/update/${batteryID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function activateBattery(batteryID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/batteries/activate/${batteryID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateBattery(batteryID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/batteries/deactivate/${batteryID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function getMotor(motorID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/motors/${motorID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function getBattery(batteryID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/batteries/${batteryID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function addManufacturer(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/manufacturers/add`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function addCountry(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/countries/single`;

    const addCountryFormData = new FormData();
    addCountryFormData.append('countryName', requestPayload.countryName);
    addCountryFormData.append('countryFlagImage', requestPayload.countryFlagImage);

    return axios.post(REQUEST_URL, addCountryFormData, { headers: { 'Authorization': AuthStr } });

}

export function editCountry(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/countries`;

    const editCountryFormData = new FormData();
    editCountryFormData.append('countryName', requestPayload.countryName);
    editCountryFormData.append('countryFlagImage', requestPayload.countryFlagImage);
    editCountryFormData.append('countryID', requestPayload.countryID);

    return axios.patch(REQUEST_URL, editCountryFormData, { headers: { 'Authorization': AuthStr } });

}

export function deleteCountry(countryID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/countries/${countryID}`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateCountry(countryID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/countries/${countryID}/activate`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateCountry(countryID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/countries/${countryID}/deactivate`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function editManufacturer(requestPayload, motorID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/manufacturers/update/${motorID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function getManufacturer(manufacturerID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/manufacturers/${manufacturerID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateManufacturer(manufacturerID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/manufacturers/activate/${manufacturerID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateManufacturer(manufacturerID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/manufacturers/deactivate/${manufacturerID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateVehicleModel(vehicleModelID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleModels/activate/${vehicleModelID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateVehicleModel(vehicleModelID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleModels/deactivate/${vehicleModelID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function updateAdmin(requestPayload, adminID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/admins/update/${adminID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function getVehicleType(vehicleTypeID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleTypes/${vehicleTypeID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function getVehicleModelData(vehicleModelID, abortControllerSignal) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/vehicleModels/${vehicleModelID}`;

    if (abortControllerSignal)
        return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr }, signal: abortControllerSignal });

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function deactivateTracker(trackerID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/trackers/deactivate/${trackerID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateTracker(trackerID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/trackers/activate/${trackerID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function createTrackerRecord(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/trackers/add`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function createVendorAccountRequest(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${DELIVERY_SERVICE_BASE_URL}/services/delivery/requestToJoin`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}


export function createVendorAccount(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/vendors/createAccountWithPhoneNumber`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function updateVendorAccount(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/vendors/createAccountWithPhoneNumber`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function confirmVendorAccount(id, requestID, requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/vendors/update/${id}?dataConfirmed=true&requestID=${requestID}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editTrackerRecord(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/trackers/update/${requestPayload.id}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function removeManufacturer(manufacturerID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/manufacturers/remove/${manufacturerID}`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function updateDriverPhoneNumber(requestPayload, userID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/updatePhoneNumber/${userID}`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function setManaBikeSubscriptionPending(subscriptionID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const GETUSER_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/manaBikeSubscriptions/setPending/${subscriptionID}`;

    return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

}

export function setManaBikeSubscriptionCompleted(subscriptionID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const GETUSER_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/manaBikeSubscriptions/setComplete/${subscriptionID}`;

    return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

}

export function setManaBikeSubscriptionCancelled(subscriptionID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const GETUSER_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/manaBikeSubscriptions/cancel/${subscriptionID}`;

    return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

}

export function approveDriverInTimeline(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/drivers/approveOnSubscription`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function createDriverCohort(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/driverCohorts/create`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function editDriverCohort(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/driverCohorts/update/${requestPayload.id}`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function removeBikeInterestLog(bikeInterestID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const GETUSER_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/bike/interest-logs/${bikeInterestID}`;

    return axios.delete(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

}

export function markBikeInterestReplied(interestLogID) {

    const GETUSER_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/bike/interest-logs/${interestLogID}/replied`;
    return axios.get(GETUSER_URL);

}

export function sendCustomEmailForBikeInterestLog(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/bike/interest-logs/email-response`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function addNoteToRequest(requestPayload, interestLogID, requestType) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    let REQUEST_URL = "";

    if (requestType === "bikeRequest")
        REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/bike/interest-logs/${interestLogID}/note`;

    if (requestType === "contactUsRequest")
        REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/contact-us/${interestLogID}/note`;

    if (requestType === "consultationRequest")
        REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/consultation/${interestLogID}/note`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function sendCustomSMSToBikeInterestLog(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/bike/interest-logs/sms-response`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function verifyTransaction(provider, reference) {

    if ((provider).toLowerCase() === "paystack") {

        const token = authenticator.currentUser.accessToken;
        const AuthStr = 'Bearer '.concat(token);
        const GETUSER_URL = `${PAYMENT_BASE_URL}/payments/ps/verify/${reference}/false`;

        return axios.get(GETUSER_URL, { headers: { 'Authorization': AuthStr } });

    }

}

export function unassignVehicle(payload) {

    const UNASSIGN_VEHICLE = `${FLEET_MANAGING_BASE_URL}/vehicles/unassignToDriver`;
    return axios.post(UNASSIGN_VEHICLE, payload);

}

export function unassignVehicleFromOrganization(requestPayload) {

    const UNASSIGN_VEHICLE = `${FLEET_MANAGING_BASE_URL}/vehicles/unassignToOrganization`;
    return axios.post(UNASSIGN_VEHICLE, requestPayload);

}

export function removeVehicle(vehicleID) {

    const DELETE_VEHICLE_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/remove/${vehicleID}`;
    return axios.delete(DELETE_VEHICLE_URL);

}

export function designateAsStaffVehicle(vehicleID) {

    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/staff-vehicle/${vehicleID}`;
    return axios.get(REQUEST_URL);

}

export function designateAsCustomerVehicle(vehicleID) {

    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/customer-vehicle/${vehicleID}`;
    return axios.get(REQUEST_URL);

}

export function removeStaffVehicleDesignation(vehicleID) {

    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/not-staff-vehicle/${vehicleID}`;
    return axios.get(REQUEST_URL);

}

export function removeCustomerVehicleDesignation(vehicleID) {

    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/vehicles/not-customer-vehicle/${vehicleID}`;
    return axios.get(REQUEST_URL);

}

export function deactivateCohort(id) {

    const DEACTIVATE_COHORT = `${FLEET_MANAGING_BASE_URL}//driverCohorts/deactivate/${id}`;
    return axios.get(DEACTIVATE_COHORT);

}

export function activateCohort(id) {

    const ACTIVATE_COHORT = `${FLEET_MANAGING_BASE_URL}//driverCohorts/activate/${id}`;
    return axios.get(ACTIVATE_COHORT);

}

export function removeCohort(id) {

    const REMOVE_COHORT = `${FLEET_MANAGING_BASE_URL}/driverCohorts/remove/${id}`;
    return axios.get(REMOVE_COHORT);

}

export function confirmBikeArrival(body) {

    const CONFIRMBIKE_ARRIVAL = `${FLEET_MANAGING_BASE_URL}/driverCohorts/confirmBikeArrival`;
    return axios.post(CONFIRMBIKE_ARRIVAL, body);

}

export function getDriver(driverID) {

    const GET_DRIVER = `${AUTH_BASE_URL}/users/drivers/${driverID}`;
    return axios.get(GET_DRIVER);

}

export function activateSubscription(subID) {

    const URL = `${SUPPORT_SERVICE_BASE_URL}/riderSubscriptionPlans/activate/${subID}`;
    return axios.get(URL);

}

export function deactivateSubscription(subID) {

    const URL = `${SUPPORT_SERVICE_BASE_URL}/riderSubscriptionPlans/deactivate/${subID}`;
    return axios.get(URL);

}

export function createSubscriptionPlan(createPayload) {

    const URL = `${SUPPORT_SERVICE_BASE_URL}/riderSubscriptionPlans/create`;
    return axios.post(URL, createPayload);

}
export function editSubscriptionPlanDetails(createPayload, subID) {

    const URL = `${SUPPORT_SERVICE_BASE_URL}/riderSubscriptionPlans/update/${subID}`;
    return axios.patch(URL, createPayload);

}

export function deleteSubscriptionPlan(subID) {

    const URL = `${SUPPORT_SERVICE_BASE_URL}/riderSubscriptionPlans/remove/${subID}`;
    return axios.delete(URL);

}

export function createTransaction(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${PAYMENT_BASE_URL}/payments/createUserTransaction`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function sendBulkMessages(comPayload) {

    const URL = `${SUPPORT_SERVICE_BASE_URL}/communications/bulk-emails-sms`;
    return axios.post(URL, comPayload);

}

export function setZohoAccountID(comPayload) {

    const URL = `${FLEET_MANAGING_BASE_URL}/drivers/set-zoho-account-id`;
    return axios.post(URL, comPayload);

}

export function removeOrganizationRecord(orgID) {

    const GETUSER_URL = `${AUTH_BASE_URL}/organizations/remove/${orgID}`;
    return axios.delete(GETUSER_URL);

}

export function removeConsultationRequestLog(consultationID) {

    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/consultation/${consultationID}`;
    return axios.delete(REQUEST_URL);

}

export function markConsultationRequestAsReplied(consultationRequestID) {

    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/consultation/${consultationRequestID}/replied`;
    return axios.get(REQUEST_URL);

}

export function sendCustomEmailForConsultationRequest(requestPayload) {

    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/consultation/email-response`;
    return axios.post(REQUEST_URL, requestPayload);

}

export function sendCustomSMSForConsultationRequest(requestPayload) {

    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/consultation/sms-response`;
    return axios.post(REQUEST_URL, requestPayload);

}

export function sendCustomEmailForContactUsRequest(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/contact-us/email-response`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function sendCustomSMSForContactUsRequest(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/contact-us/sms-response`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function removeContactUsRequestLog(ContactUsRequestID) {

    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/contact-us/${ContactUsRequestID}`;
    return axios.delete(REQUEST_URL);

}

export function removeInventoryPart(partID) {

    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/inventory/parts/${partID}`;
    return axios.delete(REQUEST_URL);

}

export function markContactUsRequestAsReplied(ContactUsRequestID) {

    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/contact-us/${ContactUsRequestID}/replied`;
    return axios.get(REQUEST_URL);

}

export function getSMSBalance() {

    const GETUSER_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/sms-balance`;
    return axios.get(GETUSER_URL);

}

export function getMessageLogs() {

    const GETUSER_URL = `${SUPPORT_SERVICE_BASE_URL}/communications/bulk-emails-sms`;
    return axios.get(GETUSER_URL);

}

export function updatedDriverDigitalAddress(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/digital-address`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function updatedGuarantorDetails(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/guarantor-details`;

    const guarantorDetailsData = new FormData();

    guarantorDetailsData.append('driverID', requestPayload.driverID);
    guarantorDetailsData.append('firstName', requestPayload.firstName);
    guarantorDetailsData.append('lastName', requestPayload.lastName);
    guarantorDetailsData.append('phoneNumber', requestPayload.phoneNumber);
    guarantorDetailsData.append('digitalAddress', requestPayload.digitalAddress);
    guarantorDetailsData.append('email', requestPayload.email);
    guarantorDetailsData.append('profession', requestPayload.profession);
    guarantorDetailsData.append('businessAddress', requestPayload.businessAddress);
    guarantorDetailsData.append('ghanaCardFrontImage', requestPayload.ghanaCardFrontImage);
    guarantorDetailsData.append('ghanaCardBackImage', requestPayload.ghanaCardBackImage);

    return axios.post(REQUEST_URL, guarantorDetailsData, { headers: { 'Authorization': AuthStr } });

}

export function setGhanaCardAsProvided(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/ghana-card-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setGhanaCardAsNotProvided(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/ghana-card-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDigitalAddressAsProvided(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/digital-address-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDigitalAddressAsNotProvided(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/digital-address-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setInfoSessionAsAttended(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/info-session-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setInfoSessionAsNotAttended(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/info-session-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setSelectedPaymentPlanAsChosen(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/payment-plan-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setSelectedPaymentPlanAsNotChosen(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/payment-plan-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverPaymentPlan(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/payment-plan`;

    return axios.post(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function updateDriverPaymentPlan(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/payment-plan`;

    return axios.patch(REQUEST_URL, requestPayload, { headers: { 'Authorization': AuthStr } });

}

export function setSafetyCVideoAsWatched(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/safety-video-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setSafetyCVideoAsNotWatched(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/safety-video-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setMaintenanceVideoAsWatched(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/maintenance-video-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setMaintenanceVideoAsNotWatched(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/maintenance-video-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setInitialDepositAsPaid(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/initial-deposit-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setInitialDepositAsNotPaid(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/initial-deposit-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setInterviewAsAttended(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/attended-interview-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setInterviewAsNotAttended(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/attended-interview-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverGuarantorDetailsAsProvided(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/guarantor-details-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverGuarantorDetailsAsNotProvided(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/guarantor-details-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverHomeAsVisited(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/home-visit-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverHomeAsNotVisited(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/home-visit-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverContractAsSigned(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/driver-contract-signed-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverContractAsNotSigned(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/driver-contract-signed-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function updateDriverSignedContract(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/driver-contract`;

    const updateDriverContract = new FormData();
    updateDriverContract.append('userID', requestPayload.userID);
    updateDriverContract.append('file', requestPayload.file ?? null);

    return axios.post(REQUEST_URL, updateDriverContract, { headers: { 'Authorization': AuthStr } });

}

export function updateDriverGuarantorSignedContract(requestPayload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/driver-guarantor-contract`;

    const updateDriverGuarantorContract = new FormData();
    updateDriverGuarantorContract.append('userID', requestPayload.userID);
    updateDriverGuarantorContract.append('file', requestPayload.file ?? null);

    return axios.post(REQUEST_URL, updateDriverGuarantorContract, { headers: { 'Authorization': AuthStr } });

}

export function setDriverGuarantorContractAsSigned(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/driver-guarantor-contract-signed-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setDriverGuarantorContractAsNotSigned(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/driver-guarantor-contract-signed-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setBikeAsReceivedByDriver(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/bike-received-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setBikeAsNotReceivedByDriver(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/bike-received-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setBikeAccessoriesAsReceivedByDriver(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/accessories-received-yes/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function setBikeAccessoriesAsNotReceivedByDriver(driverID) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/users/onboarding/accessories-received-no/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });

}

export function activateJob(jobID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const ACTIVATE_JOB_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/activate/${jobID}`;

    return axios.get(ACTIVATE_JOB_URL, { headers: { 'Authorization': AuthStr } });
}

export function deactivateJob(jobID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const ACTIVATE_JOB_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/deactivate/${jobID}`;

    return axios.get(ACTIVATE_JOB_URL, { headers: { 'Authorization': AuthStr } });
}

export function createJobOpening(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const CREATE_JOB_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/create`;

    return axios.post(CREATE_JOB_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function updateJobOpening(payload, jobID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const UPDATE_JOB_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/update/${jobID}`;

    return axios.patch(UPDATE_JOB_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function designateDriverAccountAsStaffAccount(driverID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/${driverID}/is-staff`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function removeStaffAccountDesignation(driverID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/${driverID}/not-staff`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function updateAdminPhoneNumber(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const UPDATE_PHONE_URL = `${AUTH_BASE_URL}/users/admins/updatePhone`;

    return axios.patch(UPDATE_PHONE_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function updateAdminEmail(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const UPDATE_EMAIL_URL = `${AUTH_BASE_URL}/users/admins/updateEmail`;

    return axios.patch(UPDATE_EMAIL_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function updateVendorPhoneNumber(id, payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const UPDATE_PHONE_URL = `${AUTH_BASE_URL}/users/vendors/updatePhoneNumber/${id}`;

    return axios.patch(UPDATE_PHONE_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function updateVendorEmail(id, payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const UPDATE_EMAIL_URL = `${AUTH_BASE_URL}/users/vendors/updateEmail/${id}`;

    return axios.patch(UPDATE_EMAIL_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function updateVendorDetails(id, payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const UPDATE_URL = `${AUTH_BASE_URL}/users/vendors/update/${id}`;

    return axios.patch(UPDATE_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function markApplicationAsAcknowledge(payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/acknowledge`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function markApplicantAsPotentialCandidate(payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/potential`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function markApplicantAsAccepted(payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/isAccepted`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function markApplicantAsInterviewed(payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${SUPPORT_SERVICE_BASE_URL}/jobs/interview`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function createFaultConfiguration(payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    // const REQUEST_URL = `http://10.50.0.48:8080/faultConfigs/create`;
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/create`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function editFaultConfiguration(faultConfigID, payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/${faultConfigID}`;

    return axios.patch(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function addParts(faultConfigID, payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/${faultConfigID}/add-part`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function removeParts(faultConfigID, payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/${faultConfigID}/remove-part`;

    return axios.delete(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}
export function addCategories(faultConfigID, payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/${faultConfigID}/add-category`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}
export function removeCategories(faultConfigID, payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/${faultConfigID}/remove-category`;

    return axios.delete(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}
export function updateLabourUnit(faultConfigID, payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/${faultConfigID}/update-labour-units`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}

export function deleteFaultConfig(faultConfigID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faultConfigs/${faultConfigID}/remove`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function addFaultReport(payload) {

    const createFaultReportFormData = new FormData();

    for (let i = 0; i < payload.images.length; i++) {

        const imageFile = payload.images[i];
        createFaultReportFormData.append('images', imageFile);

        if ((i + 1) === payload.images.length) {

            createFaultReportFormData.append('reporterID', payload.reporterID);
            createFaultReportFormData.append('driverID', payload.driverID);
            createFaultReportFormData.append('faultText', payload.faultText);

            const token = authenticator.currentUser.accessToken;
            const AuthStr = 'Bearer '.concat(token);
            const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/faults/create`;

            return axios.post(REQUEST_URL, createFaultReportFormData, { headers: { 'Authorization': AuthStr } });

        }

    }

}

export function createFaultCategory(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL} /fault-categories`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function editFaultCategory(payload, id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-categories/${id}`;

    return axios.patch(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function deleteFaultCategory(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-categories/${id}`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function getFaultCategory(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-categories/${id}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function addSubCategories(payload, id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-categories/${id}/add-subcategory`;

    return axios.patch(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function createFaultSubCategory(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-subcategories`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function editFaultSubCategory(payload, id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-subcategories/${id}`;

    return axios.patch(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function deleteFaultSubCategory(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-subcategories/${id}`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function getFaultSubCategory(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-subcategories/${id}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function createLabourUnit(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-labour-unit-configurations`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function editLabourUnit(payload, id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-labour-unit-configurations/${id}`;

    return axios.patch(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function deleteLabourUnit(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-labour-unit-configurations/${id}`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function getLabourUnit(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-labour-unit-configurations/${id}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function addServiceCenter(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/service-center/add`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function updateServiceCenter(payload, id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/service-center/update/${id}`;

    return axios.patch(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function deleteServiceCenter(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/service-center/${id}`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function getServiceCenter(id) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/service-center/${id}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function createFaultJob(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/fault-jobs`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function unassignTrackerFromVehicle(payload) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${FLEET_MANAGING_BASE_URL}/trackers/unassignToVehicle`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });
}

export function designateDriverAccountAsTestAccount(driverID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/${driverID}/is-test`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function removeTestAccountDesignation(driverID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/${driverID}/not-test`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function getDriverSafetyScoreData(driverID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/drivers/get-safety-score-data/${driverID}`;

    return axios.get(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function removeDriverAccount(driverID, assignerID) {
    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `${AUTH_BASE_URL}/users/drivers/${driverID}/${assignerID}`;

    return axios.delete(REQUEST_URL, { headers: { 'Authorization': AuthStr } });
}

export function getVehicleMetricInPeriod(payload) {

    const token = authenticator.currentUser.accessToken;
    const AuthStr = 'Bearer '.concat(token);
    const REQUEST_URL = `http://localhost:8080/vehicles/range-telemetry`;

    return axios.post(REQUEST_URL, payload, { headers: { 'Authorization': AuthStr } });

}
