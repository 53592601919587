import { makeStyles } from "@mui/styles";
import { Paper, Typography, styled, Box, Snackbar, Grid, IconButton, Alert, } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FLEET_MANAGING_BASE_URL } from "../../../../app/services/baseService";
import axios from "axios";
import { Button, ButtonGroup } from "react-bootstrap";
import { convertUnixTimestampToUTC } from "../../../_helpers";
import CloseIcon from '@mui/icons-material/Close';
import { MaterialReactTable } from "material-react-table";
import { removeParts } from "../../../../app/modules/Auth/_redux/authCrud";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function FaultConfigParts({ className, faultConfigId }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);

    const TableErrorMessage = useRef();
    const classes = useStyles();

    function handleClick() {
        setOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    const Alerter = React.forwardRef((props, ref) => {
        return <Alert elevation={6} variant="filled" {...props} ref={ref} />;
    });

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL(`/faultConfigs/${faultConfigId}/parts`, `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setData(data.parts);
                setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    return setOpen(true);

                }

                setSeverity("error");
                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;

                setIsError(true);
                setOpen(true);

            } finally {

                setIsRefetching(false);
                setIsLoading(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();
            setData([]);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.partData?.partName,
            id: 'partName',
            header: 'Part Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.partData?.partPrice,
            id: 'partPrice',
            header: 'Price',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.partData?.vehicleBatch,
            id: 'vehicleBatch',
            header: 'Vehicle Batch',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },


    ], []);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alerter
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alerter>
            </Snackbar>
            <div className={`card card-custom ${className} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Parts for Fault Configuration Records
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Records from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                {/* <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                aria-label="Add"
                                className={classes.margin}
                                onClick={() => {
                                    setShow(true);
                                    setEditState(false);
                                    setFieldDisabled(false);
                                    setCreateEditText("Create Fault Configuration");
                                    setInitialValues(defaultValuesToSet);
                                }}
                                startIcon={<AddBoxTwoToneIcon />}
                            >
                                Create Fault Configuration
                            </Button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableRowSelection
                    getRowId={(row) => row.id}
                    initialState={{ showColumnFilters: true }}
                    manualPagination
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableGrouping
                    enableColumnPinning
                    enableRowPinning
                    enableRowActions
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: `${TableErrorMessage.current}`,
                            }
                            : undefined
                    }
                    onPaginationChange={setPagination}
                    rowCount={rowCount}
                    state={{
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching
                    }}
                    renderDetailPanel={({ row }) => (
                        <>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>ID: </Typography>
                                            <Typography variant="p">
                                                {row.original.partData?.ID}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Last Updated: </Typography>
                                            <Typography variant="p">
                                                {convertUnixTimestampToUTC(row.original.document?.partData?.updatedAt ?? row.original.document?.partData?.createdAt)}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    renderTopToolbarCustomActions={({ table }) => {

                        const handleRemoveParts = () => {

                            setActionBtnDisabled(false);

                            const allSelectedRowIDs = [];

                            table.getSelectedRowModel().flatRows.map(async (row) => {

                                allSelectedRowIDs.push(row.original.partData?.ID);

                                try {

                                    const removePartsPayload = {
                                        parts: allSelectedRowIDs,
                                    };

                                    await removeParts(faultConfigId, removePartsPayload).then((response) => {
                                        setSeverity('success');
                                        TableErrorMessage.current = response.data.message;
                                        handleClick();
                                        onChangeWorker();
                                        setActionBtnDisabled(true);
                                        return onChangeWorker();
                                    }).catch((error) => {

                                        setSeverity('error');
                                        setActionBtnDisabled(true);

                                        if (error.response) {
                                            TableErrorMessage.current = error?.response?.data?.message;
                                            handleClick();
                                            return onChangeWorker();
                                        }

                                        TableErrorMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                        handleClick();
                                        return onChangeWorker();
                                    });

                                } catch (error) {
                                    setSeverity('error');
                                    TableErrorMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? 'an unexpected error occurred';
                                    setActionBtnDisabled(true);
                                    handleClick();
                                }
                            });

                        };

                        return (
                            <div style={{ display: 'flex', gap: '0.5rem' }}>

                                <ButtonGroup size="small" aria-label="small button group">
                                    <Button
                                        color="info"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleRemoveParts}
                                        variant="contained"
                                        size="small"
                                    >
                                        Delete Parts
                                    </Button>
                                </ButtonGroup>
                                {/* <ButtonGroup size="small" aria-label="small button group">
                                <Button
                                    color="error"
                                    disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                    onClick={handleDeactivate}
                                    variant="contained"
                                    size="small"
                                >
                                    Deactivate
                                </Button>
                                <Button
                                    color="success"
                                    disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                    onClick={handleActivate}
                                    variant="contained"
                                    size="small"
                                >
                                    Activate
                                </Button>
                            </ButtonGroup> */}
                            </div>
                        );

                    }}
                    muiSkeletonProps={{
                        animation: 'wave',
                        height: 30,
                    }}
                    muiLinearProgressProps={{
                        color: 'primary',
                    }}
                    muiPaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                        showFirstButton: true,
                        showLastButton: true,
                        color: 'success',
                        shape: 'rounded',
                        showRowsPerPage: true,
                        variant: 'outlined',
                    }}
                    enableStickyFooter
                    enableColumnResizing
                    paginationDisplayMode={"pages"}
                    paginateExpandedRows={true}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: '650px',
                        }
                    }}
                    enableBatchRowSelection
                    enableRowNumbers
                    rowNumberDisplayMode={'original'}
                    selectAllMode={"all"}
                    options={{
                        selection: true,
                    }}
                />
            </div >
        </>
    );
}