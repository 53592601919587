
export const addPeriod = 'addPeriod';

export const addPeriodAction = (item) => (
    {
        type: addPeriod,
        payload: item
    }
);


const initialWidgetState = {
    period: "This Week",
};

export const reducer = (state = initialWidgetState, action) => {

    switch (action.type) {
        case addPeriod:
            return {
                ...state, period: action.payload
            };
        default:
            return state;
    }

};
