import React from 'react';
import { VehiclesTable } from './VehiclesTable';

export const BrokenVehiclesTable = () => {

    return (
        <>
            <VehiclesTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/vehicles/broken-vehicles/getAll"}
                tableTopHeader={"Broken Vehicles"}
                tableSubHeader={"See and Manage Broken vehicle records from this table"}
            />
        </>
    );

};