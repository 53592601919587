import React, { useMemo, useState, useEffect } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useHtmlClassService } from "../../layout";
import objectPath from "object-path";

export function AreaChart({ chartColor = 'danger', className, title }) {
    const [item, setItem] = useState("This Week");
    const [seriesData, setSeriesData] = useState([]);

    /* const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseColor: objectPath.get(
                uiService.config,
                `js.colors.theme.base.${chartColor}`
            ),
            colorsThemeLightColor: objectPath.get(
                uiService.config,
                `js.colors.theme.light.${chartColor}`
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService, chartColor]); */

    const filteredData = (item) => {
        let seriesData, labels, xAxisType, thisYear;
        const labelData = [
            "Feb 2023", "Mar 2023", "April 2023", "May 2023", "June 2023", "July 2023", "Aug 2023", "Sept 2023", "Oct 2023", "Nov 2023", "Dec 2023", "Jan 2024", "Feb 2024", "Mar 2024",
        ];

        switch (item) {
            case "This Week":
                console.log("This Week Selected ");
                seriesData = [55, 46, 23, 48, 25, 39];
                labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
                xAxisType = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
                break;
            case "Previous Week":
                console.log("Previous Week Selected");
                seriesData = [40, 35, 30, 45, 20, 35];
                labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
                xAxisType = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
                break;
            case "This Month":
                console.log("This Month Selected");
                seriesData = [60, 50, 45, 55, 35, 45, 30, 33, 27, 35, 45, 77];
                labels = [
                    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",
                    "24", "25", "26", "27", "28", "29", "30", "31",
                ];
                xAxisType = [
                    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",
                    "24", "25", "26", "27", "28", "29", "30", "31",
                ];
                break;
            case "Previous Month":
                console.log("Prev Month Selected");
                seriesData = [60, 50, 45, 55, 35, 45];
                labels = [
                    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",
                    "24", "25", "26", "27", "28", "29", "30", "31",
                ];
                xAxisType = [
                    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",
                    "24", "25", "26", "27", "28", "29", "30", "31",
                ];
                break;
            case "This Quarter":
                console.log("Prev Month Selected");
                seriesData = [55, 35, 45];
                labels = [
                    "Jan", "Feb", "Mar",
                ];
                xAxisType = [
                    "Jan", "Feb", "Mar",
                ];
                break;
            case "Previous Quarter":
                console.log("Prev Quarter Selected");
                seriesData = [60, 50, 45,];
                labels = [
                    "Oct", "Nov", "Dec",
                ];
                xAxisType = [
                    "Oct", "Nov", "Dec",
                ];
                break;
            case "This Year":
                console.log("Prev Month Selected");
                seriesData = [77, 60, 12];
                labels = [
                    "Jan 2024", "Feb 2024", "Mar 2024",
                ];
                xAxisType = [
                    "Jan 2024", "Feb 2024", "Mar 2024"
                ];
                break;
            case "Previous Year":
                console.log("Prev Quarter Selected");
                seriesData = [60, 50, 45, 55, 35, 45, 30, 33, 27, 35, 45];
                labels = [
                    "Feb 2023", "Mar 2023", "April 2023", "May 2023", "June 2023", "July 2023", "Aug 2023", "Sept 2023", "Oct 2023", "Nov 2023", "Dec 2023",
                ];
                xAxisType = [
                    "Feb 2023", "Mar 2023", "April 2023", "May 2023", "June 2023", "July 2023", "Aug 2023", "Sept 2023", "Oct 2023", "Nov 2023", "Dec 2023",
                ];
                break;
            case "Year to Date":
                console.log("Year to Date Selected");
                seriesData = [60, 50, 45];
                thisYear = new Date().getFullYear();
                const yearToDate = labelData?.filter((_, index) => {
                    const year = new Date(labelData[index]).getFullYear();
                    return year === thisYear;
                });
                labels = yearToDate;
                xAxisType = yearToDate;
                break;

            default:
        }

        setSeriesData(seriesData);
        return { labels, xAxisType };
    };


    const series = [
        {
            name: "Kilometers",
            data: seriesData
        }
    ];

    const options1 = useMemo(() => {
        const { labels, xAxisType } = filteredData(item);

        return {
            chart: {
                type: 'area',
                height: 300,
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
            },
            labels: labels, // Use labels from filteredData
            xaxis: {
                type: xAxisType, // Use xAxisType from filteredData
            },
            legend: {
                horizontalAlign: 'left'
            }
        };
    }, [item]);

    const periodicals = [
        "This Week",
        "Previous Week",
        "This Month",
        "Previous Month",
        "This Quarter",
        "Previous Quarter",
        "This Year",
        "Previous Year",
        "Year to Date",
    ];

    const dropDownItems = periodicals.map((item) => {
        return (
            <Dropdown.Item
                key={item}
                onClick={(e) => {
                    setItem(e.target.textContent);
                }}

            >
                {item}
            </Dropdown.Item>
        );
    });

    useEffect(() => {
        filteredData(item);

    }, [item]);

    return (

        <>
            <section className={`p-6 ${className} rounded bg-white`}>
                <div className="d-flex flex-row justify-content-between mb-5">
                    <div style={{ fontSize: "16px" }}>
                        {title}
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle className="btn btn-hover-light-primary border border-secondary-subtle centerMan" id="dropdown-basic" as={ButtonGroup}>
                            {item}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {dropDownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div style={{ width: "100%" }}>
                    <Chart type='area' series={series} options={options1} />
                </div>
            </section>
        </>

    );
}
