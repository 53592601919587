import React from 'react';
import { useSubheader } from '../../_wahu/layout';
import { FaultSubcategoryTable } from '../../_wahu/_partials/widgets/advance-tables/FaultSubcategoryTable';

export const FaultSubcategoriesPage = () => {

    const subheader = useSubheader();

    React.useEffect(() => {

        const load = async () => {
            try {
                subheader.setTitle("Fault Sub-Category Table");
            } catch (error) {
                console.log(error);
            }
        };

        load();

    }, [subheader]);

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <FaultSubcategoryTable className="card-stretch gutter-b" />
                </div>
            </div>
        </>
    );

};
