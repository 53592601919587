import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Paper,
    styled,
    Chip,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { convertUnixTimestampToUTC } from "../../../_helpers";
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import {
    verifyTransaction
} from '../../../../app/modules/Auth/_redux/authCrud';
import {
    PAYMENT_BASE_URL
} from '../../../../app/services/baseService';
import BasicLoader from '../../loaders/SimpleLoader';
import { SimpleModal } from '../modals/SimpleModal';
import { CreateTransactionForm } from '../';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `80px`,
        width: `80px`
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function UserTransactionsTable({ customClassName }) {

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [severity, setSeverity] = useState('info');
    const [showModelDataLoader, setModelDataLoader] = useState('d-none');
    const [showCreateTransactionModal, setShowCreateTransactionModal] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Transaction Error');
    const [initialValues, setInitialValues] = useState({
        email: "",
        amount: 0,
        currency: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        isFirstPayment: false,
        isSubscriptionPayment: true,
        userID: "",
        weeksPaid: 1
    });

    const TableErrorMessage = useRef();
    const classes = useStyles();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/payments/getAllTransactions', `${PAYMENT_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    let resultant = {
                        ID: element.document.ID,
                        amount: Number(element.document.metadata.amount) / 100,
                        firstName: element.document.metadata.firstName,
                        lastName: element.document.metadata.lastName,
                        isFirstPayment: element.document.metadata.isFirstPayment,
                        isSubscriptionPayment: element.document.metadata.isSubscriptionPayment,
                        phone: element.document.metadata.phone,
                        provider: element.document.metadata.provider,
                        weeksPaid: element.document.metadata.weeksPaid,
                        createdAt: new Date(element.document.createdAt).toUTCString(),
                        status: element?.document?.verificationData?.data?.status ?? "failed",
                        paidAt: element?.document?.verificationData?.data?.paid_at ? new Date(element.document.verificationData.data.paid_at).toUTCString() : "N/A"
                    };

                    dataArrayAlt.push(resultant);

                    if ((i + 1) >= dataArray.length)
                        setDataDocument(dataArrayAlt);

                }

                setData(data.data);
                setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                setSeverity("error");
                setIsError(true);
                setOpenSnackbar(true);

                return;

            }
            finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);
            setRowCount(0);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => `${Number(row.document?.verificationData?.data?.amount ?? null) / 100 ?? 'Failed'}`,
            enableClickToCopy: true,
            id: 'verificationDataAmount',
            header: 'Amount Paid (GHS)',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.document?.verificationData?.data?.metadata?.weeksPaid ?? 'NA'}`,
            id: "weeksPaid",
            enableClickToCopy: true,
            header: '# of Weeks Paid For'
        },
        {
            accessorFn: (row) => `${row.document?.verificationData?.data?.status ?? "failed"}`,
            id: "isTransactionComplete",
            header: 'Status',
            Cell: ({ cell }) => {
                let chipColor;
                const status = cell.getValue();
                switch (status) {
                    case 'success':
                        chipColor = 'success';
                        break;
                    case 'failed':
                        chipColor = 'error';
                        break;
                    case 'abandoned':
                        chipColor = 'warning';
                        break;
                    case 'ongoing':
                        chipColor = 'info';
                        break;
                    default:
                        chipColor = 'error';
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            fontWeight: "bold"
                        }}
                    >
                        <Chip label={status} color={chipColor} />
                    </Box >
                );
            }
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toUTCString()}`,
            id: 'createdAt',
            header: 'Transaction Date',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        },
        {
            accessorFn: (row) => row?.document?.metadata?.firstName,
            id: "firstName",
            enableClickToCopy: true,
            header: 'First Name'
        },
        {
            accessorFn: (row) => row?.document?.metadata?.lastName,
            id: "lastName",
            enableClickToCopy: true,
            header: 'Last Name'
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "documentID",
            enableClickToCopy: true,
            header: 'Transaction ID'
        },
        {
            accessorFn: (row) => row?.document?.reference,
            id: "reference",
            enableClickToCopy: true,
            header: 'Payment Reference'
        },
        {
            accessorFn: (row) => `${Number(row?.document?.metadata?.amount) / 100}`,
            accessorKey: 'documentReference',
            id: "amountAttempt",
            enableClickToCopy: true,
            header: 'Attempted Payout'
        }
    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'User Transactions',
        showTitle: true
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original?.document));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create Transaction`}
                show={showCreateTransactionModal}
                close={() => setShowCreateTransactionModal(false)}
                size="lg"
                body={<CreateTransactionForm
                    initialValues={initialValues}
                    createEditText={createEditText}
                    onChanger={onChangeWorker}
                />}
            />
            <div className={`card card-custom ${customClassName} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            User Transaction Records
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            See all user transaction records here
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Create Transaction"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShowCreateTransactionModal(true);
                                        setCreateEditText("Create Transaction");
                                        setInitialValues(initialValues);
                                    }}
                                >
                                    Create Transaction
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10 overflow-auto">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={3}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Provider: </Typography>
                                            <Typography variant="p">
                                                {row.original.document?.metadata?.provider}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Is Subscription Payment?: </Typography>
                                            <Typography variant="p">
                                                <Box
                                                    sx={(theme) => ({
                                                        backgroundColor: row.original.document?.isSubscriptionPayment === true ? theme.palette.success.dark : row.original.document?.isSubscriptionPayment === false ? theme.palette.grey.dark : theme.palette.grey.dark,
                                                        borderRadius: '0.35rem',
                                                        color: '#fff',

                                                        p: '0.35rem',
                                                        textAlign: 'center'
                                                    })}
                                                >
                                                    {row.original.document?.isSubscriptionPayment === true ? "YES" : "NO"}
                                                </Box>
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>ID: </Typography>
                                            <Typography variant="p">
                                                {row.original.document?.ID}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={3}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Last Updated: </Typography>
                                            <Typography variant="p">
                                                {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>

                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={async () => {

                                    setModelDataLoader('');

                                    const provider = row.original.document.metadata.provider;
                                    const reference = row.original.document.reference;
                                    const transactionID = row.original.document.ID;

                                    try {

                                        if (transactionID === reference) {

                                            setSeverity('error');
                                            TableErrorMessage.current = "this transaction was added manually";
                                            setOpenSnackbar(true);

                                            setModelDataLoader('d-none');
                                            return closeMenu();

                                        }

                                        const response = await verifyTransaction(provider, reference);
                                        if (response) {

                                            setSeverity('success');
                                            TableErrorMessage.current = response.data.message;
                                            setOpenSnackbar(true);

                                            onChangeWorker();
                                            setModelDataLoader('d-none');

                                            return closeMenu();

                                        }

                                    } catch (error) {

                                        setSeverity('error');
                                        TableErrorMessage.current = error?.response?.data?.message ?? error?.response?.data?.message;
                                        setOpenSnackbar(true);

                                        onChangeWorker();
                                        setModelDataLoader('d-none');

                                        return closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <VerifiedIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Re-Verify Payment
                                {" "}
                                <span className='pl-2'>
                                    <BasicLoader hideShowLoader={showModelDataLoader} size="sm" />
                                </span>
                            </MenuItem>
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            return (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <Button
                                                color="info"
                                                onClick={handleExportData}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Export Page
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <Button
                                                disabled={
                                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                                }
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Export Selected
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }}
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
