import React from "react";
import { Alert, Collapse } from '@mui/material';

export function FormAlert({ isEnabled, severity, message, icon, handleDismiss, className, variant }) {

    return (
        <>
            <div className={`${className}`}>
                <Collapse
                    in={isEnabled}
                    timeout={1000}
                >
                    <Alert
                        icon={icon}
                        severity={severity}
                        variant={variant}
                        onClose={handleDismiss}
                    >
                        {message}
                    </Alert>
                </Collapse>
            </div>
        </>
    );

}
