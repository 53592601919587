import React, { useState } from "react";
import { Container, Grid, CssBaseline } from "@mui/material";
import { makeStyles } from '@mui/styles';
import OtpInput from "react-otp-input";

const useStyles = makeStyles(theme => ({
    grid: {
        height: "10px",
        textAlign: "center"
    },
    paper: {
        marginTop: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}));

export function OTPInput({ handleOtpInputChange }) {

    const [otp, setOtp] = useState('');
    const classes = useStyles();

    return (

        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid
                    container
                    style={{ backgroundColor: "white" }}
                    className={classes.grid}
                    justify="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        justify="center"
                        alignItems="center"
                        direction="column"
                    >
                        <Grid
                            item spacing={3}
                            container
                            justify="center"
                        >
                            <OtpInput
                                renderSeparator={
                                    <span>
                                        <strong>.</strong>
                                    </span>
                                }
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                inputStyle={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                    margin: "0 0.5rem",
                                    fontSize: "1.5rem",
                                    borderRadius: 4,
                                    border: "1px solid rgba(0,0,0,0.3)",
                                    color: "#000"
                                }}
                                renderInput={(props) => <input {...props} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>

    );
}