import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';

function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`,
            },
        },
    };
}

const useStylesArrow = makeStyles(theme => ({
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    popper: arrowGenerator(theme.palette.grey[700]),
}));

function ArrowTooltip(props) {
    const { arrow, ...classes } = useStylesArrow();
    const [arrowRef, setArrowRef] = React.useState(null);

    return (
        <Tooltip
            classes={classes}
            PopperProps={{
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef,
                        },
                    },
                },
            }}
            {...props}
            title={
                <React.Fragment>
                    {props.title}
                    <span className={arrow} ref={setArrowRef} />
                </React.Fragment>
            }
        />
    );
}

ArrowTooltip.propTypes = {
    title: PropTypes.node,
};

export function SimpleTooltips({ showToolTip, toolTipTittle, infoIconColor, tooltipMessage, IconFontSize }) {

    return (
        <>
            {showToolTip === true && <ArrowTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">
                            {toolTipTittle}
                        </Typography>
                        <strong>
                            <em>
                                {tooltipMessage}
                            </em>
                        </strong>
                    </React.Fragment>
                }
            >
                <span className={`svg-icon svg-icon-3x`}>
                    <ContactSupportTwoToneIcon
                        color={infoIconColor}
                        fontSize={IconFontSize}
                    />
                </span>
            </ArrowTooltip>}
        </>
    );

}
