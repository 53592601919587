/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState, useRef } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/ManaSubheader";
import { useHtmlClassService } from "../../_core/ManaLayout";
import { SimpleModal } from "../../../_partials/widgets/modals/SimpleModal";
import { TokenExpirationNotice } from "../../../_partials/widgets/data-displays/TokenExpirationNotice";
import { onAuthStateChanged } from "firebase/auth";
import { authentication } from "../../../../app/modules/Auth/firebase";
import BasicLoader from "../../../_partials/loaders/SimpleLoader";
import { sendUserNotificationToken } from "../../../../app/modules/Auth/_redux/authCrud";
import {
    Snackbar,
    IconButton,
    Alert,
    Button,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export function SubHeader() {

    const ComponentErrorMessage = useRef();

    const uiService = useHtmlClassService();
    const location = useLocation();
    const subheader = useSubheader();

    const [isTokenToExpire, setIsTokenToExpire] = useState(null);
    const [isTokenToExpireModal, setIsTokenToExpireModal] = useState(false);

    const [remainingTime, setRemainingTime] = useState(null);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const startTokenTimer = (expirationTime, userID) => {

        const calculateRemainingTime = async () => {

            const currentTime = Math.floor(Date.now() / 1000);
            const timeLeft = expirationTime - currentTime;
            //console.log(timeLeft);

            if (timeLeft <= 0) {

                setTokenExpired(true);
                return setRemainingTime(0);

            } else {

                setRemainingTime(timeLeft);
                if (timeLeft === 2740) {

                    const noticeTitle = "Token Time";
                    const notice = "Your token will expire soon.";
                    const clickActionURL = "https://admin.wahu.me";
                    const dataObject = {
                        type: "token expiry",
                    };
                    await sendUserNotificationToken(userID, notice, noticeTitle, userID, clickActionURL, dataObject).then(async (response) => {
                        console.log(response.data);
                    }).catch((error) => {
                        setSeverity("error");
                        ComponentErrorMessage.current = error.message;
                        setOpenSnackbar(true);
                    });

                }

            }

        };

        const intervalId = setInterval(calculateRemainingTime, 1000);
        return () => clearInterval(intervalId);

    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}m ${secs}s`;
    };

    const layoutProps = useMemo(() => {

        return {
            config: uiService.config,
            subheaderMobileToggle: objectPath.get(
                uiService.config,
                "subheader.mobile-toggle"
            ),
            subheaderCssClasses: uiService.getClasses("subheader", true),
            subheaderContainerCssClasses: uiService.getClasses(
                "subheader_container",
                true
            ),
        };
    }, [uiService]);

    useLayoutEffect(() => {

        const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
        const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);

        const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
        subheader.setBreadcrumbs(breadcrumbs);
        subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    // Do not remove this useEffect, needed from update title/breadcrumbs outside (from the page)
    useEffect(() => { }, [subheader]);

    useEffect(() => {

        let isMounted = true;

        isMounted && onAuthStateChanged(authentication, async (user) => {

            if (user) {
                const expirationTime = Math.floor(new Date((await user.getIdTokenResult()).expirationTime).getTime() / 1000);
                startTokenTimer(expirationTime, user.uid);
            } else {
                return setRemainingTime(0);
            }

        });

        return () => {

            isMounted = false;
            setRemainingTime(0);

        };

    }, []);

    return (

        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={() => {
                    setOpenSnackbar(false);
                }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={() => {
                        setOpenSnackbar(false);
                    }}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => {
                            setOpenSnackbar(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        setOpenSnackbar(false);
                    }}
                    severity={severity}
                >
                    {<span id="message-id">
                        {ComponentErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Token Expiration`}
                show={isTokenToExpireModal}
                close={() => setIsTokenToExpireModal(false)}
                size="lg"
                body={
                    <TokenExpirationNotice
                        tokenTime={isTokenToExpire}
                    />
                }
            />
            <div id="kt_subheader" className={`subheader py-2 py-lg-4 ${layoutProps.subheaderCssClasses}`}>
                <div className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
                    {/* Info */}
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        {layoutProps.subheaderMobileToggle && (<>
                            <button
                                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                                id="kt_subheader_mobile_toggle"
                                type="button"
                            >
                                <span />
                            </button>
                        </>)}

                        <div className="d-flex align-items-baseline mr-5">
                            <h5 className="text-dark font-weight-bold my-2 mr-5">
                                <>
                                    {subheader.title}
                                </>
                                {/*<small></small>*/}
                            </h5>

                        </div>
                        <BreadCrumbs items={subheader.breadcrumbs} />
                    </div>

                    {/* Toolbar */}
                    <div className="d-flex align-items-center">
                        {remainingTime === null && (<>
                            <div className="centerMan">
                                <BasicLoader size={"sm"} />
                            </div>
                        </>)}
                        {remainingTime !== null && <div
                            className={`${remainingTime > 0 && "bg-light-success"} font-weight-bold p-2 rounded-lg ${remainingTime <= 0 && "bg-light-danger"}`}
                            data-toggle="tooltip"
                            title="When your token will expire"
                            data-placement="left"
                        >
                            <span className={`${remainingTime > 0 && "text-success"} font-weight-bold mr-2 ${remainingTime <= 0 && "text-danger"}`} id="kt_dashboard_daterangepicker_title">
                                Your access expires in:
                            </span>
                            <span className={`${remainingTime <= 0 && "text-danger"} ${remainingTime > 0 && "text-success"} font-weight-bold`}>
                                {`${formatTime(remainingTime)}`}
                            </span>
                        </div>}
                        <QuickActions />
                    </div>
                </div>
            </div>

        </>

    );
}
