import React from "react";
import { useSubheader } from "../../_wahu/layout";
import {
    AdminsTable
} from "../../_wahu/_partials/widgets";

export const AdminsPage = () => {

    const suhbeader = useSubheader();
    React.useEffect(() => {

        const load = async () => {
            try {
                suhbeader.setTitle("Admin Tables");
            } catch (error) {
                // your Promise won't ever error, but an actual API might
                console.log(error);
            }
        };
        load();

    }, [suhbeader]);

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <AdminsTable className="card-stretch gutter-b" />
                </div>
            </div>
        </>
    );
};
