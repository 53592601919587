import React from "react";
import { JobDetailsList } from "../../_wahu/_partials/widgets/lists/JobDetailsList";

export const CareerDetailsPage = ({ jobData, jobID, onChangeFunction }) => {
    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <JobDetailsList
                        className="card-stretch gutter-b"
                        jobData={jobData}
                        jobID={jobID}
                        onChangeFunction={onChangeFunction}
                    />
                </div>
            </div>
        </>
    );
};
