import React, { useRef, useState, useEffect } from 'react';
import {
    IconButton,
    Snackbar,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Button,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import { JobApplicantDetails } from './JobApplicantDetails';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `100%`,
        width: `100%`
    },
    imgContainer: {
        height: `100px`,
        width: `100px`
    }
}));

export function JobDetailsList({ className, jobData, jobID, onChangeFunction }) {

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [severity, setSeverity] = useState('info');

    const [filterMode, setFilterMode] = useState(false);
    const [filterType, setFilterType] = useState(0);

    const [interviewedApplicants, setInterviewedApplicants] = useState([]);
    const [shortListedApplicants, setShortListedApplicants] = useState([]);
    const [acknowledgedApplicants, setAcknowledgedApplicants] = useState([]);

    const PageErrorMessage = useRef();
    const classes = useStyles();

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    }

    useEffect(() => {

        let isMounted = true;

        const shortListedApplicantsArray = [];
        const interviewedApplicantsArray = [];
        const acknowledgedApplicantsArray = [];

        const jobApplications = jobData.applications;
        if (jobApplications)
            for (let i = 0; i < jobApplications.length; i++) {

                const jobApplication = jobApplications[i];

                const interviewedState = jobApplication.isInterviewed;
                const potentialState = jobApplication.isPotential;
                const acknowledgedState = jobApplication.respondedTo;

                if (interviewedState && interviewedState === true) {
                    isMounted && interviewedApplicantsArray.push(jobApplication);
                }

                if (potentialState && potentialState === true) {
                    isMounted && shortListedApplicantsArray.push(jobApplication);
                }

                if (acknowledgedState && acknowledgedState === true) {
                    isMounted && acknowledgedApplicantsArray.push(jobApplication);
                }

                if ((i + 1) === jobApplications.length) {

                    setAcknowledgedApplicants(acknowledgedApplicantsArray);
                    setShortListedApplicants(shortListedApplicantsArray);
                    setInterviewedApplicants(interviewedApplicantsArray);

                }

            }

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [

    ]);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={handleClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {PageErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <div className={`card card-custom ${className}`}>
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">
                        {jobData.applications?.length ?? "0"}
                        {" "}
                        Applicants
                    </h3>
                    <div className="card-toolbar">
                        {filterMode && <span
                            className='cursor-pointer'
                            onClick={() => {
                                setFilterMode(false);
                                setFilterType(0);
                            }}
                        >
                            <Typography
                                variant="overline"
                                display="block"
                                color={'#ff0000'}
                            >
                                clear filters
                            </Typography>
                        </span>}
                    </div>
                </div>

                <div className="card-body pt-0">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                        <Grid item xs={12} sm={12} md={4}>
                            <div
                                className={`d-flex align-items-center ${filterMode ? filterType === 1 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded p-6 cursor-pointer`}
                                onClick={() => {
                                    setFilterMode(true);
                                    setFilterType(1);
                                }}
                            >
                                <div className="d-flex flex-column flex-grow-1">
                                    <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                        INTERVIEWED
                                    </label>
                                    <span className="text-muted font-weight-bold">
                                        {jobData.interviewedApplicants ?? 0}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div
                                className={`d-flex align-items-center ${filterMode ? filterType === 2 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded p-6 cursor-pointer`}
                                onClick={() => {
                                    setFilterMode(true);
                                    setFilterType(2);
                                }}
                            >
                                <div className="d-flex flex-column flex-grow-1">
                                    <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                        SHORTLISTED
                                    </label>
                                    <span className="text-muted font-weight-bold">
                                        {jobData.potentialApplicants ?? 0}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} className='mb-10'>
                            <div
                                className={`d-flex align-items-center ${filterMode ? filterType === 3 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded p-6 cursor-pointer`}
                                onClick={() => {
                                    setFilterMode(true);
                                    setFilterType(3);
                                }}
                            >
                                <div className="d-flex flex-column flex-grow-1">
                                    <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                        ACKNOWLEDGED
                                    </label>
                                    <span className="text-muted font-weight-bold">
                                        {jobData.acknowledgedApplicants ?? 0}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        {!filterMode && jobData.applications?.map((item, index) =>
                            <Grid key={index} item xs={12} sm={12} md={6}>
                                <JobApplicantDetails
                                    jobData={jobData}
                                    onChangeFunction={onChangeFunction}
                                    item={item}
                                    index={index}
                                />
                            </Grid>
                        )}
                        {filterMode && filterType === 1 && interviewedApplicants?.map((item, index) =>
                            <Grid key={index} item xs={12} sm={12} md={6}>
                                <JobApplicantDetails
                                    jobData={jobData}
                                    onChangeFunction={onChangeFunction}
                                    item={item}
                                    index={index}
                                />
                            </Grid>
                        )}
                        {filterMode && filterType === 2 && shortListedApplicants?.map((item, index) =>
                            <Grid key={index} item xs={12} sm={12} md={6}>
                                <JobApplicantDetails
                                    jobData={jobData}
                                    onChangeFunction={onChangeFunction}
                                    item={item}
                                    index={index}
                                />
                            </Grid>
                        )}
                        {filterMode && filterType === 3 && acknowledgedApplicants?.map((item, index) =>
                            <Grid key={index} item xs={12} sm={12} md={6}>
                                <JobApplicantDetails
                                    jobData={jobData}
                                    onChangeFunction={onChangeFunction}
                                    item={item}
                                    index={index}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            </div >
        </>
    );
}
