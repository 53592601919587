import { useFormik } from "formik";
import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { setVehicleInRepair, setVehicleOutOfRepair } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from "../../controls";
import { Alert } from "@mui/material";
import { repairTypeArray } from "../../../../app/services/baseService";
import { shallowEqual, useSelector } from "react-redux";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function SetVehicleInRepairForm({ onChanger, vehicleID, vehicleVIN, repairType, repairCallToActionText }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [severity, setSeverity] = useState('info');
    const [missingRepairType, setMissingRepairType] = useState(false);

    const FormMessenger = useRef();
    const loggedInUser = useSelector(({ auth }) => auth.user, shallowEqual);

    const creationSchema = Yup.object().shape({
        notes: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldName) => {

        if (formik.touched[fieldName] && formik.errors[fieldName]) {
            return "is-invalid";
        }

        if (formik.touched[fieldName] && !formik.errors[fieldName]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues: {
            vehicleID: vehicleID,
            notes: ""
        },
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            const adderID = loggedInUser.user_data.document.ID;
            values.adderID = adderID;

            enableLoading();
            setTimeout(() => {

                if (repairType === "in-repair") {

                    setVehicleInRepair(values).then((response) => {

                        if (response) {

                            disableLoading();
                            setSubmitting(false);

                            setAlertType({
                                type: "success",
                                icon: "fa fa-check-circle mana-icon-white"
                            });

                            onChanger();
                            setStatus(response.data.message);
                            setMissingRepairType(false);

                            return setFormAlertEnabled(true);

                        }

                    }).catch((error) => {

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });

                        onChanger();
                        disableLoading();
                        setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                        setMissingRepairType(false);
                        setSubmitting(false);

                        return setFormAlertEnabled(true);

                    });

                    return;

                }

                if (repairType === "out-repair") {

                    setVehicleOutOfRepair(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setAlertType({
                                type: "success",
                                icon: "fa fa-check-circle mana-icon-white"
                            });
                            onChanger();
                            setStatus(response.data.message);
                            setMissingRepairType(false);

                            return setFormAlertEnabled(true);
                        }

                    }).catch((error) => {

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });

                        onChanger();
                        disableLoading();
                        setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                        setMissingRepairType(false);
                        setSubmitting(false);

                        return setFormAlertEnabled(true);

                    });

                    return;

                }

            });

        }
    });

    useEffect(() => {

        let isMounted = true;

        if (!repairType) {

            FormMessenger.current = "no repair type selected, a repair type of 'in-repair' or 'out-repair' must be provided to proceed";
            isMounted && setSeverity('error');
            isMounted && setMissingRepairType(true);

            return;

        }

        if (!repairTypeArray.includes(repairType)) {

            FormMessenger.current = `invalid repair type of: "${repairType}", a repair type of "in-repair" or "out-repair" must be provided to proceed`;
            isMounted && setSeverity('error');
            isMounted && setMissingRepairType(true);

            return;

        }

        return () => isMounted = false;

    }, [repairType]);

    return (
        <>

            {missingRepairType && <div className="row mb-5">
                <div className="col-xs-12 col-sm-12 col-md-12">
                    <Alert
                        elevation={6}
                        variant="filled"
                        severity={severity}
                    >
                        {FormMessenger.current}
                    </Alert>
                </div>
            </div>}

            <div className="row mb-5">
                <div className="col-xs-6 col-sm-6 col-md-6">
                    <Alert
                        variant="outlined"
                        severity="info"
                    >
                        <span>
                            ID:
                            {" "}
                            <span className="bold">
                                {vehicleID}
                            </span>
                        </span>
                    </Alert>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6">
                    <Alert
                        variant="outlined"
                        severity="info"
                    >
                        <span>
                            VIN:
                            {" "}
                            <span className="bold">
                                {vehicleVIN}
                            </span>
                        </span>
                    </Alert>
                </div>
            </div>

            <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                    <Form.Label>Notes</Form.Label>
                    <Form.Control as={"textarea"} placeholder="Your notes" rows={6} value={formik.values.notes} onChange={formik.handleChange} className={`${getInputClasses("notes")}`} name="notes" {...formik.getFieldProps("notes")} />
                    <Form.Text className="text-muted">
                        Why is this vehicle is in repair / out of repair state
                    </Form.Text>
                    <Form.Control.Feedback>Your note looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Notes cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.notes && formik.errors.notes ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.notes}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting || missingRepairType} className={`font-weight-bold px-9 py-4 my-3`}>
                    {repairCallToActionText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form>
        </>
    );
}