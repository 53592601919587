export const AUTH_BASE_URL = "https://mana-auth-api-62cj62noaq-oa.a.run.app";
export const FLEET_MANAGING_BASE_URL = "https://fleet-managing-api-62cj62noaq-oa.a.run.app";
export const SUPPORT_SERVICE_BASE_URL = "https://support-service-api-62cj62noaq-oa.a.run.app";
export const DELIVERY_SERVICE_BASE_URL = "https://delivery-service-api-62cj62noaq-oa.a.run.app";
export const PAYMENT_BASE_URL = "https://payment-service-api-62cj62noaq-oa.a.run.app";
export const IP_TO_LOCATION_BASEURL = "https://api.ipregistry.co";
export function setStorageItem(key, status) {
    localStorage.setItem(key, JSON.stringify(status));
}

export function getStorageItem(key) {
    return JSON.parse(localStorage.getItem(key));
}

export function removeStorageItem(key) {
    localStorage.removeItem(key);
}

export function clearStorage() {

    localStorage.clear();
    sessionStorage.clear();

    document.cookie.split(';').forEach(cookieElement => {
        const eqPos = cookieElement.indexOf('=');
        const name = eqPos > -1 ? cookieElement.substring(0, eqPos) : cookieElement;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    });

}

export const repairTypeArray = [
    "in-repair",
    "out-repair"
];