import React from 'react';
import { VehiclesTable } from './VehiclesTable';

export function VehiclesInRepairTable() {

    return (
        <>
            <VehiclesTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/vehicles/repairing/getAll"}
                tableTopHeader={"Vehicles In Repair"}
                tableSubHeader={"Manage vehicles in repair"}
            />
        </>
    );

}
