import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import * as Yup from "yup";
import { getStorageItem } from '../../../../app/services/baseService';
import { createJobOpening, updateJobOpening } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateEditJobsForm({ initialValues, editState, createEditText, fieldDisabled, onChanger }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        roleTitle: Yup.string().required(),
        requirements: Yup.string().required(),
        responsibilities: Yup.string().required(),
        roleDescription: Yup.string().required(),
        additionalInformation: Yup.string(),
        closeDate: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,

        onSubmit: (values, { setStatus, setSubmitting }) => {

            const requirementsArray = values.requirements.split(";");
            values.requirements = requirementsArray;

            const responsibilitiesArray = values.responsibilities.split(";");
            values.responsibilities = responsibilitiesArray;

            const addInfoArray = values.additionalInformation.split(";");
            values.additionalInformation = addInfoArray;

            values.closeDate = new Date(values.closeDate).getTime();
            enableLoading();

            setTimeout(() => {

                if (!editState) {

                    createJobOpening(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);
                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });

                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {

                        disableLoading();
                        setSubmitting(false);

                        return;

                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        closeDate: values.closeDate,
                        requirements: values.requirements,
                        responsibilities: values.responsibilities,
                        roleDescription: values.roleDescription,
                        roleTitle: values.roleTitle,
                        additionalInformation: values.additionalInformation
                    };
                    const jobID = getStorageItem('jobIdForEdit');

                    updateJobOpening(editPayload, jobID).then(response => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);
                        }
                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'error',
                            icon: <WarningTwoToneIcon />
                        });

                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return;
                    });
                    return;
                }

            }, 1000);
        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="jobsFormRoleTitle">
                    <Form.Label>Role Title</Form.Label>
                    <Form.Control type="text" placeholder="Enter Role Title" value={formik.values.roleTitle} onChange={formik.handleChange} className={`${getInputClasses("roleTitle")}`} name="roleTitle" {...formik.getFieldProps("roleTitle")} />
                    <Form.Text className="text-muted">
                        Enter Preferred Role Title eg. Electrical Engineer
                    </Form.Text>
                    <Form.Control.Feedback>Role title looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Role title cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.roleTitle && formik.errors.roleTitle ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.roleTitle}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="jobsFormRoleDescription">
                    <Form.Label>Role Description</Form.Label>
                    <Form.Control as="textarea" placeholder="Enter Role Description" rows={"8"} value={formik.values.roleDescription} onChange={formik.handleChange} className={`${getInputClasses("roleDescription")}`} name="roleTitle" {...formik.getFieldProps("roleDescription")} />
                    <Form.Text className="text-muted">
                        Enter Role Description
                    </Form.Text>
                    <Form.Control.Feedback>Role Description looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Role Description cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.roleDescription && formik.errors.roleDescription ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.roleDescription}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <FormGroup>
                    <FormLabel>Requirements</FormLabel>
                    <Form.Control as="textarea" placeholder="eg. 5 years of work experience;" rows={"8"} value={formik.values.requirements} onChange={formik.handleChange} className={`${getInputClasses("requirements")}`} name="requirements" {...formik.getFieldProps("requirements")} />
                    <Form.Text className="text-muted">
                        <span className="text-primary">
                            Enter given requirements, each separated by a semi-colon (;)
                        </span>
                        <br />
                        <span className="text-danger">
                            <strong>NOTE: </strong>
                            Please do
                            <span className="bold"> NOT </span>
                            end the requirements list with a semi-colon, only in-between requirements as this will create a new line at the end of the list.
                        </span>
                    </Form.Text>
                    <Form.Control.Feedback>Requirements looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Requirements cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.requirements && formik.errors.requirements ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.requirements}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup>
                    <FormLabel>Responsibilities</FormLabel>
                    <Form.Control as="textarea" placeholder="eg. perform unit tests;" rows={"8"} value={formik.values.requirements} onChange={formik.handleChange} className={`${getInputClasses("responsibilities")}`} name="responsibilities" {...formik.getFieldProps("responsibilities")} />
                    <Form.Text className="text-muted">
                        <span className="text-primary">
                            Enter the responsibilities, separated by a semi-colon (;)
                        </span>
                        <br />
                        <span className="text-danger">
                            <strong>NOTE: </strong>
                            Please do
                            <span className="bold"> NOT </span>
                            end the responsibilities list with a semi-colon, only in-between requirements as this will create a new line at the end of the list.
                        </span>
                    </Form.Text>
                    <Form.Control.Feedback>Responsibilities looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Responsibilities cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.responsibilities && formik.errors.responsibilities ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.responsibilities}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup>
                    <FormLabel>Additional Information</FormLabel>
                    <Form.Control as="textarea" placeholder="eg. Ladies are highly encouraged to apply for this role;" rows={"2"} value={formik.values.additionalInformation} onChange={formik.handleChange} className={`${getInputClasses("additionalInformation")}`} name="additionalInformation" {...formik.getFieldProps("additionalInformation")} />
                    <Form.Text className="text-muted">
                        Enter the additional information, separated by a semi-colon (;)
                    </Form.Text>
                    <Form.Control.Feedback>Additional Information looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Additional Information cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.additionalInformation && formik.errors.additionalInformation ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.additionalInformation}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup>
                    <FormLabel>Close Date</FormLabel>
                    <Form.Control type="date" value={formik.values.closeDate} onChange={formik.handleChange} className={`${getInputClasses("closeDate")}`} name="closeDate" {...formik.getFieldProps("closeDate")} />
                    <Form.Text className="text-muted">
                        Choose a closeDate
                    </Form.Text>
                    <Form.Control.Feedback>Close Date looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Close Date cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.closeDate && formik.errors.closeDate ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.closeDate}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );
}