import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import getStorage from "redux-persist/es/storage/getStorage";
import * as Yup from "yup";
import { getStorageItem } from "../../../../app/services/baseService";
import { updateAdminEmail } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from "../../controls";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function UpdateAdminEmail({ onChanger }) {
    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    // eslint-disable-next-line no-useless-escape
    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const creationSchema = Yup.object().shape({
        email: Yup.string().matches(emailReg, 'Email address is not valid!').required(),
    });
    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues: {
            id: "",
            email: ""
        },
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            values.id = getStorageItem('userIdForEdit');
            enableLoading();
            setTimeout(() => {
                updateAdminEmail(values).then((response) => {
                    disableLoading();
                    setSubmitting(false);

                    if (response) {
                        setFormAlertEnabled();
                        setAlertType({
                            type: "success",
                            icon: "fa fa-check-circle mana-icon-white"
                        });
                        onChanger();
                        return setStatus(response.data.message);
                    }
                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });

                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);
                    return;
                });

                return;
            });
        }
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email address" value={formik.values.email} onChange={formik.handleChange} className={`${getInputClasses("email")}`} name="email" {...formik.getFieldProps("email")} />
                    <Form.Text className="text-muted">
                        Provide your email address
                    </Form.Text>
                    <Form.Control.Feedback>Your email looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    Update Email
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form>
        </>
    );
}