import * as auth from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        config => {
            const {
                auth: { authToken }
            } = store.getState();

            if (authToken)
                config.headers.Authorization = `Bearer ${authToken}`;

            return config;
        },
        err => Promise.reject(err)
    );

    axios.interceptors.response.use(
        function (successRes) {
            return successRes;
        },
        function (error) {

            if (error?.response?.status === 401)
                store.dispatch(auth.actions.logout());

            return Promise.reject(error);

        }
    );
}
