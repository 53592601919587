import React from 'react';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Grid, Tooltip, Button } from '@mui/material';
import { SimpleTooltips } from '../';
import { makeStyles } from '@mui/styles';
import BasicLoader from '../../loaders/SimpleLoader';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    changeLogModal: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        height: '400px',
        overflow: 'scroll'
    },
    overflower: {
        overflowAnchor: 'auto'
    }
}));

export function DashboardTileWidget({
    className,
    iconColor = "success",
    infoIconColor = "info",
    widgetHeight = "auto",
    title,
    number,
    iconSVG,
    callToActions = [{
        identifier: "",
        name: "",
        icon: null,
    }],
    clickTrendsEvent,
    clickGenderEvent,
    clickPerRider,
    showToolTip,
    toolTipTittle,
    tooltipMessage,
    IconFontSize,
    customElement,
    showActionButtons,
    showTrendActions = false,
    subTitle
}) {

    const ListOfActions = callToActions.map((action, i) => {

        return (

            <Grid item key={i}>
                <Tooltip
                    title="Click to View Chart"
                    placement='top'
                >
                    <Button
                        variant='outlined'
                        onClick={(event) => {

                            const param = action.identifier;

                            if (param === "Trends") {
                                clickTrendsEvent();
                            } else if (param === "Gender") {
                                clickGenderEvent();
                            } else if (param === "Per Rider") {
                                clickPerRider();
                            }

                        }}
                        color="primary"
                        endIcon={action.icon}
                    >
                        {action.name}
                    </Button>
                </Tooltip>
            </Grid>

        );

    });

    return (
        <>
            <div className={`card card-custom rounded-xl ${className} `} style={{ height: widgetHeight }}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {title}
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            {subTitle}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <SimpleTooltips
                            showToolTip={showToolTip}
                            toolTipTittle={toolTipTittle}
                            infoIconColor={infoIconColor}
                            tooltipMessage={tooltipMessage}
                            IconFontSize={IconFontSize}
                        />
                    </div>
                </div>
                <div className="card-body">
                    <div className='mt-6'>
                        <div className="text-dark font-weight-bolder mt-3 custom-card-number">
                            {number}
                        </div>
                    </div>
                    <div className='mt-0'>
                        {customElement}
                    </div>
                    {showTrendActions ? (<>
                        {!showActionButtons && <Grid container spacing="2" className='mt-6'>
                            <BasicLoader
                                size={"lg"}
                            />
                        </Grid>}
                        {showActionButtons && <Grid container spacing="2" className='mt-6'>
                            {ListOfActions}
                        </Grid>}
                    </>) : null}
                </div>
            </div>
        </>
    );

}

export function DashboardTileWidgetAlt({
    className,
    iconColor = "success",
    infoIconColor = "info",
    widgetHeight = "auto",
    title,
    number,
    iconSVG,
    callToActions = [{
        name: "",
        icon: null
    }],
    callToActionsState,
    clickTrendsEvent,
    clickGenderEvent,
    clickPerRider,
    showToolTip,
    toolTipTittle,
    tooltipMessage,
    IconFontSize,
    customElement,
    subTitle,
}) {

    const classes = useStyles();

    const ListOfActions = callToActions.map((action, i) => {

        return (

            <Grid item key={i}>
                <Tooltip title="Click to View Chart" placement='bottom' >
                    <Button
                        variant='text'
                        onClick={(event) => {

                            const param = event.currentTarget.firstChild.textContent;

                            if (param === "Trends") {
                                clickTrendsEvent();
                            } else if (param === "Gender") {
                                clickGenderEvent();
                            } else if (param === "Per Rider") {
                                clickPerRider();
                            }

                        }}
                        color="secondary"
                    >
                        {action.name}
                        {action.icon}
                    </Button>
                </Tooltip>
            </Grid>

        );

    });

    return (
        <>
            <div className={`card card-custom rounded-xl ${className} `} style={{ height: widgetHeight }}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {title}
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            {subTitle}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <SimpleTooltips
                            showToolTip={showToolTip}
                            toolTipTittle={toolTipTittle}
                            infoIconColor={infoIconColor}
                            tooltipMessage={tooltipMessage}
                            IconFontSize={IconFontSize}
                        />
                    </div>
                </div>
                <div className="card-body pt-2 pb-5 mt-n3">
                    <div className={`${classes.overflower} mt-6`}>
                        {number}
                    </div>
                    <div className='mt-0'>
                        {customElement}
                    </div>
                    <Grid container spacing="2" className='mt-6'>
                        {callToActionsState && ListOfActions}
                    </Grid>
                </div>
            </div>
        </>
    );

}