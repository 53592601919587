import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { LayoutSplashScreen } from "../../../../_wahu/layout";
import * as auth from "./authRedux";
import { authentication } from "../firebase";

function AuthInit(props) {

    const dispatch = useDispatch();
    const [showSplashScreen, setShowSplashScreen] = useState(true);

    // We request user by authToken before rendering the application 
    useEffect(() => {

        let isMounted = true;

        isMounted && onAuthStateChanged(authentication, async (user) => {

            if (user) {
                return setShowSplashScreen(false);
            } else {
                dispatch(props.logout());
                return setShowSplashScreen(false);
            }

        });

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        showSplashScreen ?
            <LayoutSplashScreen /> : (
                <>
                    {props.children}
                </>
            )
    );

}

export default connect(null, auth.actions)(AuthInit);
