/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
    Alert,
} from '@mui/material';
import {
    Box,
    Button,
    Typography,
    Grid,
    Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../dropdowns";
import {
    setStorageItem,
} from '../../../../app/services/baseService';
import {
    getVehicleModelData,
    getVehicle
} from '../../../../app/modules/Auth/_redux/authCrud';
import { MapContainer } from '../';
import BasicLoader from '../../loaders/SimpleLoader';
import { makeStyles } from '@mui/styles';
import MainMap from '../maps/google-maps/MapComponent';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `100%`,
        width: `100%`
    },
    imgContainer: {
        height: `100px`,
        width: `100px`
    }
}));

export function VehicleDetailsList({ className, selectedVehicleModelID, selectedVehicleData }) {

    const [vehicleModelData, setVehicleModelData] = useState({});
    const [vehicleModelIdForModelData, setVehicleModelIdForModelData] = useState("");
    const [vehicleDataForDetailing, setVehicleDataForDetailing] = useState({});
    const [stateTracker, setStateTracker] = useState(0);
    const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
    const [showModelDataLoader, setShowModelDataLoader] = useState(true);
    const [showVehicleDataLoader, setShowVehicleDataLoader] = useState(false);
    const PageErrorMessage = useRef();
    const classes = useStyles();
    const locationsArray = [];

    const returnLastTrackerTimeStamp = (timeStamp) => {
        if (timeStamp)
            return new Date(timeStamp * 1000).toUTCString();

        return "No Tracker Data Logged yet.";
    };

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorSnackBar(false);
    }

    function handleSnackBarOpen() {
        setOpenErrorSnackBar(true);
    }

    useEffect(() => {

        let isMounted = true;

        const vehicleModelDataSignalController = new AbortController();
        const vehicleDataSignalController = new AbortController();

        setVehicleModelIdForModelData(selectedVehicleModelID);
        setVehicleDataForDetailing(selectedVehicleData);

        const returnVehicleModelData = async (vehicleModelID) => {

            try {

                if (vehicleModelID) {

                    const res = getVehicleModelData(vehicleModelID, vehicleModelDataSignalController.signal).catch((error) => {

                        PageErrorMessage.current = error?.response?.data?.message ?? error?.message;
                        handleSnackBarOpen();
                        setShowModelDataLoader(false);

                    });
                    const responseData = (await res).data;

                    setVehicleModelData(responseData);
                    return setShowModelDataLoader(false);

                }

            } catch (err) {

                PageErrorMessage.current = err?.response?.data?.message ?? err?.message;

                handleSnackBarOpen();
                setShowModelDataLoader(false);

                return;

            }

        };

        const refreshVehicleData = async (vehicleID) => {

            setShowVehicleDataLoader(true);

            try {

                if (vehicleID) {

                    const res = getVehicle(vehicleID, vehicleDataSignalController.signal);
                    const responseData = (await res).data;

                    setVehicleDataForDetailing(responseData);
                    setStorageItem("vehicleDataForDetailing", responseData);

                    return setShowVehicleDataLoader(false);

                }

                return;

            } catch (err) {

                PageErrorMessage.current = err?.response?.data?.message ?? err?.message;

                handleSnackBarOpen();
                setShowVehicleDataLoader(false);

                return;

            }

        };

        const runData = async () => {

            const vehicleTrackerData = vehicleDataForDetailing?.document;
            if (vehicleTrackerData) {

                locationsArray.push({
                    name: `${vehicleTrackerData.trackerIMEI}-${vehicleTrackerData.VIN}`,
                    location: {
                        lat: vehicleTrackerData.vehicleLatitude,
                        lng: vehicleTrackerData.vehicleLongitude
                    },
                    vehicleMileage: vehicleTrackerData.vehicleMileage,
                    externalPowerSourceVoltage: vehicleTrackerData.externalBatteryVoltage,
                    positionAltitude: vehicleTrackerData.vehicleAltitude,
                    positionSpeed: vehicleTrackerData.vehicleSpeed,
                    latestTrackerDataTimeStamp: returnLastTrackerTimeStamp(vehicleTrackerData.latestTrackerDataTimeStamp),
                    battery: vehicleTrackerData.battery,
                    trackerBatterLevel: vehicleTrackerData.batteryLevel,
                });

                return locationsArray;

            }

        };

        isMounted && returnVehicleModelData(vehicleModelIdForModelData);
        isMounted && (stateTracker > 0) && refreshVehicleData(vehicleDataForDetailing.document.ID);
        isMounted && runData();

        return () => {

            isMounted = false;

            setVehicleModelIdForModelData(null);
            setVehicleDataForDetailing(null);
            setVehicleModelData(null);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        vehicleModelIdForModelData,
        stateTracker
    ]);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openErrorSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={handleClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: '100%' }}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {PageErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>

            {!showModelDataLoader && <div className={`card card-custom ${className}`}>

                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">
                        {vehicleDataForDetailing?.document?.VIN}
                    </h3>
                    <div className="card-toolbar">
                        <Dropdown className="dropdown-inline" align="end">
                            <Dropdown.Toggle
                                id="dropdown-toggle-top"
                                as={DropdownCustomToggler}
                            >
                                <i className="ki ki-bold-more-ver" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                {/* <DropdownMenu4 /> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                <div className="card-body pt-0">
                    <div className='centerMan mb-5'>
                        <Button
                            onClick={() => {
                                setStateTracker(stateTracker + 1);
                            }}
                            variant="outlined"
                            color='primary'
                        >
                            Refresh Data
                        </Button>
                    </div>

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Motor Type: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.motor?.name}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>ID: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleDataForDetailing?.document?.ID}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Brake System: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.brakeSystem}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Charging Time: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.chargingTime}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Frame Material: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.frameMaterial}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Lights: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.lights}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Load Area: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.loadArea}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Load Limit: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.loadLimit}
                                                    kg
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Max Speed: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.maxSpeed}
                                                    km/h
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Suspension: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.suspension}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Throttle Type: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.throttleType}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Transmission: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.transmission}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Manufacturer: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.manufacturer?.name}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Battery Chemistry: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.battery?.name}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Wheel Base: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.wheelbase}
                                                    mm
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Range: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleModelData?.document?.range}
                                                    km
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>Tracker IMEI: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleDataForDetailing?.document?.trackerIMEI ?? 'No assigned tracker yet'}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={3}>
                            <Item>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="label" className='bold'>VIN: </Typography>
                                    <Typography variant="p">
                                        {showModelDataLoader || showVehicleDataLoader ?
                                            (
                                                <>
                                                    <BasicLoader size="sm" />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {vehicleDataForDetailing?.document?.VIN}
                                                </>
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>

                    <div className="d-flex align-items-center bg-light-success rounded p-5 mb-9">
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <h4 className="font-weight-bold text-dark-75 mb-1">
                                Current Vehicle Location
                                {" - "}
                                {showModelDataLoader || showVehicleDataLoader ?
                                    (
                                        <>
                                            <BasicLoader size="sm" />
                                        </>
                                    ) :
                                    (
                                        <>
                                            {returnLastTrackerTimeStamp(vehicleDataForDetailing.document?.latestTrackerDataTimeStamp)}
                                        </>
                                    )
                                }
                            </h4>
                            <small>Click/tap on the map marker for more position details</small>
                            <div className="font-weight-bold mt-5">
                                <div style={{ width: '100%', height: '500px' }}>
                                    <MapContainer
                                        locations={[
                                            {
                                                name: `${vehicleDataForDetailing?.document?.trackerIMEI}-${vehicleDataForDetailing?.document?.VIN}`,
                                                location: {
                                                    lat: vehicleDataForDetailing?.document?.vehicleLatitude,
                                                    lng: vehicleDataForDetailing?.document?.vehicleLongitude,
                                                },
                                                vehicleMileage: vehicleDataForDetailing?.document?.vehicleMileage,
                                                externalPowerSourceVoltage: vehicleDataForDetailing?.document?.externalBatteryVoltage,
                                                positionAltitude: vehicleDataForDetailing?.document?.vehicleAltitude,
                                                positionSpeed: vehicleDataForDetailing?.document?.vehicleSpeed,
                                                latestTrackerDataTimeStamp: returnLastTrackerTimeStamp(vehicleDataForDetailing?.document?.latestTrackerDataTimeStamp),
                                                battery: vehicleDataForDetailing?.document?.battery,
                                                trackerBatterLevel: vehicleDataForDetailing?.document?.batteryLevel,
                                            }
                                        ]}
                                        vehicleData={vehicleDataForDetailing}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >}
        </>
    );
}
