import React, { useState } from 'react';
import Chart from "react-apexcharts";

const BarChartAltAlt = ({ categories, data, exportFilename, chartTitle, xAxisName, yAxisName, yAxisMinVal, yAxisMaxVal, chartHeight, dataAlt, yAxisNameAlt }) => {

    const [options, setOptions] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: false,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: exportFilename,
                        columnDelimiter: ',',
                        headerCategory: xAxisName,
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        }
                    },
                    svg: {
                        filename: exportFilename,
                    },
                    png: {
                        filename: exportFilename,
                    }
                }
            },
            stroke: {
                curve: "straight",
                show: true,
                width: 1,
            },
            dropShadow: {
                enabled: true,
                color: '#89ff89',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#89ff89', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 50
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1]
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            tooltip: {
                enabled: true,  // Enable tooltips on hover
                shared: true,  // Share tooltip between bar and trend line
                intersect: false,  // Tooltip will not intersect
                y: {
                    formatter: function (val) {
                        return `${val} units`;  // Custom format for tooltip values
                    },
                },
            },
            title: {
                text: chartTitle,
                align: "left"
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
        },
        xaxis: {
            categories: categories,
            name: xAxisName,
            labels: {
                show: true
            },
            title: {
                text: xAxisName
            }
        },
        yaxis: {
            title: {
                text: yAxisName ?? "Expected Contract ITMOs (ECI) & km",
            },
            min: yAxisMinVal,
            max: yAxisMaxVal
        },
    });

    const [series, setSeries] = useState([
        {
            data: data,
            name: yAxisName ?? "km",
            type: "bar"
        },
        {
            data: dataAlt,
            name: yAxisNameAlt ?? "Average Distance Ridden(Bike/Day)",
            type: "bar",
        },
    ]);

    return (

        <div className='line-chart-area'>
            <Chart
                type='bar'
                options={options}
                series={series}
                height={chartHeight ?? 500}
            />
        </div>

    );

};

export default BarChartAltAlt;