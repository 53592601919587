import React from "react";
import { CardTileWidget } from "../tiles/CardTileWidget";
import { Grid } from "@mui/material";
import { AreaChart } from "../../charts/AreaChart";

export function WidgetBody({ currentMonth, previousMonth, lastQuarter, lastYear }) {

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                <Grid item xs={12} sm={3}>
                    <CardTileWidget title={"Current Month"} number={53} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardTileWidget title={"Previous Month"} number={53} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardTileWidget title={"Last Quarter"} number={53} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardTileWidget title={"Last Year"} number={53} />
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4" alignItems="center"
                justifyContent="center"
            >
                <Grid item xs={12} sm={12}
                >
                    <AreaChart title={"eBikes Produced"} />
                </Grid>

            </Grid>
        </>
    );
}