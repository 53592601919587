import {
    IconButton,
    Snackbar,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Paper,
    Grid,
    styled,
    Chip,
    Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssignmentTurnedIn } from '@mui/icons-material';
import TtyTwoToneIcon from '@mui/icons-material/TtyTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import AssignmentLateTwoToneIcon from '@mui/icons-material/AssignmentLateTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { activateUser, churnDriver, deactivateUser, getVehicle, unChurnDriver, unassignVehicle, updateUserEmail } from '../../../../app/modules/Auth/_redux/authCrud';
import { AUTH_BASE_URL, setStorageItem } from '../../../../app/services/baseService';
import { toAbsoluteUrl } from "../../../_helpers";
import { CreateEditDriverForm, UpdateUserPhoneForm, VehicleDetailsList, CreateTransactionForm, SubscriptionStartDateForm, OrganizationsTable } from '..';
import { SimpleModal } from '../modals/SimpleModal';
import { VehiclesTableSub } from "./VehicleTableSub";
import BasicLoader from '../../loaders/SimpleLoader';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { DriverTransactions } from './DriverTransactions';
import ReadMoreTwoToneIcon from '@mui/icons-material/ReadMoreTwoTone';
import DriverDetailsPage from '../../../../app/modules/DriverProfile/DriverDetailsPage';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { riderOnboardingStepNames } from '../../../_helpers/Interfaces';
import { shallowEqual, useSelector } from "react-redux";
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import { assignDriversToOrganization } from '../../../../app/modules/Auth/_redux/authCrud';
import { mkConfig, generateCsv, download, ExportToCsv } from 'export-to-csv';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import { UpdateUserEmailForm } from '../forms/UpdateUserEmailForm';
import AssignmentReturnTwoToneIcon from '@mui/icons-material/AssignmentReturnTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';

/* const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
}); */

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

export function UntappedDriversTable({ className, onChanger, assignmentType, assignAction, isSelectionAction, selectionAction, organizationID }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showPhoneUpdateModal, setShowPhoneUpdateModal] = useState(false);
    const [driverNameForUpdate, setDriverNameForUpdate] = useState("");
    const [initialPhoneValue, setInitialPhoneValue] = useState({
        phoneNumber: ""
    });
    const [initialEmailValue, setInitialEmailValue] = useState({
        email: ""
    });
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        gender: "",
        licenseID: "",
        phoneNumber: "",
        country: ""
    });

    const [selectedDriverID, setSelectedDriverID] = useState(null);
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Account');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [disableActionButtons, setDisableActionButtons] = useState(true);
    const [severity, setSeverity] = useState('info');
    const [showVehicleDetails, setShowVehicleDetails] = useState(false);
    const [showModelDataLoader, setModelDataLoader] = useState('d-none');
    const [driverProfileImgURL, setDriverProfileImgURL] = useState('');
    const [vehicleModelID, setVehicleModelID] = useState('');
    const [vehicleData, setVehicleData] = useState({});
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [showDriverTransactions, setShowDriverTransactions] = useState(false);
    const [driverID, setDriverID] = useState("");
    const [selectedDriverName, setSelectedDriverName] = useState("");
    const [createTransactionDefaultValues, setCreateTransactionDefaultValues] = useState({
        email: "",
        amount: 0,
        currency: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        isFirstPayment: false,
        isSubscriptionPayment: false,
        userID: "",
        weeksPaid: 1
    });
    const [vehicleVin, setVehicleVin] = useState("");
    const [vehicleVinIsSet, setVehicleVinIsSet] = useState(false);
    const [rowSelection, setRowSelection] = useState({});

    const [isChurnLoadingSate, setIsChurnLoadingSate] = useState(false);
    const [unChurnLoadingSate, setUnChurnLoadingSate] = useState(false);

    const [showDriveDetails, setShowDriveDetails] = useState(false);
    const [driveDetailsData, setDriveDetailsData] = useState(false);
    const [showEmailUpdateModal, setShowEmailUpdateModal] = useState(false);
    const [showSubscriptionDateModal, setShowSubscriptionDateModal] = useState(false);
    const [subscriptionDateInitialValues, setSubscriptionDateInitialValues] = useState({
        startDate: "",
        driverID: "",
    });

    const [toggleOrganizationAssignmentConfirmationModal, setToggleOrganizationAssignmentConfirmationModal] = useState(false);
    const [selectedDriverIDForOrganization, setSelectedDriverIDForOrganization] = useState("");

    const TableMessage = useRef();
    const classes = useStyles();

    const defaultValuesToSet = {
        firstName: "",
        lastName: "",
        gender: "",
        licenseID: "",
        phoneNumber: "",
        country: ""
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));

    function triggerSnackBar() {
        setOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    function handleUnAssignment(ingressData, driverID) {

        const unAssigner = user.user_data.document.ID;
        const requestPayload = {
            vehicleID: ingressData,
            driverID: driverID,
            assignerID: unAssigner
        };

        const unAssignVehicle = async () => {
            try {
                const response = await unassignVehicle(requestPayload);
                if (response.status) {

                    TableMessage.current = response.data.message;
                    setOpen(true);
                    setSeverity("success");
                    onChangeWorker();

                }
            } catch (error) {

                TableMessage.current = error?.message ?? "could not unassign vehicle, try again later";
                setOpen(true);
                setSeverity("error");
                onChangeWorker();

            }
        };

        return unAssignVehicle();

    }

    function displayAssignedVehicle(ingressData) {

        setModelDataLoader('');

        const specVehicleID = ingressData;
        getVehicle(specVehicleID).then((response) => {

            const modelID = response.data.document.vehicleModelID;
            setVehicleModelID(modelID);

            const vehicleData = response.data.document;
            const selectedVehicleDocument = {
                document: {}
            };
            selectedVehicleDocument.document = vehicleData;
            setVehicleData(selectedVehicleDocument);

        }).finally(() => {

            setTimeout(() => {
                setModelDataLoader('d-none');
                setShowVehicleDetails(true);
            }, 3000);

        }).catch((error) => {

            TableMessage.current = error.message;
            setSeverity('error');
            triggerSnackBar();
            setModelDataLoader('d-none');

        });

    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    const driverDelegationRenderer = (rowData) => {

        const isStaffVehicleBool = rowData?.document?.isStaffAccount;
        const isCustomerVehicle = rowData?.document?.isTestAccount;

        if (isStaffVehicleBool === true) {
            return (
                <Chip label="Staff Account" variant="outlined" color="primary" icon={<ManageAccountsTwoToneIcon />}></Chip>
            );
        }

        if (isCustomerVehicle === true) {
            return (
                <Chip label="Test Account" variant="outlined" color="secondary" icon={< PersonOutlineTwoToneIcon />}></Chip >
            );
        }

        return (
            <Chip label="Rider Account" variant="outlined" color="default" icon={<SportsMotorsportsTwoToneIcon />}></Chip>
        );

    };

    const DisplayAssignedVehicles = ({ vehicleID, driverID }) => {

        if (vehicleID === "" || vehicleID === null || !vehicleID)
            return (
                <Alert
                    icon={false}
                    elevation={6}
                    variant="filled"
                    color='error'
                >
                    no vehicle(s) assigned
                </Alert>
            );

        return (
            <Alert
                icon={false}
                action={
                    <>
                        <Button
                            startIcon={<RemoveRedEyeTwoToneIcon />}
                            color="info"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                displayAssignedVehicle(vehicleID);
                            }}
                            className="mr-2"
                        >
                            Track Vehicle
                            {" "}
                            <BasicLoader
                                hideShowLoader={showModelDataLoader}
                                size="sm"
                            />
                        </Button>
                        <Button
                            startIcon={<AssignmentLateTwoToneIcon />}
                            color="error"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                handleUnAssignment(vehicleID, driverID);
                            }}
                        >
                            Unassign
                        </Button>
                    </>
                }
                elevation={7}
                variant="outlined"
            >
                {vehicleVinIsSet ? vehicleVin : vehicleID}
            </Alert>
        );

    };

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/organizations/untapped/drivers/getAll', `${AUTH_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    let dataArrayDocument = element.document;
                    dataArrayAlt.push(dataArrayDocument);

                    if ((i + 1) >= dataArray.length)
                        isMounted && setDataDocument(dataArrayAlt);

                }

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpen(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    useEffect(() => {
        console.info({ rowSelection });
    }, [rowSelection]);

    const columns = useMemo(() => [
        {
            id: 'designation',
            header: 'Designation',
            Cell: ({ cell, row }) => (
                driverDelegationRenderer(row.original)
            ),
        },
        {
            accessorFn: (row) => `${row?.document?.firstName} ${row?.document?.lastName}`,
            id: 'name',
            header: 'Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Avatar
                        alt={row.original?.document?.firstName}
                        src={row.original?.document?.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")}
                        className={classes.bigAvatar}
                    />
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.isChurned,
            header: 'Churn Status',
            id: "churnStatus",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.error.dark : cell.getValue() === false ? theme.palette.success.light : theme.palette.success.light,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Churned" : "Not Churned"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row?.document?.email ?? ""}`,
            id: "email",
            enableClickToCopy: true,
            header: 'Email',
            Cell: ({ cell, row }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() ? '' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? cell.getValue() : (<>
                        Not Added
                        <div
                            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-success"
                            onClick={() => {

                                const driverID = row.original.document.ID;
                                setSelectedDriverID(driverID);

                                setDriverNameForUpdate(row.original.document.firstName + " " + row.original.document.lastName);
                                setInitialEmailValue({
                                    email: row.original.document.email
                                });
                                setCreateEditText("Update Email Address");

                                setShowEmailUpdateModal(true);

                            }}
                        >
                            <AddBoxTwoToneIcon
                                fontSize='small'
                                color='primary'
                            />
                            <span className="pulse-ring"></span>
                        </div>
                    </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.phoneNumber ?? row?.document?.phone,
            id: "phone",
            enableClickToCopy: true,
            header: 'Phone Number',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() ? '' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? cell.getValue() : "Not Added"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.country,
            id: "country",
            enableClickToCopy: true,
            header: 'Country',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() ? '' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? cell.getValue() : "Not Added"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Inactive"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.assignedVehiclesData,
            header: 'VIN',
            id: "vehicleVin",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() && cell.getValue()?.length ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() && cell.getValue()?.length ? '#000' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() && cell.getValue()?.length ?
                        (<>
                            {cell.getValue()?.map((item, index) => (
                                <div key={index} className='mb-5'>
                                    {item.vehicleVIN}
                                </div>
                            ))}
                        </>)
                        :
                        (<>
                            <span>
                                No Vehicle
                            </span>
                        </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.assignedVehiclesData,
            header: 'Tracker IMEI',
            id: "trackerImei",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() && cell.getValue()?.length ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() && cell.getValue()?.length ? '#000' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() && cell.getValue()?.length !== 0 ?
                        (<>
                            {cell.getValue()?.map((item, index) => (
                                <div key={index} className='mb-5'>
                                    {item.trackerIMEI}
                                </div>
                            ))}
                        </>)
                        :
                        (<>
                            <span>
                                No Vehicle
                            </span>
                        </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.ID ?? row?.document?.uid,
            id: 'driverID',
            enableClickToCopy: true,
            header: 'ID',
        },
        {
            accessorFn: (row) => `${new Date(row.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            filterVariant: 'date',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        }
    ],
        [classes.bigAvatar],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Driver Data',
        showTitle: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original?.document));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    /* const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original?.document);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    }; */

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Driver`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditDriverForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    driverProfileImgURL={driverProfileImgURL}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Select vehicle(s) to be assigned`}
                show={showAssignModal}
                close={() => setShowAssignModal(false)}
                size="xl"
                body={<VehiclesTableSub
                    assignmentType="driver"
                    onChanger={onChangeWorker}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Update ${driverNameForUpdate} Phone's Number`}
                show={showPhoneUpdateModal}
                close={() => setShowPhoneUpdateModal(false)}
                size="lg"
                body={<UpdateUserPhoneForm
                    initialValues={initialPhoneValue}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Vehicle Details`}
                show={showVehicleDetails}
                close={() => setShowVehicleDetails(false)}
                size="lg"
                body={<VehicleDetailsList
                    selectedVehicleModelID={vehicleModelID}
                    selectedVehicleData={vehicleData}
                    className="card-stretch gutter-b"
                />}
            />
            <SimpleModal
                modal_tittle={`Add payment for driver`}
                show={showAddPaymentModal}
                close={() => setShowAddPaymentModal(false)}
                size="lg"
                body={<CreateTransactionForm
                    initialValues={createTransactionDefaultValues}
                    createEditText={"Create Transaction"}
                    onChanger={onChangeWorker}
                    userID={createTransactionDefaultValues.userID}
                />}

            />
            <SimpleModal
                modal_tittle={`Driver Transactions for ${selectedDriverName}`}
                show={showDriverTransactions}
                close={() => setShowDriverTransactions(false)}
                size="lg"
                body={<DriverTransactions
                    driverID={driverID}
                    driverName={selectedDriverName}
                />}

            />
            <SimpleModal
                modal_tittle={`Update ${driverNameForUpdate}'s Email Address`}
                show={showEmailUpdateModal}
                close={() => setShowEmailUpdateModal(false)}
                size="lg"
                body={<UpdateUserEmailForm
                    initialValues={initialEmailValue}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Subscription Start Date for ${selectedDriverName}`}
                show={showSubscriptionDateModal}
                close={() => setShowSubscriptionDateModal(false)}
                size="lg"
                body={
                    <SubscriptionStartDateForm
                        initialValues={subscriptionDateInitialValues}
                        editState={editState}
                        createEditText={createEditText}
                        onChanger={onChangeWorker}
                        selectedDriverID={driverID}
                    />
                }
            />
            <SimpleModal
                modal_tittle={`Assign Vehicle to Organization`}
                show={toggleOrganizationAssignmentConfirmationModal}
                close={() => setToggleOrganizationAssignmentConfirmationModal(false)}
                size="lg"
                body={<OrganizationsTable
                    onChanger={onChangeWorker}
                    driverID={selectedDriverIDForOrganization}
                    assignAction={true}
                    assignmentType={"driver"}
                    assignmentEntityName={"Assign Riders"}
                />}
            />
            {!showDriveDetails && <div className={`card card-custom ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {!isLoading && rowCount}
                            {isLoading && <BasicLoader size={'sm'} />}
                            {" "}
                            Rider Records
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Rider account management and actions
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Account");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Create Driver Account
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        options={{
                            selection: true,
                        }}
                        enableRowSelection
                        enableBatchRowSelection
                        onRowSelectionChange={setRowSelection}
                        selectAllMode={"all"}
                        getRowId={(row) => row?.document?.ID}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enablePagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowActions
                        enableRowPinning
                        enableFullScreenToggle
                        enableExpandAll
                        enableExpanding
                        muiToolbarAlertBannerProps={
                            isError ? {
                                color: `${severity}`,
                                children: `${TableMessage.current}`,
                            } : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            rowSelection,
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={6}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className={`bold ${classes.allCaps}`}>Assigned Vehicles</Typography>
                                                        <div className='mt-5'>
                                                            {row.original.document?.vehicles?.map((vehicleid) => (
                                                                <div key={vehicleid} className='mb-5'>
                                                                    <DisplayAssignedVehicles severity="info" key={vehicleid} vehicleID={vehicleid ?? null} driverID={row.original.document?.ID} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className='centerMan'>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '1rem',
                                                        }}
                                                    >
                                                        <Avatar
                                                            alt={row.original?.document?.VIN}
                                                            src={row.original?.document?.profileImageURL ?? toAbsoluteUrl("/media/products/ebike-mana.webp")}
                                                            sx={{ width: 80, height: 80 }}
                                                        />
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className={`bold ${classes.allCaps}`}>Gender</Typography>
                                                        <div className='mt-5'>
                                                            <Typography variant="p" display="block" gutterBottom>
                                                                {row.original.document?.gender}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className={`bold ${classes.allCaps}`}>ID</Typography>
                                                        <div className='mt-5'>
                                                            <Typography variant="p" display="block" gutterBottom>
                                                                {row.original.document?.ID}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Vehicle Tracker IMEI: </Typography>
                                                        <Typography variant="p">
                                                            {row.original.document?.assignedVehiclesData ? row.original.document?.assignedVehiclesData?.map((item, index) => (
                                                                <div key={index} className='mb-5'>
                                                                    {item.trackerIMEI}
                                                                </div>
                                                            )) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Vehicle VIN: </Typography>
                                                        <Typography variant="p">
                                                            {row.original.document?.assignedVehiclesData ? row.original.document?.assignedVehiclesData?.map((item, index) => (
                                                                <div key={index} className='mb-5'>
                                                                    {item.vehicleVIN}
                                                                </div>
                                                            )) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Vehicle ID: </Typography>
                                                        <Typography variant="p">
                                                            {row.original.document?.assignedVehiclesData ? row.original.document?.assignedVehiclesData?.map((item, index) => (
                                                                <div key={index} className='mb-5'>
                                                                    {item.vehicleID}
                                                                </div>
                                                            )) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>

                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const restructuredOnboardingArray = [];
                                    restructuredOnboardingArray.length = 13;

                                    let specDriverData = row.original;
                                    const driverOnboarding = specDriverData.onboarding;

                                    for (let i = 0; i < driverOnboarding.length; i++) {

                                        const element = driverOnboarding[i];
                                        const elementObjectKey = Object.keys(element)[0];

                                        switch (elementObjectKey) {
                                            case riderOnboardingStepNames[0]:
                                                restructuredOnboardingArray[0] = element;
                                                break;
                                            case riderOnboardingStepNames[1]:
                                                restructuredOnboardingArray[1] = element;
                                                break;

                                            case riderOnboardingStepNames[2]:
                                                restructuredOnboardingArray[2] = element;
                                                break;

                                            case riderOnboardingStepNames[3]:
                                                restructuredOnboardingArray[6] = element;
                                                break;

                                            case riderOnboardingStepNames[4]:
                                                restructuredOnboardingArray[10] = element;
                                                break;

                                            case riderOnboardingStepNames[5]:
                                                restructuredOnboardingArray[11] = element;
                                                break;

                                            case riderOnboardingStepNames[6]:
                                                restructuredOnboardingArray[9] = element;
                                                break;

                                            case riderOnboardingStepNames[7]:
                                                restructuredOnboardingArray[3] = element;
                                                break;

                                            case riderOnboardingStepNames[8]:
                                                restructuredOnboardingArray[4] = element;
                                                break;

                                            case riderOnboardingStepNames[9]:
                                                restructuredOnboardingArray[5] = element;
                                                break;

                                            case riderOnboardingStepNames[10]:
                                                restructuredOnboardingArray[7] = element;
                                                break;

                                            case riderOnboardingStepNames[11]:
                                                restructuredOnboardingArray[8] = element;
                                                break;

                                            case riderOnboardingStepNames[12]:
                                                restructuredOnboardingArray[12] = element;
                                                break;

                                            case riderOnboardingStepNames[13]:
                                                restructuredOnboardingArray[13] = element;
                                                break;

                                            default:
                                                break;
                                        }

                                        if ((i + 1) === driverOnboarding.length) {
                                            specDriverData.onboarding = restructuredOnboardingArray;
                                        }

                                    }

                                    setDriveDetailsData(specDriverData);
                                    setShowDriveDetails(true);
                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon
                                    style={{ color: "#174e48" }}
                                >
                                    <ReadMoreTwoToneIcon />
                                </ListItemIcon>
                                Rider Profile
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const driverID = row.original.document.ID;
                                    const driverProfileImageURL = row.original?.document?.profileImageURL;
                                    const valuesToSet = {
                                        licenseID: row.original.document.licenseID,
                                        firstName: row.original.document.firstName,
                                        lastName: row.original.document.lastName,
                                        gender: row.original.document.gender,
                                        phoneNumber: row.original.document.phone,
                                        country: row.original.document.country,
                                    };

                                    setSelectedDriverID(driverID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setDriverProfileImgURL(driverProfileImageURL);
                                    setShow(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <EditTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Edit Driver Details
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={() => {

                                    const driverID = row.original.document.ID;
                                    setSelectedDriverID(driverID);

                                    setDriverNameForUpdate(row.original.document.firstName + " " + row.original.document.lastName);
                                    setInitialEmailValue({
                                        email: row.original.document.email
                                    });
                                    setCreateEditText("Update Email Address");

                                    setShowEmailUpdateModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon
                                    style={{ color: "#004225" }}
                                >
                                    <MarkEmailReadTwoToneIcon />
                                </ListItemIcon>
                                Update Email
                            </MenuItem>,
                            <MenuItem
                                key={3}
                                onClick={() => {

                                    const driverID = row.original.document.ID;
                                    const driveVin = row.original.document;

                                    const valuesToSet = {
                                        licenseID: row.original.document.licenseID,
                                        firstName: row.original.document.firstName,
                                        lastName: row.original.document.lastName,
                                        gender: row.original.document.gender,
                                        phoneNumber: row.original.document.phone
                                    };

                                    setSelectedDriverID(driverID);
                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShowAssignModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon
                                    style={{ color: "#22abe2" }}
                                >
                                    <AssignmentTurnedIn />
                                </ListItemIcon>
                                Assign Vehicle
                            </MenuItem>,
                            <MenuItem
                                key={4}
                                onClick={() => {

                                    const driverID = row.original.document.ID;
                                    setSelectedDriverID(driverID);

                                    setDriverNameForUpdate(row.original.document.firstName + " " + row.original.document.lastName);
                                    setInitialPhoneValue({
                                        phone: row.original.document.phone
                                    });
                                    setCreateEditText("Update Phone Number");

                                    setShowPhoneUpdateModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <TtyTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                Update Phone Number
                            </MenuItem>,
                            <MenuItem
                                key={5}
                                onClick={async () => {

                                    setCreateTransactionDefaultValues({
                                        firstName: row.original.document.firstName,
                                        lastName: row.original.document.lastName,
                                        phoneNumber: row.original.document?.phone ?? row.original.document?.phoneNumber,
                                        userID: row.original.document.ID,
                                        email: row?.original?.document?.email ?? "admin@wahu.me",
                                        amount: 0,
                                        currency: "GHS",
                                        isFirstPayment: false,
                                        isSubscriptionPayment: true,
                                        weeksPaid: 1,
                                        paymentDate: new Date().getTime(),
                                        setterID: user.userID,
                                    });
                                    setShowAddPaymentModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon
                                    style={{ color: "#cc8400" }}
                                >
                                    <LocalAtmTwoToneIcon />
                                </ListItemIcon>
                                Log Driver Payment
                            </MenuItem>,
                            <MenuItem
                                key={6}
                                onClick={async () => {

                                    setShowDriverTransactions(true);
                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);
                                    return closeMenu();
                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon
                                    style={{ color: "#990000" }}
                                >
                                    <ReceiptLongRoundedIcon
                                    />
                                </ListItemIcon>
                                View Driver Transactions
                            </MenuItem>,
                            <MenuItem
                                key={7}
                                onClick={async () => {

                                    setIsChurnLoadingSate(true);
                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);

                                    churnDriver(row.original.document.ID).then((response) => {

                                        if (response) {

                                            setSeverity('success');
                                            TableMessage.current = response.data.message;
                                            triggerSnackBar();

                                            setReRender(prevState => prevState + 1);
                                            setDisableActionButtons(true);
                                            setIsChurnLoadingSate(false);
                                            onChangeWorker();

                                            setTimeout(() => {
                                                return closeMenu();
                                            }, 2000);

                                        }

                                    }).catch((error) => {

                                        setSeverity('error');
                                        setDisableActionButtons(true);

                                        if (error.response) {
                                            TableMessage.current = error?.response?.data?.message;
                                            triggerSnackBar();
                                            return onChangeWorker();
                                        }

                                        TableMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                        triggerSnackBar();
                                        onChangeWorker();

                                        setIsChurnLoadingSate(false);

                                        setTimeout(() => {
                                            return closeMenu();
                                        }, 2000);

                                    });

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <ExitToAppTwoToneIcon
                                        color='warning'
                                    />
                                </ListItemIcon>
                                Churn Driver
                                {" "}
                                {isChurnLoadingSate && <BasicLoader size={"sm"} />}
                            </MenuItem>,
                            <MenuItem
                                key={8}
                                onClick={async () => {

                                    setUnChurnLoadingSate(true);
                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);

                                    unChurnDriver(row.original.document.ID).then((response) => {

                                        if (response) {

                                            setSeverity('success');
                                            TableMessage.current = response.data.message;
                                            triggerSnackBar();

                                            setReRender(prevState => prevState + 1);
                                            setDisableActionButtons(true);
                                            setUnChurnLoadingSate(false);
                                            onChangeWorker();

                                            setTimeout(() => {
                                                return closeMenu();
                                            }, 2000);

                                        }

                                    }).catch((error) => {

                                        setSeverity('error');
                                        setDisableActionButtons(true);

                                        if (error.response) {
                                            TableMessage.current = error?.response?.data?.message;
                                            triggerSnackBar();
                                            return onChangeWorker();
                                        }

                                        TableMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                        triggerSnackBar();
                                        onChangeWorker();

                                        setUnChurnLoadingSate(false);

                                        setTimeout(() => {
                                            return closeMenu();
                                        }, 2000);

                                    });

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <AssignmentReturnTwoToneIcon
                                        color='success'
                                    />
                                </ListItemIcon>
                                Un-churn Driver
                                {" "}
                                {unChurnLoadingSate && <BasicLoader size={"sm"} />}
                            </MenuItem>,
                            <MenuItem
                                key={9}
                                onClick={async () => {

                                    row?.original?.document?.subscriptionPlan ? setEditState(true) : setEditState(false);

                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);
                                    setSubscriptionDateInitialValues({
                                        startDate: "",
                                        driverID: row.original.document.ID,
                                    });
                                    setCreateEditText("Set Date");

                                    setShowSubscriptionDateModal(true);
                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <TodayTwoToneIcon
                                        color='success'
                                    />
                                </ListItemIcon>
                                Set Subscription Start Date
                                {" "}
                                {unChurnLoadingSate && <BasicLoader size={"sm"} />}
                            </MenuItem>,
                            <MenuItem
                                key={10}
                                onClick={async () => {

                                    setSelectedDriverIDForOrganization(row.original?.document?.ID);

                                    setToggleOrganizationAssignmentConfirmationModal(true);
                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={false}
                            >
                                <ListItemIcon>
                                    <CorporateFareTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                <span>
                                    Assign to Organization
                                </span>
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleAssignment = () => {

                                setDisableActionButtons(false);

                                if (!assignmentType) {

                                    setSeverity('error');
                                    TableMessage.current = "no assignment type set for this action";

                                    setDisableActionButtons(true);
                                    return triggerSnackBar();

                                }

                                switch ((assignmentType).toLowerCase()) {

                                    case 'organization':
                                        table.getSelectedRowModel().flatRows.map(async (row) => {

                                            try {

                                                if (!organizationID) {

                                                    setSeverity('error');
                                                    TableMessage.current = "no organization ID set for this action";

                                                    setDisableActionButtons(true);
                                                    return triggerSnackBar();

                                                }

                                                const driverAssignmentPayload = ({
                                                    driverID: row.original.document.ID,
                                                    organizationID: organizationID,
                                                    assignerID: user.user_data.document.ID
                                                });

                                                await assignDriversToOrganization(driverAssignmentPayload).then((driverAssignmentResponse) => {

                                                    setSeverity('success');
                                                    TableMessage.current = driverAssignmentResponse.data.message;
                                                    triggerSnackBar();

                                                    setReRender(prevState => prevState + 1);
                                                    setDisableActionButtons(true);
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('error');
                                                    setDisableActionButtons(true);

                                                    if (error.response) {
                                                        TableMessage.current = error?.response?.data?.message;
                                                        triggerSnackBar();
                                                        return onChanger();
                                                    }

                                                    TableMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                                    triggerSnackBar();
                                                    return onChanger();

                                                });

                                            } catch (error) {

                                                setSeverity('error');
                                                TableMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? 'an unexpected error occurred';
                                                setDisableActionButtons(true);
                                                triggerSnackBar();

                                            }

                                        });

                                        break;

                                    default:
                                        setSeverity('error');
                                        TableMessage.current = 'assignment parent entity not set for this action';

                                        setDisableActionButtons(true);
                                        return triggerSnackBar();

                                }

                            };

                            const handleSelectionAction = () => {

                                setDisableActionButtons(false);

                                const selectedRows = table.getSelectedRowModel().rows;
                                setStorageItem("selectedDriverRows", selectedRows);
                                selectionAction();

                            };

                            const handleDeactivate = () => {

                                setDisableActionButtons(false);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await deactivateUser(row.original.document.ID);

                                        TableMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setDisableActionButtons(true);
                                        triggerSnackBar();

                                    } catch (error) {

                                        TableMessage.current = error.message;
                                        setSeverity('error');

                                        setDisableActionButtons(true);
                                        triggerSnackBar();

                                    }
                                });

                            };

                            const handleActivate = () => {

                                setDisableActionButtons(false);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await activateUser(row.original.document.ID);

                                        TableMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setDisableActionButtons(true);
                                        triggerSnackBar();
                                    } catch (error) {
                                        TableMessage.current = error.message;
                                        setSeverity('error');

                                        setDisableActionButtons(true);
                                        triggerSnackBar();
                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    {assignAction && <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                        <Button
                                            color="secondary"
                                            disabled={table.getSelectedRowModel().flatRows.length === 0 || !disableActionButtons || !assignAction}
                                            onClick={handleAssignment}
                                            variant="contained"
                                            size="small"
                                        >
                                            Assign Driver(s)
                                        </Button>
                                    </ButtonGroup>}
                                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                        <Button
                                            color="error"
                                            disabled={table.getSelectedRowModel().flatRows.length === 0 || !disableActionButtons}
                                            onClick={handleDeactivate}
                                            variant="contained"
                                            size="small"
                                        >
                                            Deactivate
                                        </Button>
                                        <Button
                                            color="success"
                                            disabled={table.getSelectedRowModel().flatRows.length === 0 || !disableActionButtons}
                                            onClick={handleActivate}
                                            variant="contained"
                                            size="small"
                                        >
                                            Activate
                                        </Button>
                                    </ButtonGroup>
                                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                        <Button
                                            color="secondary"
                                            onClick={handleExportData}
                                            variant="contained"
                                            size='small'
                                        >
                                            Export this Page
                                        </Button>
                                        <Button
                                            disabled={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                            }
                                            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                            variant="contained"
                                            size='small'
                                            color="secondary"
                                        >
                                            Export Selected Rows
                                        </Button>
                                    </ButtonGroup>
                                    {isSelectionAction && <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                        <Button
                                            color="secondary"
                                            disabled={table.getSelectedRowModel().flatRows.length === 0 || !disableActionButtons || !isSelectionAction}
                                            onClick={handleSelectionAction}
                                            variant="contained"
                                            size="small"
                                        >
                                            Select Riders
                                        </Button>
                                    </ButtonGroup>}
                                </div>
                            );
                        }}
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        enableColumnResizing
                        paginationDisplayMode="pages"
                        muiSelectCheckboxProps={{
                            color: 'primary',
                        }}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '450px',
                            }
                        }}
                        muiTableBodyRowProps={({ row, table }) => {
                            const { density } = table.getState();
                            return {
                                sx: {
                                    //Set a fixed height for pinned rows
                                    height: row.getIsPinned() ?
                                        `${density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69}px`
                                        :
                                        undefined,
                                },
                            };
                        }}
                        autoResetSelectedRows={false}
                        autoResetPageIndex={false}
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>}
            {
                showDriveDetails && <div className='bg-white rounded'>
                    <div className="card mb-2">
                        <div className="d-flex justify-content-start">
                            <span className="dropdown dropdown-inline p-5">
                                <span
                                    className='btn btn-clean btn-light-primary btn-sm btn-icon cursor-pointer'
                                    onClick={() => {
                                        setShowDriveDetails(false);
                                    }}
                                >
                                    <i className="fa fa-chevron-left"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <DriverDetailsPage
                        selectedDriverData={driveDetailsData}
                        onChangeFunction={onChangeWorker}
                    />
                </div>
            }
        </>
    );
}