import { useFormik } from "formik";
import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { getGuestByToken, getUserByToken, updateAdmin } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';
import BasicLoader from "../../loaders/SimpleLoader";
import { useHistory } from 'react-router-dom';
import {
    Grow,
} from '@mui/material';
import {
    onAuthStateChanged,
} from "firebase/auth";
import { connect, useDispatch } from "react-redux";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { authentication } from "../../../../app/modules/Auth/firebase";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

function OnboardingForm(props) {

    const initialValues = props.initialValues;
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const [showFirstMessage, setShowFirstMessage] = useState(true);
    const [showSecondMessage, setShowSecondMessage] = useState(false);
    const [showThirdMessage, setShowThirdMessage] = useState(false);
    const [showFourthMessage, setShowFourthMessage] = useState(false);

    const creationSchema = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().required(),
        gender: Yup.string().required(),
        phoneNumber: Yup.string(),
        country: Yup.string().required(),
        roleTitle: Yup.string().required(),
        department: Yup.string().required(),
        bioText: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                updateAdmin(values, props.adminID).then((response) => {

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });

                        onAuthStateChanged(authentication, async (user) => {

                            if (user) {

                                user.getIdToken().then(async (idToken) => {

                                    return await getUserByToken(null, props.adminID, idToken).then(async (specResponse) => {

                                        const { data: userAlt } = specResponse;
                                        dispatch(props.fulfillUser(userAlt));

                                        setStatus("profile details updated, please wait...");
                                        setFormAlertEnabled(true);

                                        setTimeout(() => {
                                            setSubmitting(false);
                                            return history.push('/dashboard');
                                        }, 6000);

                                    }).catch(async (error) => {

                                        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                                            setAlertType({
                                                type: 'danger',
                                                icon: <WarningTwoToneIcon />
                                            });

                                            formik.setStatus("network error, please check your internet connection");
                                            formik.setSubmitting(false);

                                            disableLoading();
                                            return setFormAlertEnabled(true);

                                        }

                                        if (error?.response?.status === 401) {

                                            setAlertType({
                                                type: 'danger',
                                                icon: <WarningTwoToneIcon />
                                            });

                                            formik.setStatus("your access token has expired, please login again to continue your session");
                                            formik.setSubmitting(false);

                                            disableLoading();
                                            return setFormAlertEnabled(true);

                                        }

                                        if (error?.response?.status === 403) {

                                            return await getGuestByToken().then((response) => {

                                                const { data: userAlt } = response;
                                                const userRoleArray = userAlt.user_data.auth.role;
                                                if (!userRoleArray.includes("guest") || !userRoleArray.includes("dvla")) {

                                                    setAlertType({
                                                        type: 'danger',
                                                        icon: <WarningTwoToneIcon />
                                                    });

                                                    formik.setStatus("you do not have enough permission to access to proceed");
                                                    formik.setSubmitting(false);

                                                    return dispatch(props.logout());

                                                }

                                                dispatch(props.fulfillUser(userAlt));

                                                setStatus(`${response.data.message}, please wait...`);
                                                setFormAlertEnabled(true);

                                                setTimeout(() => {
                                                    return history.push('/dashboard');
                                                }, 3000);

                                            }).catch(async (error) => {

                                                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                                                    setAlertType({
                                                        type: 'danger',
                                                        icon: <WarningTwoToneIcon />
                                                    });

                                                    formik.setStatus("network error, please check your internet connection");
                                                    formik.setSubmitting(false);

                                                    disableLoading();
                                                    return setFormAlertEnabled(true);

                                                }

                                                if (error?.response?.status === 401) {

                                                    setAlertType({
                                                        type: 'danger',
                                                        icon: <WarningTwoToneIcon />
                                                    });

                                                    formik.setStatus("your access token has expired, please login again to continue your session");
                                                    formik.setSubmitting(false);

                                                    disableLoading();
                                                    return setFormAlertEnabled(true);

                                                }

                                                setAlertType({
                                                    type: 'danger',
                                                    icon: <WarningTwoToneIcon />
                                                });

                                                formik.setStatus(
                                                    error?.response?.data?.message
                                                    ??
                                                    error?.message
                                                    ??
                                                    error?.code
                                                );
                                                formik.setSubmitting(false);

                                                disableLoading();
                                                return setFormAlertEnabled(true);

                                            });

                                        }

                                        setAlertType({
                                            type: 'danger',
                                            icon: <WarningTwoToneIcon />
                                        });

                                        formik.setStatus(
                                            error?.response?.data?.message
                                            ??
                                            error?.message
                                            ??
                                            error?.code
                                        );
                                        formik.setSubmitting(false);

                                        disableLoading();
                                        return setFormAlertEnabled(true);

                                    });

                                });

                            }

                        });

                    }

                }).catch((error) => {

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });
                    setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                    disableLoading();
                    setSubmitting(false);

                    return setFormAlertEnabled(true);

                }).finally(() => {
                    props.onChanger();
                });

            }, 1000);
        },
    });

    useEffect(() => {

        const timer1 = setTimeout(() => {
            setShowFirstMessage(false);
        }, 3000);

        const timer2 = setTimeout(() => {
            setShowSecondMessage(true);
        }, 3050);

        const timer3 = setTimeout(() => {
            setShowSecondMessage(false);
            setShowThirdMessage(true);
        }, 8000);

        const timer4 = setTimeout(() => {
            setShowSecondMessage(false);
            setShowThirdMessage(false);
            setShowFourthMessage(true);
        }, 16000);

        const timer5 = setTimeout(() => {
            setShowSecondMessage(false);
            setShowThirdMessage(false);
            setShowFourthMessage(false);
        }, 27000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
            clearTimeout(timer5);
        };

    }, []);

    return (
        <>

            <div className="centerMan">

                {showFirstMessage && <div className="centerMan" style={{
                    minHeight: "100vh",
                }}>
                    <Grow in={showFirstMessage} timeout={1000}>
                        <span className="pl-3 pr-3 pt-1 pb-1 rounded-xl bg-light-success">
                            Provisioning...
                            {" "}
                            <BasicLoader size={"sm"} />
                        </span>
                    </Grow>
                </div>}

                {showSecondMessage && <div
                    style={{
                        minHeight: "100vh",
                    }}
                    className="centerMan"
                >
                    <Grow in={showSecondMessage} timeout={1000}>
                        <div className="bold centerMan"
                            style={{
                                fontSize: "24px",
                            }}
                        >
                            {`Hi ${props.displayName}`}
                        </div>
                    </Grow>
                </div>}

                {showThirdMessage && <div
                    style={{
                        minHeight: "100vh",
                    }}
                    className="centerMan"
                >
                    <Grow
                        in={showThirdMessage}
                        timeout={1000}
                    >
                        <div className="font-size-lg">
                            <div className="centerMan bold font-size-lg">
                                this is wahuOs
                            </div>
                            <div className="centerMan" style={{
                                fontSize: "20px",
                            }}>
                                your toolbox here at Wahu...
                            </div>
                        </div>
                    </Grow>
                </div>}

                {showFourthMessage && <div
                    style={{
                        minHeight: "100vh",
                    }}
                    className="centerMan"
                >
                    <Grow in={showFourthMessage} timeout={1000}>
                        <div className="centerMan font-size-lg bold">
                            <span>
                                seems you have some potholes in your profile, lets fill them...
                            </span>
                            <br />
                            <span>
                                <BasicLoader size={"lg"} />
                            </span>
                        </div>
                    </Grow>
                </div>}

            </div>

            {!showFirstMessage && !showSecondMessage && !showThirdMessage && !showFourthMessage &&
                (<>

                    <Grow in={!showThirdMessage} timeout={1000}>
                        <div className="mt-2">
                            <div className="font-size-lg bold centerMan mb-10">
                                Complete the form to finish your profile setup
                            </div>
                        </div>
                    </Grow>
                    <Grow in={!showThirdMessage} timeout={2000}>

                        <div className="p-1">
                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Group controlId="onboardingFormFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your First Name" value={formik.values.firstName} onChange={formik.handleChange} className={`${getInputClasses("firstName")}`} name="firstName" {...formik.getFieldProps("firstName")} />
                                    <Form.Text className="text-muted">
                                        Check and confirm your first name is correct
                                    </Form.Text>
                                    <Form.Control.Feedback>First name seems fine!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        First name field cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.firstName}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Your Last Name"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        className={`${getInputClasses("lastName")}`}
                                        name="lastName"
                                        {...formik.getFieldProps("lastName")}
                                    />
                                    <Form.Text className="text-muted">
                                        Check and confirm your last name is correct
                                    </Form.Text>
                                    <Form.Control.Feedback>Last name seems fine!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Last name field cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.lastName}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" disabled={props.fieldDisabled} placeholder="Your Email Address" value={formik.values.email} onChange={formik.handleChange} className={`${getInputClasses("email")}`} name="email" {...formik.getFieldProps("email")} />
                                    <Form.Text className="text-muted">
                                        Check and confirm your email address is correct
                                    </Form.Text>
                                    <Form.Control.Feedback>Email looks valid</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Email address field cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.email}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormGender">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Control as={'select'} placeholder="Your Gender" value={formik.values.gender} onChange={formik.handleChange} className={`${getInputClasses("gender")}`} name="gender" {...formik.getFieldProps("gender")}>
                                        <option disabled defaultValue="" value="">Select one</option>
                                        <option value={"male"}>Male</option>
                                        <option value={"female"}>Female</option>
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Check and confirm your selected gender is correct
                                    </Form.Text>
                                    <Form.Control.Feedback>clear to proceed</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Gender field cannot be blank!
                                    </Form.Control.Feedback>
                                    {formik.touched.gender && formik.errors.gender ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.gender}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormPhoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="tel" disabled={props.fieldDisabled} placeholder="Your Phone Number" value={formik.values.phoneNumber} onChange={formik.handleChange} className={`${getInputClasses("phoneNumber")}`} name="phoneNumber" {...formik.getFieldProps("phoneNumber")} />
                                    <Form.Text className="text-muted">
                                        Check and confirm your phone number is correct
                                    </Form.Text>
                                    <Form.Control.Feedback>Phone number looks valid</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Phone number field cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.phoneNumber}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormCountry">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control as={"select"} data-live-search="true" value={formik.values.country} onChange={formik.handleChange} className={`${getInputClasses("country")}`} name="country" {...formik.getFieldProps("country")}>
                                        <option defaultValue="" disabled value="">Select one</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran (Islamic Republic of)">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                        <option value="Korea, Republic of">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon">Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russian Federation">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra Leone">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia (Slovak Republic)">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Sri Lanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                        <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Viet Nam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Select country
                                    </Form.Text>
                                    <Form.Control.Feedback>Country selected</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Country field cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.country && formik.errors.country ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.country}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormRoleTitle">
                                    <Form.Label>Role Title</Form.Label>
                                    <Form.Control type="text" placeholder="Your Role Title" value={formik.values.roleTitle} onChange={formik.handleChange} className={`${getInputClasses("roleTitle")}`} name="roleTitle" {...formik.getFieldProps("roleTitle")} />
                                    <Form.Text className="text-muted">
                                        Check and confirm your role title is correct
                                    </Form.Text>
                                    <Form.Control.Feedback>Phone number looks valid</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Role title field cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.roleTitle && formik.errors.roleTitle ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.roleTitle}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormDepartment">
                                    <Form.Label>Department</Form.Label>
                                    <Form.Control as={"select"} data-live-search="true" placeholder="Your Department" value={formik.values.department} onChange={formik.handleChange} className={`${getInputClasses("department")}`} name="department" {...formik.getFieldProps("department")}>
                                        <option disabled defaultValue="" value="">Select one</option>
                                        <option value={"Software"}>Software</option>
                                        <option value={"Research and Development"}>Research and Development</option>
                                        <option value={"Rider Management"}>Rider Management</option>
                                        <option value={"Collections"}>Collections</option>
                                        <option value={"Finance"}>Finance</option>
                                        <option value={"Growth"}>Growth</option>
                                        <option value={"Commercial"}>Commercial</option>
                                        <option value={"Senior Management"}>Senior Management</option>
                                        <option value={"People and Culture"}>People and Culture</option>
                                        <option value={"Service Delivery"}>Service Delivery</option>
                                        <option value={"Production"}>Production</option>
                                        <option value={"Fabrication"}>Fabrication</option>
                                        <option value={"Embedded Systems"}>Embedded Systems</option>
                                        <option value={"Electrical Systems"}>Electrical Systems</option>
                                        <option value={"CAD"}>CAD</option>
                                        <option value={"Security Services"}>Security Services</option>
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Check and confirm your selected department is correct
                                    </Form.Text>
                                    <Form.Control.Feedback>clear to proceed</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Department field cannot be blank!
                                    </Form.Control.Feedback>
                                    {formik.touched.department && formik.errors.department ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.department}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="onboardingFormBioText">
                                    <Form.Label>Bio Text</Form.Label>
                                    <Form.Control as={'textarea'} rows={5} placeholder="Your Bio Text" value={formik.values.bioText} onChange={formik.handleChange} className={`${getInputClasses("bioText")}`} name="bioText" {...formik.getFieldProps("bioText")} />
                                    <Form.Text className="text-muted">
                                        Check and confirm your short bio
                                    </Form.Text>
                                    <Form.Control.Feedback>Bio text is okay</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Bio text field cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.bioText && formik.errors.bioText ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.bioText}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                                    {props.createEditText}
                                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                                </Button>
                            </Form>
                        </div>

                    </Grow>
                </>)
            }

        </>
    );
}

export default connect(null, auth.actions)(OnboardingForm);