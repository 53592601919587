import React from 'react';
import { VehiclesTable } from './VehiclesTable';

export const TogoVehiclesTable = () => {

    return (
        <>
            <VehiclesTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/organizations/wahu-togo/vehicles/getAll"}
                tableTopHeader={"Togo Vehicles"}
                tableSubHeader={"Manage Togo vehicle records from this table"}
            />
        </>
    );

};