import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAlMRiDMuHTE4Zk6vNAs6a4YUv5A6691LA",
    authDomain: "mana-backend.firebaseapp.com",
    projectId: "mana-backend",
    storageBucket: "mana-backend.appspot.com",
    messagingSenderId: "109514594929",
    appId: "1:109514594929:web:eb04fe265e493c459dd9d0",
    measurementId: "G-2NGBSTYK9S"
};
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
authentication.languageCode = 'en';

export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
    'login_hint': 'use your phone number or email and password'
});

export const messaging = getMessaging(app);
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
});