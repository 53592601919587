import React, { useEffect, useState } from "react";
import { getStorageItem, setStorageItem } from "../../../../app/services/baseService";
import {
    IconButton,
    Typography,
    Card,
    CardHeader,
    Avatar,
    CardContent,
    Chip,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BasicLoader from "../../loaders/SimpleLoader";
import { makeStyles } from '@mui/styles';
import { toAbsoluteUrl } from "../../../_helpers";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    separator: {
        borderColor: theme.palette.primary,
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

export function MessageDetailsPage() {

    const classes = useStyles();

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedDriversData, setSelectedDriversData] = useState([]);
    const [reRender, setReRender] = useState(0);

    useEffect(() => {

        let isMounted = true;

        setIsLoadingData(true);

        const selectedDriverStored = getStorageItem("selectedDriverRows");

        isMounted && setSelectedDriversData(selectedDriverStored);
        isMounted && setIsLoadingData(false);

        return () => {

            isMounted = false;
            setSelectedDriversData(null);

        };

    }, [reRender]);

    return (
        <>
            {isLoadingData && <div className="mt-5">
                <BasicLoader
                    size={"lg"}
                />
            </div>}

            {!isLoadingData && <>
                <div className="mt-5">
                    <Typography variant="h5" component="h2">
                        Selected Riders
                    </Typography>
                    <div className="row">
                        {selectedDriversData.map((driverData, index) => {

                            const actDriverData = JSON.parse(driverData);

                            return (
                                <div
                                    key={index}
                                    className="mb-1 p-2 col-xs-12 col-sm-12 col-md-12 col-lg-6"
                                >
                                    <Card className={classes.card}>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    aria-label={actDriverData.firstName}
                                                    className={classes.avatar}
                                                    src={actDriverData.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")}
                                                >
                                                    {(actDriverData.firstName).substring(0, 1)}
                                                </Avatar>
                                            }
                                            action={
                                                <IconButton
                                                    aria-label="Settings"
                                                    onClick={() => {

                                                        if (index > -1) {

                                                            selectedDriversData.splice(index, 1);
                                                            setStorageItem("selectedDriverRows", selectedDriversData);
                                                            return setReRender(prevState => prevState + 1);

                                                        }

                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            }
                                            title={actDriverData.firstName + " " + actDriverData.lastName}
                                            subheader={actDriverData?.phoneNumber ?? actDriverData?.phone}
                                        />
                                        <CardContent>
                                            <Typography
                                                variant="body2"
                                                component="div"
                                            >
                                                {actDriverData?.isActive ? <Chip
                                                    label="Active"
                                                    color="success"
                                                /> : <Chip label="Inactive" color="error" />}
                                            </Typography>
                                        </CardContent>
                                        {/* <CardActions>
                                    <Button size="small">Learn More</Button>
                                </CardActions> */}
                                    </Card>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
            </>}

        </>
    );

}