import React from 'react';
import { Chip } from '@mui/material';

export function ChipDisplay({ label, variant, handleClick, Deletable, handleDelete, customDeleteIcon, color, size }) {

    if (Deletable) {

        return (
            <>
                <Chip
                    label={label}
                    variant={variant}
                    onClick={handleClick}
                    onDelete={handleDelete}
                    deleteIcon={customDeleteIcon}
                    color={color}
                    size={size}
                />
            </>
        );

    }

    return (
        <>
            <Chip
                label={label}
                variant={variant}
                onClick={handleClick}
                color={color}
                size={size}
            />
        </>
    );

}