import {
    IconButton,
    Snackbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    TextField,
    Typography,
    Grid,
    Paper,
    Alert,
    Chip,
    Avatar,
} from '@mui/material';
import { styled } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { assignVehicles, assignVehiclesToOrganization, assignTracker } from '../../../../app/modules/Auth/_redux/authCrud';
import { FLEET_MANAGING_BASE_URL, getStorageItem, removeStorageItem } from '../../../../app/services/baseService';
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import BasicLoader from '../../loaders/SimpleLoader';
import {
    getVehicleModelData
} from '../../../../app/modules/Auth/_redux/authCrud';
import { shallowEqual, useSelector } from "react-redux";
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import TrackChangesTwoToneIcon from '@mui/icons-material/TrackChangesTwoTone';
import { convertUnixTimestampToUTC } from "../../../_helpers";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function VehiclesTableSub({ tableClassName, assignmentType, onChanger, selectedDriverID }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [showLoader, setShowLoader] = useState('d-none');

    const TableErrorMessage = useRef();
    const vehicleModelData = useRef();
    const classes = useStyles();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    function triggerSnackBar() {
        setOpen(true);
    }

    const returnVehicleModelData = (vehicleModelID) => {

        if (vehicleModelID) {

            getVehicleModelData(vehicleModelID).then((response) => {
                return vehicleModelData.current = response?.data;
            });

        }

    };

    const assignerRenderer = (vehicleEntity, index) => {

        if (vehicleEntity && (vehicleEntity.assignmentType !== null || vehicleEntity.assignmentType !== undefined)) {

            return (
                <div key={index} className="mb-2 uppercase border-1 border-success">
                    <Chip
                        icon={vehicleEntity.assignmentType === "added" ? vehicleEntity.assignedEntity === "driver" ? <SportsMotorsportsTwoToneIcon /> : vehicleEntity.assignedEntity === "organization" ? <CorporateFareTwoToneIcon /> : vehicleEntity.assignedEntity === "tracker" ? <TrackChangesTwoToneIcon /> : <RemoveCircleTwoToneIcon /> : <RemoveCircleTwoToneIcon />}
                        label={`${vehicleEntity.assignedEntity} - ${vehicleEntity.assignmentType} - ${new Date(vehicleEntity.assignmentDateTime).toLocaleDateString()}`}
                        variant="outlined"
                        color={vehicleEntity.assignmentType === "added" ? "primary" : "error"}
                        style={{
                            width: "250px",
                            alignContent: "left",
                            alignItems: "left",
                            justifyContent: "start"
                        }}
                    />
                </div>
            );

        }

    };

    const vehicleDelegationRenderer = (rowData) => {

        const isStaffVehicleBool = rowData?.document?.isStaffVehicle;
        const isCustomerVehicle = rowData?.document?.isCustomerVehicle;
        const vehicleOrganizationArray = rowData?.document?.organizations;

        if (isStaffVehicleBool === true) {
            return (
                <Chip label="Staff Vehicle" variant="outlined" color="primary" icon={<ManageAccountsTwoToneIcon />}></Chip>
            );
        }

        if (isCustomerVehicle === true) {
            return (
                <Chip label="Customer Vehicle" variant="outlined" color="secondary" icon={< PersonOutlineTwoToneIcon />}></Chip >
            );
        }

        if (vehicleOrganizationArray && vehicleOrganizationArray?.length > 0) {

            for (let i = 0; i < vehicleOrganizationArray.length; i++) {

                const organizationID = vehicleOrganizationArray[i];
                if (organizationID === "MORG-041b040e-4a5d-4077-9415-1f5976c309c6") {
                    return (
                        <>
                            <Chip
                                label="Untapped x Wahu"
                                variant="filled"
                                color="secondary"
                                icon={<SportsMotorsportsTwoToneIcon />}
                            >

                            </Chip>
                        </>
                    );
                }

            }

        }

        return (
            <Chip label="Rider Vehicle" variant="outlined" color="default" icon={<SportsMotorsportsTwoToneIcon />}></Chip>
        );

    };

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/vehicles/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpen(true);

                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            id: 'designation',
            header: 'Designation',
            Cell: ({ cell, row }) => (
                vehicleDelegationRenderer(row?.original)
            ),
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "ID",
            enableClickToCopy: true,
            header: 'ID'
        },
        {
            accessorFn: (row) => row?.original?.document?.trackerIMEI,
            enableClickToCopy: true,
            id: 'document.trackerIMEI',
            header: 'Tracker IMEI',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    {row?.original?.document?.trackerIMEI ?
                        (
                            <>
                                <Typography
                                    noWrap
                                    sx={(theme) => ({
                                        borderRadius: '0.35rem',
                                        p: '0.35rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase'
                                    })}
                                    variant='p'
                                >
                                    {row?.original?.document?.trackerIMEI}
                                </Typography>
                            </>
                        ) :
                        (
                            <>
                                <Typography
                                    sx={(theme) => ({
                                        backgroundColor: theme.palette.error.dark,
                                        borderRadius: '0.35rem',
                                        color: '#fff',
                                        p: '0.35rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase'
                                    })}
                                    noWrap
                                    variant='p'
                                >
                                    No tracker Assigned
                                </Typography>
                            </>
                        )
                    }
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.VIN,
            id: "vin",
            enableClickToCopy: true,
            header: 'VIN/Serial #'
        },
        {
            accessorFn: (row) => row?.original?.document?.latestTrackerDataTimeStamp,
            enableClickToCopy: true,
            id: 'latestTrackerDataTimeStamp',
            header: 'Tracker Last Post',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        borderRadius: '0.35rem',
                        p: '0.35rem',
                        textAlign: 'center'
                    }}
                >
                    {row?.original?.document?.latestTrackerDataTimeStamp && row?.original?.document?.latestTrackerDataTimeStamp > 0 ? (
                        <>

                            {((Math.abs((new Date().getTime()) - (row?.original?.document?.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3)) > 8 ?
                                (
                                    <>
                                        <Typography
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.warning.light,
                                                borderRadius: '0.35rem',
                                                color: '#fff',
                                                p: '0.35rem',
                                                textAlign: 'center',
                                                textTransform: 'uppercase'
                                            })}
                                            noWrap
                                            variant='p'
                                        >
                                            {(Math.abs((new Date().getTime()) - (row?.original?.document?.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3) + " hours ago"}
                                        </Typography>
                                    </>
                                ) :
                                (
                                    <>
                                        <Typography
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.success.light,
                                                borderRadius: '0.35rem',
                                                color: '#fff',
                                                p: '0.35rem',
                                                textAlign: 'center',
                                                textTransform: 'uppercase'
                                            })}
                                            noWrap
                                            variant='p'
                                        >
                                            {(Math.abs((new Date().getTime()) - (row?.original?.document?.latestTrackerDataTimeStamp * 1000)) / 36e5).toFixed(3) + " hours ago"}
                                        </Typography>
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <>
                            <Box
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.error.dark,
                                    borderRadius: '0.35rem',
                                    color: '#fff',
                                    p: '0.35rem',
                                    textAlign: 'center'
                                })}
                            >
                                <Typography
                                    sx={(theme) => ({
                                        borderRadius: '0.35rem',
                                        p: '0.35rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase'
                                    })}
                                    noWrap
                                    variant='p'
                                >
                                    Never
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.vehicleMileage ?? 0,
            id: "vehicleMileage",
            enableClickToCopy: true,
            header: 'km - Total Mileage'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Vehicle Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.light : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    <Typography
                        sx={(theme) => ({
                            borderRadius: '0.35rem',
                            p: '0.35rem',
                            textAlign: 'center',
                            textTransform: 'uppercase'
                        })}
                        noWrap
                        variant='p'
                    >
                        {cell.getValue() === true ? "Running" : "Not Running"}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            filterVariant: 'date',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        },
        {
            accessorFn: (row) => row?.original?.document?.assignedDriversData,
            enableClickToCopy: true,
            id: 'assignedDriversData',
            header: 'Assigned Driver',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        borderRadius: '0.35rem',
                        p: '0.35rem',
                        textAlign: 'center'
                    }}
                >
                    {row?.original?.document?.assignedDriversData && row?.original?.document?.assignedDriversData?.length > 0 ? (
                        <>
                            <Typography
                                noWrap
                                sx={(theme) => ({
                                    borderRadius: '0.35rem',
                                    p: '0.35rem',
                                    textAlign: 'center',
                                    textTransform: 'uppercase'
                                })}
                                variant='p'
                            >
                                {row?.original?.document?.assignedDriversData[(row?.original?.document?.assignedDriversData?.length) - 1]?.driverFirstName + " " + row?.original?.document?.assignedDriversData[(row?.original?.document?.assignedDriversData?.length) - 1]?.driverLastName}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.error.dark,
                                    borderRadius: '0.35rem',
                                    color: '#fff',
                                    p: '0.35rem',
                                    textAlign: 'center'
                                })}
                            >
                                <Typography
                                    noWrap
                                    sx={(theme) => ({
                                        borderRadius: '0.35rem',
                                        p: '0.35rem',
                                        textAlign: 'center',
                                        textTransform: 'uppercase'
                                    })}
                                    variant='p'
                                >
                                    Unassigned
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            ),
        },
    ],
        [],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                /* autoHideDuration={8000} */
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button
                        key="undo"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}
                    >
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <div className={`${tableClassName}`}>
                <div className="pt-3 pb-3">
                    <div className='row'>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                            <div className='centerMan p-1'>
                                <p>
                                    Couldn't find the vehicle you're looking for?
                                </p>
                            </div>
                        </div>
                        <div className='col-xs-6 col-sm-6 col-md-6'>
                            <div className='centerMan p-1'>
                                <Button
                                    component={Link} to="/vehicles"
                                    variant='outlined'
                                    size='small'
                                >
                                    Create New Vehicle Record
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-3 pb-3">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Assignment History: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.assignmentHistory?.map(assignerRenderer)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Assigned Driver(s) Name: </Typography>
                                                        <Typography variant="p">
                                                            {row?.original?.document?.assignedDriversData ? row?.original?.document?.assignedDriversData.map((item, index) =>
                                                                <div key={index}>
                                                                    {item.driverFirstName + " " + item.driverLastName}
                                                                </div>
                                                            ) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Assigned Driver(s) Phone: </Typography>
                                                        <Typography variant="p">
                                                            {row?.original?.document?.assignedDriversData ? row?.original?.document?.assignedDriversData.map((item, index) =>
                                                                <div key={index}>
                                                                    {item.driverPhoneNumber}
                                                                </div>
                                                            ) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Assigned Organization: </Typography>
                                                        <Typography variant="p">
                                                            {row?.original?.document?.assignedOrganizationData ? row?.original?.document?.assignedOrganizationData.map((item, index) =>
                                                                <div key={index}>
                                                                    {item.organizationName}
                                                                </div>
                                                            ) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Assigned Organization ID: </Typography>
                                                        <Typography variant="p">
                                                            {row?.original?.document?.assignedOrganizationData ? row?.original?.document?.assignedOrganizationData.map((item, index) =>
                                                                <div key={index}>
                                                                    {item.organizationID}
                                                                </div>
                                                            ) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Assigned Driver ID: </Typography>
                                                        <Typography variant="p">
                                                            {row?.original?.document?.assignedDriversData ? row?.original?.document?.assignedDriversData.map((item, index) =>
                                                                <div
                                                                    key={index}
                                                                >
                                                                    {item.driverID}
                                                                    {" "}
                                                                    <Button
                                                                        color='error'
                                                                        size='small'
                                                                        variant='contained'
                                                                        onClick={() => {

                                                                        }}
                                                                    >
                                                                        Unassign
                                                                    </Button>
                                                                </div>
                                                            ) : ""}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>ID: </Typography>
                                                        <Typography variant="p">
                                                            {row.original.document?.ID}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Staff Vehicle?: </Typography>
                                                        <Typography variant="p">
                                                            {row.original.document?.isStaffVehicle ? "YES" : "NO"}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Customer Vehicle?: </Typography>
                                                        <Typography variant="p">
                                                            {row.original.document?.isCustomerVehicle ? "YES" : "NO"}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>CreatedAt: </Typography>
                                                        <Typography variant="p">
                                                            {convertUnixTimestampToUTC(row.original.document?.createdAt)}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Typography variant="label" className='bold'>Last Updated: </Typography>
                                                        <Typography variant="p">
                                                            {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                                        </Typography>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <Box sx={{ textAlign: 'left' }}>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="error"
                                                            aria-label="Add"
                                                            onClick={() => {

                                                            }}
                                                        >
                                                            Immobilize Bike
                                                        </Button>
                                                    </Box>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                            <Grid item xs={12}>
                                                <div className='centerMan'>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '1rem',
                                                        }}
                                                    >
                                                        <Avatar
                                                            alt={row.original?.document?.VIN}
                                                            src={row.original?.document?.profileImageURL ?? toAbsoluteUrl("/media/products/ebike-mana.webp")}
                                                            sx={{ width: 80, height: 80 }}
                                                        />
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>

                        )}
                        renderTopToolbarCustomActions={({ table }) => {
                            const handleAssignment = () => {
                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        setShowLoader('');
                                        handleSnackbarClose();

                                        if (!assignmentType) {
                                            TableErrorMessage.current = "Assignment parent entity not set at this time!";
                                            setSeverity('warning');
                                            triggerSnackBar();
                                            setShowLoader('d-none');
                                            return onChanger();
                                        }

                                        switch ((assignmentType).toLowerCase()) {
                                            case 'driver':
                                                const driverAssignmentPayload = ({
                                                    vehicleID: row.original.document.ID,
                                                    driverID: selectedDriverID,
                                                    assignerID: user.user_data.document.ID
                                                });

                                                await assignVehicles(driverAssignmentPayload).then((driverAssignmentResponse) => {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = driverAssignmentResponse.data.message;
                                                    triggerSnackBar();

                                                    setReRender(prevState => prevState + 1);
                                                    setShowLoader('d-none');
                                                    removeStorageItem('driverIdForAssignment');
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('warning');

                                                    if (error.response) {
                                                        TableErrorMessage.current = error?.response?.data?.message;
                                                        triggerSnackBar();
                                                        setShowLoader('d-none');
                                                        return onChanger();
                                                    }

                                                    TableErrorMessage.current = error ?? "an expected error occurred";
                                                    triggerSnackBar();
                                                    setShowLoader('d-none');
                                                    return onChanger();

                                                });

                                                break;

                                            case 'organization':
                                                const organizationAssignmentPayload = ({
                                                    vehicleID: row.original.vehicleID,
                                                    organizationID: getStorageItem('organizationIdForAssignment'),
                                                    assignerID: user.user_data.document.ID
                                                });

                                                await assignVehiclesToOrganization(organizationAssignmentPayload).then((assignmentResponse) => {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = assignmentResponse.data.message;
                                                    triggerSnackBar();

                                                    setReRender(prevState => prevState + 1);
                                                    setShowLoader('d-none');
                                                    removeStorageItem('organizationIdForAssignment');
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('warning');

                                                    if (error.response) {
                                                        TableErrorMessage.current = error?.response?.data?.message;
                                                        triggerSnackBar();
                                                        setShowLoader('d-none');
                                                        return onChanger();
                                                    }

                                                    TableErrorMessage.current = error ?? "an expected error occurred";
                                                    triggerSnackBar();
                                                    setShowLoader('d-none');
                                                    return onChanger();

                                                });

                                                break;

                                            case 'tracker':
                                                const trackerAssignmentPayload = ({
                                                    vehicleID: row.original.document.ID,
                                                    trackerID: getStorageItem('trackerIdForAssignment'),
                                                    assignerID: user.user_data.document.ID
                                                });

                                                await assignTracker(trackerAssignmentPayload).then((trackerAssignmentResponse) => {

                                                    setSeverity('success');
                                                    TableErrorMessage.current = trackerAssignmentResponse.data.message;
                                                    triggerSnackBar();

                                                    setReRender(prevState => prevState + 1);
                                                    setShowLoader('d-none');
                                                    removeStorageItem('trackerIdForAssignment');
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('warning');

                                                    if (error.response) {
                                                        TableErrorMessage.current = error?.response?.data?.message;
                                                        triggerSnackBar();
                                                        setShowLoader('d-none');
                                                        return onChanger();
                                                    }

                                                    TableErrorMessage.current = error ?? "an expected error occurred";
                                                    triggerSnackBar();
                                                    setShowLoader('d-none');
                                                    return onChanger();

                                                });

                                                break;

                                            default:
                                                setSeverity('warning');
                                                TableErrorMessage.current = 'Assignment parent entity not set at this time!';
                                                triggerSnackBar();
                                                setShowLoader('d-none');
                                        }

                                    } catch (error) {
                                        setSeverity('warning');
                                        TableErrorMessage.current = error.message ?? error ?? 'an unexpected error occurred';
                                        triggerSnackBar();
                                        setShowLoader('d-none');
                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="primary"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0}
                                        onClick={handleAssignment}
                                        variant="contained"
                                        size="small"
                                    >
                                        <BasicLoader hideShowLoader={showLoader} size="sm" />
                                        Assign Vehicle(s)
                                    </Button>
                                </div>
                            );
                        }}
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
