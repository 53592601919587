import React, { useRef, useState } from 'react';
import { activateDriver, deactivateDriver } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';
import {
    Grid,
    Button as MuiButton,
} from "@mui/material";
import BasicLoader from "../../loaders/SimpleLoader";
import { generalFormActions } from "../../../_helpers/Interfaces";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function GeneralPromptForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, driverData, promptText, actionType, modalCloseCallback }) {

    const formMessenger = useRef();

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(false);

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    return (
        <>

            <div className='centerMan'>
                <span>
                    <span className='font-size-lg font-weight-bold'>
                        {`${promptText}`}
                    </span>
                </span>
            </div>
            <div className='mt-5'>
                <FormAlert
                    isEnabled={isFormAlertEnabled}
                    severity={alertType.type}
                    message={formMessenger.current}
                    icon={alertType.icon}
                    handleDismiss={handleAlertDismiss}
                    className="mb-3"
                />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                    <Grid item xs={6}>
                        <div className='centerMan'>
                            <MuiButton
                                size='small'
                                variant='contained'
                                color='primary'
                                onClick={async () => {

                                    if (actionType === generalFormActions[0]) {

                                        setLoading(true);
                                        setFormAlertEnabled(false);

                                        try {

                                            const response = await activateDriver(driverData.document.ID);
                                            formMessenger.current = response.data.message;
                                            setAlertType({
                                                type: 'success',
                                                icon: <CheckCircleTwoToneIcon />
                                            });

                                            onChanger();
                                            setLoading(false);
                                            return setFormAlertEnabled(true);

                                        } catch (error) {

                                            formMessenger.current = error?.response?.data?.message ?? error?.message ?? "something went wrong, please try again";
                                            setAlertType({
                                                type: 'danger',
                                                icon: <WarningTwoToneIcon />
                                            });

                                            setLoading(false);
                                            return setFormAlertEnabled(true);

                                        }

                                    }

                                    if (actionType === generalFormActions[1]) {

                                        setLoading(true);
                                        setFormAlertEnabled(false);

                                        try {

                                            const response = await deactivateDriver(driverData.document.ID);
                                            formMessenger.current = response.data.message;
                                            setAlertType({
                                                type: 'success',
                                                icon: <CheckCircleTwoToneIcon />
                                            });

                                            onChanger();
                                            setLoading(false);
                                            return setFormAlertEnabled(true);

                                        } catch (error) {

                                            formMessenger.current = error?.response?.data?.message ?? error?.message ?? "something went wrong, please try again";
                                            setAlertType({
                                                type: 'danger',
                                                icon: <WarningTwoToneIcon />
                                            });

                                            setLoading(false);
                                            return setFormAlertEnabled(true);

                                        }

                                    }

                                }}
                                disabled={loading}
                            >
                                <span>
                                    Yes
                                    {loading && (<>
                                        {" "}
                                        <BasicLoader size={"sm"} />
                                    </>)}
                                </span>
                            </MuiButton>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className='centerMan'>
                            <MuiButton
                                size='small'
                                variant='contained'
                                color='error'
                                onClick={() => {
                                    return modalCloseCallback();
                                }}
                                disabled={loading}
                            >
                                No
                            </MuiButton>
                        </div>
                    </Grid>
                </Grid>
            </div>

        </>
    );
}