import { useFormik } from "formik";
import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { createPartRecord, editPartDetails } from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls';
import SVG from "react-inlinesvg";
import {
    IconButton,
    Snackbar,
    Button as MuiButton,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Card,
    CardHeader,
    Avatar,
    Alert,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { FLEET_MANAGING_BASE_URL, removeStorageItem } from "../../../../app/services/baseService";
import BasicLoader from "../../loaders/SimpleLoader";
import { toAbsoluteUrl } from "../../../_helpers";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    separator: {
        borderColor: theme.palette.success.light,
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
}));

function getSmsSteps() {
    return [
        'Vehicle Details',
        'Complete Build'
    ];
}

export function CreateEditRequisitionForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, partId, initialImageArray }) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [partImageFiles, setPartImageFiles] = useState(null);

    const [imageArray, setImageArray] = useState([]);
    const [reRender, setReRender] = useState(0);

    const [activeSmsStep, setActiveSmsStep] = useState(0);

    const [showProdDiv, setShowProdDiv] = useState(false);
    const [showGenDiv, setShowGenDiv] = useState(false);
    const [showTopDiv, setShowTopDiv] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    const [specVehicleModelData, setVehicleModelData] = useState([]);
    const [showLoader, setShowLoader] = useState('d-none');
    const [isError, setIsError] = useState(false);

    const TableErrorMessage = useRef();

    const smsSteps = getSmsSteps();

    const creationSchema = Yup.object().shape({
        vehicleModelID: Yup.string().required(),
        VIN: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    handleSmsNext();

                    /* createPartRecord(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            handleSmsReset();
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });

                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return;
                    }); */

                    return;

                }

                /* const editPayload = {
                    vehicleBatchNumber: values.vehicleBatchNumber,
                    partName: values.partName,
                    partCount: values.partCount,
                    partMinimumCount: values.partMinimumCount,
                    purchaseLocation: values.purchaseLocation,
                    partPrice: values.partPrice,
                    partSKU: values.partSKU,
                };

                if (partImageFiles)
                    editPayload.partImage = partImageFiles;

                editPartDetails(editPayload, partId).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(response.data.message);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });

                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);
                    return;
                }); */

            }, 1000);
        },
    });

    const handleNewProfileImage = (event) => {

        if (!initialImageArray || initialImageArray === undefined)
            initialImageArray = [];

        if (initialImageArray && initialImageArray.length && initialImageArray.length <= 0)
            initialImageArray = [];

        let files = event.target.files;
        setPartImageFiles(files);

        const partImageArray = [];
        partImageArray.push(...imageArray);

        if (files) {

            for (let i = 0; i < files.length; i++) {

                const fileObject = files[i];
                const file = URL.createObjectURL(fileObject);
                partImageArray.push(file);
                initialImageArray.push(file);

                if ((i + 1) === files.length) {
                    setImageArray(partImageArray);
                    console.log(partImageArray);
                    console.log(imageArray);
                }

            }

        }

    };

    function handleSmsNext() {

        setActiveSmsStep(prevActiveStep => prevActiveStep + 1);
        if (activeSmsStep === (smsSteps.length - 1)) {
            setReRender(prevState => prevState + 1);
            onChanger();
        }

    }

    function handleSmsBack() {
        setActiveSmsStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleSmsReset() {
        formik.resetForm();
        setActiveSmsStep(0);
    }

    const makeOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.vehicleType.name}
            {" - "}
            {X.document.motor.name}
            {" - "}
            {X.document.battery.name}
            {" - "}
            {X.document.manufacturer.name}
        </option>;
    };

    const handleLoadingAndErrors = () => {
        if (isLoading)
            return true;
        if (isRefetching)
            return true;

        return false;
    };

    useEffect(() => {

        let isMounted = true;

        if (initialImageArray && initialImageArray.length && initialImageArray.length > 0) {
            isMounted && setImageArray(initialImageArray);
        }

        const fetchVehicleModelData = async () => {

            setShowLoader('');

            if (!specVehicleModelData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/vehicles/vehicleModels/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setVehicleModelData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        fetchVehicleModelData();

        return () => isMounted = false;

    }, [initialImageArray, reRender]);

    function getSmsStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                {
                    return (
                        <>

                            <div className="mt-1">
                                <div className="row m-0">
                                    <div className='col p-2'>
                                        <div className="bg-light-primary px-6 py-8 rounded-xl">
                                            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                                <h2>
                                                    Frame
                                                </h2>
                                            </span>
                                            <span
                                                className="text-primary font-weight-bold font-size-h6 mt-2"
                                            >
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/tools/005-bebo.svg")} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col p-2'>
                                        <div className="bg-light-primary px-6 py-8 rounded-xl">
                                            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                                <h2>
                                                    Handle Bar
                                                </h2>
                                            </span>
                                            <span
                                                className="text-primary font-weight-bold font-size-h6 mt-2"
                                            >
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/tools/005-bebo.svg")} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="centerMan mt-5">
                                <MuiButton
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                >
                                    Complete Build
                                </MuiButton>
                            </div>

                        </>
                    );
                }

            case 1:
                {
                    removeStorageItem("selectedRows");
                    return (
                        <>
                            <Form
                                onSubmit={formik.handleSubmit}
                            >
                                <Form.Group controlId="VIN">
                                    <Form.Label>VIN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Vehicle VIN"
                                        value={formik.values.VIN}
                                        onChange={formik.handleChange}
                                        className={`${getInputClasses("VIN")}`}
                                        name="VIN"
                                        {...formik.getFieldProps("VIN")}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter VIN
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        VIN provided is invalid
                                    </Form.Control.Feedback>
                                    {formik.touched.VIN && formik.errors.VIN ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.VIN}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="vehicleFormModelID">
                                    <Form.Label>Vehicle Model</Form.Label>
                                    <Form.Control as="select" data-live-search="true" value={formik.values.vehicleModelID} onChange={formik.handleChange} className={`${getInputClasses("vehicleModelID")}`} name="vehicleModelID" {...formik.getFieldProps("vehicleModelID")}>
                                        <option disabled defaultValue="" value="">Select one</option>
                                        {specVehicleModelData.map(makeOption)}
                                    </Form.Control>
                                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                                    <Form.Text className="text-muted">
                                        Vehicle Type - Motor Type - Battery Type - Manufacturer
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Vehicle model selection cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.vehicleModelID && formik.errors.vehicleModelID ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.vehicleModelID}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                    className={`font-weight-bold px-9 py-4 my-3`}
                                >
                                    {createEditText}
                                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                                </Button>
                            </Form>
                        </>
                    );
                }

            case 2:
                {
                    setReRender(prevState => prevState + 1);
                    onChanger();
                    return (
                        <>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="success"
                                            className={classes.avatar}
                                        >
                                            <CheckCircleTwoToneIcon
                                                color="success"
                                                fontSize="large"
                                            />
                                        </Avatar>
                                    }
                                    title="Build Complete"
                                    subheader=""
                                />
                            </Card>
                        </>
                    );
                }
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <>

            {showTopDiv && (
                <>

                    <div className="mt-1">
                        <div className="row m-0">
                            <div className='col p-2'>
                                <div className="bg-light-primary px-6 py-8 rounded-xl">
                                    <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                        <h2>
                                            Production
                                        </h2>
                                    </span>
                                    <span
                                        className="text-primary font-weight-bold font-size-h6 mt-2"
                                    >
                                        <MuiButton
                                            color="info"
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                setShowProdDiv(true);
                                                setShowTopDiv(false);
                                            }}
                                        >
                                            Build Bike
                                        </MuiButton>
                                    </span>
                                </div>
                            </div>
                            <div className='col p-2'>
                                <div className="bg-light-primary px-6 py-8 rounded-xl">
                                    <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                        <h2>
                                            General
                                            <sup>
                                                <small className="text-muted">
                                                    coming soon
                                                </small>
                                            </sup>
                                        </h2>
                                    </span>
                                    <span
                                        className="text-primary font-weight-bold font-size-h6 mt-2"
                                    >
                                        <MuiButton
                                            color="info"
                                            variant="contained"
                                            size="small"
                                        >
                                            Start
                                        </MuiButton>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            )}

            {showProdDiv && (
                <>
                    <div className="p-0">
                        <div className="centerMan">
                            <Typography
                                variant="h5"
                                component="h2"
                            >
                                Production Requisition
                            </Typography>
                        </div>
                        <Stepper
                            activeStep={activeSmsStep}
                            alternativeLabel
                        >
                            {smsSteps.map((label, index) => (
                                <Step key={index}>
                                    <StepLabel>
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <>
                            {activeSmsStep === (smsSteps.length) ? (
                                <>
                                    <Card className={classes.card}>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    aria-label="success"
                                                    className={classes.avatar}
                                                >
                                                    <CheckCircleTwoToneIcon
                                                        color="success"
                                                    />
                                                </Avatar>
                                            }
                                            title="Bike Built"
                                            subheader="You can close the modal or click on reset to restart the creation wizard"
                                        />
                                    </Card>
                                    <div className="p-1">
                                        <hr className={classes.separator} />
                                    </div>
                                    <Button
                                        onClick={handleSmsReset}
                                    >
                                        Reset
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <div>
                                        {getSmsStepContent(activeSmsStep)}
                                    </div>
                                    <div className="p-1">
                                        <hr className={classes.separator} />
                                    </div>
                                    <div className="mt-10">
                                        {activeSmsStep !== 0 &&
                                            <>
                                                <Button
                                                    disabled={activeSmsStep === 0}
                                                    onClick={handleSmsBack}
                                                    className={classes.backButton}
                                                >
                                                    Back
                                                </Button>
                                                {activeSmsStep === smsSteps.length - 1 ?
                                                    ''
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleSmsNext}
                                                    >
                                                        Next
                                                    </Button>
                                                }
                                            </>
                                        }
                                        {activeSmsStep === 0 &&
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        setShowProdDiv(false);
                                                        setShowTopDiv(true);
                                                    }}
                                                    className={classes.backButton}
                                                >
                                                    Back
                                                </Button>
                                                {activeSmsStep === (smsSteps.length - 1) ?
                                                    <Button
                                                        disabled={true}
                                                        className={classes.backButton}
                                                    >
                                                        Next
                                                    </Button>
                                                    :
                                                    <Button
                                                        /* disabled={true} */
                                                        className={classes.backButton}
                                                        onClick={handleSmsNext}
                                                    >
                                                        Next
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </>
            )}

            {showGenDiv && (
                <>
                    <div className="p-0">
                        <div className="centerMan">
                            <Typography
                                variant="h5"
                                component="h2"
                            >
                                Production Requisition
                            </Typography>
                        </div>
                        <Stepper
                            activeStep={activeSmsStep}
                            alternativeLabel
                        >
                            {smsSteps.map((label, index) => (
                                <Step key={index}>
                                    <StepLabel>
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <>
                            {activeSmsStep === (smsSteps.length) ? (
                                <>
                                    <Card className={classes.card}>
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    aria-label="success"
                                                    className={classes.avatar}
                                                >
                                                    <CheckCircleTwoToneIcon
                                                        color="success"
                                                    />
                                                </Avatar>
                                            }
                                            title="inventory part created successfully"
                                            subheader="You can close the modal or click on reset the creation wizard to add another part"
                                        />
                                    </Card>
                                    <div className="p-1">
                                        <hr className={classes.separator} />
                                    </div>
                                    <Button
                                        onClick={handleSmsReset}
                                    >
                                        Reset
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <div>
                                        {getSmsStepContent(activeSmsStep)}
                                    </div>
                                    <div className="p-1">
                                        <hr className={classes.separator} />
                                    </div>
                                    <div className="mt-10">
                                        {activeSmsStep !== 0 &&
                                            <>
                                                <Button
                                                    disabled={activeSmsStep === 0}
                                                    onClick={handleSmsBack}
                                                    className={classes.backButton}
                                                >
                                                    Back
                                                </Button>
                                                {activeSmsStep === smsSteps.length - 1 ?
                                                    ''
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleSmsNext}
                                                    >
                                                        Next
                                                    </Button>
                                                }
                                            </>
                                        }
                                        {activeSmsStep === 0 &&
                                            <>
                                                <Button
                                                    disabled={activeSmsStep === 0}
                                                    onClick={handleSmsBack}
                                                    className={classes.backButton}
                                                >
                                                    Back
                                                </Button>
                                                {activeSmsStep === (smsSteps.length - 1) ?
                                                    <Button
                                                        disabled={true}
                                                        className={classes.backButton}
                                                    >
                                                        Next
                                                    </Button>
                                                    :
                                                    <Button
                                                        className={classes.backButton}
                                                        onClick={handleSmsNext}
                                                    >
                                                        Next
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </>
            )}

        </>
    );
}