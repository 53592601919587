import { Card, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useState } from "react";
import * as Yup from "yup";
import { FormAlert } from "../../controls";
import { Button, Form } from "react-bootstrap";
import { getStorageItem } from "../../../../app/services/baseService";
import { useFormik } from "formik";
import { createFaultJob } from "../../../../app/modules/Auth/_redux/authCrud";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateFaultJobForm({ faultReportId, onChanger }) {
    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const faultConfig = getStorageItem('selectedFaultConfig');
    const serviceCenter = getStorageItem('selectedServiceCenter');

    const faultConfigDetails = faultConfig[0].original?.document;
    const serviceCenterDetails = serviceCenter[0].original?.document;

    const initialValues = {
        faultConfigurationID: '',
        faultID: '',
        serviceCenterID: ''
    };

    const creationSchema = Yup.object().shape({
        faultConfigurationID: Yup.string().required(),
        serviceCenterID: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({

        initialValues: initialValues,
        validationSchema: creationSchema,

        onSubmit: (values, { setStatus, setSubmitting }) => {

            if (faultReportId)
                values.faultID = faultReportId;

            enableLoading();

            setTimeout(
                () => {

                    createFaultJob(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            onChanger();
                            return setStatus(response.data.message);
                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });

                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);

                        return;
                    });
                    return;
                }, 3000
            );

        }

    });


    return (
        <>
            <Form
                onSubmit={formik.handleSubmit}
                className="mt-2"
            >

                <Form.Group controlId="faultConfigurationID">
                    <Form.Label>
                        Fault Configuration
                    </Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.faultConfigurationID} className={`${getInputClasses("faultConfigurationID")}`} name="faultConfigurationID" {...formik.getFieldProps("faultConfigurationID")} >
                        <option disabled value=''>
                            Select an option
                        </option>
                        <option value={faultConfigDetails.ID}>
                            {faultConfigDetails.faultDescription}
                        </option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Choose your fault configuration
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.faultConfigurationID && formik.errors.faultConfigurationID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.faultConfigurationID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="serviceCenterID">
                    <Form.Label>
                        Service Center
                    </Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.serviceCenterID} className={`${getInputClasses("serviceCenterID")}`} name="serviceCenterID" {...formik.getFieldProps("serviceCenterID")} >
                        <option disabled value=''>
                            Select an option
                        </option>
                        <option value={serviceCenterDetails.ID}>
                            {serviceCenterDetails.name}
                        </option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Choose your Service Center
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.serviceCenterID && formik.errors.serviceCenterID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.serviceCenterID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button
                    type="submit"
                    variant="primary"
                    disabled={formik.isSubmitting}
                    className={`font-weight-bold px-9 py-4 my-3`}
                >
                    Create Fault Job
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form >
        </>
    );
}