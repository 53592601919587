import React, { useEffect, useState, useCallback } from "react";
import {
    Paper,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Carousel } from 'react-bootstrap';
import { useSubheader } from "../../_wahu/layout";
import { toAbsoluteUrl } from "../../_wahu/_helpers";
import ImageViewer from "react-simple-image-viewer";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    root: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(4)
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    mediaAlt: {
        height: 0,
        paddingTop: '100%',
    }
}));

const images = [
    `${toAbsoluteUrl("/media/overview-images/cloud-overview.PNG")}`,
    `${toAbsoluteUrl("/media/overview-images/1.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/2.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/3.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/4.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/5.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/6.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/7.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/8.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/9.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/10.jpeg")}`,
    `${toAbsoluteUrl("/media/overview-images/11.png")}`,
    `${toAbsoluteUrl("/media/overview-images/12.png")}`,
    `${toAbsoluteUrl("/media/overview-images/13.png")}`,
    `${toAbsoluteUrl("/media/overview-images/14.png")}`,
    `${toAbsoluteUrl("/media/overview-images/15.png")}`,
    `${toAbsoluteUrl("/media/overview-images/16.png")}`,
    `${toAbsoluteUrl("/media/overview-images/17.png")}`,
    `${toAbsoluteUrl("/media/overview-images/18.png")}`,
    `${toAbsoluteUrl("/media/overview-images/19.png")}`,
    `${toAbsoluteUrl("/media/overview-images/20.png")}`,
    `${toAbsoluteUrl("/media/overview-images/21.png")}`,
    `${toAbsoluteUrl("/media/overview-images/22.png")}`,
    `${toAbsoluteUrl("/media/overview-images/flespi.png")}`,
];

export const OverviewPage = () => {

    const suhbeader = useSubheader();
    useEffect(() => {
        const load = async () => {
            try {
                suhbeader.setTitle("System Overview");
            } catch (error) {
                console.log(error);
            }
        };
        load();
    }, [suhbeader]);

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const classes = useStyles();

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">
                                    System at a glance
                                </span>
                                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                    Understand the base system
                                </span>
                            </h3>
                            <div className="card-toolbar">
                                <div className="row">
                                    <div className="col-xxl-12 order-1 order-xxl-1">
                                        {/* <Fab
                                            variant="extended"
                                            size="small"
                                            color="secondary"
                                            aria-label="Add"
                                            className={classes.margin}
                                            onClick={() => {

                                            }}
                                        >
                                            Some Action
                                        </Fab> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-3 pb-10 border-top">
                            <Paper className={classes.root}>
                                <Typography variant="h5" component="h3">
                                    What is Wahu
                                </Typography>
                                <Typography component="p">
                                    The Wahu platform is an open platform for emission-free vehicles from MANA Mobility as well as third parties that allows sign-up, purchase and operations of vehicles as well as orchestrates a mobility transport service for people and goods.
                                </Typography>
                            </Paper>
                            <Paper className={classes.root}>
                                <Typography variant="h5" component="h3">
                                    Backend Architecture
                                </Typography>
                                <img
                                    className="d-block w-100"
                                    src={toAbsoluteUrl("/media/overview-images/cloud-overview.PNG")}
                                    alt="First slide"
                                    onClick={() => openImageViewer(0)}
                                />
                            </Paper>
                            <Paper className={classes.root}>
                                <Typography variant="h5" component="h3">
                                    Fleet Management and Monitoring
                                </Typography>
                                <Carousel className="mt-10">
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/flespi.png")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(23)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                </Carousel>
                                <Typography variant="h5" component="h3" className="mt-5">
                                    <a href="https://flespi.io">Go to Flespi</a>
                                </Typography>
                            </Paper>
                            <Paper className={classes.root}>
                                <Typography variant="h5" component="h3">
                                    Delivery Platform
                                </Typography>
                                <Carousel className="mt-10">
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/1.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(1)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/2.jpeg")}
                                            alt="Third slide"
                                            onClick={() => openImageViewer(2)}
                                        />

                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Second slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/3.jpeg")}
                                            alt="Third slide"
                                            onClick={() => openImageViewer(3)}
                                        />

                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/4.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(4)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/5.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(5)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/6.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(6)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/7.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(7)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/8.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(8)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/9.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(9)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={toAbsoluteUrl("/media/overview-images/10.jpeg")}
                                            alt="First slide"
                                            onClick={() => openImageViewer(10)}
                                        />
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                </Carousel>
                                <Typography variant="h5" component="h3" className="mt-5">
                                    <a href="https://mana-vendor-web.vercel.app/login">See Delivery Platform</a>
                                </Typography>
                            </Paper>
                            <Paper className={classes.root}>
                                <Typography variant="h5" component="h3">
                                    Mobile Application
                                </Typography>
                                <Carousel className="mt-10">
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/11.png")}
                                                alt="First slide"
                                                onClick={() => openImageViewer(11)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">First slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/12.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(12)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Second slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block  w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/13.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(13)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/14.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(14)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/15.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(15)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/16.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(16)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/17.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(17)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/18.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(18)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/19.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(19)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/20.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(20)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/21.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(21)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="centerMan">
                                            <img
                                                className="d-block w-50 h-80"
                                                src={toAbsoluteUrl("/media/overview-images/22.png")}
                                                alt="Third slide"
                                                onClick={() => openImageViewer(22)}
                                            />
                                        </div>
                                        {/* <Carousel.Caption>
                                            <h3 className="text-black">Third slide label</h3>
                                            <p></p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>

                                </Carousel>
                                <Typography variant="h5" component="h3" className="mt-5">
                                    <a href="https://play.google.com/store/apps/details?id=com.mana.rider">See App on Google Play Store</a>
                                </Typography>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="centerMan">
                {isViewerOpen && (
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={true}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)"
                        }}
                        closeOnClickOutside={true}
                    />
                )}
            </div>
        </>
    );
};
