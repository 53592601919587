import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { FLEET_MANAGING_BASE_URL, setStorageItem } from "../../../../app/services/baseService";
import BasicLoader from "../../loaders/SimpleLoader";

export function VehicleCohortMetrics({ cohortID, specQuarterFilterString, metricsLoaderIndicator, totalVehicleCount }) {

    const widgetErrorMessage = useRef();

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [severity, setSeverity] = useState('info');

    const [monthlyMetricsDataYSeries, setMonthlyMetricsDataYSeries] = useState(null);
    const [monthlyMetricsDataXSeries, setMonthlyMetricsDataXSeries] = useState(null);

    const [isMonthlyLoading, setIsMonthlyLoading] = useState(true);
    const [isMonthlyContentLoaded, setIsMonthlyContentLoaded] = useState(false);

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    }

    function triggerSnackBar() {
        setOpenSnackBar(true);
    }

    useEffect(() => {

        let isMounted = true;
        let quarterMonthlyMileageValue = 0;

        const getVehicleCohorts = async () => {

            const url = new URL(`/vehicles/cohort-telemetry/${cohortID}`, `${FLEET_MANAGING_BASE_URL}`);
            try {

                const monthlyMileageArray = [];
                const monthlyTimerArray = [];
                const actVehicleCount = [];

                const response = axios.get(url.href);
                const data = (await response).data;
                const specVehicleCount = data.vehicleCohortData.vehiclesArray.length;

                for (let i = 0; i < data.monthlyTelemetry.length; i++) {

                    const monthlyMileage = data.monthlyTelemetry[i].monthlyMileage;
                    let timerSeries = "";
                    if (monthlyMileage) {

                        actVehicleCount.push(specVehicleCount);

                        if (monthlyMileage <= 0)
                            monthlyMileageArray.push(0.00);
                        else
                            monthlyMileageArray.push((monthlyMileage).toFixed(2));

                        if (data?.monthlyTelemetry[i]?.weeklyTelemetryDocID)
                            timerSeries = data?.monthlyTelemetry[i]?.weeklyTelemetryDocID;
                        else
                            timerSeries = data?.monthlyTelemetry[i]?.monthlyTelemetryDocID;

                        monthlyTimerArray.push(timerSeries);

                    } else {

                        actVehicleCount.push(specVehicleCount);
                        monthlyMileageArray.push(0);

                        if (data?.monthlyTelemetry[i]?.weeklyTelemetryDocID)
                            timerSeries = data?.monthlyTelemetry[i]?.weeklyTelemetryDocID;
                        else
                            timerSeries = data?.monthlyTelemetry[i]?.monthlyTelemetryDocID;

                        monthlyTimerArray.push(timerSeries);

                    }

                    if ((i + 1) === data.monthlyTelemetry.length) {

                        switch (specQuarterFilterString) {

                            case "q42024": {

                                const newY = monthlyMileageArray.slice(0, 3);
                                const newX = monthlyTimerArray.slice(0, 3);
                                const newSize = actVehicleCount.slice(0, 3);

                                setMonthlyMetricsDataYSeries(newY);
                                setMonthlyMetricsDataXSeries(newX);

                                setIsMonthlyLoading(false);
                                setIsMonthlyContentLoaded(true);

                                for (let j = 0; j < newY.length; j++) {

                                    const monthlyMileageValue = newY[j];
                                    quarterMonthlyMileageValue = Number(Number(monthlyMileageValue) + quarterMonthlyMileageValue);

                                    if ((j + 1) === newY.length) {
                                        setStorageItem("q42024Count", newSize[0]);
                                        metricsLoaderIndicator(quarterMonthlyMileageValue, newSize[0], "q42024");
                                    }

                                }

                                return;

                            }

                            case "q32024": {

                                const newY = monthlyMileageArray.slice(3, 6);
                                const newX = monthlyTimerArray.slice(3, 6);

                                setMonthlyMetricsDataYSeries(newY);
                                setMonthlyMetricsDataXSeries(newX);

                                setIsMonthlyLoading(false);
                                setIsMonthlyContentLoaded(true);

                                for (let j = 0; j < newY.length; j++) {

                                    const monthlyMileageValue = newY[j];
                                    quarterMonthlyMileageValue = Number(Number(monthlyMileageValue) + quarterMonthlyMileageValue);

                                    if ((j + 1) === newY.length) {
                                        metricsLoaderIndicator(quarterMonthlyMileageValue, 146, "q32024", 60);
                                    }

                                }

                                return;

                            }

                            case "q22024": {

                                const newY = monthlyMileageArray.slice(6, 9);
                                const newX = monthlyTimerArray.slice(6, 9);

                                setMonthlyMetricsDataYSeries(newY);
                                setMonthlyMetricsDataXSeries(newX);

                                setIsMonthlyLoading(false);
                                setIsMonthlyContentLoaded(true);

                                for (let j = 0; j < newY.length; j++) {

                                    const monthlyMileageValue = newY[j];
                                    quarterMonthlyMileageValue = Number(Number(monthlyMileageValue) + quarterMonthlyMileageValue);

                                    if ((j + 1) === newY.length) {
                                        console.log(quarterMonthlyMileageValue);
                                        metricsLoaderIndicator(quarterMonthlyMileageValue, 86, "q22024", 27);
                                    }

                                }

                                return;

                            }

                            case "q12024": {

                                const newY = monthlyMileageArray.slice(9, 12);
                                const newX = monthlyTimerArray.slice(9, 12);

                                setMonthlyMetricsDataYSeries(newY);
                                setMonthlyMetricsDataXSeries(newX);

                                setIsMonthlyLoading(false);
                                setIsMonthlyContentLoaded(true);

                                for (let j = 0; j < newY.length; j++) {

                                    const monthlyMileageValue = newY[j];
                                    quarterMonthlyMileageValue = Number(Number(monthlyMileageValue) + quarterMonthlyMileageValue);

                                    if ((j + 1) === newY.length) {
                                        metricsLoaderIndicator(quarterMonthlyMileageValue, 59, "q12024", 59);
                                    }

                                }

                                return;

                            }

                            default: {

                                setMonthlyMetricsDataYSeries(monthlyMileageArray);
                                setMonthlyMetricsDataXSeries(monthlyTimerArray);

                                setIsMonthlyLoading(false);
                                setIsMonthlyContentLoaded(true);

                                for (let j = 0; j < monthlyMileageArray.length; j++) {

                                    const monthlyMileageValue = monthlyMileageArray[j];
                                    quarterMonthlyMileageValue = Number(monthlyMileageValue) + quarterMonthlyMileageValue;

                                    if ((j + 1) === monthlyMileageArray.length) {
                                        metricsLoaderIndicator(quarterMonthlyMileageValue, totalVehicleCount);
                                    }

                                }

                                return;

                            }

                        }

                    }

                }

                return;

            } catch (error) {

                widgetErrorMessage.current = error?.response?.data?.message ? `error loading vehicle cohorts: ${error?.response?.data?.message}` : `error loading vehicle cohorts ${error?.message}`;
                setSeverity("error");
                triggerSnackBar();

                return;

            }

        };

        isMounted === true && getVehicleCohorts();

        return () => {

            isMounted = false;
            setMonthlyMetricsDataYSeries(null);
            setMonthlyMetricsDataXSeries(null);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cohortID, specQuarterFilterString]);

    return (
        <>
            <div>
                {isMonthlyLoading && (<>
                    <div className="centerMan">
                        <BasicLoader size={"sm"} />
                    </div>
                </>)}
                {isMonthlyContentLoaded && !isMonthlyLoading && monthlyMetricsDataXSeries && (
                    <>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {monthlyMetricsDataXSeries.map((item, index) => {

                                            return (
                                                <th className="p-1" key={index}>
                                                    <span className="text-dark-55 font-weight-bold d-block font-size-sm text-nowrap">
                                                        {item}
                                                    </span>
                                                </th>
                                            );

                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {monthlyMetricsDataYSeries.map((item, index) => {

                                            return (
                                                <td className="p-1" key={index}>
                                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                        {item}
                                                    </span>
                                                </td>
                                            );

                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </>
    );

}