import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import NightlightRoundTwoToneIcon from '@mui/icons-material/NightlightRoundTwoTone';

export default function RiderSafetyInfo() {
    return (
        <>
            <section>
                <Box p={4} bgcolor={"#ffffff"}>
                    <h3 className='centerMan'>The Rider Safety Score System</h3>
                    The Rider Safety Score System is based on these metrics
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <BoltIcon />
                            </ListItemIcon>
                            <ListItemText primary="Harsh Breaking Events" secondary="Applying the brakes suddenly and forcefully, causing the vehicle to come to a sharp stop. A harsh braking event could indicate a collision while too many harshbraking events could suggest aggressive or unsafe driving behavior and are monitored to promote safe riding habits." />
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <SpeedTwoToneIcon />
                            </ListItemIcon>
                            <ListItemText primary="OverSpeeding Events" secondary="When the e-bike surpasses the designated speed limit or exceeds a predetermined safe speed threshold. Overspeeding events are monitored to identify and address potential safety risks and promote adherence to speed limits for safe driving." />
                        </ListItem>
                    </List>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <NightlightRoundTwoToneIcon />
                            </ListItemIcon>
                            <ListItemText primary="Distance Riden Over 10pm" secondary="It is a specific measurement of the distance covered during nighttime hours (after 10:00 PM) and can be used for various purposes, such as tracking travel patterns, monitoring driving behavior, or calculating nighttime usage for specific vehicles or transportation services." />
                        </ListItem>
                    </List>
                </Box>
            </section>
        </>
    );
}
