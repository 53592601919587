import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as widget from "../app/modules/Auth/_redux/tileWidgetRedux";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    widget: widget.reducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
