import React from 'react';
import { DriversTable } from './DriversTable';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';

export function DriversInPipelineTable() {

    return (
        <>
            <DriversTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/drivers/drivers-pipeline"}
                tableTopHeader={"Riders in Pipeline"}
                baseUrl={FLEET_MANAGING_BASE_URL}
                tableSubHeader={"Converting Riders in Pipeline to active riders"}
            />
        </>
    );

}
