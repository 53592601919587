import { makeStyles } from "@mui/styles";
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Snackbar,
    Typography,
    styled,
    Alert,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { getServiceCenter } from "../../../../app/modules/Auth/_redux/authCrud";
import CloseIcon from '@mui/icons-material/Close';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { toAbsoluteUrl } from "../../../_helpers";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `100%`,
        width: `100%`
    },
    imgContainer: {
        height: `100px`,
        width: `100px`
    }
}));

export function ServiceCenterDetailsList({ serviceCenterId }) {

    const PageErrorMessage = useRef();
    const classes = useStyles();

    const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
    const [serviceCenterDataLoader, setServiceCenterDataLoader] = useState('d-none');
    const [data, setData] = useState([]);
    const [zoom, setZoom] = useState(4);

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorSnackBar(false);
    }

    function handleSnackBarOpen() {
        setOpenErrorSnackBar(true);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async (serviceCenterId) => {

            try {

                setServiceCenterDataLoader('');

                if (serviceCenterId) {

                    const res = getServiceCenter(serviceCenterId).catch((error) => {
                        console.log(error);
                        handleSnackBarOpen();
                        setServiceCenterDataLoader('d-none');
                    });

                    const responseData = (await res).data;

                    isMounted && setData(responseData);
                    isMounted && setServiceCenterDataLoader('d-none');

                    return;

                }

            } catch (error) {

                PageErrorMessage.current = error.message;
                handleSnackBarOpen();
                setServiceCenterDataLoader('d-none');

                return;

            }
        };

        fetchData(serviceCenterId);

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [serviceCenterId]);


    console.log(data);

    const date = new Date(data.document?.createdAt).toUTCString();

    const location = {
        name: data.document?.location.name,
        location: {
            lat: data.document?.location.latitude,
            lng: data.document?.location.longitude,
        }
    };

    const mapStyles = {
        height: "100%",
        width: "100%",
    };

    const defaultCenter = {
        lat: 6.2414088,
        lng: 0.3311344
    };


    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openErrorSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={handleClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: '100%' }}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {PageErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>

            <Box sx={{ p: 2 }} className='text-start'>
                <Box sx={{ py: 2 }}>
                    <Typography variant='h4'>
                        {data.document?.name}
                    </Typography>
                </Box>
                <Divider sx={{ opacity: 0.6 }} />
                <Box sx={{ py: 2 }}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography sx={{ fontWeight: 900 }}> Created at: </Typography>
                        <Typography>
                            {date}
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography sx={{ fontWeight: 900 }}> Description: </Typography>
                        <Typography>
                            {data.document?.serviceDescription}
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography sx={{ fontWeight: 900 }}> Email: </Typography>
                        <Typography>
                            {data.document?.email}
                        </Typography>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography sx={{ fontWeight: 900 }} >Phone Number: </Typography>
                        <Typography>
                            {data.document?.phoneNumber}
                        </Typography>
                    </div>
                </Box>
            </Box>
            <div style={{ width: '100%', height: '500px' }}>
                <LoadScript googleMapsApiKey='AIzaSyCZQXzHdOyxtRg5hg2m06vFeQ12YRroi4M'>
                    <GoogleMap
                        mapContainerStyle={mapStyles}
                        zoom={zoom}
                        center={defaultCenter}
                    >
                        {
                            location ?
                                <Marker
                                    title="Click to zoom"
                                    key={location.location.lat}
                                    position={location.location}
                                    onClick={() => {
                                        setZoom(8);
                                    }}
                                    icon={toAbsoluteUrl("/media/svg/icons/General/location.svg")}
                                /> : ''
                        }
                    </GoogleMap>

                </LoadScript>
            </div>

        </>
    );

}