import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

const DynamicApexChart = ({ categories, data, exportFilename, chartTitle, xAxisName, yAxisName, yAxisMinVal, yAxisMaxVal, chartType, horizontalBar, chartHeight, labelFontSize, labelFontWeight }) => {

    const [options, setOptions] = useState({
        chart: {
            id: "basic-line",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: exportFilename,
                        columnDelimiter: ',',
                        headerCategory: xAxisName,
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        }
                    },
                    svg: {
                        filename: exportFilename,
                    },
                    png: {
                        filename: exportFilename,
                    }
                }
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 1,
            },
            dropShadow: {
                enabled: true,
                color: '#89ff89',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#89ff89', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                textAnchor: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontWeight: 'bold',
                    colors: undefined
                },
            },
            title: {
                text: chartTitle,
                align: "left"
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 2,
                borderRadiusApplication: 'around',
                borderRadiusWhenStacked: 'last',
                isFunnel3d: true,
                colors: {
                    ranges: [{
                        from: 0,
                        to: 0,
                        color: undefined
                    }],
                    backgroundBarColors: [],
                    backgroundBarOpacity: 1,
                    backgroundBarRadius: 0,
                },
                horizontal: horizontalBar ? true : false,
            }
        },
        xaxis: {
            categories: categories,
            name: xAxisName,
            labels: {
                show: true,
                style: {
                    fontSize: labelFontSize ? labelFontSize : "12px",
                    fontWeight: labelFontWeight ? labelFontWeight : "",
                    cssClass: 'apex-x-axis'
                }
            },
            title: {
                text: xAxisName
            }
        },
        yaxis: {
            title: {
                text: yAxisName
            },
            min: yAxisMinVal,
            max: yAxisMaxVal
        },
    });

    const [series, setSeries] = useState(
        [{
            data: data,
            name: yAxisName,
        }]
    );

    useEffect(() => {

        let isMounted = true;

        isMounted && setSeries(
            [{
                data: data,
                name: yAxisName,
            }]
        );
        isMounted && setOptions({
            chart: {
                id: "basic-line",
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                        customIcons: []
                    },
                    export: {
                        csv: {
                            filename: exportFilename,
                            columnDelimiter: ',',
                            headerCategory: xAxisName,
                            headerValue: 'value',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString();
                            }
                        },
                        svg: {
                            filename: exportFilename,
                        },
                        png: {
                            filename: exportFilename,
                        }
                    }
                },
                stroke: {
                    curve: "smooth",
                    show: true,
                    width: 1,
                },
                dropShadow: {
                    enabled: true,
                    color: '#89ff89',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#89ff89', 'transparent'],
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                    textAnchor: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        fontWeight: 'bold',
                        colors: undefined
                    },
                },
                title: {
                    text: chartTitle,
                    align: "left"
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100]
                    },
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    borderRadiusApplication: 'around',
                    borderRadiusWhenStacked: 'last',
                    isFunnel3d: true,
                    colors: {
                        ranges: [{
                            from: 0,
                            to: 0,
                            color: undefined
                        }],
                        backgroundBarColors: [],
                        backgroundBarOpacity: 1,
                        backgroundBarRadius: 0,
                    },
                    horizontal: horizontalBar ? true : false,
                }
            },
            xaxis: {
                categories: categories,
                name: xAxisName,
                labels: {
                    show: true,
                    style: {
                        fontSize: labelFontSize ? labelFontSize : "12px",
                        fontWeight: labelFontWeight ? labelFontWeight : "",
                        cssClass: 'apex-x-axis'
                    }
                },
                title: {
                    text: xAxisName
                }
            },
            yaxis: {
                title: {
                    text: yAxisName
                },
                min: yAxisMinVal,
                max: yAxisMaxVal
            },
        });

        return () => {

            isMounted = false;

            setSeries([]);
            setOptions(null);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, yAxisName]);

    /* const options = {
        chart: {
            id: "basic-line",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: exportFilename,
                        columnDelimiter: ',',
                        headerCategory: xAxisName,
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        }
                    },
                    svg: {
                        filename: exportFilename,
                    },
                    png: {
                        filename: exportFilename,
                    }
                }
            },
            stroke: {
                curve: "smooth",
                show: true,
                width: 1,
            },
            dropShadow: {
                enabled: true,
                color: '#89ff89',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#89ff89', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                textAnchor: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontWeight: 'bold',
                    colors: undefined
                },
            },
            title: {
                text: chartTitle,
                align: "left"
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
        },
        xaxis: {
            categories: categories,
            name: xAxisName,
            labels: {
                show: true,
                style: {
                    fontSize: labelFontSize ? labelFontSize : "12px",
                    fontWeight: labelFontWeight ? labelFontWeight : "",
                    cssClass: 'apex-x-axis'
                }
            },
            title: {
                text: xAxisName
            }
        },
        yaxis: {
            title: {
                text: yAxisName
            },
            min: yAxisMinVal,
            max: yAxisMaxVal
        },
    }; */

    /* const series = [{
        name: yAxisName,
        data: data,
    }]; */

    //return <Chart options={options} series={series} type="bar" width="500" />;

    return (

        <div className='line-chart-area'>
            <Chart
                type={chartType ? chartType : 'bar'}
                options={options}
                series={series}
                height={chartHeight}
            />
        </div>

    );

};

export default DynamicApexChart;