/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@mui/styles';
import {
    IconButton,
    Snackbar,
    Grow,
    Button,
    Alert,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import {
    Nav,
    Tab
} from 'react-bootstrap';
import { CreateEditVehicleCohortForm } from "../forms/CreateEditVehicleCohortForm";
import { SimpleModal } from "../modals/SimpleModal";
import { FLEET_MANAGING_BASE_URL } from "../../../../app/services/baseService";
import axios from "axios";
import BasicLoader from "../../loaders/SimpleLoader";
import { VehicleCohortSizeLimitForm } from "../forms/VehicleCohortSizeLimitForm";
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import { VehicleCohortMetrics } from "./VehicleCohortMetrics";
import { CarbonSummaryCard } from "./CarbonSummaryCard";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`
    }
}));

export function VehicleCohortsWidget({ className }) {

    const classes = useStyles();
    const navigate = useHistory();
    const widgetErrorMessage = useRef();

    const [showCohortCreationModal, setShowCohortCreationModal] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Vehicle Cohort');
    const [reRender, setReRender] = useState(0);

    const [showCohortSizeLimitModal, setShowCohortSizeLimitModal] = useState(false);

    const [vehicleCohortSizeLimit, setVehicleCohortSizeLimit] = useState(0);
    const [isLoadingSizeLimit, setIsLoadingSizeLimit] = useState(false);

    const [isLimitFetchError, setIsLimitFetchError] = useState(false);
    const [isCohortFetchError, setIsCohortFetchError] = useState(false);

    const [vehicleCohorts, setVehicleCohorts] = useState([]);
    const [carbonCohorts, setCarbonCohorts] = useState([]);

    const [isLoadingCohorts, setIsLoadingCohorts] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [severity, setSeverity] = useState('info');

    const [toggleBtnText, setToggleBtnText] = useState("Change to Details View");
    const [toggleDetailView, setToggleDetailView] = useState(false);

    const [key, setKey] = useState("Carbon");

    const [quarterFilterString, setQuarterFilterString] = useState("");
    const [isLoadedQuarterFilterString, setIsLoadedQuarterFilterString] = useState(false);

    const [carbonBikesDeployed, setCarbonBikesDeployed] = useState(0);

    const quarterMonthlyMileageValue = useRef({
        count: 0,
        bikeCount: 0,
        specCount: 0,
    });

    function sortByCreationDate(arr) {
        return arr.sort((a, b) => a.document.creationDate - b.document.creationDate);
    }

    const handleQuarterChange = (event) => {

        setIsLoadedQuarterFilterString(false);

        quarterMonthlyMileageValue.current = ({
            count: 0,
            bikeCount: 0,
            specCount: 0,
        });
        setQuarterFilterString(event.target.value);

    };

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    }

    function triggerSnackBar() {
        setOpenSnackBar(true);
    }

    function randomAnimationDuration() {
        return Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
    }

    useEffect(() => {

        let isMounted = true;

        const getVehicleCohortSizeLimit = async () => {

            setIsLoadingSizeLimit(true);
            setIsLimitFetchError(false);

            const url = new URL('/vehicles/cohorts/size', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                setVehicleCohortSizeLimit(data?.size ?? 0);
                setIsLoadingSizeLimit(false);

            } catch (error) {

                widgetErrorMessage.current = error?.response?.data?.message ? `error loading vehicle cohort size limit: ${error?.response?.data?.message}` : `error loading vehicle cohort size limit: ${error?.message}`;
                setSeverity("error");
                triggerSnackBar();

                setIsLimitFetchError(true);
                setIsLoadingSizeLimit(false);

                return;

            }

        };

        const getVehicleCohorts = async () => {

            setIsLoadingCohorts(true);
            setIsCohortFetchError(false);

            handleSnackbarClose();

            const url = new URL('/vehicles/cohorts/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const regularCohort = [];
                const carbonCohort = [];

                const actData = data.data;
                for (let i = 0; i < actData.length; i++) {

                    if (actData[i]?.document?.isCarbonCohort === true) {
                        carbonCohort.push(actData[i]);
                    } else {
                        regularCohort.push(actData[i]);
                    }

                    if ((i + 1) === actData.length) {

                        sortByCreationDate(carbonCohort);

                        setVehicleCohorts(regularCohort);
                        setCarbonCohorts(carbonCohort);
                        let deployedBikeCount = 0;

                        for (let k = 0; k < carbonCohort.length; k++) {

                            const vehicleCount = carbonCohort[k].document.vehicles.length;
                            deployedBikeCount = deployedBikeCount + vehicleCount;

                            if ((k + 1) === carbonCohort.length) {
                                setCarbonBikesDeployed(deployedBikeCount);
                                setIsLoadingCohorts(false);
                            }

                        }



                    }

                }

            } catch (error) {

                widgetErrorMessage.current = error?.response?.data?.message ? `error loading vehicle cohorts: ${error?.response?.data?.message}` : `error loading vehicle cohorts ${error?.message}`;
                setSeverity("error");
                triggerSnackBar();

                setIsCohortFetchError(true);
                setIsLoadingCohorts(false);

                return;

            }

        };

        isMounted === true && getVehicleCohortSizeLimit();
        isMounted === true && getVehicleCohorts();

        return () => {

            isMounted = false;
            setVehicleCohorts(null);

        };

    }, [reRender]);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openSnackBar}
                autoHideDuration={10000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button
                        key="undo"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}
                    >
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {widgetErrorMessage.current}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create Vehicle Cohort`}
                show={showCohortCreationModal}
                close={() => setShowCohortCreationModal(false)}
                size="xl"
                body={<CreateEditVehicleCohortForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Set Vehicle Cohort Size Limit`}
                show={showCohortSizeLimitModal}
                close={() => setShowCohortSizeLimitModal(false)}
                size="md"
                body={<VehicleCohortSizeLimitForm
                    initialValues={initialValues}
                    onChanger={onChangeWorker}
                />}
            />
            <div className={`card card-custom ${className} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Vehicle Cohorts
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage vehicle cohorts from this window
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {

                                        setCreateEditText("Create Cohort");
                                        setEditState(false);
                                        setInitialValues({
                                            creationDate: "",
                                            name: "",
                                            vehicleType: "",
                                        });

                                        return setShowCohortCreationModal(true);

                                    }}
                                >
                                    Create Vehicle Cohort
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <div className="row m-0">
                        <div className="col bg-light-success px-6 py-3 rounded-xl mb-7">
                            <span className="text-success font-weight-bold font-size-h6">
                                Vehicle Cohort Size Limit:
                            </span>
                            <span className="svg-icon svg-icon-3x svg-icon-success d-block">
                                <span className="text-success font-weight-bold font-size-h2">
                                    {isLoadingSizeLimit && <BasicLoader size={"lg"} />}
                                    {!isLoadingSizeLimit && vehicleCohortSizeLimit}
                                    {isLimitFetchError && (
                                        <>
                                            <ErrorTwoToneIcon color="error" />
                                            {" "}
                                            <span className="text-danger font-size-h6">
                                                {widgetErrorMessage.current}
                                            </span>
                                        </>
                                    )}
                                </span>
                            </span>
                            <div className="row mt-2">
                                <div className="col">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="medium"
                                        fullWidth
                                        onClick={() => {

                                            setInitialValues({
                                                size: vehicleCohortSizeLimit
                                            });

                                            return setShowCohortSizeLimitModal(true);

                                        }}
                                    >
                                        Set New Size Limit
                                    </Button>
                                </div>
                                <div className="col">
                                    <Button
                                        variant="contained"
                                        color="info"
                                        size="medium"
                                        fullWidth
                                        onClick={async () => {
                                            setToggleDetailView(prevState => !prevState);
                                            setToggleBtnText(toggleDetailView ? "Change to Details View" : "Change to Group View");
                                        }}
                                    >
                                        {toggleBtnText}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="centerMan">
                        {isLoadingCohorts && (
                            <>
                                <div className="centerMan">
                                    <BasicLoader size={"lg"} />
                                </div>
                            </>
                        )}
                    </div>
                    {isCohortFetchError && (<>
                        <div className="centerMan">
                            <ErrorTwoToneIcon color="error" fontSize="large" />
                            <div className="text-danger">
                                {widgetErrorMessage.current}
                            </div>
                        </div>
                    </>)}
                    {!toggleDetailView && <div className="row m-0 mt-5">
                        {!isLoadingCohorts && vehicleCohorts.map((item, index) => {

                            return (
                                <Grow
                                    in={!isLoadingCohorts}
                                    style={{ transformOrigin: '0 1 0' }}
                                    {...(!isLoadingCohorts ? { timeout: randomAnimationDuration() } : {})}
                                    key={index}
                                >
                                    <div className="col bg-light-primary px-6 py-8 rounded-xl mr-7 ml-7 mb-7">
                                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block">
                                            <span className="text-primary font-weight-bold font-size-h3">
                                                {item.document.name}
                                            </span>
                                            <br />
                                            <small className="text-primary text-nowrap">
                                                {item.document_id}
                                            </small>
                                        </span>
                                        <span className="text-primary mt-2">
                                            <span className="font-size-h6">
                                                {new Date(item.document.creationDate).toDateString()}
                                            </span>
                                            <br />
                                            <span className="font-size-h6">
                                                {item.document.vehicles.length}
                                                {" Vehicles"}
                                            </span>
                                        </span>
                                        <div className="mt-2 centerMan">
                                            <span
                                                className="font-size-sm cursor-pointer"
                                                onClick={() => {
                                                    navigate.push('/vehicles/cohorts/details', {
                                                        cohortID: item.document_id,
                                                        cohortName: item.document.name,
                                                    });
                                                }}
                                            >
                                                Click for more details
                                                {" "}
                                                <LaunchTwoToneIcon />
                                            </span>
                                        </div>
                                    </div>
                                </Grow>
                            );

                        })}
                    </div>}
                    {toggleDetailView &&
                        (
                            <div>
                                {!isLoadingCohorts && (
                                    <>
                                        <Tab.Container defaultActiveKey={key}>
                                            <Nav
                                                as="ul"
                                                onSelect={(_key) => setKey(_key)}
                                                className="nav nav-pills nav-pills-sm nav-dark-75"
                                            >
                                                <Nav.Item className="nav-item" as="li">
                                                    <Nav.Link
                                                        eventKey="Carbon"
                                                        className={`nav-link py-2 px-4 ${key === "Carbon" ? "active" : ""}`}
                                                    >
                                                        Klik Cohorts
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="nav-item" as="li">
                                                    <Nav.Link
                                                        eventKey="Regular"
                                                        className={`nav-link py-2 px-4 ${key === "Regular" ? "active" : ""}`}
                                                    >
                                                        Regular Cohorts
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>

                                        <div className={`tab-content mt-5 p-3 rounded-xl`} id="myTabTables12">
                                            <div className={`tab-pane fade ${key === "Regular" ? "show active" : ""}`}>
                                                {key === "Regular" && (
                                                    <>
                                                        <Grow
                                                            in={!isLoadingCohorts}
                                                            style={{ transformOrigin: '0 1 0' }}
                                                            {...(!isLoadingCohorts ? { timeout: randomAnimationDuration() } : {})}
                                                        >
                                                            <div className="table-responsive">
                                                                <table className="table table-vertical-center">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="p-1" style={{ width: "100px" }}>#</th>
                                                                            <th className="p-1" style={{ minWidth: "250px" }}>Cohort Name</th>
                                                                            <th className="p-1">Mileage</th>
                                                                            <th className="p-1" style={{ minWidth: "50px" }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {vehicleCohorts.map((cohort, index) => {

                                                                            const cohortID = cohort.document.ID;

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <div className="symbol symbol-80 symbol-light-success mr-2 mt-2">
                                                                                            <span className="symbol-label">
                                                                                                {cohort.document.vehicles.length}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span
                                                                                            className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                                                                                            onClick={() => {
                                                                                                navigate.push('/vehicles/cohorts/details', {
                                                                                                    cohortID: cohortID,
                                                                                                    cohortName: cohort.document.name,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            {cohort.document.name}
                                                                                        </span>
                                                                                        <span className="text-muted font-weight-bold d-block">
                                                                                            {cohortID}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <VehicleCohortMetrics
                                                                                            cohortID={cohortID}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <span
                                                                                            className="btn btn-icon btn-light btn-sm"
                                                                                            onClick={() => {
                                                                                                navigate.push('/vehicles/cohorts/details', {
                                                                                                    cohortID: cohortID,
                                                                                                    cohortName: cohort.document.name,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <span className="svg-icon svg-icon-md svg-icon-success">
                                                                                                <SVG
                                                                                                    src={toAbsoluteUrl(
                                                                                                        "/media/svg/icons/Navigation/Arrow-right.svg"
                                                                                                    )}
                                                                                                ></SVG>
                                                                                            </span>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );

                                                                        })}
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </Grow>
                                                    </>
                                                )}
                                            </div>
                                            <div className={`tab-pane fade ${key === "Carbon" ? "show active" : ""}`}>
                                                {key === "Carbon" && (
                                                    <>

                                                        <div className="mb-5 mt-2 bg-light-primary p-2 rounded-lg">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <FormControl
                                                                        sx={{ m: 1, minWidth: 120 }}
                                                                        size="medium"
                                                                    >
                                                                        <InputLabel id="demo-simple-select-autowidth-label">Filter by Quarter</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-autowidth-label"
                                                                            id="demo-simple-select-autowidth"
                                                                            value={quarterFilterString}
                                                                            onChange={handleQuarterChange}
                                                                            autoWidth
                                                                            label="Age"
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>Select Month for Metrics</em>
                                                                            </MenuItem>
                                                                            <MenuItem value={"q12024"}>Q1 2024</MenuItem>
                                                                            <MenuItem value={"q22024"}>Q2 2024</MenuItem>
                                                                            <MenuItem value={"q32024"}>Q3 2024</MenuItem>
                                                                            <MenuItem value={"q42024"} disabled>Q4 2024</MenuItem>
                                                                        </Select>
                                                                        <FormHelperText className="text-muted font-weight-bold">
                                                                            Select a quarter to filter data
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                </div>
                                                            </div>

                                                            {!isLoadedQuarterFilterString && (<>
                                                                <BasicLoader size={"lg"} />
                                                            </>)}

                                                            {isLoadedQuarterFilterString && (<>
                                                                <CarbonSummaryCard
                                                                    quarterMonthlyMileageValue={quarterMonthlyMileageValue.current.count}
                                                                    bikeCount={quarterMonthlyMileageValue.current.bikeCount}
                                                                    specCount={quarterMonthlyMileageValue.current.specCount}
                                                                />
                                                            </>)}

                                                        </div>

                                                        <Grow
                                                            in={!isLoadingCohorts}
                                                            style={{ transformOrigin: '0 1 0' }}
                                                            {...(!isLoadingCohorts ? { timeout: randomAnimationDuration() } : {})}
                                                        >
                                                            <div className="table-responsive">
                                                                <table className="table table-vertical-center">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="p-1" style={{ width: "100px" }}>#</th>
                                                                            <th className="p-1" style={{ minWidth: "250px" }}>Cohort Name</th>
                                                                            <th className="p-1">Mileage(km)</th>
                                                                            <th className="p-1" style={{ minWidth: "50px" }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {carbonCohorts.map((cohort, index) => {

                                                                            const cohortID = cohort.document.ID;

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <div className="symbol symbol-80 symbol-light-success mr-2 mt-2">
                                                                                            <span className="symbol-label">
                                                                                                {cohort.document.vehicles.length}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span
                                                                                            className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                                                                                            onClick={() => {
                                                                                                navigate.push('/vehicles/cohorts/details', {
                                                                                                    cohortID: cohortID,
                                                                                                    cohortName: cohort.document.name,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            {cohort.document.name}
                                                                                        </span>
                                                                                        <span className="text-muted font-weight-bold d-block">
                                                                                            {cohortID}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <VehicleCohortMetrics
                                                                                            cohortID={cohortID}
                                                                                            specQuarterFilterString={quarterFilterString}
                                                                                            metricsLoaderIndicator={(data, bikeCount, quarterName, specCount) => {

                                                                                                if (!specCount)
                                                                                                    specCount = 0;

                                                                                                let specQuarterMonthlyMileageValue = quarterMonthlyMileageValue.current.count + data;
                                                                                                quarterMonthlyMileageValue.current = ({
                                                                                                    count: specQuarterMonthlyMileageValue,
                                                                                                    bikeCount: bikeCount,
                                                                                                    specCount: specCount,
                                                                                                });

                                                                                                setTimeout(() => {
                                                                                                    setIsLoadedQuarterFilterString(true);
                                                                                                }, 3000);

                                                                                            }}
                                                                                            totalVehicleCount={carbonBikesDeployed}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <span
                                                                                            className="btn btn-icon btn-light btn-sm"
                                                                                            onClick={() => {
                                                                                                navigate.push('/vehicles/cohorts/details', {
                                                                                                    cohortID: cohortID,
                                                                                                    cohortName: cohort.document.name,
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <span className="svg-icon svg-icon-md svg-icon-success">
                                                                                                <SVG
                                                                                                    src={toAbsoluteUrl(
                                                                                                        "/media/svg/icons/Navigation/Arrow-right.svg"
                                                                                                    )}
                                                                                                ></SVG>
                                                                                            </span>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );

                                                                        })}
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </Grow>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    }
                    {/* <div className="row m-0">
                        <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                                ></SVG>
                            </span>
                            <a
                                href="#"
                                className="text-danger font-weight-bold font-size-h6 mt-2"
                            >
                                Item Orders
                            </a>
                        </div>
                        <div className="col bg-light-success px-6 py-8 rounded-xl">
                            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Urgent-mail.svg"
                                    )}
                                ></SVG>
                            </span>
                            <a
                                href="#"
                                className="text-success font-weight-bold font-size-h6 mt-2"
                            >
                                Bug Reports
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
