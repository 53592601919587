import { useFormik } from "formik";
import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { updateDriverPhoneNumber } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function UpdateUserPhoneForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, selectedDriverID }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    // eslint-disable-next-line no-useless-escape
    const phoneRegExp = /^\+?[1-9]\d{1,14}$/;

    const creationSchema = Yup.object().shape({
        phone: Yup.string().matches(phoneRegExp, 'phone number is not valid!').required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                updateDriverPhoneNumber(values, selectedDriverID).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(response.data.message);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });
                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);
                    return onChanger();
                });

            }, 1000);
        },
    });

    useEffect(() => {

        return () => {
            setAlertType(null);
        };

    }, []
    );

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="driverFormPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder="Phone Number" value={formik.values.phone} onChange={formik.handleChange} className={`${getInputClasses("phone")}`} name="phone" {...formik.getFieldProps("phone")} />
                    <Form.Text className="text-muted">
                        Provide driver phone number
                    </Form.Text>
                    <Form.Control.Feedback>The phone Number looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Phone number field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.phone && formik.errors.phone ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.phone}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );
}