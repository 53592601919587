import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import {
    FormattedMessage,
    injectIntl
} from "react-intl";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { FormAlert } from '../../../../_wahu/_partials/controls';
import {
    Container,
    CssBaseline,
    Snackbar,
    IconButton,
    Alert,
    Button,
} from "@mui/material";
import OtpInput from "react-otp-input";
import { makeStyles } from '@mui/styles';
import { getGuestByToken, getUserByToken, signin, updateUserNotificationToken } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import {
    authentication,
    googleAuthProvider,
    messaging
} from "../firebase";
import {
    RecaptchaVerifier,
    signInWithPopup,
    onAuthStateChanged,
} from "firebase/auth";
import BasicLoader from "../../../../_wahu/_partials/loaders/SimpleLoader";
import { getToken } from "firebase/messaging";
import CloseIcon from '@mui/icons-material/Close';
import { allowedRoleList } from "../../../../_wahu/_helpers/Interfaces";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const useStyles = makeStyles(theme => ({
    grid: {
        height: "10px",
        textAlign: "center"
    },
    paper: {
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}));

const initialValues = {
    phoneNumber: ""
};

function Login(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const ComponentErrorMessage = useRef();

    const [otp, setOtp] = useState('');
    const [isOtpInputActive, setIsOtpInputActive] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [showAlert, setShowAlert] = useState(true);
    const [googleSignInToggled, setGoogleSignInToggled] = useState(false);
    const [googleSignInLoading, setGoogleSignInLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('info');

    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        phoneNumber: Yup.string().required("phone number is required to proceed")
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const generateRecaptcha = () => {

        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        }, authentication);

    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            setShowAlert(false);
            setGoogleSignInToggled(true);
            enableLoading();

            setOtp('');
            setIsErrored(false);
            setSubmitting(true);
            setIsOtpInputActive(false);

            setTimeout(() => {

                signin(values.phoneNumber, window.recaptchaVerifier).then((response) => {

                    if (response) {

                        disableLoading();

                        setAlertType({
                            type: 'warning',
                            icon: <WarningTwoToneIcon />
                        });

                        setStatus(
                            response.code
                            ??
                            response.message
                            ??
                            intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_LOGIN",
                            })
                        );

                        setShowAlert(true);
                        setSubmitting(false);
                        setGoogleSignInToggled(false);

                        return dispatch(props.logout());

                    }

                    disableLoading();
                    setGoogleSignInToggled(false);
                    return setIsOtpInputActive(true);

                }).catch((error) => {

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });

                    setStatus(
                        error
                        ??
                        error.response?.message
                        ??
                        intl.formatMessage({
                            id: "AUTH.VALIDATION.INVALID_LOGIN",
                        })
                    );

                    disableLoading();
                    setShowAlert(true);
                    setSubmitting(false);
                    setGoogleSignInToggled(false);

                    return dispatch(props.logout());

                });

            }, 1000);
        },
    });

    const handleOtpChange = (otpCode) => {

        setOtp(otpCode);
        if (otpCode.length === 6) {

            enableLoading();

            setIsErrored(false);
            setShowAlert(false);

            let confirmationResult = window.confirmationResult;
            return confirmationResult.confirm(otpCode).then((otpResponse) => {
                setGoogleSignInToggled(false);
            }).catch((error) => {

                setAlertType({
                    type: 'danger',
                    icon: <WarningTwoToneIcon />
                });

                setIsErrored(true);
                setShowAlert(true);

                formik.setStatus(
                    error.code
                    ??
                    error.message
                    ??
                    intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN",
                    })
                );

                return disableLoading();

            });

        }

    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function generateRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        if (!window.recaptchaVerifier) {

            try {
                generateRecaptcha();
            } catch (error) {

                setAlertType({
                    type: 'warning',
                    icon: <WarningTwoToneIcon />
                });

                formik.setStatus("recaptcha verifier container has been destroyed, please wait while we repair the container");
                generateRecaptcha();
                setShowAlert(true);

                return disableLoading();

            }

        }

        if (window.recaptchaVerifier) {

            const isRecaptchaDestroyed = window.recaptchaVerifier.destroyed;
            if (isRecaptchaDestroyed === true) {

                enableLoading();

                setAlertType({
                    type: 'warning',
                    icon: <WarningTwoToneIcon />
                });

                formik.setStatus("recaptcha verifier container has been destroyed, please wait while we repair the container");
                generateRecaptcha();

                setShowAlert(false);
                return disableLoading();

            }

        }

        isMounted && onAuthStateChanged(authentication, async (user) => {

            if (user) {

                const userID = user.uid;
                await user.getIdToken().then(async (idToken) => {

                    return await getUserByToken(controller.signal, userID, idToken).then(async (response) => {

                        const { data: userAlt } = response;
                        dispatch(props.login(user));
                        dispatch(props.fulfillUser(userAlt));

                        await getToken(messaging, { vapidKey: process.env.REACT_APP_PUSH_MESSAGING_KEY }).then(async (currentToken) => {

                            if (currentToken) {

                                await updateUserNotificationToken(user.uid, currentToken, controller.signal).then(async () => {

                                    await Notification.requestPermission().catch((error) => {

                                        setSeverity("error");
                                        ComponentErrorMessage.current = error.message;
                                        setOpenSnackbar(true);

                                        dispatch(props.login(user));
                                        return dispatch(props.fulfillUser(userAlt));

                                    });

                                }).catch(async (error) => {

                                    setSeverity("error");
                                    ComponentErrorMessage.current = error.message;
                                    setOpenSnackbar(true);

                                    dispatch(props.login(user));
                                    return dispatch(props.fulfillUser(userAlt));

                                });

                            } else {

                                setSeverity("error");
                                ComponentErrorMessage.current = "No registration token available. Request permission to generate one.";
                                setOpenSnackbar(true);

                                dispatch(props.login(user));
                                return dispatch(props.fulfillUser(userAlt));

                            }

                        }).catch((error) => {

                            setSeverity("error");
                            ComponentErrorMessage.current = error.message;
                            setOpenSnackbar(true);

                            return dispatch(props.fulfillUser(userAlt));

                        });

                    }).catch(async (error) => {

                        if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                            setAlertType({
                                type: 'danger',
                                icon: <WarningTwoToneIcon />
                            });

                            formik.setStatus("network error, please check your internet connection");
                            formik.setSubmitting(false);

                            disableLoading();
                            setShowAlert(true);

                            setGoogleSignInToggled(false);
                            setGoogleSignInLoading(false);

                            return dispatch(props.logout());

                        }

                        if (error?.response?.status === 401) {

                            setAlertType({
                                type: 'danger',
                                icon: <WarningTwoToneIcon />
                            });

                            formik.setStatus("your access token has expired, please login again to continue your session");
                            formik.setSubmitting(false);

                            setShowAlert(true);
                            disableLoading();

                            setGoogleSignInToggled(false);
                            setGoogleSignInLoading(false);

                            return dispatch(props.logout());

                        }

                        if (error?.response?.status === 403) {

                            let allowedRoleCounter = 0;
                            return await getGuestByToken(controller.signal).then(async (response) => {

                                const { data: userAlt } = response;

                                dispatch(props.login(user));
                                dispatch(props.fulfillUser(userAlt));

                                const userRoleArray = userAlt.user_data.auth.role;
                                for (let i = 0; i < userRoleArray.length; i++) {

                                    const specRole = userRoleArray[i];
                                    if (allowedRoleList.includes(specRole))
                                        allowedRoleCounter = allowedRoleCounter + 1;

                                    if ((i + 1) === userRoleArray.length) {

                                        if (allowedRoleCounter > 1) {

                                            setAlertType({
                                                type: 'danger',
                                                icon: <WarningTwoToneIcon />
                                            });

                                            formik.setStatus("you do not have enough permission to access to proceed");
                                            formik.setSubmitting(false);

                                            setShowAlert(true);
                                            disableLoading();

                                            setGoogleSignInToggled(false);
                                            setGoogleSignInLoading(false);

                                            return dispatch(props.logout());

                                        }

                                    }

                                }

                                await getToken(messaging, { vapidKey: process.env.REACT_APP_PUSH_MESSAGING_KEY }).then(async (currentToken) => {

                                    if (currentToken) {

                                        await updateUserNotificationToken(user.uid, currentToken, controller.signal).then(async () => {

                                            await Notification.requestPermission().then(() => {

                                                dispatch(props.login(user));
                                                return dispatch(props.fulfillUser(userAlt));

                                            }).catch((error) => {

                                                setSeverity("error");
                                                ComponentErrorMessage.current = error.message;
                                                setOpenSnackbar(true);

                                                dispatch(props.login(user));
                                                return dispatch(props.fulfillUser(userAlt));

                                            });

                                        }).catch(async (error) => {

                                            setSeverity("error");
                                            ComponentErrorMessage.current = error.message;
                                            setOpenSnackbar(true);

                                            dispatch(props.login(user));
                                            return dispatch(props.fulfillUser(userAlt));

                                        });

                                    } else {

                                        setSeverity("error");
                                        ComponentErrorMessage.current = "No registration token available. Request permission to generate one.";
                                        setOpenSnackbar(true);

                                        dispatch(props.login(user));
                                        return dispatch(props.fulfillUser(userAlt));

                                    }

                                }).catch((error) => {

                                    setSeverity("error");
                                    ComponentErrorMessage.current = error.message;
                                    setOpenSnackbar(true);

                                    return dispatch(props.fulfillUser(userAlt));

                                });

                            }).catch(async (error) => {

                                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                                    setAlertType({
                                        type: 'danger',
                                        icon: <WarningTwoToneIcon />
                                    });

                                    formik.setStatus("network error, please check your internet connection");
                                    formik.setSubmitting(false);

                                    setShowAlert(true);
                                    disableLoading();

                                    setGoogleSignInToggled(false);
                                    setGoogleSignInLoading(false);

                                    return dispatch(props.logout());

                                }

                                if (error?.response?.status === 401) {

                                    setAlertType({
                                        type: 'danger',
                                        icon: <WarningTwoToneIcon />
                                    });

                                    formik.setStatus("your access token has expired, please login again to continue your session");
                                    formik.setSubmitting(false);

                                    setShowAlert(true);
                                    disableLoading();

                                    setGoogleSignInToggled(false);
                                    setGoogleSignInLoading(false);

                                    return dispatch(props.logout());

                                }

                                setAlertType({
                                    type: 'danger',
                                    icon: <WarningTwoToneIcon />
                                });

                                formik.setStatus(
                                    error?.response?.data?.message
                                    ??
                                    error?.message
                                    ??
                                    error?.code
                                );
                                formik.setSubmitting(false);

                                setShowAlert(true);
                                disableLoading();

                                setGoogleSignInToggled(false);
                                setGoogleSignInLoading(false);

                                return dispatch(props.logout());

                            });

                        }

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });

                        formik.setStatus(
                            error?.response?.data?.message
                            ??
                            error?.message
                            ??
                            error?.code
                        );
                        formik.setSubmitting(false);

                        setShowAlert(true);
                        disableLoading();

                        setGoogleSignInToggled(false);
                        setGoogleSignInLoading(false);

                        return dispatch(props.logout());

                    });

                }).catch((error) => {

                    setSeverity("error");
                    ComponentErrorMessage.current = error.message;
                    setOpenSnackbar(true);

                    return dispatch(props.logout());

                });

            }

        });

        return () => {

            isMounted = false;
            controller.abort();

            setAlertType(null);

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {ComponentErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <div className="login-form login-signin" id="kt_login_signin_form">
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        <FormattedMessage id="AUTH.LOGIN.TITLE" />
                    </h3>
                    <p className="font-weight-bold mana-font-gold">
                        Login to begin your session
                    </p>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    {
                        formik.status ?
                            <FormAlert isEnabled={showAlert} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={() => { setShowAlert(false); }} />
                            :
                            null
                    }
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Phone Number"
                            type="tel"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("phoneNumber")}`}
                            name="phoneNumber"
                            {...formik.getFieldProps("phoneNumber")}
                            required
                            disabled={googleSignInToggled}
                            id="phoneNumber"
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.phoneNumber}
                                </div>
                            </div>
                        ) : (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span className="text-muted">
                                        Enter phone number with country code
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="centerMan">
                        <div className={isOtpInputActive ? "form-group fv-plugins-icon-container mt-3" : "d-none"}>
                            <Container component="main" maxWidth="sm">
                                <CssBaseline />
                                <div className={classes.paper}>
                                    <OtpInput
                                        renderSeparator={
                                            <span>
                                                <strong>.</strong>
                                            </span>
                                        }
                                        value={otp}
                                        onChange={handleOtpChange}
                                        numInputs={6}
                                        inputStyle={{
                                            width: "2.5rem",
                                            height: "2.5rem",
                                            margin: "0 0.5rem",
                                            fontSize: "1.5rem",
                                            borderRadius: 4,
                                            border: "1px solid rgba(0,0,0,0.3)",
                                            color: "#000"
                                        }}
                                        renderInput={(props) => <input {...props} id={generateRandomNumber(1, 1000)} />}
                                        shouldAutoFocus={true}
                                    />
                                    <small className={isErrored ? "font-red" : "d-none font-red"}>
                                        check and enter the correct OTP code
                                    </small>
                                </div>
                            </Container>
                        </div>
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
                        <button id="kt_login_signin_submit" type="submit" disabled={formik.isSubmitting} className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
                            <span id="signageText">Log In</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                    <div className="pb-lg-0 pb-5 centerMan">
                        <button
                            type="button"
                            className="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                            onClick={async () => {

                                formik.setSubmitting(true);
                                setGoogleSignInToggled(true);
                                setGoogleSignInLoading(true);

                                await signInWithPopup(authentication, googleAuthProvider).then(async (result) => {

                                    /* const credential = GoogleAuthProvider.credentialFromResult(result);
                                    const token = credential.accessToken;
                                    const user = result.user; */

                                    return;

                                }).catch((error) => {

                                    const errorCode = error.code;
                                    const errorMessage = error.message;

                                    setAlertType({
                                        type: 'danger',
                                        icon: <WarningTwoToneIcon />
                                    });

                                    setGoogleSignInToggled(false);
                                    setGoogleSignInLoading(false);
                                    formik.setSubmitting(false);

                                    formik.setStatus(`code: ${errorCode}, message: ${errorMessage}`);
                                    return setShowAlert(true);

                                });

                            }}
                            disabled={googleSignInToggled}
                        >
                            <span className="svg-icon svg-icon-md">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z" fill="#4285F4" />
                                    <path d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z" fill="#34A853" />
                                    <path d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z" fill="#FBBC05" />
                                    <path d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z" fill="#EB4335" />
                                </svg>
                            </span>
                            Sign in with Google
                            {" "}
                            {googleSignInLoading && <BasicLoader
                                size={"sm"}
                            />}
                        </button>
                    </div>
                </form>
            </div>

        </>

    );
};

export default injectIntl(connect(null, auth.actions)(Login));
