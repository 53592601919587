import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { sendCustomEmailForBikeInterestLog } from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls/';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function SendCustomEmailForBikeInterestForm({ initialValues, createEditText, onChanger, contactUsRequestID, contactUsRequestData }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        bikeInterestLogID: Yup.string().required("bike interest ID request ID not attached to payload"),
        subject: Yup.string().required("subject filed not attached to payload"),
        message: Yup.string().required("message filed not attached to payload"),
        senderID: Yup.string().required("sender ID not attached to payload"),
        senderFirstName: Yup.string().required("sender first name not attached to payload"),
        senderLastName: Yup.string().required("sender last name not attached to payload"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                sendCustomEmailForBikeInterestLog(values).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(response.data.message);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });

                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {

                    disableLoading();
                    setSubmitting(false);
                    return onChanger();

                });

            }, 1000);

        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="bikeInterestLogEmailResponseFormID">
                    <Form.Label>Request ID</Form.Label>
                    <Form.Control type="text" readOnly={true} value={formik.values.bikeInterestLogID} onChange={formik.handleChange} className={`${getInputClasses("bikeInterestLogID")}`} name="bikeInterestLogID" {...formik.getFieldProps("bikeInterestLogID")} />
                    <Form.Text className="text-muted">
                        eBike Interest Request ID
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        eBike Interest Request ID cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.bikeInterestLogID && formik.errors.bikeInterestLogID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.bikeInterestLogID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="bikeInterestLogEmailResponseFormSubject">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" placeholder="Email Subject" value={formik.values.subject} onChange={formik.handleChange} className={`${getInputClasses("subject")}`} name="subject" {...formik.getFieldProps("subject")} />
                    <Form.Text className="text-muted">
                        Email Subject
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Subject cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.subject && formik.errors.subject ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.subject}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="bikeInterestLogEmailResponseFormMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control type="textarea" rows={5} placeholder="Email Message" value={formik.values.message} onChange={formik.handleChange} className={`${getInputClasses("message")}`} name="message" {...formik.getFieldProps("message")} />
                    <Form.Text className="text-muted">
                        Email Message
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Message cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.message && formik.errors.message ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.message}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="bikeInterestLogEmailResponseFormSenderID">
                    <Form.Label>Sender ID</Form.Label>
                    <Form.Control type="text" readOnly={true} value={formik.values.senderID} onChange={formik.handleChange} className={`${getInputClasses("senderID")}`} name="senderID" {...formik.getFieldProps("senderID")} />
                    <Form.Text className="text-muted">
                        Email Sender ID
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Sender ID cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.senderID && formik.errors.senderID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.senderID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="bikeInterestLogEmailResponseFormSenderFirstName">
                    <Form.Label>Sender First Name</Form.Label>
                    <Form.Control type="text" readOnly={true} value={formik.values.senderFirstName} onChange={formik.handleChange} className={`${getInputClasses("senderFirstName")}`} name="senderFirstName" {...formik.getFieldProps("senderFirstName")} />
                    <Form.Text className="text-muted">
                        Email Sender First Name
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Sender First Name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.senderFirstName && formik.errors.senderFirstName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.senderFirstName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="bikeInterestLogEmailResponseFormSenderLastName">
                    <Form.Label>Sender Last Name</Form.Label>
                    <Form.Control type="text" readOnly={true} value={formik.values.senderLastName} onChange={formik.handleChange} className={`${getInputClasses("senderLastName")}`} name="senderLastName" {...formik.getFieldProps("senderLastName")} />
                    <Form.Text className="text-muted">
                        Email Sender Last Name
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Sender Last Name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.senderLastName && formik.errors.senderLastName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.senderLastName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}