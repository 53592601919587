import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { updateLabourUnit } from "../../../../app/modules/Auth/_redux/authCrud";
import axios from "axios";
import { FLEET_MANAGING_BASE_URL } from "../../../../app/services/baseService";
import { useFormik } from "formik";
import { Button, Form, FormGroup } from "react-bootstrap";
import { FormAlert } from "../../controls";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function UpdateLabourUnitsForm({ initialValues, onChanger, faultConfigID }) {
    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [labourUnitArray, setLabourUnitArray] = useState([]);


    const creationSchema = Yup.object().shape({
        labourUnits: Yup.number().required(),
        labourUnitConfigID: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    useEffect(() => {

        let isMounted = true;

        const fetchLabourUnitsData = async () => {

            const url = new URL('/fault-labour-unit-configurations', `${FLEET_MANAGING_BASE_URL}`);

            try {
                const response = await axios.get(url.href);
                const data = response.data;

                isMounted && setLabourUnitArray(data.data);


            } catch (error) {

                console.log(error);
            }

        };

        fetchLabourUnitsData();

        return () => {
            isMounted = false;
        };
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            console.log(values);
            enableLoading();
            setTimeout(() => {

                updateLabourUnit(faultConfigID, values).then((response) => {
                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(response.data.message);
                    }
                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });

                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );
                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);

                    return;
                });
            }, 3000);

        }
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <FormGroup controlId="labourUnitsConfig">
                    <Form.Label>Select Level</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.labourUnitConfigID} onChange={formik.handleChange} className={`${getInputClasses("labourUnitConfigID")}`} name="labourUnitConfigID" {...formik.getFieldProps("labourUnitConfigID")} >
                        <option disabled value="">Select one</option>
                        {
                            labourUnitArray.map((item) => {
                                return (
                                    <option key={item.document.ID} value={item.document.ID}>
                                        {item.document.title}
                                    </option>
                                );
                            })
                        }
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Choose job level
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Level field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.labourUnitConfigID && formik.errors.labourUnitConfigID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.labourUnitConfigID}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId="labourUnits">
                    <Form.Label>
                        Number of Labour Units
                    </Form.Label>
                    <Form.Control type="number" placeholder="2" value={formik.values.labourUnits} onChange={formik.handleChange} className={`${getInputClasses("labourUnits")}`} name="labourUnits" {...formik.getFieldProps("labourUnits")} />
                    <Form.Text className="text-muted">
                        Number of Labour Units
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Labour Units cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.labourUnits && formik.errors.labourUnits ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.labourUnits}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    Update Labour Units
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>


            </Form >
        </>
    );
}