import React from 'react';
import { useSubheader } from '../../_wahu/layout';
import { FaultCategoriesTable } from '../../_wahu/_partials/widgets/advance-tables/FaultCategoriesTable';

export const FaultCategoriesPage = () => {

    const subheader = useSubheader();

    React.useEffect(() => {

        const load = async () => {
            try {
                subheader.setTitle("Fault Category Table");
            } catch (error) {
                console.log(error);
            }
        };

        load();

    }, [subheader]);
    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <FaultCategoriesTable className="card-stretch gutter-b" />
                </div>
            </div>
        </>
    );

};
