import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { createPartRecord, editPartDetails, removePartImage } from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls';
import {
    IconButton,
    Snackbar,
    Button as MuiButton,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Card,
    CardHeader,
    Avatar,
    Alert,
    Grid,
    styled,
    Paper,
    Box,
    Collapse
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { removeStorageItem } from "../../../../app/services/baseService";
import { SimpleModal } from "../modals/SimpleModal";
import { RemoveRecordConfirmForm } from "./RemoveRecordConfirmForm";
import BasicLoader from "../../loaders/SimpleLoader";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    separator: {
        borderColor: theme.palette.success.light,
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    confirmationWindow: {
        borderColor: theme.palette.success.light,
        borderWidth: '1px',
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function getSmsSteps() {
    return [
        'Select Part Images',
        'Submit Part Details'
    ];
}

export function CreateEditPartForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, partId, initialImagesArray }) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(false);
    const [partImageFiles, setPartImageFiles] = useState(null);

    const [imageArray, setImageArray] = useState([]);
    const [reRender, setReRender] = useState(0);

    const [activeSmsStep, setActiveSmsStep] = useState(0);
    const [showDeleteConfirmationForm, setShowDeleteConfirmationForm] = useState(false);

    const [imageIndexToRemove, setImageIndexToRemove] = useState(null);
    const [imageUrlToRemove, setImageUrlToRemove] = useState(null);
    const [imageObjectFileName, setImageObjectFileName] = useState(null);

    const [isEditStateSet, setIsEditStateSet] = useState(false);
    const globalErrorText = useRef();

    const smsSteps = getSmsSteps();

    const creationSchema = Yup.object().shape({
        vehicleBatchNumber: Yup.number().required(),
        partName: Yup.string().required(),
        partCount: Yup.number().required(),
        partMinimumCount: Yup.number().required(),
        purchaseLocation: Yup.string().required(),
        partPrice: Yup.string().required(),
        partSKU: Yup.string().required(),
    });

    const ImageDeletionConfirmation = ({ rowData, indexToRemove, imageToRemove, imageObjectFileName }) => {

        return (
            <>
                <div className="p-5 mt-5">

                    <Box
                        sx={(theme) => ({
                            border: 1,
                            borderColor: '#FFF3F3',
                            borderRadius: '0.35em',
                            padding: '20px',
                        })}
                    >

                        <div className="form-group row">
                            <div className="col-lg-12 col-xl-12">
                                <div className="centerMan">
                                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                                    <h6>Are you sure you want to delete this image?</h6>
                                    <small className="text-danger font-weight-bold">
                                        This image is a live image for the part selected, deleting it will remove it as a part image
                                    </small>
                                    <div className="centerMan">
                                        <div
                                            className="image-input image-input-outline"
                                            id="kt_profile_avatar"
                                        >
                                            <div
                                                className="image-input-wrapper"
                                                style={{ backgroundImage: `url(${imageToRemove})` }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Item>
                                    <MuiButton
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                        onClick={() => {
                                            setShowDeleteConfirmationForm(false);
                                        }}
                                    >
                                        No
                                    </MuiButton>
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item>
                                    <MuiButton
                                        variant="contained"
                                        color="error"
                                        disabled={loading}
                                        onClick={async () => {

                                            enableLoading();
                                            if (indexToRemove > -1) {

                                                const requestPayload = {
                                                    partID: partId,
                                                    imageFileName: imageObjectFileName,
                                                };

                                                return await removePartImage(requestPayload).then((response) => {

                                                    if (response) {

                                                        initialImagesArray.splice(indexToRemove, 1);
                                                        imageArray.splice(indexToRemove, 1);
                                                        setImageIndexToRemove(null);

                                                        setAlertType({
                                                            type: 'success',
                                                            icon: <CheckCircleTwoToneIcon />
                                                        });
                                                        globalErrorText.current = response.data.message;
                                                        setFormAlertEnabled(true);

                                                        setTimeout(() => {
                                                            setShowDeleteConfirmationForm(false);
                                                            setFormAlertEnabled(false);
                                                            disableLoading();
                                                        }, 2000);

                                                        return;

                                                    }

                                                }).catch((error) => {

                                                    setAlertType({
                                                        type: 'danger',
                                                        icon: <WarningTwoToneIcon />
                                                    });
                                                    globalErrorText.current = error.response.data.message;
                                                    setFormAlertEnabled(true);
                                                    disableLoading();

                                                    return;

                                                });

                                            }

                                            setAlertType({
                                                type: 'danger',
                                                icon: <WarningTwoToneIcon />
                                            });
                                            globalErrorText.current = "invalid image selection index";
                                            setFormAlertEnabled(true);
                                            disableLoading();

                                            return;

                                        }}
                                    >
                                        Yes
                                        {" "}
                                        {loading && <BasicLoader size={"sm"} />}
                                    </MuiButton>
                                </Item>
                            </Grid>
                        </Grid>

                        <div className="mt-4">
                            <FormAlert
                                isEnabled={isFormAlertEnabled}
                                severity={alertType.type}
                                message={globalErrorText.current}
                                icon={alertType.icon}
                                handleDismiss={handleAlertDismiss}
                            />
                        </div>

                    </Box>

                </div>
            </>
        );

    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    if (partImageFiles)
                        values.partImages = partImageFiles;

                    createPartRecord(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });

                            handleSmsNext();
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });

                        onChanger();
                        return setStatus(
                            error?.response?.data?.error?.code
                            ??
                            error?.response?.data?.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return;
                    });

                    return;

                }

                const editPayload = {
                    vehicleBatchNumber: values.vehicleBatchNumber,
                    partName: values.partName,
                    partCount: values.partCount,
                    partMinimumCount: values.partMinimumCount,
                    purchaseLocation: values.purchaseLocation,
                    partPrice: values.partPrice,
                    partSKU: values.partSKU,
                };

                if (partImageFiles)
                    editPayload.partImages = partImageFiles;

                editPartDetails(editPayload, partId).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(response.data.message);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });

                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);
                    return;
                });

            }, 1000);
        },
    });

    const handleNewProfileImage = (event) => {

        if (!initialImagesArray || initialImagesArray === undefined)
            initialImagesArray = [];

        if (initialImagesArray && initialImagesArray.length && initialImagesArray.length <= 0)
            initialImagesArray = [];

        let files = event.target.files;
        setPartImageFiles(files);

        const partImageArray = [];
        partImageArray.push(...imageArray);

        if (files) {

            for (let i = 0; i < files.length; i++) {

                const fileObject = files[i];
                const file = URL.createObjectURL(fileObject);
                partImageArray.push(file);
                initialImagesArray.push(file);

                if ((i + 1) === files.length) {
                    setImageArray(partImageArray);
                }

            }

        }

    };

    function handleSmsNext() {

        setActiveSmsStep(prevActiveStep => prevActiveStep + 1);
        if (activeSmsStep === (smsSteps.length - 1)) {
            setReRender(prevState => prevState + 1);
            onChanger();
        }

    }

    function handleSmsBack() {
        setActiveSmsStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleSmsReset() {
        formik.resetForm();
        setActiveSmsStep(0);
        setImageArray([]);
    }

    useEffect(() => {

        let isMounted = true;

        if (initialImagesArray && initialImagesArray.length && initialImagesArray.length > 0) {

            if (isEditStateSet === false) {

                const initialImagesArrayAlt = [];
                for (let i = 0; i < initialImagesArray.length; i++) {

                    initialImagesArrayAlt.push(initialImagesArray[i].imageUrl);

                    if ((1 + i) === initialImagesArray.length) {
                        isMounted && setImageArray(initialImagesArrayAlt);
                    }

                }

            }

        }

        return () => isMounted = false;

    }, [initialImagesArray, reRender, isEditStateSet]);

    function getSmsStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                {
                    removeStorageItem("selectedRows");
                    return (
                        <>
                            <div className="form-group row">
                                <div className="col-lg-12 col-xl-12">
                                    {(!imageArray || imageArray.length <= 0) && (
                                        <>
                                            <div className="centerMan">
                                                <div
                                                    className="image-input image-input-outline"
                                                    id="kt_profile_avatar"
                                                >
                                                    <div
                                                        className="image-input-wrapper"
                                                        style={{ backgroundImage: "none" }}
                                                    />
                                                    <label
                                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                        data-action="change"
                                                        data-toggle="tooltip"
                                                        title=""
                                                        data-original-title="Change avatar"
                                                    >
                                                        <i className="fa fa-pen icon-sm text-muted"></i>
                                                        <input
                                                            type="file"
                                                            name="pic"
                                                            accept="image/*"
                                                            multiple
                                                            onChange={e => handleNewProfileImage(e)}
                                                        />
                                                        <input type="hidden" name="profile_avatar_remove" />
                                                    </label>
                                                    <span
                                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                        data-action="cancel"
                                                        data-toggle="tooltip"
                                                        title=""
                                                        data-original-title="Cancel avatar"
                                                    >
                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                    </span>
                                                    <span
                                                        onClick={() => {
                                                            setImageArray([]);
                                                        }}
                                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                        data-action="remove"
                                                        data-toggle="tooltip"
                                                        title=""
                                                        data-original-title="Remove avatar"
                                                    >
                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="row m-0">
                                        {imageArray && imageArray.length > 0 &&
                                            (
                                                <>
                                                    <div className="row">

                                                        {imageArray.map((image, index) =>
                                                            <div className='col p-2' key={index}>
                                                                <div className="image-input image-input-outline" id="kt_profile_avatar">
                                                                    <div className="image-input-wrapper" style={{ backgroundImage: `url(${image})` }} />
                                                                    <label
                                                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                        data-action="change"
                                                                        data-toggle="tooltip"
                                                                        title=""
                                                                        data-original-title="Change avatar"
                                                                    >
                                                                        <i className="fa fa-pen icon-sm text-muted"></i>
                                                                        <input
                                                                            type="file"
                                                                            name="pic"
                                                                            accept="image/*"
                                                                            multiple
                                                                            onChange={e => handleNewProfileImage(e)}
                                                                        />
                                                                        <input type="hidden" name="profile_avatar_remove" />
                                                                    </label>
                                                                    <span
                                                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                        data-action="cancel"
                                                                        data-toggle="tooltip"
                                                                        title=""
                                                                        data-original-title="Cancel avatar"
                                                                    >
                                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                    </span>
                                                                    <span
                                                                        onClick={() => {

                                                                            if (index > -1) {

                                                                                if (editState === true) {

                                                                                    setIsEditStateSet(true);

                                                                                    const indexToRemove = initialImagesArray.findIndex(y => y.imageUrl === image);
                                                                                    if (indexToRemove > -1) {

                                                                                        setImageIndexToRemove(indexToRemove);
                                                                                        setImageUrlToRemove(image);
                                                                                        setImageObjectFileName(initialImagesArray[indexToRemove].imageFileName);

                                                                                        return setShowDeleteConfirmationForm(true);

                                                                                    }

                                                                                    const currentImagesArray = imageArray;
                                                                                    currentImagesArray.splice(index, 1);
                                                                                    setImageArray(currentImagesArray);

                                                                                    setReRender(prevState => prevState + 1);

                                                                                    return;

                                                                                }

                                                                                const currentImageArray = imageArray;

                                                                                currentImageArray.splice(index, 1);
                                                                                setImageArray(currentImageArray);
                                                                                setReRender(prevState => prevState + 1);

                                                                            }

                                                                        }}
                                                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                                        data-action="remove"
                                                                        data-toggle="tooltip"
                                                                        title=""
                                                                        data-original-title="Remove avatar"
                                                                    >
                                                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>

                                    <div className="centerMan">
                                        <span className="form-text text-muted">
                                            Allowed file types: png, jpg, jpeg.
                                        </span>
                                    </div>
                                    {showDeleteConfirmationForm && (
                                        <>
                                            <ImageDeletionConfirmation
                                                indexToRemove={imageIndexToRemove}
                                                imageToRemove={imageUrlToRemove}
                                                imageObjectFileName={imageObjectFileName}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    );
                }

            case 1:
                {
                    return (
                        <>

                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Group controlId="SKU">
                                    <Form.Label>Part SKU</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="part SKU"
                                        value={formik.values.partSKU}
                                        onChange={formik.handleChange}
                                        className={`${getInputClasses("partSKU")}`}
                                        name="partSKU"
                                        {...formik.getFieldProps("partSKU")}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter part SKU
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Part SKU cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.partSKU && formik.errors.partSKU ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.partSKU}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="vehicleBatchNumber">
                                    <Form.Label>Vehicle Batch Number</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="vehicle batch number"
                                        value={formik.values.vehicleBatchNumber}
                                        onChange={formik.handleChange}
                                        className={`${getInputClasses("vehicleBatchNumber")}`}
                                        name="vehicleBatchNumber"
                                        {...formik.getFieldProps("vehicleBatchNumber")}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter vehicle batch number
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Vehicle batch number cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.vehicleBatchNumber && formik.errors.vehicleBatchNumber ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.vehicleBatchNumber}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="partName">
                                    <Form.Label>Part Name</Form.Label>
                                    <Form.Control type="text" placeholder="Part name" value={formik.values.partName} onChange={formik.handleChange} className={`${getInputClasses("partName")}`} name="partName" {...formik.getFieldProps("partName")} />
                                    <Form.Text className="text-muted">
                                        Enter part name
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Part name cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.partName && formik.errors.partName ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.partName}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="partCount">
                                    <Form.Label>Part Count</Form.Label>
                                    <Form.Control type="number" placeholder="Part count" value={formik.values.partCount} onChange={formik.handleChange} className={`${getInputClasses("partCount")}`} name="partCount" {...formik.getFieldProps("partCount")} />
                                    <Form.Text className="text-muted">
                                        Enter part count
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Part count cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.partCount && formik.errors.partCount ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.partCount}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="partMinimumCount">
                                    <Form.Label>Part Minimum Count</Form.Label>
                                    <Form.Control type="number" placeholder="part minimum count" value={formik.values.partMinimumCount} onChange={formik.handleChange} className={`${getInputClasses("partMinimumCount")}`} name="partMinimumCount" {...formik.getFieldProps("partMinimumCount")} />
                                    <Form.Text className="text-muted">
                                        Enter part minimum count
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Part minimum count cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.partMinimumCount && formik.errors.partMinimumCount ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.partMinimumCount}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="purchaseLocation">
                                    <Form.Label>Purchase Location</Form.Label>
                                    <Form.Control type="text" placeholder="Purchase Location" value={formik.values.purchaseLocation} onChange={formik.handleChange} className={`${getInputClasses("purchaseLocation")}`} name="purchaseLocation" {...formik.getFieldProps("purchaseLocation")} />
                                    <Form.Text className="text-muted">
                                        Enter purchase location
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Purchase location cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.purchaseLocation && formik.errors.purchaseLocation ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.purchaseLocation}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                <Form.Group controlId="partPrice">
                                    <Form.Label>Part Price</Form.Label>
                                    <Form.Control type="text" placeholder="Part Price" value={formik.values.partPrice} onChange={formik.handleChange} className={`${getInputClasses("partPrice")}`} name="partPrice" {...formik.getFieldProps("partPrice")} />
                                    <Form.Text className="text-muted">
                                        Enter part price
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        Part price cannot be blank
                                    </Form.Control.Feedback>
                                    {formik.touched.partPrice && formik.errors.partPrice ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                {formik.errors.partPrice}
                                            </div>
                                        </div>
                                    ) : null}
                                </Form.Group>

                                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                                    {createEditText}
                                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                                </Button>
                            </Form>

                        </>
                    );
                }

            case 2:
                {
                    setReRender(prevState => prevState + 1);
                    onChanger();
                    return (
                        <>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="success"
                                            className={classes.avatar}
                                        >
                                            <CheckCircleTwoToneIcon
                                                color="success"
                                                fontSize="large"
                                            />
                                        </Avatar>
                                    }
                                    title="Bulk SMS sent successfully"
                                    subheader=""
                                />
                            </Card>
                        </>
                    );
                }
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <>

            <div className="p-0">
                <div className="centerMan">
                    <Typography
                        variant="h5"
                        component="h2"
                    >
                        {editState &&
                            (<>
                                <span>
                                    Edit Inventory Part
                                </span>
                            </>)
                        }
                        {!editState &&
                            (<>
                                <span>
                                    Create Inventory Part
                                </span>
                            </>)
                        }
                    </Typography>
                    <Typography
                        variant="p"
                    >
                        {editState &&
                            (<>
                                <small>
                                    You can add to or remove already uploaded part images
                                </small>
                            </>)
                        }
                    </Typography>
                </div>
                <Stepper
                    activeStep={activeSmsStep}
                    alternativeLabel
                    className="mb-1"
                >
                    {smsSteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <>
                    {activeSmsStep === smsSteps.length ? (
                        <>
                            <div className="mt-5">

                                <Card className={classes.card}>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                aria-label="success"
                                                className={classes.avatar}
                                            >
                                                <CheckCircleTwoToneIcon
                                                    color="success"
                                                />
                                            </Avatar>
                                        }
                                        title="inventory part created successfully"
                                        subheader="You can close the modal or click on reset the creation wizard to add another part"
                                    />
                                </Card>
                                <div className="p-1">
                                    <hr className={classes.separator} />
                                </div>
                                <Button
                                    onClick={handleSmsReset}
                                >
                                    Reset
                                </Button>

                            </div>
                        </>
                    ) : (
                        <>
                            <div className="mt-5">

                                <div>
                                    {getSmsStepContent(activeSmsStep)}
                                </div>
                                <div className="p-1">
                                    <hr className={classes.separator} />
                                </div>
                                <div className="mt-10">
                                    {activeSmsStep !== 0 &&
                                        <>
                                            <Button
                                                disabled={activeSmsStep === 0}
                                                onClick={handleSmsBack}
                                                className={classes.backButton}
                                            >
                                                Back
                                            </Button>
                                            {activeSmsStep === smsSteps.length - 1 ?
                                                ''
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSmsNext}
                                                >
                                                    Next
                                                </Button>
                                            }
                                        </>
                                    }
                                    {activeSmsStep === 0 &&
                                        <>
                                            <Button
                                                disabled={activeSmsStep === 0}
                                                onClick={handleSmsBack}
                                                className={classes.backButton}
                                            >
                                                Back
                                            </Button>
                                            {activeSmsStep === (smsSteps.length - 1) ?
                                                <Button
                                                    disabled={true}
                                                    className={classes.backButton}
                                                >
                                                    Next
                                                </Button>
                                                :
                                                <Button
                                                    className={classes.backButton}
                                                    onClick={handleSmsNext}
                                                >
                                                    Next
                                                </Button>
                                            }
                                        </>
                                    }
                                </div>

                            </div>
                        </>
                    )}
                </>
            </div>

        </>
    );
}