import {
    IconButton,
    Snackbar,
    Box,
    Button,
    Typography,
    Paper,
    Grid,
    styled,
    Alert,
    Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { getDriver } from "../../../../app/modules/Auth/_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import BasicLoader from '../../loaders/SimpleLoader';
import PerfectScrollbar from "react-perfect-scrollbar";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: "20px"
}));

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
    swipeEasing: true,
    minScrollbarLength: 20
};

export function CohortDriversList({ cohortDriversIDs }) {

    const [severity, setSeverity] = useState('info');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [driversDetailArray, setDriversDetailArray] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isLoadingError, setIsLoadingError] = useState(false);
    const [filterMode, setFilterMode] = useState(false);
    const [filterType, setFilterType] = useState(0);

    const [completedGhanaCardCount, setCompletedGhanaCardCount] = useState(0);
    const [completedDigitalAddressCount, setCompletedDigitalAddressCount] = useState(0);
    const [completedGuarantorDetailsCount, setCompletedGuarantorDetailsCount] = useState(0);
    const [completedInitialDepositCount, setCompletedInitialDepositCount] = useState(0);
    const [completedBikeAssignedCount, setCompletedBikeAssignedCount] = useState(0);
    const [completedDriverContractSignedCount, setCompletedDriverContractSignedCount] = useState(0);

    const [unfetchedRiderIdArray, setUnfetchedRiderIdArray] = useState([]);
    const [driverCohortRawData, setDriverCohortRawData] = useState([]);
    const [unfetchedRiderIDs, setUnfetchedRiderIDs] = useState(false);

    const TableEventMessage = useRef();
    const classes = useStyles();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setSnackbarOpen(false);
    }

    useEffect(() => {

        let isMounted = true;

        setDriverCohortRawData(cohortDriversIDs);

        const driverDataArray = [];
        const verifiedDriverArray = [];
        const activatedDriverCount = [];

        const fetchDriverData = async (driverDataArray, driverID) => {

            try {

                const response = getDriver(driverID);
                const data = (await response).data?.user_data;
                driverDataArray.push(data);

            } catch (error) {
                unfetchedRiderIdArray.push(driverID);
                setUnfetchedRiderIDs(true);
            }

        };

        const fetchData = async () => {

            for (let i = 0; i < driverCohortRawData.length; i++) {

                const driverIdForCohort = driverCohortRawData[i];
                await fetchDriverData(driverDataArray, driverIdForCohort);

                if ((i + 1) === driverCohortRawData.length) {

                    for (let j = 0; j < driverDataArray.length; j++) {

                        const driverRecord = driverDataArray[j];

                        const bikeReceived = driverRecord.bikeReceived.state;
                        const initialDeposit = driverRecord.depositData.state;
                        const ghanaCard = driverRecord.ghanaCardData.state;
                        const digitalAddress = driverRecord.digitalAddressData.state;
                        const guarantorDetails = driverRecord.guarantorDetails.state;
                        const driverContractSigned = driverRecord.driverContractSigned.state;

                        if (bikeReceived)
                            setCompletedBikeAssignedCount(prevState => prevState + 1);

                        if (initialDeposit)
                            setCompletedInitialDepositCount(prevState => prevState + 1);

                        if (ghanaCard)
                            setCompletedGhanaCardCount(prevState => prevState + 1);

                        if (guarantorDetails)
                            setCompletedGuarantorDetailsCount(prevState => prevState + 1);

                        if (digitalAddress)
                            setCompletedDigitalAddressCount(prevState => prevState + 1);

                        if (driverContractSigned)
                            setCompletedDriverContractSignedCount(prevState => prevState + 1);

                        if ((1 + j) === driverDataArray.length) {

                            setDriversDetailArray(driverDataArray);
                            setIsDataLoading(false);

                            return;

                        }

                    }

                }

            }

        };

        isMounted && fetchData();

        return () => isMounted = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverCohortRawData]);


    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button
                        key="undo"
                        color="primary"
                        size="small"
                        onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableEventMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="scroll pr-7 mr-n7"
                style={{
                    maxHeight: "500px",
                    position: "relative"
                }}
            >
                {isDataLoading ? (
                    isLoadingError ?
                        (<>
                            <div className='centerMan mb-1'>
                                <span className='label label-lg label-light-danger label-inline label-rounded p-8'>
                                    <span>
                                        <i className="fas fa-exclamation-triangle"></i>
                                        {" "}
                                        could not load mileage metrics:
                                        {" "}
                                        {TableEventMessage.current}
                                    </span>
                                </span>
                            </div>
                            <div className="timeline timeline-justified timeline-4">
                                <div className="timeline-bar"></div>
                                <div className="timeline-items">
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            <Skeleton
                                                sx={(theme) => ({
                                                    backgroundColor: theme.palette.error.light,
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                                variant="circular"
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <div className="timeline-label">
                                            <Skeleton
                                                sx={(theme) => ({
                                                    backgroundColor: theme.palette.error.light,
                                                    borderRadius: '0.35rem',
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                                variant="rectangular"
                                                height={20}
                                                width={50}
                                            />
                                        </div>
                                        <div className="timeline-content">
                                            <Skeleton
                                                sx={(theme) => ({
                                                    backgroundColor: theme.palette.error.light,
                                                    borderRadius: '0.35rem',
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                                variant="rectangular"
                                                height={20}
                                            />
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            <Skeleton
                                                sx={(theme) => ({
                                                    backgroundColor: theme.palette.error.light,
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                                variant="circular"
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                        <div className="timeline-label">
                                            <Skeleton
                                                sx={(theme) => ({
                                                    backgroundColor: theme.palette.error.light,
                                                    borderRadius: '0.35rem',
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                                variant="rectangular"
                                                height={20}
                                                width={50}
                                            />
                                        </div>
                                        <div className="timeline-content">
                                            <Skeleton
                                                sx={(theme) => ({
                                                    backgroundColor: theme.palette.error.light,
                                                    borderRadius: '0.35rem',
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                                variant="rectangular"
                                                height={20}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>) : (<>
                            <div className='centerMan'>
                                <span className='bg-light-warning mb-5 pl-3 pr-3 rounded'>
                                    loading rider cohort, this may take a while...
                                    {" "}
                                    <BasicLoader size={"sm"} />
                                </span>
                            </div>
                            <div className="timeline timeline-justified timeline-4">
                                <div className="timeline-bar"></div>
                                <div className="timeline-items">
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            <Skeleton
                                                variant="circular"
                                                width={20}
                                                height={20}
                                                sx={() => ({
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                            />
                                        </div>
                                        <div className="timeline-label">
                                            <Skeleton
                                                variant="rectangular"
                                                height={20}
                                                width={50}
                                                sx={() => ({
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    borderRadius: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                            />
                                        </div>
                                        <div className="timeline-content">
                                            <Skeleton
                                                variant="rectangular"
                                                height={20}
                                                sx={() => ({
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    borderRadius: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                            />
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            <Skeleton
                                                variant="circular"
                                                width={20}
                                                height={20}
                                                sx={() => ({
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                            />
                                        </div>
                                        <div className="timeline-label">
                                            <Skeleton
                                                variant="rectangular"
                                                height={20}
                                                width={50}
                                                sx={() => ({
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    borderRadius: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                            />
                                        </div>
                                        <div className="timeline-content">
                                            <Skeleton
                                                variant="rectangular"
                                                height={20}
                                                sx={() => ({
                                                    color: '#fff',
                                                    p: '0.35rem',
                                                    borderRadius: '0.35rem',
                                                    textAlign: 'center'
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                ) : (
                    <>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={12} sm={12} md={4}>
                                <div
                                    className={`d-flex align-items-center ${filterMode ? filterType === 1 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded-xl p-6 cursor-pointer`}
                                    onClick={() => {
                                        setFilterMode(true);
                                        setFilterType(1);
                                    }}
                                >
                                    <div className="d-flex flex-column flex-grow-1">
                                        <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                            COHORT COUNT
                                        </label>
                                        <span className="text-muted font-weight-bold">
                                            {driversDetailArray.length ?? 0}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <div
                                    className={`d-flex align-items-center ${filterMode ? filterType === 2 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded-xl p-6 cursor-pointer`}
                                    onClick={() => {
                                        setFilterMode(true);
                                        setFilterType(2);
                                    }}
                                >
                                    <div className="d-flex flex-column flex-grow-1">
                                        <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                            VERIFIED
                                        </label>
                                        <span className="text-muted font-weight-bold">
                                            {completedDigitalAddressCount}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} className='mb-10'>
                                <div
                                    className={`d-flex align-items-center ${filterMode ? filterType === 3 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded-xl p-6 cursor-pointer`}
                                    onClick={() => {
                                        setFilterMode(true);
                                        setFilterType(3);
                                    }}
                                >
                                    <div className="d-flex flex-column flex-grow-1">
                                        <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                            ACTIVATED
                                        </label>
                                        <span className="text-muted font-weight-bold">
                                            {completedInitialDepositCount}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        {unfetchedRiderIDs && <div className="bg-light-warning rounded-xl p-6 cursor-pointer">
                            <div className='centerMan bg-light-warning p-2 mb-2'>
                                <span>
                                    We could not retrieve data for the following rider IDs below:
                                </span>
                            </div>
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} className="mb-4">
                                {unfetchedRiderIdArray.map((driverID, index) => {
                                    return (
                                        <Grid item xs={2} sm={4} md={4} key={index}>
                                            <div className='bg-light-primary p-2 rounded'>
                                                <span className=''>
                                                    {`${index + 1}. ${driverID}`}
                                                </span>
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>}
                        {driversDetailArray.map((item, index) =>
                            <div key={index} className='p-5'>
                                <Item className='mb-3 rounded-xl p-5'>
                                    <div className='row mb-10'>
                                        <div className='col-md-4'>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <img alt="avatar" height={50} width={50} src={item.document.profileImageURL ?? toAbsoluteUrl("/media/svg/icons/General/User.svg")} loading="lazy" style={{ borderRadius: '50%', border: "1px solid", borderColor: "#1F384C" }} />
                                            </Box>
                                        </div>
                                        <div className='col-md-4'>
                                            <Box
                                                sx={{ textAlign: 'center' }}
                                                display='flex'
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Typography variant="h6">
                                                    {item.document.firstName}
                                                    {" "}
                                                    {item.document.lastName}
                                                </Typography>
                                            </Box>
                                        </div>
                                        <div className='col-md-4'>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Typography variant="h6">
                                                    {item.document.phone}
                                                </Typography>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className='row pl-5 pr-5'>
                                        <div className='col-md-12'>
                                            <div className="timeline timeline-justified timeline-4">
                                                <div className="timeline-bar"></div>
                                                <div className="timeline-items">
                                                    <div className="timeline-item">
                                                        <div className="timeline-badge">
                                                            <CheckCircleTwoToneIcon
                                                                fontSize='large'
                                                                color='success'
                                                            />
                                                        </div>
                                                        <div className="timeline-label">
                                                            <div className='bg-light-primary rounded p-3'>
                                                                <span className='font-weight-bold'>
                                                                    1. Rider Downloads the Wahu Rider App
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-content">
                                                            <span>
                                                                This rider downloaded the Wahu App on:
                                                                {" "}
                                                                {`${new Date(item.document.createdAt).toDateString()}`}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="timeline-item">
                                                        <div className="timeline-badge">
                                                            {item.driverContractSigned.createdAt ? (
                                                                <>
                                                                    <CheckCircleTwoToneIcon
                                                                        fontSize='large'
                                                                        color='success'
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <HighlightOffTwoToneIcon
                                                                        fontSize='large'
                                                                        color='error'
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="timeline-label">
                                                            <div className='bg-light-primary rounded p-3'>
                                                                <span className='font-weight-bold'>
                                                                    2. Rider Provides Data for Verification Process
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-content">
                                                            <div className='row'>
                                                                <div className='col-sm-12 col-xs-12 col-md-6 col-lg-6 p-2'>
                                                                    <div className='alt-background'>
                                                                        {item.ghanaCardData.createdAt ? (
                                                                            <>
                                                                                <div>
                                                                                    <small>Ghana Card:</small>
                                                                                    <br />
                                                                                    <span>
                                                                                        <CheckCircleTwoToneIcon
                                                                                            fontSize='large'
                                                                                            color='success'
                                                                                        />
                                                                                        {" "}
                                                                                        {new Date(item.ghanaCardData.createdAt).toDateString()}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <small>Ghana Card:</small>
                                                                                    {" "}
                                                                                    <HighlightOffTwoToneIcon
                                                                                        fontSize='large'
                                                                                        color='error'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12 col-xs-12 col-md-6 col-lg-6 p-2'>
                                                                    <div className='alt-background'>
                                                                        {item.digitalAddressData.createdAt ? (
                                                                            <>
                                                                                <div>
                                                                                    <small>Digital Address:</small>
                                                                                    <br />
                                                                                    <span>
                                                                                        <CheckCircleTwoToneIcon
                                                                                            fontSize='large'
                                                                                            color='success'
                                                                                        />
                                                                                        {" "}
                                                                                        {new Date(item.digitalAddressData.createdAt).toDateString()}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <small>Digital Address:</small>
                                                                                    {" "}
                                                                                    <HighlightOffTwoToneIcon
                                                                                        fontSize='large'
                                                                                        color='error'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-sm-12 col-xs-12 col-md-6 col-lg-6 p-2'>
                                                                    <div className='alt-background'>
                                                                        {item.guarantorDetails.createdAt ? (
                                                                            <>
                                                                                <div>
                                                                                    <small>Guarantor Details:</small>
                                                                                    <br />
                                                                                    <span>
                                                                                        <CheckCircleTwoToneIcon
                                                                                            fontSize='large'
                                                                                            color='success'
                                                                                        />
                                                                                        {" "}
                                                                                        {new Date(item.guarantorDetails.createdAt).toDateString()}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <small>Guarantor Details:</small>
                                                                                    {" "}
                                                                                    <HighlightOffTwoToneIcon
                                                                                        fontSize='large'
                                                                                        color='error'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12 col-xs-12 col-md-6 col-lg-6 p-2'>
                                                                    <div className='alt-background'>
                                                                        {item.driverContractSigned.createdAt ? (
                                                                            <>
                                                                                <div>
                                                                                    <small>Contract Signed:</small>
                                                                                    <br />
                                                                                    <span>
                                                                                        <CheckCircleTwoToneIcon
                                                                                            fontSize='large'
                                                                                            color='success'
                                                                                        />
                                                                                        {" "}
                                                                                        {new Date(item.driverContractSigned.createdAt).toDateString()}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <small>Contract Signed:</small>
                                                                                    {" "}
                                                                                    <HighlightOffTwoToneIcon
                                                                                        fontSize='large'
                                                                                        color='error'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="timeline-item">
                                                        <div className="timeline-badge">
                                                            {item.bikeReceived.createdAt ? (
                                                                <>
                                                                    <CheckCircleTwoToneIcon
                                                                        fontSize='large'
                                                                        color='success'
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <HighlightOffTwoToneIcon
                                                                        fontSize='large'
                                                                        color='error'
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="timeline-label">
                                                            <div className='bg-light-primary rounded p-3'>
                                                                <span className='font-weight-bold'>
                                                                    3. Rider Pays their initial Deposit to get Activated
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="timeline-content">
                                                            <div className='row'>
                                                                <div className='col-sm-12 col-xs-12 col-md-6 col-lg-6 p-2'>
                                                                    <div className='alt-background'>
                                                                        {item.depositData.createdAt ? (
                                                                            <>
                                                                                <div>
                                                                                    <small>Initial Deposit:</small>
                                                                                    <br />
                                                                                    <span>
                                                                                        <CheckCircleTwoToneIcon
                                                                                            fontSize='large'
                                                                                            color='success'
                                                                                        />
                                                                                        {" "}
                                                                                        {new Date(item.depositData.createdAt).toDateString()}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <small>Initial Deposit:</small>
                                                                                    {" "}
                                                                                    <HighlightOffTwoToneIcon
                                                                                        fontSize='large'
                                                                                        color='error'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-12 col-xs-12 col-md-6 col-lg-6 p-2'>
                                                                    <div className='alt-background'>
                                                                        {item.bikeReceived.createdAt ? (
                                                                            <>
                                                                                <div>
                                                                                    <small>Bike Assigned:</small>
                                                                                    <br />
                                                                                    <span>
                                                                                        <CheckCircleTwoToneIcon
                                                                                            fontSize='large'
                                                                                            color='success'
                                                                                        />
                                                                                        {" "}
                                                                                        {new Date(item.bikeReceived.createdAt).toDateString()}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <small>Bike Assigned:</small>
                                                                                    {" "}
                                                                                    <HighlightOffTwoToneIcon
                                                                                        fontSize='large'
                                                                                        color='error'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Item>
                            </div>
                        )}
                        {/* <div className="timeline timeline-justified timeline-4">
                            <div className="timeline-bar"></div>
                            {driversDetailArray.map((item, index) =>
                                <div className="timeline-items p-8" key={index}>
                                    <div className="timeline-item">
                                        <div className="timeline-badge">
                                            <img alt="avatar" height={30} width={30} src={item.document.profileImageURL ?? toAbsoluteUrl("/media/svg/icons/General/User.svg")} loading="lazy" style={{ borderRadius: '50%', border: "1px solid", borderColor: "#1F384C" }} />
                                        </div>
                                        <div className="timeline-label">
                                            <ChipDisplay
                                                label={`${item.document.firstName} ${(item.document.lastName)}`}
                                                color="info"
                                                size="large"
                                            />
                                        </div>
                                        <div className="timeline-content">
                                            {`${item.document.phone}`}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> */}
                    </>
                )}
            </PerfectScrollbar>
            {/* {driversDetailArray.map((item, index) =>
                <div key={index}>
                    <ParentItem>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="p">
                                        <Box
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <img alt="avatar" height={50} width={50} src={item.document.profileImageURL ?? toAbsoluteUrl("/media/svg/icons/General/User.svg")} loading="lazy" style={{ borderRadius: '50%', border: "1px solid", borderColor: "#1F384C" }} />
                                        </Box>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    sx={{ textAlign: 'center' }}
                                    display='flex'
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h6">
                                        {item.document.firstName}
                                        {" "}
                                        {item.document.lastName}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6">
                                        {item.document.phone}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6">
                                        {item.document.isApproved.state ? <Chip label="Approved" color='success' /> : <Chip label="Not Approved" variant='outline' color='error' />}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6">
                                        {item.document.isSubscribed.state ? <Chip label="Subscribed" color='success' /> : <Chip label="Not Subscribed" variant='outline' color='error' />}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </ParentItem>
                    
                </div>
            )} */}
        </>
    );
}