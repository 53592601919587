import React from "react";
import { toAbsoluteUrl } from "../../../_wahu/_helpers";
import "../../../_wahu/_assets/sass/pages/error/error-3.scss";

export function ErrorPage3() {
    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/error/bg3.jpg")})`
                }}
            >
                <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
                    <h1 className="construction-text text-stroke text-transparent">Opps</h1>
                    <p className="display-4 font-weight-boldest text-white mb-12">
                        Well, since you're here...
                    </p>
                    <p className="font-size-h1 font-weight-boldest text-dark-75">
                        the page you're looking for is either out of bounds
                        <br />
                        or still under construction.
                    </p>
                    {/* <p className="font-size-h4 line-height-md">
                        Please give the software guys some small pressure
                        <br />
                        not a lot, just small, so they finish this one :)
                    </p> */}
                </div>
            </div>
        </div>
    );
}
