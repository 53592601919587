import { IconButton, Paper, Snackbar, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `100%`,
        width: `100%`
    },
    imgContainer: {
        height: `100px`,
        width: `100px`
    }
}));

const MuiAlert = React.forwardRef((props, ref) => {
    return <Alert elevation={6} variant="filled" {...props} ref={ref} />;
});

export function VendorDetailsList() {

    const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
    const classes = useStyles();

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorSnackBar(false);
    }

    function handleSnackBarOpen() {
        setOpenErrorSnackBar(true);
    }

    useEffect(() => {

    },
        []
    );

    return (<>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={openErrorSnackBar}
            autoHideDuration={6000}
            onClose={handleClose}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            action={[
                <Button key="undo" color="secondary" size="small" onClick={handleClose}>
                    UNDO
                </Button>,
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            ]}
        >

        </Snackbar>
    </>);
}