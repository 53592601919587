import {
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    ListItem,
    Paper,
    Snackbar,
    Stack,
    Typography,
    styled,
    Alert,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { getFaultCategory } from "../../../../app/modules/Auth/_redux/authCrud";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `100%`,
        width: `100%`
    },
    imgContainer: {
        height: `100px`,
        width: `100px`
    }
}));

export function FaultCategoryDetailsList({ faultCategoryId }) {

    const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
    const [faultCategoryDataLoader, setFaultCategoryDataLoader] = useState('d-none');
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const PageErrorMessage = useRef();
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenErrorSnackBar(false);
    }

    function handleSnackBarOpen() {
        setOpenErrorSnackBar(true);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async (faultCategoryId) => {

            try {

                setFaultCategoryDataLoader('');

                if (faultCategoryId) {

                    const res = getFaultCategory(faultCategoryId).catch((error) => {
                        console.log(error);
                        handleSnackBarOpen();
                        setFaultCategoryDataLoader('d-none');
                    });

                    const responseData = (await res).data;

                    isMounted && setData(responseData);
                    isMounted && setFaultCategoryDataLoader('d-none');

                    return;
                }

            } catch (error) {
                PageErrorMessage.current = error.message;
                handleSnackBarOpen();
                setFaultCategoryDataLoader('d-none');

                return;
            }
        };

        fetchData(faultCategoryId);

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [faultCategoryId]);

    const date = new Date(data.document?.createdAt).toUTCString();
    const subs = data.document?.subCategories;

    const listOfSubs = subs?.map((sub, i) => {

        return (
            <Chip size="medium" label={sub} key={i} color="info" sx={{
                m: 2
            }} />
        );

    });


    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openErrorSnackBar}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={handleClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: '100%' }}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {PageErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <section>
                <Box sx={{ p: 2 }}>
                    <h3>
                        {data.document?.name}
                    </h3>
                    {`Created at:${date}`}
                </Box>
                <Divider sx={{ opacity: 0.6 }} />
                <Box sx={{ p: 2 }}>
                    <h3>Sub-Categories</h3>
                    <Typography>
                        Count:
                        {" "}
                        {data.document?.subCategories?.length}
                    </Typography>
                    <Stack direction='row' spacing={1} >
                        {listOfSubs}
                    </Stack>
                </Box>


            </section >

        </>
    );

}