import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Box,
    Button,
    IconButton,
    ListItemIcon,
    MenuItem,
    Paper,
    Snackbar,
    TextField,
    Typography,
    styled,
    Grid,
    Alert,
} from "@mui/material";
import { FLEET_MANAGING_BASE_URL, setStorageItem } from "../../../../app/services/baseService";
import Axios from "axios";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from '@mui/icons-material/Close';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { MaterialReactTable } from "material-react-table";
import { convertUnixTimestampToUTC } from "../../../_helpers";
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { deleteServiceCenter } from "../../../../app/modules/Auth/_redux/authCrud";
import { SimpleModal } from "../modals/SimpleModal";
import { AddUpdateServiceCenterForm } from "../forms/AddUpdateServiceCenterForm";
import { ServiceCenterDetailsList } from "../lists/ServiceCenterDetailsList";
import { ButtonGroup } from "react-bootstrap";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function ServiceCenterTable({ className, selectionAction, isSelectionAction }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showServiceCenterDetails, setShowServiceCenterDetails] = useState(false);
    const [initialValues, setInitialValues] = useState({
        serviceDescription: "",
        locationLatitude: 0,
        locationLongitude: 0,
        name: "",
        locationName: "",
        phoneNumber: "",
        email: ""
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Fault Category');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [selectedServiceCenterID, setSelectedServiceCenterID] = useState('');

    const serviceCenterID = useRef();
    const TableErrorMessage = useRef();
    const classes = useStyles();

    const defaultValuesToSet = {
        serviceDescription: "",
        locationLatitude: 0,
        locationLongitude: 0,
        name: "",
        locationName: "",
        phoneNumber: "",
        email: ""
    };

    function handleClick() {
        setOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/service-center/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = Axios.get(url.href, { signal: controller.signal });
                const data = (await response).data;

                setData(data.data);
                setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    return setOpen(true);

                }

                setSeverity("error");
                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;

                setIsError(true);
                setOpen(true);

            } finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        isMounted && fetchData();

        return () => {

            isMounted = false;
            controller.abort();

            setData([]);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.name,
            id: 'name',
            header: 'Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.phoneNumber,
            id: 'phoneNumber',
            header: 'Phone Number',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.location.name,
            id: 'locationName',
            header: 'Location Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.email,
            id: 'email',
            header: 'Email',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Deactivated"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            filterVariant: 'date',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        }
    ], []);

    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={"Add Service Center"}
                show={show}
                size="lg"
                close={() => {
                    setShow(false);
                }}
                body={
                    <AddUpdateServiceCenterForm
                        initialValues={initialValues}
                        createEditText={createEditText}
                        editState={editState}
                        onChanger={onChangeWorker}
                        serviceCenterId={serviceCenterID.current}
                    />
                }
            />
            <SimpleModal
                modal_tittle={"Service Center Details"}
                show={showServiceCenterDetails}
                size="lg"
                close={() => {
                    setShowServiceCenterDetails(false);
                }}
                body={
                    <ServiceCenterDetailsList
                        serviceCenterId={selectedServiceCenterID}
                    />
                }
            />
            <div className={`card card-custom ${className} rounded-lg`}>
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Service Center Records
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Service Center Records from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Add Service Center");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Add Service Center
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableRowSelection
                    getRowId={(row) => row.id}
                    initialState={{ showColumnFilters: true }}
                    manualPagination
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableGrouping
                    enableColumnPinning
                    enableRowPinning
                    enableRowActions
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: `${TableErrorMessage.current}`,
                            }
                            : undefined
                    }
                    onPaginationChange={setPagination}
                    rowCount={rowCount}
                    state={{
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching
                    }}
                    renderDetailPanel={({ row }) => (
                        <>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Description: </Typography>
                                            <Typography variant="p">
                                                {row.original.document?.serviceDescription}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>ID: </Typography>
                                            <Typography variant="p">
                                                {row.original.document?.ID}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Last Updated: </Typography>
                                            <Typography variant="p">
                                                {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Latitude: </Typography>
                                            <Typography variant="p">
                                                {row.original.document.location.latitude}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Longitude: </Typography>
                                            <Typography variant="p">
                                                {row.original.document.location.longitude}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        <MenuItem key={1} onClick={() => {

                            const serviceCenterId = row.original.document.ID;
                            serviceCenterID.current = serviceCenterId;
                            const defaultValues = {
                                serviceDescription: row.original.document.serviceDescription,
                                locationLatitude: row.original.document.locationLatitude,
                                locationLongitude: row.original.document.locationLongitude,
                                name: row.original.document.name,
                                locationName: row.original.document.locationName,
                                phoneNumber: row.original.document.phoneNumber,
                                email: row.original.document.email
                            };
                            setShow(true);
                            setEditState(true);
                            setFieldDisabled(true);
                            setCreateEditText("Update Service Center");
                            setInitialValues(defaultValues);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <ModeEditTwoToneIcon
                                    color='primary'
                                />
                            </ListItemIcon>
                            Edit Fault Category
                        </MenuItem>,
                        <MenuItem key={2} onClick={() => {
                            const serviceCenterId = row.original.document.ID;
                            setSelectedServiceCenterID(serviceCenterId);
                            setShowServiceCenterDetails(true);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <InfoTwoToneIcon
                                    style={{ color: "#22ABE2" }}
                                />
                            </ListItemIcon>
                            See Service Center Details
                        </MenuItem>,
                        <MenuItem key={3} onClick={async () => {
                            const serviceCenterID = row.original.document?.ID;

                            try {
                                await deleteServiceCenter(serviceCenterID).then(() => {

                                    setSeverity('error');
                                    TableErrorMessage.current = "Service Center removed successfully";
                                    handleClick();
                                    onChangeWorker();
                                    closeMenu();
                                }).catch((error) => {
                                    setSeverity('error');
                                    TableErrorMessage.current = (error).toString();
                                    handleClick();
                                    closeMenu();
                                });

                            } catch (error) {
                                setSeverity('error');
                                TableErrorMessage.current = (error).toString();
                                handleClick();
                                closeMenu();
                            }

                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <DeleteTwoToneIcon
                                    style={{ color: "#990000" }}
                                />
                            </ListItemIcon>
                            Delete Service Center
                        </MenuItem>,

                    ]}
                    renderTopToolbarCustomActions={({ table }) => {

                        const handleSelectionAction = () => {

                            setActionBtnDisabled(false);
                            const selectedRows = table.getSelectedRowModel().rows;

                            setStorageItem("selectedServiceCenter", selectedRows);
                            selectionAction();
                        };

                        return (
                            <div>
                                {isSelectionAction && <ButtonGroup size="small" aria-label="small button group">
                                    <Button
                                        color="secondary"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleSelectionAction}
                                        variant="contained"
                                        size="small"
                                    >
                                        Select Service Center
                                    </Button>
                                </ButtonGroup>}
                            </div>
                        );
                    }}
                    muiSkeletonProps={{
                        animation: 'wave',
                        height: 30,
                    }}
                    muiLinearProgressProps={{
                        color: 'primary',
                    }}
                    muiPaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                        showFirstButton: true,
                        showLastButton: true,
                        color: 'success',
                        shape: 'rounded',
                        showRowsPerPage: true,
                        variant: 'outlined',
                    }}
                    enableStickyFooter
                    enableColumnResizing
                    paginationDisplayMode={"pages"}
                    paginateExpandedRows={true}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: '650px',
                        }
                    }}
                    enableBatchRowSelection
                    enableRowNumbers
                    rowNumberDisplayMode={'original'}
                    selectAllMode={"all"}
                    options={{
                        selection: true,
                    }}
                    enableBottomToolbar
                    positionPagination="both"
                />

            </div>

        </>
    );
}