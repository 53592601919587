import React from "react";
import { useSubheader } from "../../_wahu/layout";
import { ServiceCenterTable } from "../../_wahu/_partials/widgets/advance-tables/ServiceCenterTable";

export function ServiceCenterPage() {

    const subheader = useSubheader();

    React.useEffect(() => {

        const load = async () => {
            try {
                subheader.setTitle("Servicing");
            } catch (error) {
                console.log(error);
            }
        };

        load();

    }, [subheader]);


    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <ServiceCenterTable className="gutter-b" />
                </div>
            </div>
        </>
    );
}