import React, { useState, useRef, useEffect } from "react";
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import PsychologyAltTwoToneIcon from '@mui/icons-material/PsychologyAltTwoTone';
import ElectricBikeTwoToneIcon from '@mui/icons-material/ElectricBikeTwoTone';
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import GavelTwoToneIcon from '@mui/icons-material/GavelTwoTone';
import CreditScoreTwoToneIcon from '@mui/icons-material/CreditScoreTwoTone';
import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';
import AirlineStopsTwoToneIcon from '@mui/icons-material/AirlineStopsTwoTone';
import BubbleChartTwoToneIcon from '@mui/icons-material/BubbleChartTwoTone';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import { DynamicOnboardingForm } from "../../../../_wahu/_partials/widgets/forms/DynamicOnboardingForm";
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { Chip } from "@mui/material";
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import BasicLoader from "../../../../_wahu/_partials/loaders/SimpleLoader";

export function DriverOnboardingQueue({ driverDetails, onChangeFunction, isChanger }) {

    const settingsModalContent = useRef();

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [stateData, setStateData] = useState();
    const [stepIdentifier, setStepIdentifier] = useState("");
    const [specDriverData, setSpecDriverData] = useState(null);

    const getStepTitles = (obj) => {
        for (const key in obj) {
            return obj[key]['title'];
        }
    };

    useEffect(() => {

        let isMounted = true;

        isMounted && setSpecDriverData(driverDetails);

        return () => isMounted = false;

    }, [driverDetails]);

    return (

        <>

            {!specDriverData && !showSettingsModal && <div className="centerMan">
                <BasicLoader size={"md"} />
            </div>}
            {showSettingsModal && (<>

                <DynamicOnboardingForm
                    driverData={specDriverData}
                    stateData={stateData}
                    stepIdentifier={stepIdentifier}
                    onChangeFunction={onChangeFunction}
                    isChanger={isChanger}
                    modalBackControl={() => {
                        setShowSettingsModal(false);
                    }}
                />

            </>)}
            {!showSettingsModal && specDriverData && <div className="driver-inner-profile-container">
                <div className="table-responsive">
                    {specDriverData.onboarding.map((item, index) =>
                        <table className="table table-borderless table-vertical-center" key={index}>
                            <thead>
                                <tr>
                                    <th className="p-0" style={{ width: "50px" }} />
                                    <th className="p-0" style={{ minWidth: "200px" }} />
                                    <th className="p-0" style={{ width: "150px" }} />
                                    <th className="p-0" style={{ minWidth: "125px" }} />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="pl-0 py-4">
                                        <div className="symbol symbol-50 symbol-light mr-1">
                                            <span className="symbol-label">
                                                {index === 0 &&
                                                    <SportsMotorsportsTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 1 &&
                                                    <PsychologyAltTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 2 &&
                                                    <ElectricBikeTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 3 &&
                                                    <PaidTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 4 &&
                                                    <AlternateEmailTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 5 &&
                                                    <GavelTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 6 &&
                                                    <CreditScoreTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 7 &&
                                                    <GavelTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 8 &&
                                                    <DetailsTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 9 &&
                                                    <AirlineStopsTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 10 &&
                                                    <BubbleChartTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 11 &&
                                                    <EngineeringTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 12 &&
                                                    <AddShoppingCartTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 13 &&
                                                    <HealthAndSafetyTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                                {index === 14 &&
                                                    <AdminPanelSettingsTwoToneIcon
                                                        className="h-50 align-self-center"
                                                        color="info"
                                                        fontSize="large"
                                                    />
                                                }
                                            </span>
                                        </div>
                                    </td>
                                    <td className="pl-0" style={{ maxWidth: "200px" }}>
                                        <span className="font-weight-bolder text-primary mb-1 font-size-lg">
                                            {getStepTitles(item)}
                                        </span>
                                        <div>
                                            {/* <span className="font-weight-bolder">Date:</span> */}
                                            {" "}
                                            <Chip
                                                label={item[Object.keys(item)[0]].updatedAt ?
                                                    <span className={`${item[Object.keys(item)[0]].state ? "text-success" : "text-danger"} font-weight-bold`}>
                                                        {new Date(item[Object.keys(item)[0]].updatedAt).toUTCString()}
                                                    </span>
                                                    :
                                                    item[Object.keys(item)[0]].createdAt ?
                                                        <span className={`${item[Object.keys(item)[0]].state ? "text-success" : "text-danger"} font-weight-bold`}>
                                                            {new Date(item[Object.keys(item)[0]].createdAt).toUTCString()}
                                                        </span>
                                                        :
                                                        item[Object.keys(item)[0]].state ?
                                                            <span className="text-danger font-weight-bold">
                                                                invalid date or date is null in db
                                                            </span>
                                                            :
                                                            <span className="text-danger font-weight-bold">
                                                                not started
                                                            </span>
                                                }
                                                variant="outlined"
                                                color={item[Object.keys(item)[0]]?.state ? "success" : "error"}
                                            />
                                        </div>
                                    </td>
                                    <td className="text-left">
                                        {item[Object.keys(item)[0]].state ?
                                            <span className="label label-lg rounded-lg label-light-success label-inline">
                                                <small className="font-weight-bolder">
                                                    COMPLETED
                                                </small>
                                            </span> :
                                            <span className="label label-lg rounded-lg label-light-danger label-inline">
                                                <small className="font-weight-bolder">
                                                    INCOMPLETE
                                                </small>
                                            </span>
                                        }
                                    </td>
                                    <td className="text-right pr-0">
                                        <span
                                            className="btn btn-icon btn-light btn-sm cursor-pointer"
                                            onClick={() => {

                                                settingsModalContent.current = item;
                                                for (const key in item) {
                                                    setStateData(item[key]);
                                                    setStepIdentifier(Object.keys(item)[0]);
                                                }

                                                setShowSettingsModal(true);

                                            }}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <SettingsTwoToneIcon color="info" />
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div>}

        </>

    );

}