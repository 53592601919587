import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Paper,
    styled,
    Alert,
    ButtonGroup,
} from '@mui/material';
import { Nav, Tab } from "react-bootstrap";
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import {
    activateVehicle,
    deactivateVehicle,
    markBikeInterestReplied
} from '../../../../app/modules/Auth/_redux/authCrud';
import {
    SUPPORT_SERVICE_BASE_URL
} from '../../../../app/services/baseService';
import { SendCustomEmailForBikeInterestForm, RemoveRecordConfirmForm, SendCustomSMSForBikeInterestForm, AddNoteToRequestForm } from '../';
import { SimpleModal } from '../modals/SimpleModal';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ForwardToInboxTwoToneIcon from '@mui/icons-material/ForwardToInboxTwoTone';
import { entityTypesForDeletionArray } from '../../../_helpers/Interfaces';
import BasicLoader from '../../loaders/SimpleLoader';
import { shallowEqual, useSelector } from "react-redux";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DynamicApexChart from '../../charts/DynamicApexChart';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `80px`,
        width: `80px`
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

export function BikeInterestsTable({ className }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [show, setShow] = useState(false);
    const [showSMSModal, setShowSMSModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [showBikeInterestActionModal, setShowBikeInterestActionModal] = useState(false);

    const [initialValues, setInitialValues] = useState({
        vehicleModelID: '',
        VIN: '',
        batteryID: ''
    });

    const [createEditText, setCreateEditText] = useState('Create Record');
    const [severity, setSeverity] = useState('info');
    const [selectedBikeInterestID, setSelectedBikeInterestID] = useState("");
    const [selectedBikeInterestData, setSelectedBikeInterestData] = useState({});

    const [analyticsData, setAnalyticsData] = useState(null);
    const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(true);

    const [weeklyMaxYaxisVal, setWeeklyMaxYaxisVal] = useState(100);
    const [monthlyMaxYaxisVal, setMonthlyMaxYaxisVal] = useState(100);

    const [actionModalTitle, setActionModalTitle] = useState("");
    const [settingReply, setSettingReply] = useState(false);

    const [key, setKey] = useState("Monthly");

    const TableErrorMessage = useRef();
    const classes = useStyles();

    function handleClick() {
        setOpenSnackbar(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;
        const firstController = new AbortController();
        const secondController = new AbortController();

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/communications/bike/interest-logs', `${SUPPORT_SERVICE_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href, { signal: firstController.signal });
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    let dataArrayDocument = element.document;

                    const dataToExport = {
                        count: i + 1,
                        firstName: dataArrayDocument.firstName,
                        lastName: dataArrayDocument.lastName,
                        email: dataArrayDocument?.email ? dataArrayDocument?.email : "Not provided",
                        phoneNumber: dataArrayDocument?.phoneNumber ? dataArrayDocument.phoneNumber : dataArrayDocument.phone ? dataArrayDocument.phone : "Not provided",
                        bikeUsage: dataArrayDocument?.bikeUse ? dataArrayDocument?.bikeUse : "Not provided",
                        paymentPlan: dataArrayDocument.paymentPlan ? dataArrayDocument.paymentPlan : "not provided",
                        isEmailResponse: dataArrayDocument?.isEmailResponse ? "emailed" : "not emailed",
                        isSMSResponse: dataArrayDocument?.isSMSResponse ? "texted" : "not texted",
                        repliedTo: dataArrayDocument?.replied ? "contacted" : "not contacted",
                        notes: dataArrayDocument?.note ? dataArrayDocument?.note.map((addedNote, index) => (
                            `${index}. ${addedNote.note}`
                        )) : "N/A",
                    };
                    dataArrayAlt.push(dataToExport);

                    if ((i + 1) >= dataArray.length)
                        setDataDocument(dataArrayAlt);

                }

                setData(data.data);
                setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                setSeverity("error");
                setIsError(true);
                setOpenSnackbar(true);

                return;

            }
            finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        const fetchAnalytics = async () => {

            const url = new URL('/communications/bike-interest-requests/analytics', `${SUPPORT_SERVICE_BASE_URL}`);

            try {

                const response = axios.get(url.href, { signal: secondController.signal });
                const data = (await response).data;

                const monthlyMaxYaxisVal = Math.max(...data.monthlyCountArray);
                setMonthlyMaxYaxisVal(monthlyMaxYaxisVal);

                const weeklyMaxYaxisVal = Math.max(...data.weeklyCountArray);
                setWeeklyMaxYaxisVal(weeklyMaxYaxisVal);

                setAnalyticsData(data);
                setIsLoadingAnalytics(false);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);
                    setIsLoadingAnalytics(false);

                    return;

                }

                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                setSeverity("error");
                setIsError(true);
                setOpenSnackbar(true);
                setIsLoadingAnalytics(false);

                return;

            }

        };

        isMounted && fetchAnalytics();
        isMounted && fetchData();

        return () => {

            isMounted = false;
            firstController.abort();
            secondController.abort();

            setData([]);
            setAnalyticsData([]);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.firstName,
            id: "document.firstName",
            enableClickToCopy: true,
            header: 'First Name'
        },
        {
            accessorFn: (row) => row?.document?.lastName,
            id: "document.lastName",
            enableClickToCopy: true,
            header: 'Last Name'
        },
        {
            accessorFn: (row) => row?.document?.phone ?? row?.document?.phoneNumber,
            id: "document.phone",
            enableClickToCopy: true,
            header: 'Phone Number',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? "" : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() ? "#000" : "#fff",
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? cell.getValue() : "Not provided"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row?.document?.email ?? `None Provided`}`,
            enableClickToCopy: true,
            id: 'document.email',
            header: 'Email',
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.isEmailResponse,
            header: 'Responded by email?',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === undefined ? theme.palette.error.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.success.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Yes" : cell.getValue() === undefined ? "Not Yet" : cell.getValue() === false ? "Not Yet" : "Yes"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.isSMSResponse,
            header: 'Responded by SMS?',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === undefined ? theme.palette.error.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.success.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Yes" : cell.getValue() === undefined ? "Not Yet" : cell.getValue() === false ? "Not Yet" : "Yes"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.replied,
            header: 'Contacted?',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === undefined ? theme.palette.error.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.success.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Yes" : cell.getValue() === undefined ? "Not Yet" : cell.getValue() === false ? "Not Yet" : "Yes"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.paymentPlan,
            id: "document.paymentPlan",
            enableClickToCopy: true,
            header: 'Payment Plan'
        },
        {
            accessorFn: (row) => row?.document?.bikeUse,
            id: "document.bikeUse",
            enableClickToCopy: true,
            header: 'Bike Usage'
        },
        /* {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.grey.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Inactive"}
                </Box>
            ),
        }, */
        {
            accessorFn: (row) => new Date(row?.document?.createdAt).toLocaleDateString(),
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "document.UID",
            enableClickToCopy: true,
            header: 'ID'
        },
    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Wahu Bike Interest Records',
        showTitle: true
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row, index) => ({
            count: index + 1,
            firstName: row.original?.document?.firstName,
            lastName: row.original?.document?.lastName,
            email: row.original?.document?.email ? row.original?.document?.email : "Not provided",
            phoneNumber: row.original?.document?.phoneNumber ? row.original?.document?.phoneNumber : row.original?.document?.phone ? row.original?.document?.phone : "Not provided",
            bikeUsage: row.original?.document?.bikeUse ? row.original?.document?.bikeUse : "Not provided",
            paymentPlan: row.original?.document.paymentPlan ? row.original?.document?.paymentPlan : "not provided",
            isEmailResponse: row.original?.document?.isEmailResponse ? "emailed" : "not emailed",
            isSMSResponse: row.original?.document?.isSMSResponse ? "texted" : "not texted",
            repliedTo: row.original?.document?.replied ? "contacted" : "not contacted",
            notes: row.original?.document?.note ? row.original?.document?.note.map((addedNote, index) => (
                `${index}. ${addedNote.note}`
            )) : "N/A",
        })));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={actionModalTitle}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<SendCustomEmailForBikeInterestForm
                    initialValues={initialValues}
                    createEditText={createEditText}
                    onChanger={onChangeWorker}
                    bikeInterestID={selectedBikeInterestID}
                    bikeInterestData={selectedBikeInterestData}
                />}
            />
            <SimpleModal
                modal_tittle={actionModalTitle}
                show={showNotesModal}
                close={() => setShowNotesModal(false)}
                size="lg"
                body={<AddNoteToRequestForm
                    initialValues={initialValues}
                    createEditText={createEditText}
                    onChanger={onChangeWorker}
                    requestID={selectedBikeInterestID}
                    requestData={selectedBikeInterestData}
                    requestType={"bikeRequest"}
                />}
            />
            <SimpleModal
                modal_tittle={actionModalTitle}
                show={showSMSModal}
                close={() => setShowSMSModal(false)}
                size="lg"
                body={<SendCustomSMSForBikeInterestForm
                    initialValues={initialValues}
                    createEditText={createEditText}
                    onChanger={onChangeWorker}
                    bikeInterestID={selectedBikeInterestID}
                    bikeInterestData={selectedBikeInterestData}
                />}
            />
            <SimpleModal
                modal_tittle={actionModalTitle}
                show={showBikeInterestActionModal}
                close={() => setShowBikeInterestActionModal(false)}
                size="md"
                body={<RemoveRecordConfirmForm
                    recordID={selectedBikeInterestID}
                    recordData={selectedBikeInterestData}
                    onChanger={onChangeWorker}
                    entityType={entityTypesForDeletionArray[0]}
                    modalBackAction={() => {
                        setShowBikeInterestActionModal(false);
                    }}
                />}
            />
            <div className={`card card-custom ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {isLoading && <BasicLoader size={"sm"} />}
                            {!isLoading && rowCount?.count}
                            {" "}
                            eBike Interest Requests
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage eBike Interest Logs from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10 overflow-auto">

                    <div className="row mb-5">
                        <div className='col p-2'>
                            <div className="bg-light-success px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                    <h2>
                                        {isLoading && <BasicLoader size={"sm"} />}
                                        {!isLoading && (rowCount?.contactedCount ?? 0)}
                                    </h2>
                                </span>
                                <span className="text-success font-weight-bold font-size-h6 mt-2">
                                    # Contacted
                                </span>
                            </div>
                        </div>
                        <div className='col p-2'>
                            <div className="bg-light-warning px-6 py-8 rounded-xl">
                                <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                    <h2>
                                        {isLoading && <BasicLoader size={"sm"} />}
                                        {!isLoading && (rowCount?.contactedCount ? rowCount?.count ? Number(rowCount?.count) - Number(rowCount?.contactedCount) : "N/A" : "N/A")}
                                    </h2>
                                </span>
                                <span
                                    className="text-warning font-weight-bold font-size-h6 mt-2"
                                >
                                    # Pending
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-12">
                            <div className="card card-custom card-stretch gutter-b">
                                <div className="card-header border-0 pt-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label font-weight-bolder text-dark">
                                            Metrics
                                        </span>
                                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                            Monthly - Weekly Metrics
                                        </span>
                                    </h3>
                                    <div className="card-toolbar">
                                        <Tab.Container defaultActiveKey={key}>
                                            <Nav
                                                as="ul"
                                                onSelect={(_key) => setKey(_key)}
                                                className="nav nav-pills nav-pills-sm nav-dark-75"
                                            >
                                                <Nav.Item className="nav-item" as="li">
                                                    <Nav.Link
                                                        eventKey="Monthly"
                                                        className={`nav-link py-2 px-4 ${key === "Monthly" ? "active" : ""}`}
                                                    >
                                                        Monthly
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="nav-item" as="li">
                                                    <Nav.Link
                                                        eventKey="Weekly"
                                                        className={`nav-link py-2 px-4 ${key === "Weekly" ? "active" : ""}`}
                                                    >
                                                        Weekly
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                </div>
                                <div className="card-body pt-2 pb-5 mt-n3">
                                    <div className="bg-light-primary rounded-xl tab-content mt-5" id="myTabTables12">
                                        <div className={`tab-pane fade ${key === "Monthly" ? "show active" : ""}`}>
                                            {key === "Monthly" && (
                                                <>
                                                    <div className="px-6 py-8 rounded-xl">
                                                        {isLoadingAnalytics && <BasicLoader size={"md"} />}
                                                        {!isLoadingAnalytics && <DynamicApexChart
                                                            categories={analyticsData?.monthlyTimeSeriesArray}
                                                            data={analyticsData?.monthlyCountArray}
                                                            exportFilename={"Contact Us Requests(Exported from Wahu OS)"}
                                                            chartTitle={"Contact Us Requests"}
                                                            xAxisName={"MONTH"}
                                                            yAxisName={"# of Requests"}
                                                            yAxisMinVal={0}
                                                            yAxisMaxVal={monthlyMaxYaxisVal}
                                                            horizontalBar={false}
                                                            chartHeight={300}
                                                            labelFontWeight={"bold"}
                                                        />}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className={`tab-pane fade ${key === "Weekly" ? "show active" : ""}`}>
                                            {key === "Weekly" && (
                                                <>
                                                    <div className="px-6 py-8 rounded-xl">
                                                        {isLoadingAnalytics && <BasicLoader size={"md"} />}
                                                        {!isLoadingAnalytics && <DynamicApexChart
                                                            categories={analyticsData?.weeklyTimeSeriesArray}
                                                            data={analyticsData?.weeklyCountArray}
                                                            exportFilename={"Contact Us Requests(Exported from Wahu OS)"}
                                                            chartTitle={"Contact Us Requests"}
                                                            xAxisName={"WEEK"}
                                                            yAxisName={"# of Requests"}
                                                            yAxisMinVal={0}
                                                            yAxisMaxVal={weeklyMaxYaxisVal}
                                                            horizontalBar={false}
                                                            chartHeight={300}
                                                            labelFontSize={"9px"}
                                                            labelFontWeight={"bold"}
                                                        />}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount?.count}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <>

                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="label" className='bold'>Notes: </Typography>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                {row.original.document?.note && row.original.document?.note.map((noteObj, index) =>
                                                    <Grid item xs key={index}>
                                                        <Item>
                                                            <Box sx={{ textAlign: 'left' }}>
                                                                <Typography variant="p">
                                                                    <span className="bold">
                                                                        {`${index + 1}.`}
                                                                    </span>
                                                                    {" "}
                                                                    <span className="bold">
                                                                        {noteObj.note}
                                                                    </span>
                                                                </Typography>
                                                            </Box>
                                                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                                <Grid item xs>
                                                                    <span className="bg-light-primary rounded-lg pl-3 pr-3 text-nowrap">
                                                                        <span className="bold">
                                                                            Date:
                                                                        </span>
                                                                        {" "}
                                                                        {`${new Date(noteObj.dateTime).toUTCString()}`}
                                                                    </span>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <span className="bg-light-primary rounded-lg pl-3 pr-3 text-nowrap">
                                                                        <span className="bold">
                                                                            Adder:
                                                                        </span>
                                                                        {" "}
                                                                        {`${noteObj?.adderName ? noteObj.adderName : "N/A"}`}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Item>
                                                    </Grid>
                                                )}
                                                {!row.original?.document?.note && (
                                                    <>
                                                        <Grid item xs>
                                                            <Item>
                                                                <Box sx={{ textAlign: 'left' }}>
                                                                    <Typography variant="p">
                                                                        No notes added yet
                                                                    </Typography>
                                                                </Box>
                                                            </Item>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Item>
                                    </Grid>
                                </Grid>

                            </>

                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const bikeInterestID = row.original.document?.ID;
                                    setSelectedBikeInterestID(bikeInterestID);

                                    const bikeInterestData = row.original;
                                    setSelectedBikeInterestData(bikeInterestData);
                                    setActionModalTitle(`Send a response email to ${bikeInterestData.document.firstName} - ${bikeInterestData.document.email}`);

                                    const valuesToSet = {
                                        bikeInterestLogID: bikeInterestID,
                                        message: "",
                                        subject: "",
                                        senderID: user.user_data.document.ID,
                                        senderFirstName: user.user_data.document.firstName,
                                        senderLastName: user.user_data.document.lastName,
                                    };
                                    setInitialValues(valuesToSet);

                                    setCreateEditText("Send Email");
                                    setShow(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <ForwardToInboxTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Respond by Email
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const bikeInterestID = row.original.document?.ID;
                                    setSelectedBikeInterestID(bikeInterestID);

                                    const bikeInterestData = row.original;
                                    setSelectedBikeInterestData(bikeInterestData);
                                    setActionModalTitle(`Send a response SMS to ${bikeInterestData.document.firstName} - ${bikeInterestData.document.phone}`);

                                    const valuesToSet = {
                                        bikeInterestLogID: row.original.document.ID,
                                        message: "",
                                        messageHeader: "Wahu",
                                        senderID: user.user_data.document.ID,
                                        senderFirstName: user.user_data.document.firstName,
                                        senderLastName: user.user_data.document.lastName,
                                    };
                                    setInitialValues(valuesToSet);

                                    setCreateEditText("Send SMS");
                                    setShowSMSModal(true);

                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <SmsTwoToneIcon
                                        color='secondary' />
                                </ListItemIcon>
                                Respond by SMS
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={() => {

                                    const bikeInterestID = row.original.document?.ID;
                                    setSelectedBikeInterestID(bikeInterestID);

                                    const bikeInterestData = row.original;
                                    setSelectedBikeInterestData(bikeInterestData);
                                    setActionModalTitle(`Remove eBike Interest Log from ${bikeInterestData.document.firstName} ${bikeInterestData.document.lastName}?`);
                                    setShowBikeInterestActionModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <DeleteTwoToneIcon
                                        style={{ color: "#990000" }}
                                    />
                                </ListItemIcon>
                                Delete Interest Log
                            </MenuItem>,
                            <MenuItem
                                key={3}
                                onClick={async () => {

                                    const bikeInterestID = row.original.document?.ID;
                                    const handleMarkBikeInterestReplied = async () => {

                                        setSettingReply(true);

                                        try {

                                            const response = await markBikeInterestReplied(bikeInterestID);
                                            setSeverity('success');
                                            TableErrorMessage.current = response.data.message;
                                            handleClick();
                                            setReRender(prevState => prevState + 1);

                                            setSettingReply(false);
                                            closeMenu();

                                        } catch (error) {

                                            setSeverity('error');
                                            TableErrorMessage.current = error.response.data.message ?? "could not perform this action at this time";
                                            handleClick();

                                            setSettingReply(false);
                                            closeMenu();

                                        }

                                    };

                                    await handleMarkBikeInterestReplied().then(() => {
                                        closeMenu();
                                    });

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <DoneAllTwoToneIcon
                                        color='success'
                                    />
                                </ListItemIcon>
                                Mark as contacted &nbsp;
                                {settingReply && <BasicLoader size={"sm"} />}
                            </MenuItem>,
                            <MenuItem
                                key={4}
                                onClick={() => {

                                    const bikeInterestID = row.original.document?.ID;
                                    setSelectedBikeInterestID(bikeInterestID);

                                    const bikeInterestData = row.original;
                                    setSelectedBikeInterestData(bikeInterestData);
                                    setActionModalTitle(`Add a note for this request from ${bikeInterestData.document.firstName} - ${bikeInterestData.document.email}`);

                                    const valuesToSet = {
                                        note: ""
                                    };
                                    setInitialValues(valuesToSet);

                                    setCreateEditText("Add Note");
                                    setShowNotesModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <EditNoteIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Add Note
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {
                            const handleDeactivate = () => {
                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await deactivateVehicle(row.original.document.ID);
                                        handleClick();
                                        setSeverity('info');
                                        TableErrorMessage.current = response.data.message;
                                        setReRender(prevState => prevState + 1);
                                    } catch (error) {
                                        handleClick();
                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                    }
                                });
                            };

                            const handleActivate = () => {
                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await activateVehicle(row.original.document.ID);
                                        handleClick();
                                        setSeverity('info');
                                        TableErrorMessage.current = response.data.message;
                                        setReRender(prevState => prevState + 1);
                                    } catch (error) {
                                        handleClick();
                                        setSeverity('error');
                                        TableErrorMessage.current = error.message;
                                    }
                                });
                            };

                            return (

                                <>
                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                        <ButtonGroup
                                            variant="contained"
                                            aria-label="outlined primary button group"
                                            size="small"
                                        >
                                            <Button
                                                color="error"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                onClick={handleDeactivate}
                                                variant="contained"
                                                size="small"
                                            >
                                                Deactivate
                                            </Button>
                                            <Button
                                                color="success"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0}
                                                onClick={handleActivate}
                                                variant="contained"
                                                size="small"
                                            >
                                                Activate
                                            </Button>
                                        </ButtonGroup>
                                        <ButtonGroup
                                            variant="contained"
                                            aria-label="outlined primary button group"
                                            size="small"
                                        >
                                            <Button
                                                color="primary"
                                                onClick={handleExportData}
                                                variant="contained"
                                                size="small"
                                            >
                                                Export This Page
                                            </Button>
                                            <Button
                                                color="primary"
                                                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                variant="contained"
                                                size="small"
                                            >
                                                Export Selected Rows
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </>

                            );
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
