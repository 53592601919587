import { useFormik } from "formik";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { FormAlert } from "../../controls";
import { sendBulkMessages } from "../../../../app/modules/Auth/_redux/authCrud";
import { getStorageItem } from "../../../../app/services/baseService";
import { makeStyles } from '@mui/styles';
import { shallowEqual, useSelector } from "react-redux";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    separator: {
        borderColor: theme.palette.primary,
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.primary}`
    }
}));

export function BulkSmsForm({ onChanger }) {

    const classes = useStyles();
    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });

    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        messageHeader: Yup.string().required(),
        messageSubject: Yup.string(),
        messageBody: Yup.string().required(),
    });

    const initialValues = {
        userIDs: [],
        messageHeader: "Wahu",
        messageBody: "",
        channel: "sms",
        senderID: "",
        senderFirstName: "",
        senderLastName: "",
    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({

        initialValues: initialValues,
        validationSchema: creationSchema,

        onSubmit: (values, { setStatus, setSubmitting }) => {

            const userIDArray = [];
            const driverDataArray = getStorageItem("selectedDriverRows");

            values.senderID = user.user_data.document.ID;
            values.senderFirstName = user.user_data.document.firstName;
            values.senderLastName = user.user_data.document.lastName;

            values.senderLastName = user.user_data.document.lastName;
            values.senderLastName = user.user_data.document.lastName;
            values.senderLastName = user.user_data.document.lastName;

            for (let i = 0; i < driverDataArray.length; i++) {

                const driverData = driverDataArray[i];
                const driverID = driverData.original.document.ID;
                userIDArray.push(driverID);

                if ((1 + i) === driverDataArray.length) {

                    values.userIDs = userIDArray;
                    enableLoading();

                    setTimeout(() => {

                        sendBulkMessages(values).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: <CheckCircleTwoToneIcon />
                                });

                                onChanger();
                                return setStatus(response.data.message);

                            }

                        }).catch((error) => {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'error',
                                icon: <WarningTwoToneIcon />
                            });
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );

                        }).finally(() => {

                            disableLoading();
                            setSubmitting(false);

                            return;

                        });

                    }, 1000);

                }

            }

        }
    });

    return (
        <>

            <Form
                onSubmit={formik.handleSubmit}
                className="mt-2"
            >
                <Form.Group controlId="messageHeader">
                    <Form.Label>
                        From
                    </Form.Label>
                    <Form.Control type="text" rows={4} placeholder="Message" value={formik.values.messageHeader} onChange={formik.handleChange} className={`${getInputClasses("messageHeader")}`} name="messageHeader" {...formik.getFieldProps("messageHeader")} />
                    <Form.Text className="text-muted">
                        Enter your who the message is from eg. Wahu or Wahu Rider Rewards. This is optional
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        From field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.messageHeader && formik.errors.messageHeader ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.messageHeader}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="messageBody">
                    <Form.Label>
                        Message
                    </Form.Label>
                    <Form.Control as={"textarea"} rows={4} placeholder="Message" value={formik.values.messageBody} onChange={formik.handleChange} className={`${getInputClasses("messageBody")}`} name="messageBody" {...formik.getFieldProps("messageBody")} />
                    <Form.Text className="text-muted">
                        Enter your message
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Message field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.messageBody && formik.errors.messageBody ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.messageBody}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>
                {formik.status ? <FormAlert
                    isEnabled={isFormAlertEnabled}
                    severity={alertType.type}
                    message={formik.status}
                    icon={alertType.icon}
                    handleDismiss={handleAlertDismiss}
                /> : null}

                <Button
                    variant="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`font-weight-bold px-9 py-4 my-3`}
                >
                    Send SMS
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );

}