import React from "react";
import { useSubheader } from "../../_wahu/layout";
import {
    UserTransactionsTable
} from '../../_wahu/_partials/widgets';

export const UserTransactionsPage = () => {

    const suhbeader = useSubheader();
    React.useEffect(() => {
        const load = async () => {
            try {
                suhbeader.setTitle("User Transactions Table");
            } catch (error) {
                console.log(error);
            }
        };
        load();
    }, [suhbeader]);

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <UserTransactionsTable customClassName="card-stretch gutter-b" />
                </div>
            </div>
        </>
    );
};
