import React, { useState, useEffect } from 'react';
import {
    Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    hide: {
        display: "none"
    }
}));

export function PdfViewer({ data, fileURL }) {

    const [jobApplicationData, setJobApplicationData] = useState({});
    const [applicationFileURL, setApplicationFileURL] = useState("");

    const classes = useStyles();

    useEffect(() => {

        let isMounted = true;
        isMounted && setJobApplicationData(data);
        isMounted && setApplicationFileURL(fileURL);

        console.log(data);
        console.log(fileURL);

        console.log(applicationFileURL);
        console.log(applicationFileURL);

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-xxl-12 order-1 order-xxl-1">
                        <div className='centerMan mt-5 mb-5'>
                            <a
                                href={fileURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={1}
                            >
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    aria-label="Download"
                                    className={classes.margin}
                                    onClick={() => {
                                        //setShow(true);
                                    }}
                                >
                                    Download File
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
                <iframe
                    title={jobApplicationData.roleTitle}
                    src={`https://docs.google.com/viewer?url=${fileURL}&embedded=true`}
                    style={{ width: "100%", height: "600px" }}
                >
                </iframe>
            </div >
        </>
    );

}