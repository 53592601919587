import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { createTransaction } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls/';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateTransactionForm({ initialValues, createEditText, onChanger, userID }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    // eslint-disable-next-line no-useless-escape
    const phoneRegExp = /^\+?[1-9]\d{1,14}$/;

    const creationSchema = Yup.object().shape({
        email: Yup.string().required(),
        amount: Yup.number().required(),
        currency: Yup.string().required(),
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        phoneNumber: Yup.string().matches(phoneRegExp, 'phone number is not valid!').required(),
        isFirstPayment: Yup.bool(),
        isSubscriptionPayment: Yup.bool(),
        weeksPaid: Yup.number(),
        paymentDate: Yup.date().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (userID && userID !== "")
                    values.userID = userID;
                else
                    values.userID = values.phoneNumber;

                values.paymentDate = new Date(values.paymentDate).getTime();

                if (values.isFirstPayment === "true")
                    values.isFirstPayment = true;
                if (values.isFirstPayment === "false")
                    values.isFirstPayment = false;

                if (values.isSubscriptionPayment === "true")
                    values.isSubscriptionPayment = true;
                if (values.isSubscriptionPayment === "false")
                    values.isSubscriptionPayment = false;

                createTransaction(values).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: <CheckCircleTwoToneIcon />
                        });
                        onChanger();
                        return setStatus(response.data.message);
                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: <WarningTwoToneIcon />
                    });
                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);
                    return onChanger();
                });

            }, 1000);
        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="createTransactionFormAmount">
                    <Form.Label>
                        Amount
                    </Form.Label>
                    <Form.Control type="number" placeholder="300/400" value={formik.values.amount} onChange={formik.handleChange} className={`${getInputClasses("amount")}`} name="amount" {...formik.getFieldProps("amount")} />
                    <Form.Text className="text-muted">
                        Amount in Ghana Cedis
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Amount cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.amount && formik.errors.amount ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.amount}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionFormCurrency">
                    <Form.Label>
                        Currency
                    </Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.currency} onChange={formik.handleChange} className={`${getInputClasses("currency")}`} name="currency" {...formik.getFieldProps("currency")} >
                        <option disabled value="">Select one</option>
                        <option value="GHS">GHS</option>
                        <option value="USD">USD</option>
                        <option value="NGN">NGN</option>
                        <option value="ZAR">ZAR</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Currency Paid In
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Currency cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.currency && formik.errors.currency ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.currency}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionFormEmail">
                    <Form.Label>
                        Email
                    </Form.Label>
                    <Form.Control type="email" placeholder="example@mail.com" value={formik.values.email} onChange={formik.handleChange} className={`${getInputClasses("email")}`} name="email" {...formik.getFieldProps("email")} />
                    <Form.Text className="text-muted">
                        Email Address of Rider
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email address cannot be black
                    </Form.Control.Feedback>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionFormFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="First name" value={formik.values.firstName} onChange={formik.handleChange} className={`${getInputClasses("firstName")}`} name="firstName" {...formik.getFieldProps("firstName")} />
                    <Form.Text className="text-muted">
                        Provide your first name and any other names
                    </Form.Text>
                    <Form.Control.Feedback>Your first name looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        First name / other names cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.firstName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionFormLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last name" value={formik.values.lastName} onChange={formik.handleChange} className={`${getInputClasses("lastName")}`} name="lastName" {...formik.getFieldProps("lastName")} />
                    <Form.Text className="text-muted">
                        Provide your last name / surname
                    </Form.Text>
                    <Form.Control.Feedback>Your last name looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Last name / surname cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.lastName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionFormPaymentDate">
                    <Form.Label>Payment Date</Form.Label>
                    <Form.Control type="datetime-local" placeholder="Payment Date" value={formik.values.paymentDate} onChange={formik.handleChange} className={`${getInputClasses("paymentDate")}`} name="paymentDate" {...formik.getFieldProps("paymentDate")} />
                    <Form.Text className="text-muted">
                        Provide payment date for transaction
                    </Form.Text>
                    <Form.Control.Feedback>Date looks okay!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Payment date cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.paymentDate && formik.errors.paymentDate ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.paymentDate}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionFormPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder="Phone Number" value={formik.values.phoneNumber} onChange={formik.handleChange} className={`${getInputClasses("phoneNumber")}`} name="phoneNumber" {...formik.getFieldProps("phoneNumber")} />
                    <Form.Text className="text-muted">
                        Add country code when inputting phone number
                    </Form.Text>
                    <Form.Control.Feedback>Your phone number looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Phone number cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.phoneNumber}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group controlId="createTransactionIsFirstPayment">
                    <Form.Label>
                        Is First Payment?
                        <span className="text-info">
                            <small>(Applies to rider subscription payments only)</small>
                        </span>
                    </Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.isFirstPayment} onChange={formik.handleChange} className={`${getInputClasses("isFirstPayment")}`} name="isFirstPayment" {...formik.getFieldProps("isFirstPayment")} >
                        <option disabled value="">Select one</option>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Is this a first payment?
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Is first payment cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.isFirstPayment && formik.errors.isFirstPayment ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.isFirstPayment}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionSubscriptionPayment">
                    <Form.Label>
                        Is Subscription Payment?
                        <span className="text-info">
                            <small>(Applies to rider subscription payments only)</small>
                        </span>
                    </Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.isSubscriptionPayment} onChange={formik.handleChange} className={`${getInputClasses("isSubscriptionPayment")}`} name="isSubscriptionPayment" {...formik.getFieldProps("isSubscriptionPayment")} >
                        <option disabled value="">Select one</option>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Is this a subscription payment?
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Is subscription payment cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.isSubscriptionPayment && formik.errors.isSubscriptionPayment ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.isSubscriptionPayment}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="createTransactionFormWeeksPaid">
                    <Form.Label>
                        Weeks Paid
                        <span className="text-info">
                            <small>(Applies to rider subscription payments only)</small>
                        </span>
                    </Form.Label>
                    <Form.Control type="number" placeholder="300" value={formik.values.weeksPaid} onChange={formik.handleChange} className={`${getInputClasses("weeksPaid")}`} name="weeksPaid" {...formik.getFieldProps("weeksPaid")} />
                    <Form.Text className="text-muted">
                        Number of Weeks the Rider is Paying For
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Weeks paid cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.weeksPaid && formik.errors.weeksPaid ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.weeksPaid}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );
}