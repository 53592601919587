/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {

    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    };

    return <div id="kt_header_menu" className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`} {...layoutProps.headerMenuAttributes}>
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>

            {/* <li data-menu-toggle={layoutProps.menuDesktopToggle} aria-haspopup="true" className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/google-material')}`}>
                <NavLink className="menu-link menu-toggle" to="/google-material">
                    <span className="menu-text">Material UI</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material/inputs')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link menu-toggle" to="/google-material/inputs">
                                <span className="svg-icon menu-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/PenAndRuller.svg")} />
                                </span>
                                <span className="menu-text">
                                    Inputs
                                </span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                <ul className="menu-subnav">
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/autocomplete')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/autocomplete">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Autocomplete</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/buttons')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/buttons">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Buttons</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/checkboxes')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/checkboxes">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Checkboxes</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/pickers')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/pickers">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Pickers</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/radio-buttons')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/radio-buttons">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Radio Buttons</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/selects')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/selects">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Selects</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/switches')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/switches">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Switches</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/text-fields')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/text-fields">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Text Fields</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/inputs/transfer-list')}`}>
                                        <NavLink className="menu-link" to="/google-material/inputs/transfer-list">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Transfer List</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/google-material/navigation')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link menu-toggle" to="/google-material/navigation">
                                <span className="svg-icon menu-icon">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-from-left.svg")} />
                                </span>
                                <span className="menu-text">
                                    Navigation
                                </span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                <ul className="menu-subnav">
                                    <li className={`menu-item ${getMenuItemActive('/google-material/navigation/bottom-navigation')}`}>
                                        <NavLink className="menu-link" to="/google-material/navigation/bottom-navigation">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Bottom Navigation</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/navigation/breadcrumbs')}`}>
                                        <NavLink className="menu-link" to="/google-material/navigation/breadcrumbs">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Breadcrumbs</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/navigation/drawer')}`}>
                                        <NavLink className="menu-link" to="/google-material/navigation/drawer">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Drawers</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/navigation/links')}`}>
                                        <NavLink className="menu-link" to="/google-material/navigation/links">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">NavLinks</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/navigation/menus')}`}>
                                        <NavLink className="menu-link" to="/google-material/navigation/menus">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Menus</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/navigation/steppers')}`}>
                                        <NavLink className="menu-link" to="/google-material/navigation/steppers">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Steppers</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/google-material/navigation/tabs')}`}>
                                        <NavLink className="menu-link" to="/google-material/navigation/tabs">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Tabs</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li> */}

            {/* <li data-menu-toggle={layoutProps.menuDesktopToggle} aria-haspopup="true" className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/custom')}`}>
                <NavLink className="menu-link menu-toggle" to="/custom">
                    <span className="menu-text">Custom</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/e-commerce')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link menu-toggle" to="/e-commerce">
                                <span className="menu-text">
                                    eCommerce
                                </span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                <ul className="menu-subnav">
                                    <li className={`menu-item ${getMenuItemActive('/e-commerce/customers')}`}>
                                        <NavLink className="menu-link" to="/e-commerce/customers">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Customers</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/e-commerce/products')}`}>
                                        <NavLink className="menu-link" to="/e-commerce/products">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Products</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/builder')}`}>
                            <NavLink className="menu-link" to="/builder">
                                <span className="menu-text">Layout Builder</span>
                            </NavLink>
                        </li>
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/error')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link menu-toggle" to="/error">
                                <span className="menu-text">
                                    Error Pages
                                </span>
                                <i className="menu-arrow" />
                            </NavLink>
                            <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                                <ul className="menu-subnav">
                                    <li className={`menu-item ${getMenuItemActive('/error/error-v1')}`}>
                                        <NavLink className="menu-link" to="/error/error-v1">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Error 1</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/error/error-v2')}`}>
                                        <NavLink className="menu-link" to="/error/error-v2">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Error 2</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/error/error-v3')}`}>
                                        <NavLink className="menu-link" to="/error/error-v3">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Error 3</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/error/error-v4')}`}>
                                        <NavLink className="menu-link" to="/error/error-v4">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Error 4</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/error/error-v5')}`}>
                                        <NavLink className="menu-link" to="/error/error-v5">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Error 5</span>
                                        </NavLink>
                                    </li>
                                    <li className={`menu-item ${getMenuItemActive('/error/error-v6')}`}>
                                        <NavLink className="menu-link" to="/error/error-v6">
                                            <i className="menu-bullet menu-bullet-dot"><span /></i>
                                            <span className="menu-text">Error 6</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li> */}

        </ul>
    </div>;
}
