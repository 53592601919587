import React, { useEffect, useRef, useState } from "react";
import { PlaygroundWidget } from "../widgets/tiles/PlaygroundWidget";
import { AreaChart } from "../charts/AreaChart";
import { FLEET_MANAGING_BASE_URL } from "../../../app/services/baseService";
import axios from "axios";
import { CollectionsWidget } from "../widgets/tiles/CollectionsWidget";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import {
    IconButton,
    Snackbar,
    Grid,
    Alert
} from '@mui/material';
import { DashboardDefault } from "./DashboardDefault";
import BasicLoader from "../loaders/SimpleLoader";
import { CardTileWidget } from "../widgets/tiles/CardTileWidget";
import { SimpleModal } from "../widgets/modals/SimpleModal";
import { WidgetBody } from "../widgets/modal-body/WidgetBody";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `80px`,
        width: `80px`
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 1,
        width: 50,
        height: 50,
    }
}));

export function DashboardTest() {

    const [bikesProducedData, setBikesProducedData] = useState([]);
    const [activeSubscriptionData, setActiveSubscriptionData] = useState([]);
    const [activationsData, setActivationsData] = useState([]);
    const [readyBikesData, setReadyBikesData] = useState([]);
    const [soldBikesData, setSoldBikesData] = useState([]);
    const [inactiveBikesData, setInactiveBikesData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [openModal, setOpenModal] = useState(false);

    const [isActiveSubscriptionLoading, setIsActiveSubscriptionLoading] = useState(false);
    const [activeSubscriptionsMonthlyData, setActiveSubscriptionsMonthlyData] = useState({
        yData: "",
        xData: "",
    });
    const [activeSubscriptionsWeeklyData, setActiveSubscriptionsWeeklyData] = useState({
        yData: "",
        xData: "",
    });
    const [bikesProducesLoading, setBikesProducesLoading] = useState(false);
    const [bikesProducesMonthlyData, setBikesProducesMonthlyData] = useState({
        yData: "",
        xData: "",
    });
    const [bikesProducesWeeklyData, setBikesProducesWeeklyData] = useState({
        yData: "",
        xData: "",
    });
    const [soldBikesLoading, setSoldBikesLoading] = useState(false);
    const [soldBikesMonthlyData, setSoldBikesMonthlyData] = useState({
        yData: "",
        xData: "",
    });
    const [soldBikesWeeklyData, setSoldBikesWeeklyData] = useState({
        yData: "",
        xData: "",
    });
    const [inactiveBikesLoading, setInactiveBikesLoading] = useState(false);
    const [inactiveBikesMonthlyData, setInactiveBikesMonthlyData] = useState({
        yData: "",
        xData: "",
    });
    const [inactiveBikesWeeklyData, setInactiveBikesWeeklyData] = useState({
        yData: "",
        xData: "",
    });

    const DashboardMessage = useRef();
    const classes = useStyles();

    function handleSnackbarClose() {
        setOpenSnackbar(false);
    }

    const nameOfPeriodicals = [
        {
            title: "This Week",
            value: "This Week",
            state: true,
        },
        {
            title: "Previous Week",
            value: "Previous Week",
            state: true,

        },
        {
            title: "This Month",
            value: "This Month",
            state: true,
        },
        {
            title: "Previous Month",
            value: "Previous Month",
            state: true,
        },
        {
            title: "This Quarter",
            value: "This Quarter",
            state: true,
        },
        {
            title: "Previous Quarter",
            value: "Previous Quarter",
            state: true,
        },
        {
            title: "This Year",
            value: "This Year",
            state: true,
        },
        {
            title: "Previous Year",
            value: "Previous Year",
            state: true,
        },
        {
            title: "Year to Date",
            value: "Year to Date",
            state: true,
        }
    ];

    const nameOfPeriodicalsAlt = [
        {
            title: "This Month",
            value: "This Month",
            state: true,
        },
        {
            title: "Previous Month",
            value: "Previous Month",
            state: true,
        },
        {
            title: "Last 3 Months",
            value: "This Quarter",
            state: true,
        },
        {
            title: "Last 6 Months",
            value: "Previous Quarter",
            state: true,
        },
        {
            title: "This Year",
            value: "This Year",
            state: true,
        },
        {
            title: "Last Year",
            value: "Previous Year",
            state: true,
        },
        {
            title: "Year to Date",
            value: "Year to Date",
            state: true,
        }
    ];

    useEffect(() => {

        let isMounted = true;

        const fetchBikesProducedData = async () => {

            setBikesProducesLoading(true);
            const url = new URL('/counters/vehicles/getVehicleCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setBikesProducedData(data);
                setBikesProducesLoading(false);

                setBikesProducesMonthlyData({
                    yData: data.monthlyCountArray,
                    xData: data.monthlyTimeSeriesArray,
                });
                setBikesProducesWeeklyData({
                    yData: data.weeklyCountArray,
                    xData: data.weeklyTimeSeriesArray,
                });

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    DashboardMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                DashboardMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        const activeSubscription = async () => {

            setIsActiveSubscriptionLoading(true);
            const url = new URL('/counters/drivers/getActiveDriverCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                // console.log(data);
                isMounted && setActiveSubscriptionData(data);

                setActiveSubscriptionsMonthlyData({
                    yData: data.monthlyCountArray,
                    xData: data.monthlyTimeSeriesArray,
                });

                setActiveSubscriptionsWeeklyData({
                    yData: data.weeklyCountArray,
                    xData: data.weeklyTimeSeriesArray,
                });

                isMounted && setIsActiveSubscriptionLoading(false);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    DashboardMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);
                    setIsActiveSubscriptionLoading(false);

                    return;

                }

                DashboardMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;

                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);
                setIsActiveSubscriptionLoading(false);

                return;

            }

        };

        const activations = async () => {

            if (!activationsData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/counters/vehicles/getAssignedVehiclesCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                // console.log(data);
                isMounted && setActivationsData(data);


            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    DashboardMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                DashboardMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        const readyBikes = async () => {

            if (!readyBikesData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('counters/vehicles/ready-vehicle-count', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                // console.log(data);
                isMounted && setReadyBikesData(data);


            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    DashboardMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                DashboardMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };
        const soldBikes = async () => {

            if (!soldBikesData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('counters/vehicles/getSoldVehicleCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                // console.log(data);
                isMounted && setSoldBikesData(data);
                setSoldBikesLoading(false);

                setSoldBikesMonthlyData({
                    yData: data.monthlyCountArray,
                    xData: data.monthlyTimeSeriesArray,
                });
                setSoldBikesWeeklyData({
                    yData: data.weeklyCountArray,
                    xData: data.weeklyTimeSeriesArray,
                });


            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    DashboardMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                DashboardMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };
        const inactiveBikes = async () => {

            if (!inactiveBikesData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('counters/vehicles/getInActiveVehiclesCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                // console.log(data);
                isMounted && setInactiveBikesData(data);
                setInactiveBikesLoading(false);

                setInactiveBikesMonthlyData({
                    yData: data.monthlyCountArray,
                    xData: data.monthlyTimeSeriesArray,
                });
                setInactiveBikesWeeklyData({
                    yData: data.weeklyCountArray,
                    xData: data.weeklyTimeSeriesArray,
                });


            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    DashboardMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                DashboardMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchBikesProducedData();
        activations();
        readyBikes();
        activeSubscription();
        soldBikes();
        inactiveBikes();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        reRender
    ]);

    const monthlyData = {
        xData: ["Feb 2023", "Mar 2023", "Apr 2023", "May 2023", "Jun 2023", "Jul 2023", "Aug 2023", "Sep 2023", "Oct 2023", "Nov 2023", "Dec 2023", "Jan 2024", "Feb 2024", "Mar 2024"],
        yData: [15, 10, 25, 20, 35, 40, 15, 30, 35, 20, 45, 20, 5, 10]
    };

    const weeklyData = {
        xData: ["Week 1", "Week 2", "Week 3", "Week 4"],
        yData: [5, 3, 0, 10]
    };

    // /** eBikes Produced:: START */
    // console.log(bikesProducedData);
    // const bikesProducedMonthlyData = {};
    // bikesProducedMonthlyData.yData = bikesProducedData?.monthlyCountArray;
    // bikesProducedMonthlyData.xData = bikesProducedData?.monthlyTimeSeriesArray;

    // console.log(bikesProducedMonthlyData);

    // const bikesProducedWeeklyData = {};
    // bikesProducedWeeklyData.yData = bikesProducedData.weeklyCountArray;
    // bikesProducedWeeklyData.xData = bikesProducedData.weeklyTimeSeriesArray;
    // /** eBikes Produced:: END */

    /** Activations:: START */
    const activationsMonthlyData = {};
    activationsMonthlyData.yData = activationsData.monthlyCountArray;
    activationsMonthlyData.xData = activationsData.monthlyTimeSeriesArray;

    const activationsWeeklyData = {};
    activationsWeeklyData.yData = activationsData.weeklyCountArray;
    activationsWeeklyData.xData = activationsData.weeklyTimeSeriesArray;
    /** Activations:: START */

    /** Ready eBikes:: START */
    const readyBikesMonthlyData = {};
    readyBikesMonthlyData.yData = readyBikesData.monthlyCountArray;
    readyBikesMonthlyData.xData = readyBikesData.monthlyTimeSeriesArray;

    const readyBikesWeeklyData = {};
    readyBikesWeeklyData.yData = readyBikesData.weeklyCountArray;
    readyBikesWeeklyData.xData = readyBikesData.weeklyTimeSeriesArray;
    /** Ready eBikes:: START */

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {DashboardMessage.current}
                </Alert>
            </Snackbar>
            <SimpleModal
                show={openModal}
                size='lg'
                modal_tittle={"eBikes Produced"}
                close={() => { setOpenModal(false); }}
                body={<WidgetBody />}
            />
            <DashboardDefault />
            {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4" >
                <Grid item xs={12} sm={6} >
                    {!bikesProducesLoading && <PlaygroundWidget
                        chartID="chart1"
                        weeklyData={bikesProducesWeeklyData}
                        monthlyData={bikesProducesMonthlyData}
                        title={"eBikes Produced"}
                        count={200}
                        nameOfPeriodicals={nameOfPeriodicals}
                    />
                    }
                    {bikesProducesLoading && <BasicLoader size={'lg'} />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PlaygroundWidget
                        chartID="chart2"
                        weeklyData={readyBikesWeeklyData}
                        monthlyData={readyBikesMonthlyData}
                        title={"Ready eBikes"}
                        nameOfPeriodicals={nameOfPeriodicals}
                    />
                </Grid>
            </Grid >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4" >
                <Grid item xs={12} sm={6}>
                    {
                        !inactiveBikesLoading && <PlaygroundWidget
                            chartID="chart3"
                            weeklyData={weeklyData}
                            monthlyData={monthlyData}
                            title={"Inactive eBikes"}
                            nameOfPeriodicals={nameOfPeriodicals}
                        />
                    }
                    {inactiveBikesLoading && <BasicLoader size={'lg'} />}

                </Grid>
                <Grid item xs={12} sm={6}>
                    {!isActiveSubscriptionLoading && <PlaygroundWidget
                        chartID="chart4"
                        weeklyData={activeSubscriptionsWeeklyData}
                        monthlyData={activeSubscriptionsMonthlyData}
                        title={"Active Subscriptions"}
                        nameOfPeriodicals={nameOfPeriodicals}
                    />}
                    {isActiveSubscriptionLoading && <BasicLoader size={'lg'} />}
                </Grid>
            </Grid >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                <Grid item xs={12} sm={6}>
                    {
                        !soldBikesLoading && <PlaygroundWidget
                            chartID="chart5"
                            yAxisData={[25, 30, 22, 27, 35]}
                            weeklyData={soldBikesWeeklyData}
                            monthlyData={soldBikesMonthlyData}
                            title={"eBikes sold"}
                            nameOfPeriodicals={nameOfPeriodicals}
                        />
                    }
                    {soldBikesLoading && <BasicLoader size={'lg'} />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PlaygroundWidget
                        chartID="chart6"
                        weeklyData={activationsWeeklyData}
                        monthlyData={activationsMonthlyData}
                        title={"Activations"}
                        nameOfPeriodicals={nameOfPeriodicals}
                    />
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                <Grid item xs={12} sm={6}>
                    <AreaChart title={"Kilometers Ridden"} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AreaChart title={"CO2 saved"} />
                </Grid>
            </Grid> */}
            {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                <Grid item xs={6} >
                    <CollectionsWidget
                        title={"Collections Rate"}
                    />
                </Grid>
            </Grid> */}
            {/* <DashboardDefault></DashboardDefault> */}
        </>
    );
}