import React, { useEffect, useRef, useState } from 'react';
import {
    Grid,
    Button
} from '@mui/material';
import { FormAlert } from '../../controls';
import {
    removeBikeInterestLog,
    removeOrganizationRecord,
    removeConsultationRequestLog,
    removeContactUsRequestLog,
    removeInventoryPart,
    removeDriverAccount,
    deleteCountry
} from '../../../../app/modules/Auth/_redux/authCrud';
import { entityTypesForDeletionArray } from '../../../_helpers/Interfaces';
import BasicLoader from '../../loaders/SimpleLoader';
import { shallowEqual, useSelector } from "react-redux";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function RemoveRecordConfirmForm({ recordID, recordData, onChanger, entityType, modalBackAction }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(false);
    const [selectedRecordID, setSelectedRecordID] = useState("");
    const [selectedRecordData, setSelectedRecordData] = useState();

    const TableErrorMessage = useRef();

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const handleEntityDeletion = async (deletionFunction) => {

        enableLoading();

        if (entityType === entityTypesForDeletionArray[8]) {

            return await deletionFunction(selectedRecordID, user.user_data.document.ID).then((response) => {

                setAlertType({
                    type: 'success',
                    icon: <CheckCircleTwoToneIcon />
                });
                TableErrorMessage.current = response.data.message;
                setFormAlertEnabled(true);
                onChanger();

                setTimeout(() => {
                    disableLoading();
                    modalBackAction();
                }, 2000);

            }).catch((error) => {

                setAlertType({
                    type: 'danger',
                    icon: <WarningTwoToneIcon />
                });
                TableErrorMessage.current = error.response.data.message ?? "an error occurred while performing the deletion action";
                setFormAlertEnabled(true);

                return disableLoading();

            });

        }

        return await deletionFunction(selectedRecordID).then((response) => {

            setAlertType({
                type: 'success',
                icon: <CheckCircleTwoToneIcon />
            });
            TableErrorMessage.current = response.data.message;

            setFormAlertEnabled(true);
            onChanger();

            setTimeout(() => {
                disableLoading();
                modalBackAction();
            }, 2000);

        }).catch((error) => {

            setAlertType({
                type: 'danger',
                icon: <WarningTwoToneIcon />
            });
            TableErrorMessage.current = error?.response?.data?.message ?? "an error occurred while performing the deletion action";
            setFormAlertEnabled(true);

            return disableLoading();

        });

    };

    useEffect(() => {

        let isMounted = true;
        enableLoading();

        isMounted && setSelectedRecordID(recordID);
        isMounted && setSelectedRecordData(recordData);
        disableLoading();

        return () => isMounted = false;

    }, [recordData, recordID]);

    return (
        <>

            <div className="form-group row">
                <div className="col-lg-12 col-xl-12">
                    <div className="centerMan">
                        <div className="text-center font-size-lg font-weight-bold">
                            <span>
                                This action is irreversible
                                <br />
                                Are you sure you want to delete this record?
                            </span>
                            <br />
                            <small>
                                Entity ID:
                                {" "}
                                {selectedRecordID}
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className='centerMan'>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                modalBackAction();
                            }}
                            disabled={loading}
                        >
                            No
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='centerMan'>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={async () => {

                                enableLoading();

                                switch (entityType) {
                                    case entityTypesForDeletionArray[0]:
                                        handleEntityDeletion(removeBikeInterestLog);
                                        break;

                                    case entityTypesForDeletionArray[1]:
                                        handleEntityDeletion(removeOrganizationRecord);
                                        break;

                                    case entityTypesForDeletionArray[5]:
                                        handleEntityDeletion(removeConsultationRequestLog);
                                        break;

                                    case entityTypesForDeletionArray[6]:
                                        handleEntityDeletion(removeContactUsRequestLog);
                                        break;

                                    case entityTypesForDeletionArray[7]:
                                        handleEntityDeletion(removeInventoryPart);
                                        break;

                                    case entityTypesForDeletionArray[8]:
                                        handleEntityDeletion(removeDriverAccount);
                                        break;

                                    case entityTypesForDeletionArray[9]:
                                        handleEntityDeletion(deleteCountry);
                                        break;

                                    default:
                                        disableLoading();
                                        setAlertType({
                                            type: 'danger',
                                            icon: <WarningTwoToneIcon />
                                        });
                                        TableErrorMessage.current = "could not find a matching entity type";
                                        break;
                                }

                            }}
                            disabled={loading}
                        >
                            Yes
                            {" "}
                            {loading && <BasicLoader size={"sm"} />}
                        </Button>
                    </div>
                </Grid>
            </Grid>

            <div className="mt-4">
                <FormAlert
                    isEnabled={isFormAlertEnabled}
                    severity={alertType.type}
                    message={TableErrorMessage.current}
                    icon={alertType.icon}
                    handleDismiss={handleAlertDismiss}
                />
            </div>

        </>
    );
}