import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import { DriverTransactions } from "../../../../_wahu/_partials/widgets/advance-tables/DriverTransactions";
import { DriverVehicleMetricsPage } from "../../../pages/DriverVehicleMetricsPage";
import { DriverOnboardingQueue } from "./DriverOnboardingQueue";
import BasicLoader from "../../../../_wahu/_partials/loaders/SimpleLoader";

export function DriverActionsTabs({ className, driverDetails, onChangeFunction, isChanger }) {

    const [key, setKey] = useState("Onboarding");
    const [specDriverData, setSpecDriverData] = useState(null);

    useEffect(() => {

        let isMounted = true;

        isMounted && setSpecDriverData(driverDetails);

        return () => {

            isMounted = false;
            setSpecDriverData(null);

        };

    }, [driverDetails]);

    return (

        <>
            {!specDriverData && <div className="centerMan">
                <BasicLoader
                    size={"lg"}
                />
            </div>}
            {specDriverData && <div className="row">
                <div className="col-lg-12">
                    <div className={`card card-custom no-border-shadow rounded-xl ${className}`}>
                        <div className="card-header border-0 pt-5">
                            <div className="card-toolbar">
                                <Tab.Container defaultActiveKey={key}>
                                    <Nav
                                        as="ul"
                                        onSelect={(_key) => setKey(_key)}
                                        className="nav nav-pills nav-pills-sm nav-dark-75"
                                    >
                                        <Nav.Item className="nav-item border rounded" as="li">
                                            <Nav.Link
                                                eventKey="Onboarding"
                                                className={`nav-link py-2 px-4 ${key === "Onboarding" ? "active" : ""}`}
                                            >
                                                Onboarding
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item border rounded" as="li">
                                            <Nav.Link
                                                eventKey="Subscription"
                                                className={`nav-link py-2 px-4 ${key === "Subscription" ? "active" : ""}`}
                                            >
                                                Subscription
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item border rounded" as="li">
                                            <Nav.Link
                                                eventKey="Metrics"
                                                className={`nav-link py-2 px-4 ${key === "Metrics" ? "active" : ""}`}
                                            >
                                                Metrics
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Tab.Container>
                            </div>
                        </div>
                        <div className="card-body pt-2 pb-0 mt-n3">
                            <div className={`tab-content mt-5`} id="myTabTables12">
                                {/* begin::Tap pane Onboarding Queue */}
                                <div className={`tab-pane fade ${key === "Onboarding" ? "show active" : ""}`}>
                                    {key === "Onboarding" && <DriverOnboardingQueue
                                        driverDetails={specDriverData}
                                        onChangeFunction={onChangeFunction}
                                        isChanger={isChanger}
                                    />}
                                </div>
                                {/* end::Tap pane Onboarding Queue */}

                                {/* begin::Tap pane Transactions */}
                                <div className={`tab-pane fade ${key === "Subscription" ? "show active" : ""}`}>
                                    {key === "Subscription" && <DriverTransactions
                                        driverID={specDriverData?.user_data?.document?.ID}
                                        driverName={`${specDriverData?.user_data?.document?.firstName} ${specDriverData?.user_data?.document?.lastName}`}
                                        driverData={specDriverData}
                                    />}
                                </div>
                                {/* end::Tap pane Transactions */}

                                {/* begin::Tap pane Driving Metrics */}
                                <div className={`tab-pane fade ${key === "Metrics" ? "show active" : ""}`}>
                                    {key === "Metrics" && <DriverVehicleMetricsPage
                                        driverVehicleID={specDriverData?.user_data?.document?.vehicles ? specDriverData?.user_data?.document?.vehicles[0] : []}
                                        selectedDriverName={`${specDriverData?.user_data?.document?.firstName} ${specDriverData?.user_data?.document?.lastName}`}
                                        selectedDriverData={specDriverData}
                                        driverID={specDriverData?.user_data?.document?.ID}
                                    />}
                                </div>
                                {/* end::Tap pane Driving Metrics */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>

    );
}
