import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { createVehicleCohort } from '../../../../app/modules/Auth/_redux/authCrud';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { FormAlert } from '../../controls';
import BasicLoader from '../../loaders/SimpleLoader';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateEditVehicleCohortForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, vehicleID }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(false);

    const [isLoadingVehicleTypes, setIsLoadingVehicleTypes] = useState(true);
    const [isFetchingVehicleTypeErrored, setIsFetchingVehicleTypeErrored] = useState(false);

    const [vehicleTypeData, setVehicleTypeData] = useState([]);

    const widgetErrorMessage = useRef();

    const creationSchema = Yup.object().shape({
        creationDate: Yup.string().required(),
        name: Yup.string().required(),
        vehicleType: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    values.vehicles = [];
                    values.creationDate = new Date(values.creationDate).getTime();

                    return createVehicleCohort(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setAlertType({
                                type: 'success',
                                icon: <CheckCircleTwoToneIcon />
                            });
                            setStatus(response.data.message);
                            setFormAlertEnabled(true);

                            return onChanger();

                        }

                    }).catch((error) => {

                        setAlertType({
                            type: 'danger',
                            icon: <WarningTwoToneIcon />
                        });
                        setStatus(
                            error.response.data.message
                            ??
                            error.response.data?.error?.code
                            ??
                            'an error occurred, please try again later'
                        );
                        setFormAlertEnabled(true);
                        disableLoading();

                        return onChanger();

                    });

                }

                if (editState === true) {
                    return;
                }

            }, 1000);
        },
    });

    const makeOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.type}
        </option>;
    };

    useEffect(() => {

        let isMounted = true;

        const fetchVehicleTypeData = async () => {

            const url = new URL('/vehicles/vehicleTypes/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                setVehicleTypeData(data.data);
                setIsLoadingVehicleTypes(false);

            } catch (error) {

                formik.setStatus(error?.response?.data?.message ? `error loading vehicle cohorts: ${error?.response?.data?.message}` : `error loading vehicle cohorts ${error?.message}`);
                widgetErrorMessage.current = error?.response?.data?.message ? `error loading vehicle cohorts: ${error?.response?.data?.message}` : `error loading vehicle cohorts ${error?.message}`;

                setIsFetchingVehicleTypeErrored(true);
                setAlertType({
                    type: 'danger',
                    icon: <WarningTwoToneIcon />
                });
                setFormAlertEnabled(true);
                setIsLoadingVehicleTypes(false);

                return onChanger();

            }

        };

        isMounted && fetchVehicleTypeData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="creationDate">
                    <Form.Label>Cohort Creation Date</Form.Label>
                    <Form.Control type="date" placeholder="Cohort Start Date" value={formik.values.creationDate} onChange={formik.handleChange} className={`${getInputClasses("creationDate")}`} name="creationDate" {...formik.getFieldProps("creationDate")} />
                    <Form.Text className="text-muted">
                        Cohort Creation Date
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Cohort creation date cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.creationDate && formik.errors.creationDate ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.creationDate}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="name">
                    <Form.Label>Cohort Name (Identity)</Form.Label>
                    <Form.Control type="text" value={formik.values.name} onChange={formik.handleChange} className={`${getInputClasses("name")}`} name="name" {...formik.getFieldProps("name")} />
                    <Form.Text className="text-muted">
                        Add a name to identify this cohort
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Cohort name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.name}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleType">
                    <Form.Label>Vehicle Type</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.vehicleType} onChange={formik.handleChange} className={`${getInputClasses("vehicleType")}`} name="vehicleType" {...formik.getFieldProps("vehicleType")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        {!isLoadingVehicleTypes && !isFetchingVehicleTypeErrored && vehicleTypeData.map(makeOption)}
                    </Form.Control>
                    {isLoadingVehicleTypes && <BasicLoader size="sm" />}
                    {isFetchingVehicleTypeErrored && (<>
                        <small className="text-danger">
                            <ErrorTwoToneIcon color="error" />
                            {" "}
                            {widgetErrorMessage.current}
                        </small>
                    </>)}
                    <Form.Text className="text-muted">
                        Vehicle type, eg.eBike
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle type selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.vehicleType && formik.errors.vehicleType ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.vehicleType}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form>

        </>
    );
}