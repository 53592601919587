import React from 'react';
import { useSubheader } from '../../_wahu/layout';
import { LabourUnitsTable } from '../../_wahu/_partials/widgets/advance-tables/LabourUnitsTable';

export const LabourUnitsPage = () => {

    const subheader = useSubheader();

    React.useEffect(() => {

        const load = async () => {
            try {
                subheader.setTitle("Labour Units Table");
            } catch (error) {
                console.log(error);
            }
        };

        load();

    }, [subheader]);

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <LabourUnitsTable className="card-stretch gutter-b" />
                </div>
            </div>
        </>
    );

};
