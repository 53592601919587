import React from 'react';
import {
    Button,
    Snackbar,
    IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
//import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

/* function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
} */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export function DashboardSnackbar(props) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={props.open}
                autoHideDuration={props.autoHideDuration}
                onClose={props.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={props.handleClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
                message={<span id="message-id">
                    {props.message}
                </span>}
            >
                {/* <Alert
                    onClose={handleClose}
                    severity={severity}
                >
                    {<span id="message-id">{message}</span>}
                </Alert> */}
            </Snackbar>
        </div>
    );
}