import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { createLabourUnit, editLabourUnit } from "../../../../app/modules/Auth/_redux/authCrud";
import { Button, Form, FormGroup } from "react-bootstrap";
import { FormAlert } from "../../controls";
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

export function CreateEditLabourUnitConfigForm({ initialValues, onChanger, editState, createEditText, fieldDisabled, labourUnitId }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [categoryData, setCategoryData] = useState([]);
    const [categoryDataLoading, setCategoryDataLoading] = useState(true);


    const creationSchema = Yup.object().shape({
        title: Yup.string().required(),
        maxLabourUnit: Yup.number().required(),
        minLabourUnit: Yup.number().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            enableLoading();
            setTimeout(
                () => {
                    if (!editState) {
                        createLabourUnit(values).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: <CheckCircleTwoToneIcon />
                                });
                                onChanger();
                                return setStatus(response.data.message);
                            }
                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'error',
                                icon: <WarningTwoToneIcon />
                            });

                            onChanger();
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );
                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);

                            return;
                        });
                        return;
                    }
                    if (editState === true) {

                        const editPayload = {
                            title: values.title,
                            maxLabourUnit: values.maxLabourUnit,
                            minLabourUnit: values.minLabourUnit
                        };

                        editLabourUnit(editPayload, labourUnitId).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: <CheckCircleTwoToneIcon />
                                });
                                onChanger();
                                return setStatus(response.data.message);
                            }

                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'error',
                                icon: <WarningTwoToneIcon />
                            });

                            onChanger();
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );
                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);
                            return;
                        });
                        return;
                    }
                }, 3000
            );

        }
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <FormGroup controlId='subCategoryName'>
                    <Form.Label>
                        Title
                    </Form.Label>
                    <Form.Control type="text" placeholder="e.g LEVEL 1" value={formik.values.title} onChange={formik.handleChange} className={`${getInputClasses("title")}`} name="title" {...formik.getFieldProps("title")} />
                    <Form.Text>
                        Title of labour unit configurations
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Title of labour of unit configurations cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.title && formik.errors.title ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.title}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId='subCategoryName'>
                    <Form.Label>
                        Minimum Labour Unit Count
                    </Form.Label>
                    <Form.Control type="number" placeholder="1" value={formik.values.minLabourUnit} onChange={formik.handleChange} className={`${getInputClasses("minLabourUnit")}`} name="minLabourUnit" {...formik.getFieldProps("minLabourUnit")} />
                    <Form.Text>
                        One labour unit count = 15 mins
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Minimum labour unit count cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.minLabourUnit && formik.errors.minLabourUnit ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.minLabourUnit}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId='subCategoryName'>
                    <Form.Label>
                        Maximum Labour Unit Count
                    </Form.Label>
                    <Form.Control type="number" placeholder="2" value={formik.values.maxLabourUnit} onChange={formik.handleChange} className={`${getInputClasses("maxLabourUnit")}`} name="maxLabourUnit" {...formik.getFieldProps("maxLabourUnit")} />
                    <Form.Text>
                        One labour unit count = 15 mins
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Maximum labour unit count cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.maxLabourUnit && formik.errors.maxLabourUnit ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.maxLabourUnit}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} severity={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}